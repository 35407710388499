import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-project-context',
  templateUrl: './project-context.component.html',
  styleUrls: ['./project-context.component.sass']
})
export class ProjectContextComponent implements OnInit {


  constructor(private http:HttpClient,private readonly changeDetector: ChangeDetectorRef,private toastr:ToastrService) { }

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  open= false
  newTemplate:any
  @Input() project:any
  username = localStorage.getItem("userId")
  readonly items = ['Share Project As Template','Complete/Archive Project']


  @Output() loading: EventEmitter<any> =   new EventEmitter();
  @Output() hide: EventEmitter<any> =   new EventEmitter();
  
  ngOnInit(): void {
  }

  MenuItem(name:string){
    if(name=="Share Project As Template"){
      this.createTemplate()
    }

    if(name=="Complete/Archive Project"){
      this.project.complete = true
      this.project.archived = true
      this.updateProject()
    }
  }


  createTemplate(){
    //console.log("update properly")
    this.newTemplate = this.http.get(this.prod_api+"/templates/create?id="+this.project.id+"&userId="+this.username).subscribe(data=>{
      this.newTemplate = data
      this.changeDetector.detectChanges();
      //console.log(this.newTemplate)
      this.toastr.success("Successfully Created Template")
    },err=>{
      //console.log(err)
      this.toastr.error("Failed To Created Template")
    })
  }

  updateProject(){
    this.http.put(this.prod_api+"/projects/update",this.project).subscribe(data=>{
      //console.log("this project was updated")
    })
  }

  deleteProject(){
    this.loading.emit()
    //console.log(this.project)
    this.http.delete(this.prod_api+"/projects/delete?ProjectID="+this.project.id).subscribe((data)=>{
      this.project = data
      this.loading.emit()
      this.hide.emit()
      this.changeDetector.detectChanges();
    },err=>{
      //console.log(err)
    })
  }

}
