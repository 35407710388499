<div id="container" *ngIf="isVisible">
  <label class="container" (click)="toggleComplete()">
    <input type="checkbox" [checked]="isComplete">
    <span class="mark" id="{{status_marker}}"></span>
  </label>
    <div  id="{{task.taskID}}" class="title"  contenteditable="true" (blur)="updateTitle()">{{title}}</div>
    <app-board-item-context (click)="toggleShow()" (taskToDelete)="removeTask($event)" [task]="task"></app-board-item-context>
</div>
<div id="strip_container" *ngIf="isShown">
  <div id="strip"></div>
  <div id="extra_task_information_container">
    <tui-loader
    [showLoader]="isLoading"
    [overlay]="true"
    >
    <button id="flow-button-subTask" (click)="addSubTask()">Add Sub-Task</button>
    <div *ngFor="let sub of task.realsubTasks">
      <app-board-sub-item [title]="sub.title" [subTask]="sub" [task]="task"></app-board-sub-item>
    </div>
   
</tui-loader>
  </div>
</div>
