import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';

import {TuiAlertService, TuiDialogContext, TuiDialogService, TuiDialogSize} from '@taiga-ui/core';
import {PolymorpheusContent} from '@tinkoff/ng-polymorpheus';
import * as uuid from "uuid"

@Component({
  selector: 'app-time-item',
  templateUrl: './time-item.component.html',
  styleUrls: ['./time-item.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeItemComponent implements OnInit {

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  
  constructor(private http:HttpClient,@Inject(TuiAlertService) private readonly alertService: TuiAlertService,public changeDetector:ChangeDetectorRef) { }

  @Input() timeSpent:number=0
  @Input() task:any
  timeString:string=""
  play:boolean=false;
  text = "text"
  open:false
  timeLeft: number = 0;
  interval:any
  currentId = uuid.v4()
  time_value = 0;

  showNotification(): void {
    this.alertService.open(`Timer Started`, {label: `Timing Task!`}).subscribe();
  }

  startTimer() {
    //console.log("timer started")
    this.showNotification()
    this.play = true;
    this.text = "running";
    this.interval = setInterval(() => {
        this.timeLeft++;
        //console.log(this.timeLeft)
      
         var a = Math.floor(this.timeLeft/3600); //hours
         var x = this.timeLeft%3600;
         var b = Math.floor(x/60); //minutes
         var c = this.timeLeft%60; //seconds

         if(c<10 && b<10){
           this.timeString = a+":0"+b+":0"+c
         }
         if(c<10 && b >10){
           this.timeString = a+":"+b+":0"+c
         }
         if(c>10 && b <10){
           this.timeString = a+":0"+b+":"+c
         }else{
           this.timeString = a+":"+b+":"+c
         }

         var elTime = document.getElementById(this.currentId);
         elTime.textContent =  this.timeString;
        // this.updateTime()
    },1000)
  }

  reverseDirection(){
    //console.log("timer countdown started")
    this.showNotification()
    this.play = true;
    this.text = "running";
    this.interval = setInterval(() => {
        this.timeLeft--;
        //console.log(this.timeLeft)
      
         var a = Math.floor(this.timeLeft/3600); //hours
         var x = this.timeLeft%3600;
         var b = Math.floor(x/60); //minutes
         var c = this.timeLeft%60; //seconds

         if(c<10 && b<10){
           this.timeString = a+":0"+b+":0"+c
         }
         if(c<10 && b >10){
           this.timeString = a+":"+b+":0"+c
         }
         if(c>10 && b <10){
           this.timeString = a+":0"+b+":"+c
         }else{
           this.timeString = a+":"+b+":"+c
         }

         var elTime = document.getElementById(this.currentId);
         elTime.textContent =  this.timeString;
        // this.updateTime()
    },1000)
  }

  pauseTimer() {
    this.play = false;
    this.text = "text";
    clearInterval(this.interval);
    //console.log("timer stopped")
    this.updateTime()
  }

  SetCountdown(){
    this.timeLeft = this.time_value
    this.updateTime()
    var a = Math.floor(this.timeLeft/3600); //hours
    var x = this.timeLeft%3600;
    var b = Math.floor(x/60); //minutes
    var c = this.timeLeft%60; //seconds

    if(c<=9 && b <=9){
      this.timeString = a+":0"+b+":0"+c
    }
    if(c<=9 && b >9){
      this.timeString = a+":"+b+":0"+c
    }
    if(c>9 && b <=9){
      this.timeString = a+":0"+b+":"+c
    }else{
      this.timeString = a+":"+b+":"+c
    }

  }

  ngOnInit(): void {

      this.timeLeft = this.timeSpent
      var a = Math.floor(this.timeLeft/3600); //hours
      var x = this.timeLeft%3600;
      var b = Math.floor(x/60); //minutes
      var c = this.timeLeft%60; //seconds

      if(c<=9 && b <=9){
        this.timeString = a+":0"+b+":0"+c
      }
      if(c<=9 && b >9){
        this.timeString = a+":"+b+":0"+c
      }
      if(c>9 && b <=9){
        this.timeString = a+":0"+b+":"+c
      }else{
        this.timeString = a+":"+b+":"+c
      }

  }

  updateTime(){
    //console.log(this.task)
    this.task.timeSpent = this.timeLeft
    this.task = this.http.put(this.prod_api+"/tasks/update",this.task).subscribe(
        data=>{
          this.task = data
          //console.log(this.task.timeSpent)
          this.changeDetector.detectChanges()
          //console.log("should have updated the time value")
        }, err=>{
          //console.log(err)
        });
        this.changeDetector.detectChanges()
  }

}
