import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { project } from './project';
import * as uuid from 'uuid'
import { LoginServiceService } from 'src/app/services/login_service/login-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ShepherdService } from 'angular-shepherd';


@Component({
  selector: 'app-dash-contianer',
  templateUrl: './dash-contianer.component.html',
  styleUrls: ['./dash-contianer.component.sass']
})
export class DashContianerComponent implements OnInit {

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  templateArray:any
  isVisible:boolean = true
  hideShared:boolean = true
  currentId = uuid.v4()
  isLoading = false
  defaultStepOptions:any

  constructor(private http:HttpClient,private service:LoginServiceService, private router:ActivatedRoute,private shepherdService: ShepherdService,private router_nav:Router) {}

  projectsArray:any
  sharedsArray:any
  apiObject:any
  newProject:any
  username:any
  wallpaper = Math.floor(Math.random() * (8 - 1 + 1)) + 1;

  ngOnInit(): void {
    this.isVisible = (localStorage.getItem("hideTemplates")!=null)?false:true
    this.wallpaper = Math.floor(Math.random() * (8 - 1 + 1)) + 1;
    this.username = localStorage.getItem("userId")
    this.isLoading = true
    this.apiObject = this.http.get(this.prod_api+"/projects/personal?OwnerToken="+this.username).subscribe(
      data=>{
        //console.log(data);
        this.projectsArray = data;
        //console.log(this.projectsArray)
        this.isLoading = false
        
      }, err=>{
        this.isLoading = false
        //console.log(err);
      }
    );

    this.isLoading = true
    this.apiObject = this.http.get(this.prod_api+"/projects/shared?MyToken="+this.username).subscribe(
      data=>{
        //console.log(data);
        this.sharedsArray = data;
        //console.log(this.sharedsArray)
        this.isLoading = false

      }, err=>{
        this.isLoading = false
        //console.log(err);
      }
    );


    this.isLoading = true
    //console.log("update properly")
    this.templateArray = this.http.get(this.prod_api+"/templates/read").subscribe(data=>{
      this.templateArray = data
      this.templateArray = this.templateArray.reverse()
      this.isLoading = false
    },err=>{
      this.isLoading = false
      //console.log(err)
    })

    this.shepherdService.defaultStepOptions = {
      classes: 'step-walker',
      scrollTo: false,
      cancelIcon: {
        enabled: true
      }
    };
    this.defaultStepOptions = this.shepherdService.defaultStepOptions
  }


  ngAfterViewInit() {
    const that = this
    this.shepherdService.defaultStepOptions = this.defaultStepOptions;
    this.shepherdService.modal = true;
    this.shepherdService.confirmCancel = true;
    this.shepherdService.addSteps([
    {
      id: 'sidebar-step',
      title:"Welcome to Flowspace 🎉🎉",
      text: 'This is the sidebar, you can use it to navigate across the different parts of the app && change settings and preferences',
      attachTo: {
        element: '#sidebar',
        on: 'right'
      },
      buttons: [
        {
          text: 'Next',
          action: function() {
           
            that.shepherdService.next()
          }
        }
      ]
    },
    {
      id: 'sidebar-step',
      title:"First Some House Keeping",
      text: 'For the best experience, please adjust your browser zoom level to the recommended 80%, or until the scrollbar disappears just press (CTRL & -)',
      attachTo: {
        element: '',
        on: 'right'
      },
      buttons: [
        {
          text: 'Next',
          action: function() {
           
            that.shepherdService.next()
          }
        }
      ]
    },
    {
      id: 'calendar-step',
      title:"Calendar View",
      text: 'Welcome to the Calendar View! Here, you can keep track of all your upcoming events, meetings, and deadlines in one convenient location. ',
      attachTo: {
        element: '#calendar',
        on: 'right'
      },
      buttons: [
        {
          text: 'Next',
          action: function() {
           
            that.shepherdService.next()
          }
        }
      ]
    },
    {
      id: 'project-step',
      title:"Projects View",
      text: 'This is where you can plan, organize, and track progress on all your important projects. Create new projects, comment on tasks, and assign tasks to team members to stay on top of everything.',
      attachTo: {
        element: '#vision',
        on: 'right'
      },
      buttons: [
        {
          text: 'Next',
          action: function() {
           
            that.shepherdService.next()
          }
        }
      ]
    },
    {
      id: 'notes-step',
      title:"Notes View",
      text: 'Here you can jot down all your thoughts, ideas, and research. Keep your notes organized with folders and never forget an important detail again.',
      attachTo: {
        element: '#notes',
        on: 'right'
      },
      buttons: [
        {
          text: 'Next',
          action: function() {
           
            that.shepherdService.next()
          }
        }
      ]
    },
    {
      id: 'vision-step',
      title:"Vision Board",
      text: 'This feature enables you to create a visual representation of your goals and aspirations. Add images, quotes, and affirmations to keep you motivated and inspired.',
      attachTo: {
        element: '#board',
        on: 'right'
      },
      buttons: [
        {
          text: 'Next',
          action: function() {
           
            that.shepherdService.next()
          }
        }
      ]
    },
    {
      id: 'goal-step',
      title:"Goal Board",
      text: 'Use this feature to set SMART goals and track your progress towards achieving them. Break down big goals into smaller, more manageable steps and celebrate your successes along the way.',
      attachTo: {
        element: '#goal',
        on: 'right'
      },
      buttons: [
        {
          text: 'Next',
          action: function() {
           
            that.shepherdService.next()
          }
        }
      ]
    },
    {
      id: 'chat-step',
      title:"Chatroom🎉",
      text: 'This is where you can communicate with your friends, family and team members and collaborate on projects staying connected. Send messages, share files, and keep everyone on the same page.',
      attachTo: {
        element: '#chat',
        on: 'right'
      },
      buttons: [
        {
          text: 'Next',
          action: function() {
           
            that.shepherdService.next()
          }
        }
      ]
    },
    {
      id: 'mood-step',
      title:"Mood Tracker",
      text: 'We have the Mood Tracker! This feature lets you log your daily mood and track patterns over time. Use it to gain insight into your emotional well-being and take steps towards improving your mental health. ',
      attachTo: {
        element: '#score',
        on: 'right'
      },
      buttons: [
        {
          text: 'Next',
          action: function() {
            that.router_nav.navigate(['/table'],{ queryParams: { id: 'b275793a-8b22-4382-8b2e-f11608d278e4' }})
            that.shepherdService.next()
          }
        }
      ]
    },

    {
      id: 'add-template-step',
      title:"Project View",
      text: 'From here, you can save files, keep track of notifications, switch between views, and complete, archive, or delete your projects.',
      attachTo: {
        element: '',
        on: 'bottom'
      },
      buttons: [
        {
          text: 'Next',
          action: function() {
              return that.shepherdService.next();
            
          }
        }
      ]
    },
    {
      id: 'add-template-step',
      title:"Project Table View",
      text: 'Welcome to the project table view! This is where you can save files, check project notifications, switch between views, mark projects as complete or archive them, and even delete them if needed.',
      attachTo: {
        element: '#buttons',
        on: 'bottom'
      },
      buttons: [
        {
          text: 'Next',
          action: function() {
            that.router_nav.navigate(['/calendar'])
            return that.shepherdService.next();
            
          }
        }
      ]
    },
    {
      id: 'add-template-step',
      title:"Monthly View",
      text: 'This is your monthly calendar view, which gives you an overview of all your upcoming events and tasks for the month',
      attachTo: {
        element: '',
        on: 'bottom'
      },
      buttons: [
        {
          text: 'Next',
          action: function() {
            that.router_nav.navigate(['/time'])
            return that.shepherdService.next();
            
          }
        }
      ]
    },
    {
      id: 'add-template-step',
      title:"Weekly View",
      text: 'This is your weekly calendar view, which lets you plan out your week and stay on top of your schedule.',
      attachTo: {
        element: '',
        on: 'bottom'
      },
      buttons: [
        {
          text: 'Next',
          action: function() {
            that.router_nav.navigate(['/daily'])
            return that.shepherdService.next();
            
          }
        }
      ]
    },
    {
      id: 'add-template-step',
      title:"Daily View",
      text: 'This is your daily calendar view, which shows you everything you need to do for the day, including appointments, tasks, and deadlines',
      attachTo: {
        element: '',
        on: 'bottom'
      },
      buttons: [
        {
          text: 'Next',
          action: function() {
            that.router_nav.navigate(['/goals'])
            return that.shepherdService.next();
            
          }
        }
      ]
    },
    {
      id: 'add-template-step',
      title:"Personal Goals",
      text: 'This is where you can set and track your personal goals, whether they are related to health, fitness, relationships, or any other aspect of your life.',
      attachTo: {
        element: '.personal',
        on: 'bottom'
      },
      buttons: [
        {
          text: 'Next',
          action: function() {
            return that.shepherdService.next();
            
          }
        }
      ]
    },
    {
      id: 'add-template-step',
      title:"Financial Goals",
      text: 'This is where you can set and track your financial goals, such as saving for a down payment on a house or paying off your credit card debt.',
      attachTo: {
        element: '.financial',
        on: 'bottom'
      },
      buttons: [
        {
          text: 'Next',
          action: function() {
            return that.shepherdService.next();
            
          }
        }
      ]
    },
    {
      id: 'add-template-step',
      title:"Work Goals",
      text: 'This is where you can set and track your work-related goals, such as completing a project by a certain deadline or increasing your productivity.',
      attachTo: {
        element: '.work',
        on: 'bottom'
      },
      buttons: [
        {
          text: 'Next',
          action: function() {
            return that.shepherdService.next();
            
          }
        }
      ]
    },
    {
      id: 'add-template-step',
      title:"Spiritual Goals",
      text: 'This is where you can set and track your spiritual goals, such as meditating every day or reading a spiritual text.',
      attachTo: {
        element: '.spiritual',
        on: 'bottom'
      },
      buttons: [
        {
          text: 'Next',
          action: function() {
            return that.shepherdService.next();
            
          }
        }
      ]
    },
    {
      id: 'add-template-step',
      title:"Business Goals",
      text: 'This is where you can set and track your business goals, such as increasing revenue or launching a new product.',
      attachTo: {
        element: '.business',
        on: 'bottom'
      },
      buttons: [
        {
          text: 'Next',
          action: function() {
            that.router_nav.navigate(['/chat'])
            return that.shepherdService.next();
            
          }
        }
      ]
    },
    {
      id: 'add-template-step',
      title:"Chatroom",
      text: 'welcome to the chatroom',
      attachTo: {
        element: '',
        on: 'bottom'
      },
      buttons: [
        {
          text: 'Next',
          action: function() {
            return that.shepherdService.next();
            
          }
        }
      ]
    },
    {
      id: 'add-template-step',
      title:"Start Chat",
      text: 'This is where you can start a chat with other users of the app, so you can discuss a project or ask for help and feedback',
      attachTo: {
        element: '#plus',
        on: 'bottom'
      },
      buttons: [
        {
          text: 'Next',
          action: function() {
            that.router_nav.navigate(['/vision'])
            return that.shepherdService.next();
            
          }
        }
      ]
    },
    {
      id: 'add-template-step',
      title:"Welcome to you vision board",
      text: 'welcome to your vision board',
      attachTo: {
        element: '#plus',
        on: 'bottom'
      },
      buttons: [
        {
          text: 'Next',
          action: function() {
            that.router_nav.navigate(['/vision'])
            return that.shepherdService.next();
            
          }
        }
      ]
    },
    {
      id: 'add-template-step',
      title:"Add Vision Board",
      text: 'This is where you can create and add vision boards to your projects, so you can visualize your goals and stay motivated. ',
      attachTo: {
        element: '#menu_item',
        on: 'bottom'
      },
      buttons: [
        {
          text: 'Finish',
          action: function() {
            that.router_nav.navigate(['/project'])
            localStorage.setItem("didTour","true")
            return that.shepherdService.complete();
            
          }
        }
      ]
    },

]);

if(localStorage.getItem("didTour")===null && this.username!=null){
this.shepherdService.start();
}

}



  createProject()
  {
    this.isLoading = true
    const Project:project = {
      "id":uuid.v4(),
      "subProject":"",
      "searchableID":uuid.v4(),
      "owner":this.username,
      "title":"Project Title",
      "description":"just a basic description of the project as a whole, this can contain links to notes and other parts of the project that really matter etc",
      "collaborators":[],
      "deadline":new Date(),
      "dateCreated":new Date(),
      "dateStart":new Date(),
      "archived":false,
      "deleted":false,
      "complete":false,
      "additionalString":"",
      "additionalArray":[],
      "project_color":""
    }
    
    this.newProject = this.http.post(this.prod_api+"/projects/new",Project).subscribe(data=>{
        this.newProject = data
        this.projectsArray.push(this.newProject)
        //console.log(this.newProject)
        this.isLoading = false
        
    },err=>{
      this.isLoading = false
      //console.log(err)
    })
    
  }
  hideTemplates(){
    this.isVisible = !this.isVisible
  }

  hideShared_(){
    this.hideShared = !this.hideShared
  }

 
}
