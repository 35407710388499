import { Component, OnInit, Input, HostListener, Inject, ChangeDetectorRef } from '@angular/core';
import { HttpClient, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { TvPtServiceService } from 'src/app/services/tableView_projectTile/tv-pt-service.service';
import { ProjectInfoServiceService } from 'src/app/services/project_info_service/project-info-service.service';

import { ToastrService } from 'ngx-toastr';
import { ModalService } from 'src/app/Modals/_modal';
import { TuiDialogService,TuiDialogContext } from '@taiga-ui/core';
import {PolymorpheusContent} from '@tinkoff/ng-polymorpheus';

@Component({
  selector: 'app-project-tile',
  templateUrl: './project-tile.component.html',
  styleUrls: ['./project-tile.component.sass']
})
export class ProjectTileComponent implements OnInit {

  constructor(private changeDetector:ChangeDetectorRef,private http:HttpClient, private service:TvPtServiceService,private info_service:ProjectInfoServiceService,private toastr:ToastrService,@Inject(TuiDialogService) private readonly dialogService: TuiDialogService) { }

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"

  isLoading = false
  @Input() projectName = ""
  @Input() projectDescription = ""
  @Input() projectDeadline = ""
  @Input() projectID = ""
  @Input() numcollabs:number= 0
  @Input() percentageComplete= 15
  @Input() projectRef:any
  @Input() username:string=""
  isVisible:boolean =true
  apiObject:any
  subProjects:any
  numsubs:number=0
  @Input() project:any
  collaborator: string="";
  newTemplate:any

  selectedFiles: any;
  currentFileUpload: any;
  progress:number = 0;
  selectedFile = null;
  changeImage = false;
  filename="no file selected"
  isUploading:boolean = true

  imagebase64:any =""
  usersArray:any

  allTasksArray:any[] = []
  completed:number =0

  ngOnInit(): void {
    this.isLoading =true
    this.apiObject = this.http.get(this.prod_api+"/projects/subprojects?MainProjectToken="+this.projectID).subscribe(
      data=>{
        this.subProjects = data
        this.subProjects.forEach(elem=>{
          this.http.get(this.prod_api+"/tasks/project?ProjectID="+elem.id).subscribe(
            dataTasks=>{
              var taskArray:any = dataTasks
              //////console.log(taskArray)
              taskArray.forEach(task=>{
                this.allTasksArray.push(task)
                if(task.currentStatus=="COMPLETE"){
                  this.completed++
                  //console.log(this.completed)

                }
              })
              
            }, err=>{
              ////console.log(err)
            }
          );
       
          ////console.log(this.allTasksArray)
      
        })
       
        this.changeDetector.detectChanges()
        this.numsubs = this.subProjects.length
      }, err=>{
        ////console.log(err)
      });

      setTimeout(()=>{
        if(this.allTasksArray.length!=0){
          this.percentageComplete = parseFloat(((this.completed/this.allTasksArray.length)*100).toFixed(2))
          this.isLoading = false
          this.changeDetector.detectChanges()
        }else{
          this.percentageComplete = 0;
          this.isLoading = false
        }
        
      },10000)
      


      this.http.get(this.prod_api+"/users/migrate").subscribe(data=>{
        this.usersArray = data
      })

      
    
  }

  load(){
    this.service.updateBlocks(this.subProjects)
    let message = this.projectRef
    this.info_service.updateProject(message)
  }

  setLoading(){
    this.isLoading = !this.isLoading
  }

  hideProject(){
    this.isVisible = false
  }

  deleteProject(){
    this.isLoading = true
    this.project = this.http.delete(this.prod_api+"/projects/delete?ProjectID="+this.project.id).subscribe(
        data=>{
          this.project = data
          this.toastr.success("Project Successfully Deleted")
          this.isLoading = false
          this.isVisible = false
          this.changeDetector.detectChanges()
        }, err=>{
          ////console.log(err)
          this.toastr.error("Failed to Delete Project")
        });
  }

  createTemplate(){
    ////console.log("update properly")
    this.newTemplate = this.http.get(this.prod_api+"/templates/create?id="+this.project.id+"&userId="+this.username).subscribe(data=>{
      this.newTemplate = data
      ////console.log(this.newTemplate)
      this.toastr.success("Successfully Created Template")
    },err=>{
      ////console.log(err)
      this.toastr.error("Failed To Created Template")
    })
  }


  archiveProject(){
    this.project.archived = true;
    this.project = this.http.put(this.prod_api+"/projects/update",this.project).subscribe(
        data=>{
          this.project = data
          ////console.log(this.project.title)
        }, err=>{
          ////console.log(err)
        });
  }


  completeProject(){
    this.project.complete = true;
    this.project = this.http.put(this.prod_api+"/projects/update",this.project).subscribe(
        data=>{
          this.project = data
          ////console.log(this.project.title)
        }, err=>{
          ////console.log(err)
        });
  }







  warnUser(){
    this.showDialog("please right click this button")
  }

  showDialog(str): void {
    this.dialogService
        .open(str, {label: `Warning`, size: `s`})
        .subscribe();
  }


updateProject(){
  this.project = this.http.put(this.prod_api+"/projects/update",this.project).subscribe(
      data=>{
        this.project = data
        ////console.log(this.project.title)
      }, err=>{
        ////console.log(err)
      });
}


  addCollaborator(name:string){
    this.project.collaborators.push(name)
    this.collaborator=""
    this.updateProject()
  }
  
  removeCollab(collab:string){
    this.project.collaborators.splice(this.project.collaborators.indexOf(collab),1);
    this.changeDetector.detectChanges()
    this.updateProject()
  }

  changedImage(event: { target: { files: null[]; }; }) {
    this.selectedFile = event.target.files[0];
  }
  
  upload() {
    this.isUploading = !this.isUploading
    this.progress = 0;
    this.currentFileUpload = this.selectedFiles.item(0);
    this.pushFileToStorage(this.currentFileUpload).subscribe((event: any) => {
      if (event.type === HttpEventType.UploadProgress) {
        this.progress = Math.round(100 * event.loaded / event.total);
      } else if (event instanceof HttpResponse) {
        this.toastr.success('File Successfully Uploaded');
        this.isUploading = !this.isUploading
      }
      this.selectedFiles = undefined;
     }
    );
  }
  
  selectFile(event: any) {
    ////console.log("running this code and doing it well")
    this.selectedFiles = event.target.files;
    this.upload()
  }
  
  
  pushFileToStorage(file: File):any{
    const data: FormData = new FormData();
    data.append('title', file.name);
    data.append('image', file);
    data.append('id',this.project.id)
    ////console.log(this.project)
    const newRequest = new HttpRequest('POST', this.dev_api+'/projects/photos', data, {
      reportProgress: true,
      responseType: 'text'
    });
    return this.http.request(newRequest);
  }


  showDialogCollab(content: PolymorpheusContent<TuiDialogContext>): void {
    this.dialogService.open(content).subscribe();
  }
  

}
