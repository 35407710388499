import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CleanupCrewService {

  private selectedDay = new BehaviorSubject<number>(0);
  day = this.selectedDay.asObservable();
  constructor() { }

  updateBlocks(message: number) {
    this.selectedDay.next(message)
    }
}
