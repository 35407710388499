<div id="toolbar">
    <app-month-year id="first" [now]="now" (ForwardOne)="populate(now)" (PrevOne)="populate(now)"></app-month-year>
    <div  id="second">
    <app-view-switcher></app-view-switcher>
    </div>
    
    <div id="inner-toolbar">
        <div id="buttons" *ngFor="let button of buttonArray">
            <div id="button_container" routerLink="/{{button.router}}">
                <div id="title_tooblbar_button"><img src="{{button.image}}"/></div>
                <div id="back_toobar_button">{{button.userid}}</div>
            </div>
         </div>
    </div>
</div>

<tui-loader
[showLoader]="isLoading"
[overlay]="true"
>
    <div id="days_cont">
        <app-calendar-day-label [Title]="Days[0]" [thinkmap]="'rounder'"></app-calendar-day-label>
        <app-calendar-day-label [Title]="Days[1]"></app-calendar-day-label>
        <app-calendar-day-label [Title]="Days[2]"></app-calendar-day-label>
        <app-calendar-day-label [Title]="Days[3]"></app-calendar-day-label>
        <app-calendar-day-label [Title]="Days[4]"></app-calendar-day-label>
        <app-calendar-day-label [Title]="Days[5]"></app-calendar-day-label>
        <app-calendar-day-label [Title]="Days[6]" [thinkmap]="'rounder_still'"></app-calendar-day-label> 
    </div>
    <div id="container">
        <ng-container *ngFor="let block of DayBlocks">
            <app-calendar-day-block (refresh)="refresh()" [block]="block" [defaultProject]="defaultProject"></app-calendar-day-block>
        </ng-container>
      
    </div> 
</tui-loader>

