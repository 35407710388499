
<div 
id="{{item.id}}"
class="{{className}}"
[style.transform]="transform$ | async"
(tuiPan)="onPan($event)"
*ngIf="isVisible &&(hideToolbar==null)"
(contextmenu)="link_($event)"
>
    <tui-loader
    [showLoader]="isloading"
    [inheritColor]="true"
    [overlay]="true"
    >
<div *ngIf="showImage">
    <img [src]="imgSrc|safe" id="{{imgSize}}">
</div>

<div *ngIf="showVideo">
    <iframe  id="video" [src]="videoSrc | safe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</div>
<input  *ngIf="showVideo " id="comment-input"  style="padding-right:15px;padding-left:15px;" placeholder="Place Embeded Video URL" type="text" [(ngModel)]="videoSrc" (ngModelChange)="checkYTVid()"> 
<input  *ngIf="!showVideo && showImage" id="comment-input"  style="padding-right:15px;padding-left:15px;" placeholder="Place Image URL" type="text" [(ngModel)]="imgSrc  " (ngModelChange)="uploadLink()"> 

<div *ngIf="color">
    <input id="{{colorId}}"  class="color" type="color" [(ngModel)]="colorString" value="{{color}}" (change)="updateColor()"/>
    <label class="colorBtn" for="{{colorId}}"  [style.backgroundColor]="colorString"></label>
</div>


<div id="bottom" >
    <div id="toolbar"  *ngIf="(isDisabled=='false' || isDisabled == null || isDisabled == undefined)" >
        <button (click)="resize()"       tippy="Resize" id="toolbar-button" *ngIf="isBoard==false">
            <tui-svg
            src="tuiIconExpand"
            class="icon">
          </tui-svg>
        </button>
        <button (click)="hideImg()"      tippy="Hide Image" id="toolbar-button" *ngIf="isBoard==false">
            <tui-svg
            src="tuiIconImgLarge"
            class="icon">
          </tui-svg>
        </button>
        <button (click)="toggleVideo()"  tippy="Toggle Video" id="toolbar-button" *ngIf="isBoard==false">
            <tui-svg
            src="tuiIconPlayLarge"
            class="icon">
          </tui-svg>
        </button>
        <button (click)="toggleText()"   tippy="Toggle Text" id="toolbar-button" *ngIf="isBoard==false">
            <tui-svg
            src="tuiIconFontLarge"
            class="icon">
          </tui-svg>
        </button>
        <button (click)="toggleColor()"  tippy="Toggle Color" id="toolbar-button" *ngIf="isBoard==false">
            <tui-svg
            src="tuiIconPaintLarge"
            class="icon">
          </tui-svg>
        </button>
        <button (click)="toggleEditor()" tippy="Toggle Edior" id="toolbar-button">
            <tui-svg
            src="tuiIconEditLarge"
            class="icon">
          </tui-svg>
        </button>
        <button (click)="deleteItem()"   tippy="Delete" id="toolbar-button" style="color:red">
            <tui-svg
            src="tuiIconTrashLarge"
            class="icon">
          </tui-svg></button>
        <button  id="toolbar-button" *ngIf="isBoard==true"  tippy="Open Board" (click)="loadNewBoard_()">
            <tui-svg
            src="tuiIconExternalLarge"
            class="icon">
          </tui-svg>
        </button>
        <label *ngIf="!showVideo"  for="{{picId}}" id="label_upload" tippy="Upload Image">
            <tui-svg
            src="tuiIconUploadLarge"
            class="icon">
          </tui-svg>
            <input  id="{{picId}}" type="file" (change)="uploadImage($event)"> 
        </label>
    </div>

    <div id="toolbar" *ngIf="isDisabled=='true'" >
        <button (click)="resize()"       id="toolbar-button" *ngIf="isBoard==false"> <tui-svg
            src="tuiIconExpand"
            class="icon">
          </tui-svg>
        </button>
        <button (click)="hideImg()"       id="toolbar-button" *ngIf="isBoard==false"> <tui-svg
            src="tuiIconImgLarge"
            class="icon">
          </tui-svg></button>
        <button (click)="toggleVideo()"   id="toolbar-button" *ngIf="isBoard==false"> <tui-svg
            src="tuiIconPlayLarge"
            class="icon">
          </tui-svg></button>
        <button (click)="toggleText()"    id="toolbar-button" *ngIf="isBoard==false"> <tui-svg
            src="tuiIconFontLarge"
            class="icon">
          </tui-svg></button>
        <button (click)="toggleColor()"   id="toolbar-button" *ngIf="isBoard==false"> <tui-svg
            src="tuiIconPaintLarge"
            class="icon">
          </tui-svg></button>
        <button (click)="toggleEditor()"  id="toolbar-button"> <tui-svg
            src="tuiIconEditLarge"
            class="icon">
          </tui-svg></button>
        <label *ngIf="!showVideo"  for="{{picId}}" id="label_upload" >
            <tui-svg
            src="tuiIconUploadLarge"
            class="icon">
          </tui-svg>
            <input  id="{{picId}}" type="file" (change)="uploadImage($event)"> 
        </label>
        <button  id="toolbar-button" *ngIf="isBoard==true"   (click)="loadNewBoard_()"> <tui-svg
            src="tuiIconExternalLarge"
            class="icon">
          </tui-svg>
        </button>
        <button (click)="deleteItem()"    id="toolbar-button" style="color:red"> <tui-svg
            src="tuiIconTrashLarge"
            class="icon">
          </tui-svg>
        </button>
      
    </div>


    
    <div *ngIf="textVisible">	
      <tui-editor
      *ngIf="isSaved"
      class="{{editor_class}}"
      [(ngModel)]="control"
    >
    </tui-editor>
        <div *ngIf="!isSaved">
            <p id="para_max" [innerHTML]="control"></p>
        </div>
        <tui-editor-socket *ngIf="isSaved"
        class="socket"
        [content]="control.value || ''"
    ></tui-editor-socket>
    </div>
</div>
</tui-loader>
</div>



<!-- for what the hidetoolbar settings disabled -->
<div 
id="{{item.id}}"
class="{{className}}"
[style.transform]="transform$ | async"
(tuiPan)="onPan($event)"
*ngIf="isVisible && (hideToolbar!=null)"
(contextmenu)="link_($event)"
>
    <tui-loader
  
    [showLoader]="isloading"
    [inheritColor]="true"
    [overlay]="true"
    >
<div *ngIf="showImage">
    <img [src]="imgSrc|safe" id="{{imgSize}}">
</div>

<div *ngIf="showVideo">
    <iframe  id="video" [src]="videoSrc | safe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</div>
<input  *ngIf="showVideo " id="comment-input"  style="padding-right:15px;padding-left:15px;" placeholder="Place Embeded Video URL" type="text" [(ngModel)]="videoSrc" (ngModelChange)="checkYTVid()"> 
<input  *ngIf="!showVideo && showImage " id="comment-input"  style="padding-right:15px;padding-left:15px;" placeholder="Place Image URL" type="text" [(ngModel)]="imgSrc  " (ngModelChange)="uploadLink()"> 

<div *ngIf="color">
    <input id="{{colorId}}"  class="color" type="color" [(ngModel)]="colorString" value="{{color}}" (change)="updateColor()"/>
    <label class="colorBtn" for="{{colorId}}"  [style.backgroundColor]="colorString"></label>
</div>


<div id="bottom" >
    <div id="toolbar"  *ngIf="(isDisabled=='false' || isDisabled == null || isDisabled == undefined)" >
        <button (click)="resize()"       tippy="Resize" id="toolbar-button" *ngIf="isBoard==false">r</button>
        <button (click)="hideImg()"      tippy="Hide Image" id="toolbar-button" *ngIf="isBoard==false">h</button>
        <button (click)="toggleVideo()"  tippy="Toggle Video" id="toolbar-button" *ngIf="isBoard==false">v</button>
        <button (click)="toggleText()"   tippy="Toggle Text" id="toolbar-button" *ngIf="isBoard==false">ht</button>
        <button (click)="toggleColor()"  tippy="Toggle Color" id="toolbar-button" *ngIf="isBoard==false">c</button>
        <button (click)="toggleEditor()" tippy="Toggle Edior" id="toolbar-button">s</button>
        <button (click)="deleteItem()"   tippy="Delete" id="toolbar-button" style="color:red">d</button>
        <button  id="toolbar-button" *ngIf="isBoard==true"  tippy="Open Board" (click)="loadNewBoard_()">o</button>
        <label *ngIf="!showVideo"  for="{{picId}}" id="label_upload" tippy="Upload Image">
            up
            <input  id="{{picId}}" type="file" (change)="uploadImage($event)"> 
        </label>
    </div>

    <div id="toolbar" *ngIf="isDisabled=='true'" >
        <button (click)="resize()"       id="toolbar-button" *ngIf="isBoard==false"><img src="../../../assets/resize.png" height="24px" width="24px"/></button>
        <button (click)="hideImg()"       id="toolbar-button" *ngIf="isBoard==false"><img src="../../../assets/image_board.png" height="24px" width="24px"/></button>
        <button (click)="toggleVideo()"   id="toolbar-button" *ngIf="isBoard==false"><img src="../../../assets/youtube_board.png" height="24px" width="24px"/></button>
        <button (click)="toggleText()"    id="toolbar-button" *ngIf="isBoard==false"><img src="../../../assets/text_board.png" height="24px" width="24px"/></button>
        <button (click)="toggleColor()"   id="toolbar-button" *ngIf="isBoard==false"><img src="../../../assets/color_board.png" height="24px" width="24px"/></button>
        <button (click)="toggleEditor()"  id="toolbar-button"><img src="../../../assets/save_board.png" height="24px" width="24px"/></button>
        <label *ngIf="!showVideo"  for="{{picId}}" id="label_upload" >
            <img src="../../../assets/upload.png" height="24px" width="24px"/>
            <input  id="{{picId}}" type="file" (change)="uploadImage($event)"> 
        </label>
        <button  id="toolbar-button" *ngIf="isBoard==true"   (click)="loadNewBoard_()"><img src="../../../assets/open_board.png" height="24px" width="24px"/></button>
        <button (click)="deleteItem()"    id="toolbar-button" style="color:red"><img src="../../../assets/delete_board.png" height="24px" width="24px"/></button>
      
    </div>

    
    
    <div *ngIf="textVisible">	
        <tui-editor  *ngIf="!isSaved"
            class="editor"
            [formControl]="control"
        ></tui-editor>
        <div *ngIf="isSaved">
            <p id="para_max" [innerHTML]="control.value "></p>
        </div>
        <tui-editor-socket *ngIf="isSaved"
        class="socket"
        [content]="control.value || ''"
    ></tui-editor-socket>
    </div>
</div>
</tui-loader>
</div>


