export interface toolbar{
    value:string;
    image:string;
    name:string;
} 

export const buttonArray = [
    {
        value:"your_projects",
        image:"../../../assets/sml_projects.png",
        userid:"Your Projects",
        router:"/project",
    }
    // ,{
    //     value:"global_actions",
    //     image:"../../../assets/lrg_context.png",
    //     userid:"Global Actions",
    //     router:""
    // }
]