import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import * as uuid from "uuid"

@Component({
  selector: 'app-archived-projects-dashboard',
  templateUrl: './archived-projects-dashboard.component.html',
  styleUrls: ['./archived-projects-dashboard.component.sass']
})
export class ArchivedProjectsDashboardComponent implements OnInit {

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  templateArray:any
  currentId = uuid.v4()
  projectsArray:any
  apiObject:any
  newProject:any
  username:any
  hideShared:boolean = true
  sharedsArray:any
  isLoading = false
  constructor(private http:HttpClient) {
    this.username = localStorage.getItem("userId")
   }

  ngOnInit(): void {
    this.username = localStorage.getItem("userId")
    this.isLoading = true
    this.apiObject = this.http.get(this.prod_api+"/projects/shared?MyToken="+this.username).subscribe(
      data=>{
        //console.log(data);
        this.sharedsArray = data;
        this.isLoading = false

      }, err=>{
        this.isLoading = false
        //console.log(err);
      }
    );
  }
  

  hideShared_(){
    this.hideShared = !this.hideShared
    
  }

}
