import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shared-projects-dashboard',
  templateUrl: './shared-projects-dashboard.component.html',
  styleUrls: ['./shared-projects-dashboard.component.sass']
})
export class SharedProjectsDashboardComponent implements OnInit {


  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  templateArray:any

  projectsArray:any
  apiObject:any
  newProject:any
  username:any
  
  constructor() {
    this.username = localStorage.getItem("userId")
   }

  ngOnInit(): void {
    this.username = localStorage.getItem("userId")
  }

}
