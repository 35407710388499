import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tab-container',
  templateUrl: './tab-container.component.html',
  styleUrls: ['./tab-container.component.sass']
})
export class TabContainerComponent implements OnInit {


  dev_api ="http://localhost:8080"
  prod_api ="https://server.flowspaceproducitivity.com"
  
  constructor() { }

  ngOnInit(): void {
  }

}
