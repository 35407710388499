import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { GoalsNavServiceService } from 'src/app/services/goals-nav-service.service';
import * as uuid from "uuid"

@Component({
  selector: 'app-goals-item-container',
  templateUrl: './goals-item-container.component.html',
  styleUrls: ['./goals-item-container.component.sass']
})
export class GoalsItemContainerComponent implements OnInit {

  username = localStorage.getItem("userId")
  goals:any

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  type_display
  constructor(private http:HttpClient,private goal_service:GoalsNavServiceService) { }

  ngOnInit(): void {
    this.http.get(this.prod_api+"/goals/getUsers?owner="+this.username).subscribe(data=>{
      this.goals = data
      //console.log(this.goals)
    },(err)=>{
      //console.log(err)
    })

    this.goal_service.goal_type_.subscribe((data)=>{
      this.type_display = data
      //console.log(this.type_display)
  })
  }

  createGoal(){
    //console.log("creating goal...")
    var goal = {
      owner: this.username,
      goalID:uuid.v4(),
      name:"Goal Name",
      why:"This is where you put down why you want to achieve the goal, make sure it relevent to you and specific to your situation",
      how:"This is a basic off the top of your head plan to just put into perspective what you will need in order to accomplish this goal",
      units:100,
      progress:0,
      dueDate:new Date(),
      isComplete:false,
      type:this.type_display,
      parentGoal: null
  }

  this.http.post(this.prod_api+"/goals/create",goal).subscribe(
    data=>{
      this.goals.push(data)
      //console.log(data)
    }, err=>{
      //console.log(err)
    });


  }
}
