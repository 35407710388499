import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { project } from 'src/app/Dashboard/ProjectDashboard/dash-contianer/project';

@Injectable({
  providedIn: 'root'
})
export class TvPtServiceService {


  private blocks = new BehaviorSubject<project[]>([]);
  subProjects = this.blocks.asObservable();
  constructor() { }

  updateBlocks(message: any) {
    this.blocks.next(message)
    //console.log(this.blocks)
    //console.log(this.subProjects)
    localStorage.setItem("currentblockArray",JSON.stringify(this.blocks))
    }
}
