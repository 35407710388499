import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';

import { buttonArray } from './toolbar_buttons';
import * as uuid from "uuid"
import { project } from 'src/app/Dashboard/ProjectDashboard/dash-contianer/project';
import { ToastrService } from 'ngx-toastr';
import { WebsocketService } from 'src/app/services/websockets/web-socket-service.service';
import { changeDateSeparator } from '@taiga-ui/cdk';
import { Router } from '@angular/router';
export interface dayblock{
  day:number
  isShown:boolean
  taskArray:any[]
  blockDate:Date
  taskArrayComplete:any
}



@Component({
  selector: 'app-calendar-container',
  templateUrl: './calendar-container.component.html',
  styleUrls: ['./calendar-container.component.sass']
})
export class CalendarContainerComponent implements OnInit {

  constructor(private http:HttpClient,private toastr:ToastrService, public messageService:WebsocketService,private router:Router) { }

  isLoading = false
  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  username = localStorage.getItem("userId")

  currentId = uuid.v4()
  buttonArray = buttonArray
  Days:string[]=['Sun','Mon','Tue','Wed','Thu','Fri','Sat']
  DayBlocks:dayblock[]=[]

  now:Date = new Date()
  firstDay = new Date(this.now.getFullYear(), this.now.getMonth(), 1);
  lastDay = new Date(this.now.getFullYear(), this.now.getMonth() + 1, 0);
  firstIndex:number =this.firstDay.getDay()-1


  taskArray:any[]

  defaultProject:any


  currentYear = this.now.getFullYear();
  currentMonth = this.now.getMonth() + 1;

  daysInCurrentMonth = this.getDaysInMonth(this.currentYear, this.currentMonth);

  refresh(){
    //console.log("got the refresh event")
    this.ngOnInit()
  }

  ngOnInit(): void {

    this.getDefault()
    this.populate(this.now)

    if(localStorage.getItem('didViewGoal')!=null){
      localStorage.removeItem('didViewGoal')
      window.location.reload()
    }
  }

  populate(currentDate:Date){
    this.isLoading = true
    this.now = currentDate
    this.firstDay = new Date(this.now.getFullYear(), this.now.getMonth(), 1);
    this.lastDay = new Date(this.now.getFullYear(), this.now.getMonth() + 1, 5);
    this.firstIndex = this.firstDay.getDay()
    ////console.log(this.firstIndex)
    this.currentYear = this.now.getFullYear();
    this.currentMonth = this.now.getMonth() + 1;
    this.daysInCurrentMonth = this.getDaysInMonth(this.currentYear, this.currentMonth);

    this.DayBlocks=[
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-00"),"taskArrayComplete":[]},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-00"),"taskArrayComplete":[]},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-00"),"taskArrayComplete":[]},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-00"),"taskArrayComplete":[]},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-00"),"taskArrayComplete":[]},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-00"),"taskArrayComplete":[]},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-00"),"taskArrayComplete":[]},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-00"),"taskArrayComplete":[]},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-00"),"taskArrayComplete":[]},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-00"),"taskArrayComplete":[]},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-00"),"taskArrayComplete":[]},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-00"),"taskArrayComplete":[]},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-00"),"taskArrayComplete":[]},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-00"),"taskArrayComplete":[]},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-00"),"taskArrayComplete":[]},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-00"),"taskArrayComplete":[]},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-00"),"taskArrayComplete":[]},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-00"),"taskArrayComplete":[]},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-00"),"taskArrayComplete":[]},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-00"),"taskArrayComplete":[]},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-00"),"taskArrayComplete":[]},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-00"),"taskArrayComplete":[]},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-00"),"taskArrayComplete":[]},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-00"),"taskArrayComplete":[]},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-00"),"taskArrayComplete":[]},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-00"),"taskArrayComplete":[]},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-00"),"taskArrayComplete":[]},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-00"),"taskArrayComplete":[]},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-00"),"taskArrayComplete":[]},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-00"),"taskArrayComplete":[]},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-00"),"taskArrayComplete":[]},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-00"),"taskArrayComplete":[]},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-00"),"taskArrayComplete":[]},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-00"),"taskArrayComplete":[]},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-00"),"taskArrayComplete":[]}
    ]

    for(let i=1;i<=this.daysInCurrentMonth;i++){
      if(this.firstIndex>34){
        this.firstIndex=0
      }
      this.DayBlocks[this.firstIndex].day=i
      this.DayBlocks[this.firstIndex].isShown=true
      this.DayBlocks[this.firstIndex].blockDate=this.addDays(this.firstDay,i-1);
      this.firstIndex++
    }

    this.firstIndex = this.firstDay.getDay()

    this.http.get(this.prod_api+"/tasks/range?start="+this.firstDay.toISOString()+"&end="+this.lastDay.toISOString()+"&OwnerToken="+this.username).subscribe((data:any[])=>{
        this.taskArray = data
        ////console.log(this.taskArray)
        this.http.get(this.prod_api+"/tasks/shared?MyToken="+this.username).subscribe((data:any)=>{
          var tasks = data
          var combined = this.taskArray.concat(tasks)
          for(let i=1;i<=this.daysInCurrentMonth;i++){
            combined.forEach((data: any) => {
              if(this.firstIndex>34){
                this.firstIndex=0
              }
                var date = new Date(data.dueDate)
                date.setHours(0,0,0,0)
                var date2 = this.DayBlocks[this.firstIndex].blockDate
                // ////console.log(date)
                // console.warn("block date:"+date2)
               if(date.getTime() === date2.getTime()){
                 ////console.log(data.title)
                 ////console.log(new Date(data.dueDate))
                 ////console.log(this.DayBlocks[this.firstIndex].blockDate)
                 this.DayBlocks[this.firstIndex].taskArray.push(data)
                 this.DayBlocks[this.firstIndex].taskArrayComplete = combined

                 ////console.log(this.DayBlocks[this.firstIndex].taskArray)
               }
            });
            this.firstIndex++
            console.log(this.firstIndex)
           
          }
          this.isLoading = false

        },err=>{
          ////console.log(err)
        })

      })


  }

  getDaysInMonth(year:any, month:any) {
    return new Date(year, month, 0).getDate();
  }

  addDays(date:Date, days:number) {
    const copy = new Date(Number(date))
    copy.setDate(date.getDate() + days)
    return copy
  }

  createProject()
  {
      this.isLoading = true
     const Project:project = {
       "id":localStorage.getItem("userId")||"",
       "subProject":"",
       "searchableID":uuid.v4(),
       "project_color":"",
       "owner":localStorage.getItem("userId")||"",
       "title":"Default Project",
       "description":"just a basic project to contain tasks added through the calendar but have no parents, tasks from here can always to moved at your earliest convience",
       "collaborators":[],
       "deadline":new Date(),
       "dateCreated":new Date(),
       "dateStart":new Date(),
       "archived":false,
       "deleted":false,
       "complete":false,
       "additionalArray":[],
       "additionalString":""
     }

     this.http.post(this.prod_api+"/projects/new",Project).subscribe(data=>{
         this.defaultProject = data
         this.toastr.success("Successfully Created Project")
         this.isLoading = false
     },err=>{
       ////console.log(err)
      // this.toastr.error("Failed to Created project")
     })
  }

  getDefault(){
    this.isLoading = true
    this.http.get(this.prod_api+"/projects/personal/id?ProjectID="+this.username).subscribe(data=>{
      this.defaultProject = data
     // this.toastr.success("Successfully Got Project")
     this.isLoading = false
   },err=>{
    this.createProject()
    ////console.log(err)
    this.toastr.error("Failed to Get project")
   })
  }

}
