<div id="container" (click)="showDialogSm(template)">
    <div id="context" >  
        <tui-svg
        src="tuiIconUsersLarge">
      </tui-svg>
    </div>
    <div id="text" class="truncate">{{project.collaborators[0]}}</div>
</div>

<ng-template  #template let-observer>
    <div id="comment-iten-container" *ngFor="let collaborator_ of project.collaborators">
      <div>
        {{collaborator_}}
      </div>
      <button id="flow-button" (click)="removeCollab(collaborator_)">Remove</button>
    </div>
    <div id="inputbox" >
      <h2>Select A User To Add Them To The Project:</h2>
  <input placeholder="search"   id="comment-input_serach" [(ngModel)]="searchtext"/>
  <div id="item-container">
      <div *ngFor="let user of usersArray" >
          <div *ngIf="user.username!=''">
              <div id="flexer" *ngIf="user.username.toLowerCase().includes(searchtext.toLowerCase())"  (click)="addCollaborator(user.username)">
                  <tui-avatar
                  text="{{user.username}}"
                  size="m"
                  [autoColor]="true"
              ></tui-avatar>
                  <div>
                      <div id="name">
                          {{user.username}}
                      </div>
                      <div id="bio">
                          {{user.bio}}
                      </div>
                  </div>
                    
              </div>
          </div>
      </div>
  </div>
    </div>
</ng-template>



