export interface links{
    value:string;
    htmlid:string;
    userid:string;
} 

export const linkArray = [
    {
        value:"https://github.com/",
        htmlid:"GitHub"
    },{
        value:"https://youtube.com/",
        htmlid:"Youtube"
    },{
        value:"https://google.com/",
        htmlid:"google",
    },{
        value:"https://slack.com/",
        htmlid:"slack"
    },{
        value:"https://spotify.com/",
        htmlid:"spotify"
    }
]