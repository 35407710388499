<tui-root>
<div id="container">
    <div id="title" class="personal" (click)="updateTypes(null)">Personal Goals</div>
    <div id="separator"></div>
    <div id="title" class="financial" (click)="updateTypes('financial')">Financial Goals</div>
    <div id="separator"></div>
    <div id="title"  class="work" (click)="updateTypes('work')">Work Goals</div>
    <div id="separator"></div>
    <div id="title" class="spiritual" (click)="updateTypes('spiritual')">Spiritual Goals</div>
    <div id="separator"></div>
    <div id="title" class="business" (click)="updateTypes('business')">Business Goals</div>
</div>

</tui-root>