<div id="sidebar">
    <div id="nav" *ngIf="!disabled">
          <a *ngIf="!isDisabled" routerLink="/calendar"> <div class="btn_menu" id="calendar" tippy="This is the Calendar View, you can use this to view all you tasks in one place" ></div></a>
          <a *ngIf="isDisabled" routerLink="/calendar"> <div class="btn_menu" id="calendar"></div></a>
          <a *ngIf="!isDisabled" routerLink="/project"> <div class="btn_menu" id="vision" tippy="This is the Projects View, you can use this to create and manage your projects" ></div></a>
          <a *ngIf="isDisabled" routerLink="/project"> <div class="btn_menu" id="vision"></div></a>
          <a *ngIf="!isDisabled" routerLink="/notes"> <div class="btn_menu" id="notes" tippy="This is The Notes View, you can use this to create and manage your notes" ></div></a>
          <a *ngIf="isDisabled" routerLink="/notes"> <div class="btn_menu" id="notes"></div></a>
          <a *ngIf="!isDisabled" routerLink="/vision"> <div class="btn_menu" id="board" tippy="This is The Vision View, you can use this to create and manage your vision boards"></div></a>
          <a *ngIf="isDisabled" routerLink="/vision"> <div class="btn_menu" id="board"></div></a>
          <a *ngIf="!isDisabled" routerLink="/goals"> <div class="btn_menu" id="goal" tippy="This is The Goal View, you can use this to create and manage your Goals"></div></a>
          <a *ngIf="isDisabled" routerLink="/goals"> <div class="btn_menu" id="goal"></div></a>
          <div *ngIf="!isDisabled" routerLink="/chat" class="btn_menu" id="chat" (click)="updateValue()" [badge]="badgeCount" tippy="This is the Chats View,here is where you can chat with other flowspace users"></div>
          <div *ngIf="isDisabled" routerLink="/chat" class="btn_menu" id="chat" (click)="updateValue()" [badge]="badgeCount" ></div>
          <tui-hosted-dropdown
          [sided]="false"
          [content]="status_dropdown"
          [(open)]="open_moodmenu">
          <div id="score">
            {{score}}
          </div>
        </tui-hosted-dropdown>
    </div>
    <div id="nav" *ngIf="disabled">
       <div class="btn_menu" id="calendar"></div>
       <div class="btn_menu" id="vision" ></div>
       <div class="btn_menu" id="notes" ></div>
       <div class="btn_menu" id="board"></div>
       <div class="btn_menu" id="goal"></div>
       <div class="btn_menu" id="chat" (click)="updateValue()" [badge]="badgeCount" ></div>
      <tui-hosted-dropdown
      [sided]="false"
      [content]="status_dropdown"
      [(open)]="open_moodmenu">
      <div id="score">
        {{score}}
      </div>
    </tui-hosted-dropdown>
  </div>
    <div id="buffer_top"></div>
    <div id="link_holder">
      <div id="nav_links" *ngFor="let link of customlinks">
        <a href="{{link.value}}" target="_blank" tippy="{{link.htmlid}}"><div class="btn_menu_avatar">
          <tui-avatar 
          class="tarry"
          text="{{link.htmlid}}"
          size="s"
          [autoColor]="true">
        </tui-avatar></div></a>
     </div>
    </div>
 

    <div id="nav_settings">
        <div id="buffer"></div>
       
        <a href="https://futurrr.co" target="_blank"><div class="btn_menu" id="futurrr"></div></a>
        <div class="btn_menu" id="settings" (click)="showDialog(settings,header_settings,'m','Settings')"></div>
        <div class="btn_menu" id="profile" (click)="showDialog(template,header,'m','Profile')"  [ngStyle]="{'background-image': 'url(' + imagebase64 + ')'}"></div>
    </div>
</div>

<ng-template  #template let-observer id="userList">

  <label (mouseover)="toggleTrue()" id="changeImage" for="Image" >Change Picture</label>
  <input (mouseover)="toggleTrue()" type="file" id="Image" (change)="uploadImage($event)">
        <div id="spacer"></div>
        Username:
      <div id="name">
        {{username}}
      </div>
      <div id="spacer"></div>
      Bio:
      <input id="bio"  [(ngModel)]="bio"  (blur)="updateBio()"/>
        
    
      <div id="spacer"></div>
      Email:
      <div contenteditable="true" id="email" (blur)="updateEmail()">
        {{user.email}}
      </div>
      <div id="spacer"></div>
      Membership Type: <span id="status"><i>FREE TRIAL</i></span>
      <div>
        {{user.membershipType}}
        <button id="flow-button" (blur)="upgradeToPremium()">Upgrade To Premium</button>
      </div> 
      <button id="flow-button-logout" routerLink="/" (click)="logout()">LOGOUT</button>
</ng-template>
<ng-template #header>
  <tui-loader
  [showLoader]="isUploading"
  [overlay]="true"
  class="header"
  >
  <div>
      <div id="dp_profile" [ngStyle]="{'background-image': 'url(' + imagebase64 + ')'}"></div>
  </div>
  </tui-loader>
</ng-template>

<ng-template #header_settings>
  <div class="header_settings" >
      <div>Flowspace Settings</div>
  </div>
</ng-template>


<ng-template  #settings let-observer id="settings">
  <div>
    <h2>Choose Your Favourite Wallpaper</h2>
    <div id="grid">
        <div (click)="set(1)" id="preview" class="one"></div>
        <div (click)="set(2)" id="preview" class="two"></div>
        <div (click)="set(3)" id="preview" class="three"></div>
        <div (click)="set(4)" id="preview" class="four"></div>
        <div (click)="set(5)" id="preview" class="five"></div>
        <div (click)="set(6)" id="preview" class="six"></div>
        <div (click)="set(7)" id="preview" class="seven"></div>
        <div (click)="set(8)" id="preview" class="eight"></div>
        <div (click)="set(9)" id="preview" class="nine"></div>
        <div (click)="set(10)" id="preview" class="ten"></div>
        <div (click)="set(11)" id="preview" class="eleven"></div>
        <div (click)="unset()" id="preview"><div style="padding-top:30px">Random</div></div>
    </div>
  </div>

  <div id="views">
    <h2>Choose Your Default View</h2>
    <div >
      <form [formGroup]="defaultViewForm">
        <div tuiGroup
          class="group"
          orientation = "vertical"
          [collapsed]="true">

          <tui-checkbox-block
            formControlName="project"
            contentAlign="right"
            [hideCheckbox]="true"
            (change)="setView('project')"
            size="l">
            Project View
          </tui-checkbox-block>

          <tui-checkbox-block
          formControlName="daily"
          contentAlign="right"
          [hideCheckbox]="true"
          (change)="setView('daily')"
          size="l">
          Daily View
        </tui-checkbox-block>

          <tui-checkbox-block
          formControlName="time"
          contentAlign="right"
          [hideCheckbox]="true"
          (change)="setView('time')"
          size="l">
          Time View
        </tui-checkbox-block>

          <tui-checkbox-block
          formControlName="calendar"
          contentAlign="right"
          [hideCheckbox]="true"
          (change)="setView('calendar')"
          size="l">
          Calendar View
        </tui-checkbox-block>

          <tui-checkbox-block
          formControlName="notes"
          contentAlign="right"
          [hideCheckbox]="true"
          (change)="setView('notes')"
          size="l">
          Notes View
        </tui-checkbox-block>

          <tui-checkbox-block
          formControlName="vision"
          contentAlign="right"
          [hideCheckbox]="true"
          (change)="setView('vision')"
          size="l">
          Vision View
        </tui-checkbox-block>

          <tui-checkbox-block
          formControlName="chat"
          contentAlign="right"
          [hideCheckbox]="true"
          (change)="setView('chat')"
          size="l">
          Chat View
        </tui-checkbox-block>

          <tui-checkbox-block
          formControlName="overdue"
          contentAlign="right"
          [hideCheckbox]="true"
          (change)="setView('overdue')"
          size="l">
          Overdue View
        </tui-checkbox-block>

          <tui-checkbox-block
          formControlName="overdue"
          contentAlign="right"
          [hideCheckbox]="true"
          (change)="unsetView()"
          size="l">
          Default View
        </tui-checkbox-block>
         
        </div>
    </form>
        
    </div>
  </div>
  <div id="views">
    <h2>Tooltips & Training Wheels</h2>
    <div >
      <form [formGroup]="settingsForm">
          <div tuiGroup
            class="group"
            orientation = "vertical"
            [collapsed]="true">
            <tui-checkbox-block
              formControlName="disableTooltips"
              contentAlign="right"
              [hideCheckbox]="true"
              (change)="setDisabled($event)"
              size="l">
              Disable Sidebar Tooltips
            </tui-checkbox-block>
            <tui-checkbox-block
            formControlName="hideCompleteTasks"
            contentAlign="right"
            [hideCheckbox]="true"
            (change)="setCompleteTasksDisabled($event)"
            size="l">
            Hide Complete Tasks
          </tui-checkbox-block>
            <tui-checkbox-block
              formControlName="hideTemplates"
              contentAlign="right"
              [hideCheckbox]="true"
              (change)="setTemplatesDisabled($event)"
              size="l">
              Hide templates
            </tui-checkbox-block>
          </div>
      </form>
    </div>
  </div>
  <div id="views">
    <h2>Personal Links</h2>
    <div>
    <div id="links_check"  *ngFor="let link of customlinks">
      <div id="item">
        <div>
          <tui-svg
          src="tuiIconCloseLarge"
          class="icon"
          (click)="remove(link)"
          ></tui-svg>
        </div>
        <div id="update">{{link.htmlid}}</div>
      </div>
    
    </div>
    <input type="text" [(ngModel)]="name" id="comment-input" placeholder="Please Enter The Name of the link"/>
    <input type="text" [(ngModel)]="value" id="comment-input" placeholder="Please Enter The Value of the link"/>
    <br/>
    <br/>
    <button id="flow-button" (click)="addLink()">Save Link</button>
  
    <div id="link_button">   
          <label class="container">
          <input type="checkbox" [checked]="isComplete" (change)="resetLinks()">
          <span class="mark" id="{{status_marker}}"></span>
        </label>
        <div>Reset Links</div>
    </div>

    
       
    </div>
  </div>
  
</ng-template>

<ng-template
    #status_dropdown
    let-close="close"
>
   <app-moodtracker (click)="updateScore()"></app-moodtracker>
</ng-template>