import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileServiceService {

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"

  constructor(private http:HttpClient) { }

  pushFileToStorage(file: File, projectID:string):any{
      const data: FormData = new FormData();
      data.append('file', file);
      data.append('projectID', projectID);
      const newRequest = new HttpRequest('POST', this.prod_api+'/projects/upload', data, {
        reportProgress: true,
        responseType: 'text'
      });
      return this.http.request(newRequest);
}
}
