import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-toolbar',
  templateUrl: './dashboard-toolbar.component.html',
  styleUrls: ['./dashboard-toolbar.component.sass']
})
export class DashboardToolbarComponent implements OnInit {

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  
  constructor() { }
  @Input() username:string=""
  @Input() part:string="Projects"
  ngOnInit(): void {
    //console.log(this.username)
    
  }

}
