import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class GoalsNavServiceService {

  private goal_type = new BehaviorSubject<any>(null);
  goal_type_ = this.goal_type.asObservable();

  constructor() { }

  updateType(message: any) {
    this.goal_type.next(message)
    //console.log(this.goal_type)
    }
}
