import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-search-archive',
  templateUrl: './search-archive.component.html',
  styleUrls: ['./search-archive.component.sass']
})
export class SearchArchiveComponent implements OnInit {

  constructor() { }

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  
  ngOnInit(): void {
  }

}
