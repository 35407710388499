import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-comments-item',
  templateUrl: './comments-item.component.html',
  styleUrls: ['./comments-item.component.sass']
})
export class CommentsItemComponent implements OnInit {

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  color = "white"
  constructor() { }
  @Input() numComments:number=0
  @Input() collabName:string=""
  ngOnInit(): void {
  }

}
