<!-- <div id="float">
    <div id="wrap_inner">
        <button (click)="h1Content()" id="toolbar" tippy="Heading 1">
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAQCAYAAAAbBi9cAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABZSURBVHgB7ZLNDQAQDIVbsagNrGIiIxmBJ9GLEBK9EF/yDtLoz2spN2jCKi4YUuKhRLDNQxFK1UMWIxlMPgzj/QJORguQkwfvrHbUUd+Zmtl2t+KKf5A3JirwzjgKL+PDRgAAAABJRU5ErkJggg==" width="18" height="16" alt="submit" />
        </button>
        <button (click)="h2Content()" id="toolbar" tippy="Heading 2">
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAChSURBVHgB3ZLRDYAgDERb4wCO4AiMoJs5ihs4AiM4gm7gCNiaEpCgCPrlJQ2kBw9oASOCC6X8UBV8rJ8CqaSKQlNsUmKet9bMagpv9EC+OKdKgFpSE0UjB4z2pmAeKgC6J7pbH6ohU4jYR9KdjGv2kyO+Mq6mwytgANPJDXe+OXd75gYVAwW2iLX4DSr9h3FYIdDCokK7mL4DXgF9/+5w1g4DVJ3rEdLSgAAAAABJRU5ErkJggg==" width="18" height="16" alt="submit" />
        </button>
        <span id="covering"> 
            <button (click)="ContentTwelve()" id="toolbar_size" tippy="Font Size 12">
                12
            </button>
            <button (click)="ContentFourteen()" id="toolbar_size" tippy="Font Size 14">
                14
            </button>
            <button (click)="ContentEighteen()" id="toolbar_size" tippy="Font Size 18">
                18
            </button>
            <button (click)="ContentTwenty()" id="toolbar_size" tippy="Font Size 20">
                20
            </button>
            <button (click)="ContentTwentyFour()" id="toolbar_size" tippy="Font Size 24">
                24
            </button>
        </span>
        
        <tui-hosted-dropdown
        [content]="dropdown_highlight"
        [(open)]="open_highlight"
        id="status" 
        >
            <button (click)="saveRange_Selection()" id="toolbar" tippy="Highlight">
                <tui-svg
                src="tuiIconHiliteLarge"
                class="icon">
            </tui-svg>
            </button>
    </tui-hosted-dropdown>
    
    
        <tui-hosted-dropdown
        [content]="dropdown_menu"
        [(open)]="open"
        id="status" 
        >
            <button (click)="saveRange_Selection()" id="toolbar" tippy="Change Color">
                <tui-svg
                src="tuiIconColorLarge"
                class="icon">
              </tui-svg>
            </button>
         
      </tui-hosted-dropdown>
    
      <ng-template #dropdown_menu
        let-close="close"
    >
      <div id="pallete">
        <div  id="one" class="color_choice" (click)="colorContent('rgba(244, 87, 37, 0.32)')"></div>
        <div  id="two" class="color_choice" (click)="colorContent(' #a8cef1')"></div>
        <div  id="three" class="color_choice" (click)="colorContent(' #3682db')"></div>
        <div  id="four" class="color_choice" (click)="colorContent(' #8dda71')"></div>
        <div  id="five" class="color_choice" (click)="colorContent(' #34b41f')"></div>
        <div  id="six" class="color_choice" (click)="colorContent(' #e29398')"></div>
        <div  id="seven" class="color_choice" (click)="colorContent(' #b8474e')"></div>
        <div  id="eight" class="color_choice" (click)="colorContent(' #fcc068')"></div>
        <div  id="nine" class="color_choice" (click)="colorContent(' #ff8a00')"></div>
        <div  id="ten" class="color_choice" (click)="colorContent(' #dab3f9')"></div>
        <div  id="eleven" class="color_choice" (click)="colorContent(' #7b439e')"></div>
        <div  id="twelve" class="color_choice" (click)="colorContent(' #fee797')"></div>
        <div  id="thirteen" class="color_choice" (click)="colorContent(' #fcbb14')"></div>
        <div  id="fourteen" class="color_choice" (click)="colorContent(' #ea97c4')"></div>
        <div  id="fifteen" class="color_choice" (click)="colorContent(' #bd65a4')"></div>
        <div  id="sixteen" class="color_choice" (click)="colorContent(' #7fd7cc')"></div>
        <div  id="seventeen" class="color_choice" (click)="colorContent(' #2fad96')"></div>
        <div  id="eighteen" class="color_choice" (click)="colorContent(' #d4aca2')"></div>
        <div  id="nineteen" class="color_choice" (click)="colorContent(' #9d6f64')"></div>
        <div  id="twenty" class="color_choice" (click)="colorContent(' #d2e9a2')"></div>
        <div  id="twenty-one" class="color_choice" (click)="colorContent(' #aadc42')"></div>
        <div  id="twenty-two" class="color_choice" (click)="colorContent(' #a0c5df')"></div>
        <div  id="twenty-three" class="color_choice" (click)="colorContent(' #b0b0b0')"></div>
        <div  id="twenty-four" class="color_choice" (click)="colorContent(' #d7d7d7')"></div>
        <div  id="twenty-five" class="color_choice" (click)="colorContent(' #ededed')"></div>
      </div>
    </ng-template>


      <ng-template #dropdown_highlight
        let-close="close"
    >
      <div id="pallete">
        <div  id="one" class="color_choice" (click)="highlightContent('rgba(244, 87, 37, 0.32)')"></div>
        <div  id="two" class="color_choice" (click)="highlightContent(' #a8cef1')"></div>
        <div  id="three" class="color_choice" (click)="highlightContent(' #3682db')"></div>
        <div  id="four" class="color_choice" (click)="highlightContent(' #8dda71')"></div>
        <div  id="five" class="color_choice" (click)="highlightContent(' #34b41f')"></div>
        <div  id="six" class="color_choice" (click)="highlightContent(' #e29398')"></div>
        <div  id="seven" class="color_choice" (click)="highlightContent(' #b8474e')"></div>
        <div  id="eight" class="color_choice" (click)="highlightContent(' #fcc068')"></div>
        <div  id="nine" class="color_choice" (click)="highlightContent(' #ff8a00')"></div>
        <div  id="ten" class="color_choice" (click)="highlightContent(' #dab3f9')"></div>
        <div  id="eleven" class="color_choice" (click)="highlightContent(' #7b439e')"></div>
        <div  id="twelve" class="color_choice" (click)="highlightContent(' #fee797')"></div>
        <div  id="thirteen" class="color_choice" (click)="highlightContent(' #fcbb14')"></div>
        <div  id="fourteen" class="color_choice" (click)="highlightContent(' #ea97c4')"></div>
        <div  id="fifteen" class="color_choice" (click)="highlightContent(' #bd65a4')"></div>
        <div  id="sixteen" class="color_choice" (click)="highlightContent(' #7fd7cc')"></div>
        <div  id="seventeen" class="color_choice" (click)="highlightContent(' #2fad96')"></div>
        <div  id="eighteen" class="color_choice" (click)="highlightContent(' #d4aca2')"></div>
        <div  id="nineteen" class="color_choice" (click)="highlightContent(' #9d6f64')"></div>
        <div  id="twenty" class="color_choice" (click)="highlightContent(' #d2e9a2')"></div>
        <div  id="twenty-one" class="color_choice" (click)="highlightContent(' #aadc42')"></div>
        <div  id="twenty-two" class="color_choice" (click)="highlightContent(' #a0c5df')"></div>
        <div  id="twenty-three" class="color_choice" (click)="highlightContent(' #b0b0b0')"></div>
        <div  id="twenty-four" class="color_choice" (click)="highlightContent(' #d7d7d7')"></div>
        <div  id="twenty-five" class="color_choice" (click)="highlightContent(' #ededed')"></div>
      </div>
    </ng-template>
     
        <button (click)="boldContent()" id="toolbar" tippy="Bold" >
            <tui-svg
            src="tuiIconBoldLarge"
            class="icon">
          </tui-svg>
        </button>
        <button (click)="italicContent()" id="toolbar" tippy="Italic">
            <tui-svg 
            src="tuiIconItalicLarge"
            class="icon">
          </tui-svg>
        </button>
        <button (click)="underlineContent()" id="toolbar" tippy="Underline">
            <tui-svg
            src="tuiIconUnderlineLarge"
            class="icon">
          </tui-svg>
        </button>
        <button (click)="strikeContent()" id="toolbar" tippy="Strikethrough">
            <tui-svg
            src="tuiIconStrikeThroughLarge"
            class="icon">
          </tui-svg>
        </button>
        <button (click)="bulletContent()" id="toolbar" tippy="Bullet Points">
            <tui-svg
            src="tuiIconViewListLarge"
            class="icon">
          </tui-svg>
        </button>
        <button (click)="numberContent()" id="toolbar" tippy="Numebred Points">
            <tui-svg
            src="tuiIconOLLarge"
            class="icon">
          </tui-svg>
        </button>
    
        <label for="Image" id="toolbar" tippy="Add Image">  
            <tui-svg
            src="tuiIconImgLarge"
            class="icon">
          </tui-svg>
        </label>
        <input type='file' id="Image"  name="message_to"  (change)="encodeImageFileAsURL($event)"/>
    
        <button  id="toolbar" (click)="downloadAsPDF()" tippy="Save as PDF (THIS FEATURE IS OFFLINE)">
            <tui-svg
            src="tuiIconDownloadLarge"
            class="icon">
          </tui-svg>
          </button>
        <button  id="toolbar" (click)="checkLinks()" tippy="Activate Links">
            <tui-svg
            src="tuiIconLinkLarge"
            class="icon">
          </tui-svg>
        </button>
          <button  id="toolbar" (click)="saveContent()" tippy="Save" >
            <tui-svg
            src="tuiIconFileLarge"
            class="icon">
          </tui-svg>
        </button>
    </div>
   
</div> -->

<div id="float">
  <div id="status">
      {{status}}
  </div>
  
</div>
<div class="scroll">
  <tui-loader
    [showLoader]="isLoading"
    [overlay]="true"
    >
    <!-- <div  style="font-family: 'Poppins';"  tabindex="1"    (keyup.Space)="saveOnWord()"  (keydown.control.s)="saveContentCTRL($event)" (keydown.control.l)="checkLinks()" (paste)="onPaste($event)" autocomplete="on" autocorrect="on" autocapitalize="on" spellcheck="true" id="container_content_editable" [contentEditable]="editable"></div> -->
    <!-- <div id="anchor">Auto-save is currently being reworked... please use CTRL+S to save your note</div> -->
    <tui-editor
    [(ngModel)]="control"
     (keydown.control.s)="saveContentCTRL($event)"
     (keydown.space)="saveContent()"
     (blur)="updateNoteContent()"
    class="editor"
  >
  </tui-editor>
  </tui-loader>
  </div>



