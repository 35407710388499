import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WebsocketService } from 'src/app/services/websockets/web-socket-service.service';

@Component({
  selector: 'app-flowspace',
  templateUrl: './flowspace.component.html',
  styleUrls: ['./flowspace.component.sass']
})
export class FlowspaceComponent implements OnInit {

  constructor(public messageService:WebsocketService,private http:HttpClient, private router:Router) { }
  wallpaper_set = localStorage.getItem("wallpaper")
  username = localStorage.getItem('userId')
  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  wallpaper = Math.floor(Math.random() * (11 - 1 + 1)) + 1;
  ngOnInit(): void {
    this.wallpaper = Math.floor(Math.random() * (11 - 1 + 1)) + 1;
    
    if(this.username != null){
      this.messageService.updateRoomId(this.username)
    }else{
      this.router.navigate(
        ['/login']
      )
    }
    

  }

}
