import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-calendar-day-label',
  templateUrl: './calendar-day-label.component.html',
  styleUrls: ['./calendar-day-label.component.sass']
})
export class CalendarDayLabelComponent implements OnInit {

  @Input() thinkmap:string=""
  constructor() { }
  @Input() Title:string=""
  ngOnInit(): void {
  }

}
