import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-deadline',
  templateUrl: './project-deadline.component.html',
  styleUrls: ['./project-deadline.component.sass']
})
export class ProjectDeadlineComponent implements OnInit {

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  @Input() deadline:Date=new Date()
  deadlineString:string=""
  monthArray = ['January', 'Feburary','March','April','May','June','July','August','September','October','November','December']
  
  constructor() { 
    this.deadlineString = this.deadline.getUTCDate()+" "+this.monthArray[this.deadline.getMonth()]+" "+this.deadline.getFullYear()
  }
 
  ngOnInit(): void {
  }

}
