import { HttpClient } from '@angular/common/http';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-board-column-header',
  templateUrl: './board-column-header.component.html',
  styleUrls: ['./board-column-header.component.sass']
})
export class BoardColumnHeaderComponent implements OnInit {

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  
  constructor(private http:HttpClient,private toastr:ToastrService) { }
  @Input() title:string=""
  @Input() project:any
 
  ngOnInit(): void {
    //console.log(this.title)
   
  }

  updateTitle(){
    //console.log(this.project.id)
    var elem:any = document.getElementById(this.project.id)
    var innerHtml:string = elem.innerText
    //console.log(innerHtml)
    //console.log("**********************working welll********************")
    this.project.title = innerHtml
    this.project = this.http.put(this.prod_api+"/projects/update",this.project).subscribe(
        data=>{
          this.project = data
        }, err=>{
          //console.log(err)
        });
  }

  deleteProject(){
    this.project = this.http.delete(this.prod_api+"/projects/delete?ProjectID="+this.project.id).subscribe(
        data=>{
          this.project = data
          this.toastr.success("Project Successfully Deleted")
        }, err=>{
          //console.log(err)
          this.toastr.error("Failed to Delete Project")
        });
  }


  warnUser(){
    alert("please right click this button")
  }

}
