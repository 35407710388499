
<div id="container" *ngIf="subTask.isVisible">
    <label class="container" (click)="toggleComplete()">
        <input type="checkbox" [checked]="isCompleted">
        <span class="mark" ></span>
      </label>
      <tui-loader
class="inline-flex tui-space_right-2"
    [showLoader]="isLoading"
    [inheritColor]="true"
    [overlay]="true"
>
      <div  id="{{currentId}}" class="title" contenteditable="true" (click)="selectAll()" (blur)="updateTitle()"  [ngClass]="isCompleted ? 'strikethrough' : 'null'">{{title}}</div>
    </tui-loader>
      <!-- <app-subtask-comments></app-subtask-comments>
      <app-subtask-time></app-subtask-time> -->
      <app-subtask-context-menu (click)="removeItem()" ></app-subtask-context-menu>
</div>

