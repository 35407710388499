<div id="toolbar">
    <div  id="second">
    <app-view-switcher></app-view-switcher>
    </div>
    
    <div id="inner-toolbar">
        <div id="buttons" *ngFor="let button of buttonArray">
            <div id="button_container" routerLink="/{{button.router}}">
                <div id="title_tooblbar_button"><img src="{{button.image}}"/></div>
                <div id="back_toobar_button">{{button.userid}}</div>
            </div>
         </div>
    </div>
</div>
<tui-loader
[showLoader]="isLoading"
[overlay]="true"
>
<div>
    <div id="days">
        <app-day-label [value]="165432" [title]="'Time'" class="time"></app-day-label>
        <app-day-label [value]="1" [title]="'Mon'" id="top"></app-day-label>
        <app-day-label [value]="2" [title]="'Tue'" id="top"></app-day-label>
        <app-day-label [value]="3" [title]="'Wed'" id="top"></app-day-label>
        <app-day-label [value]="4" [title]="'Thu'" id="top"></app-day-label>
        <app-day-label [value]="5" [title]="'Fri'" id="top"></app-day-label>
        <app-day-label [value]="6" [title]="'Sat'" id="top"></app-day-label>
        <app-day-label [value]="0" [title]="'Sun'" id="top"></app-day-label>
      
    </div>

    <div id="container">
        <ng-container *ngFor="let block of slots">
            <app-time-slot [defaultProject]="defaultProject" [slot]="block" class="{{block.scroll}}"  id="{{block.scroll}}" ></app-time-slot>
        </ng-container>
      
    </div> 
</div>
</tui-loader>
