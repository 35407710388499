import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-board-item-context',
  templateUrl: './board-item-context.component.html',
  styleUrls: ['./board-item-context.component.sass']
})
export class BoardItemContextComponent implements OnInit {

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"

  @Input() task:any

  @Output() taskToDelete: EventEmitter<any> =   new EventEmitter();

  constructor(private http:HttpClient) { }



  deleteTask(){
    this.http.delete(this.prod_api+"/tasks/delete?taskToDelete="+this.task.taskID).subscribe(data=>{
      this.taskToDelete.emit(this.task)
    },err=>{
      //console.log(err)
    })
    
  }


  ngOnInit(): void {
  }


  warnUser(){
    alert("please right click this button")
  }

}
