import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ProjectInfoServiceService } from 'src/app/services/project_info_service/project-info-service.service';

@Component({
  selector: 'app-project-description',
  templateUrl: './project-description.component.html',
  styleUrls: ['./project-description.component.sass']
})
export class ProjectDescriptionComponent implements OnInit {

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"

  apiObject:any
  subProjects:any
  numsubs:number=0
  @Input() project:any
  collaborator: string="";
  @Input() description:string=""
  color:string = "#ffffff"
  
  constructor( private http:HttpClient, private info_service:ProjectInfoServiceService) {
    //console.log("*********************************************************************************")
    //console.log(this.project)
   
    //console.log("*********************************************************************************")
   }


  
  ngOnInit(): void {
    
      this.color = this.project.project_color
      this.description = this.project.description
      
   
  }

  updateProject(){
    var elem:any = document.getElementById("description")
    var innerHtml:string = elem.innerText
    this.project.description = innerHtml
    this.project = this.http.put(this.prod_api+"/projects/update",this.project).subscribe(
        data=>{
          this.project = data
          //console.log(this.project.title)
        }, err=>{
          //console.log(err)
        });

}

updateColor(){
  this.project.project_color = this.color
  this.project = this.http.put(this.prod_api+"/projects/update",this.project).subscribe(
    data=>{
      this.project = data
      //console.log(this.project.project_color)
    }, err=>{
      //console.log(err)
    });

}

}
