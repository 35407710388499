import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NoteEditorServiceService {


  public noteArray = new BehaviorSubject<any[]>([]);
  private note = new BehaviorSubject<any>({});
  private index = new BehaviorSubject<any>({});
  
  editorNote = this.noteArray.asObservable();
  editorIndex = this.index.asObservable();
  editorCurrentNote = this.note.asObservable();
  constructor() { }

  updateProject(message: any) {
    ////console.log(message)
    this.noteArray.next(message)
  }

  updateIndex(message: number) {
      //alert("index update: "+message)
      this.index.next(message)
  }

  updateCurrentNote(arg0: { note: any; }) {
    this.note.next(arg0.note)
  }


}
