<div id="container" >
    <div class="stack">
        <tui-avatar
        *ngIf="project.collaborators[0]!=(null||undefined)"
        class="child"
        [autoColor]="true"
        [text]="project.collaborators[0]"
        size="xs"></tui-avatar>
        <tui-avatar
        *ngIf="project.collaborators[1]!=(null||undefined)"
        class="child1"
        [autoColor]="true"
        [text]="project.collaborators[1]"
        size="xs"></tui-avatar>
        <tui-avatar
        *ngIf="project.collaborators[2]!=(null||undefined)"
        class="child2"
        [autoColor]="true"
        [text]="project.collaborators[2]"
        size="xs"></tui-avatar>
        <div class="text">{{numcollabs}} Members </div>
    </div>
</div>
