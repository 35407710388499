import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, HostListener, Inject, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {TuiDialogContext, TuiDialogService} from '@taiga-ui/core';
import {PolymorpheusContent} from '@tinkoff/ng-polymorpheus';
import { ToastrService } from 'ngx-toastr';

import { NoteEditorServiceService } from 'src/app/services/note_editor_service/note-editor-service.service';
import { WebsocketService } from 'src/app/services/websockets/web-socket-service.service';
import * as uuid from "uuid"

@Component({
  selector: 'app-note-item',
  templateUrl: './note-item.component.html',
  styleUrls: ['./note-item.component.sass']
})
export class NoteItemComponent implements OnInit {

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  highlight: string;
  selectedUser:string=""
  constructor(private http:HttpClient,private service:NoteEditorServiceService,private toastr:ToastrService,private Activatedroute:ActivatedRoute,@Inject(TuiDialogService) private readonly dialogService: TuiDialogService,private socketService:WebsocketService) { }
  isLoading = false
  note:any
  @Input() index:number = 0
  currentId = uuid.v4()
  updatedNote:any
  @Output() currentNote = new EventEmitter<any>();
  isShown:boolean = false
  newNote:any
  @Input() notesArray:any
  @Input() icon:string="../../../assets/note_icon.png"
  sub:any
  notebookId:any
  isVisible=true
  isHighlighted=false
  usersArray:any

  ngOnInit(): void {
    
    this.http.get(this.prod_api+"/users/migrate").subscribe(data=>{
      this.usersArray = data
    })

    this.sub = this.Activatedroute.queryParamMap
    .subscribe(params => {
    this.notebookId = params.get('id')||0;     
    });

    this.note = this.notesArray[this.index]
    this.service.updateProject(this.notesArray)

    // this.service.editorNote.subscribe(data=>{
    //   //console.log(this.isShown)
    //   if(this.isShown){
    //     //console.log("this is truer than true")
    //     this.notesArray = data 
    //     this.isShown = true
    //   }else{
    //     this.notesArray = data 
    //   }

  
    //   ////console.log("this is working even though it is ugly************************************")
    // })


    this.notesArray.forEach((element:any) => {
      if(element.notePagesID == this.note.id){
        this.icon = '../../../assets/note_folder.png'
      }
    });

    this.service.editorIndex.subscribe(data=>{
      //alert(this.index)
     // alert(data)
      //console.log(data)
      if(this.index == data){
        this.isHighlighted = true
        this.highlight="highlight"
      }else{
        this.highlight=""
        this.isHighlighted = false
      }
    })


  }


  updateTitle(){
    this.isLoading =true
    var elem:any = document.getElementById(this.currentId)
    var innerHtml:string = elem.innerText
    ////console.log("**********************************************")
    ////console.log(innerHtml)
    this.notesArray[this.index].title = innerHtml

    this.http.put(this.prod_api+"/notes/update_note",this.note).subscribe(
        data=>{
          this.updatedNote = data
          this.isLoading =false
          //console.log(data)

        }, err=>{
          //console.log(err)
        });
  }

  selectNote() {
    this.currentNote.emit({note:this.note,index:this.index});
    this.service.updateProject(this.notesArray)
    this.service.updateIndex(this.index)
    this.service.updateCurrentNote({note:this.note})

  }

  selectUser(username){
    //console.log("this code is running")
    this.selectedUser = username

    this.socketService.showNotification("Started Sharing","Making A Copy of the note...")
    var copy  = this.note

    copy.notebookID = username
    copy.id = uuid.v4()
    this.socketService.showNotification("Sharing 50% Done?🔥😌","Uploading the note...")

    //console.log(copy)
    this.http.post(this.prod_api+"/notes/add_note",copy).subscribe(data=>{
      this.newNote = data
      //console.log(this.newNote)
      this.socketService.showNotification("Successfully Shared A Copy Of Note","🔥🔥🔥🔥🔥😌😌😌😌😌")
    },err=>{
      //console.log(err)
    })

  }

  toggleShow(){
    this.isShown = !this.isShown
  }

  addSubNote(){
    this.isLoading =true
    const note = {
      title:"Sub-Note Title",
      content:"",
      favourite:false,
      collaboratorsTank :[],
      labels:[],
      isPinned :false,
      notebookID:this.notebookId,
      id:uuid.v4(),
      searchableID :uuid.v4(),
      dateCreated: new Date(),
      lastModified: new Date(),
      noteOwner:localStorage.getItem('userId'),
      notePagesID: this.note.id
    }

    this.newNote = this.http.post(this.prod_api+"/notes/add_note",note).subscribe(data=>{
      this.newNote = data
      //console.log(this.newNote)
      this.toastr.success("Successfully Created Sub Note")
      this.notesArray.push(this.newNote)
      this.icon = '../../../assets/note_folder.png'
      this.isLoading =false
    },err=>{
      //console.log(err)
    })

  }

    deleteNote(){
      this.isLoading =true
      this.note = this.http.delete(this.prod_api+"/notes/delete?noteId="+this.note.id).subscribe(data=>{
        this.toastr.success("Successfully Deleted Note")
        //this.notesArray.splice(this.notesArray.indexOf(this.note),1)
        this.isVisible = false
        this.isShown = false
        this.isLoading =false
      },err=>{
        //console.log(err)
      })
    }
    

  updatedText(note:any){
    document.getElementById("container_content_editable")!.innerHTML = note.content
  }


  warnUser(){
    alert("please right click this button")
  }

  showDialog(content: PolymorpheusContent<TuiDialogContext>): void {
    this.dialogService.open(content).subscribe();
  }


}
