<div id="container">
    <div id="header">
        <img src="../../../assets/note_folder.png" width="20px" height="20px"  alt="" id="icon">
        <div id="title">EMIC V2 API Documentation</div>
        <div id="context">...</div>
    </div>
    <app-note-item></app-note-item>
    <app-note-item></app-note-item>
    <app-note-item></app-note-item>
    <app-note-item></app-note-item>
    <app-note-item></app-note-item>
</div>
