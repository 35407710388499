import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-board-column-header-context',
  templateUrl: './board-column-header-context.component.html',
  styleUrls: ['./board-column-header-context.component.sass']
})
export class BoardColumnHeaderContextComponent implements OnInit {

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  
  constructor() { }

  ngOnInit(): void {
  }

}
