<tui-root *ngIf="!isMobile">

<tui-theme-night></tui-theme-night>
 <tui-root tuiMode="onDark"> 

<div  id="updateStatus" *ngIf="!networkStatus">
    Internet Connection Status:
    {{ networkStatus ? "IS CONNECTED" : "NOT CONNECTED" }}
</div>
<app-flowspace></app-flowspace> 

 </tui-root>

</tui-root>

<div id="show_mobile" *ngIf="isMobile">
    sorry flowspace is not yet ready for mobile💔 <br/>
    but spreading the word and using the app more will help us get there sooner
</div>