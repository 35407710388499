import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, Output,EventEmitter, HostListener, Inject } from '@angular/core';
import { TuiDialogService } from '@taiga-ui/core';


@Component({
  selector: 'app-table-item-context',
  templateUrl: './table-item-context.component.html',
  styleUrls: ['./table-item-context.component.sass']
})
export class TableItemContextComponent implements OnInit {

  constructor(private http:HttpClient,@Inject(TuiDialogService) private readonly dialogService: TuiDialogService) { }

  @Input() task:any

  @Output() taskToDelete: EventEmitter<any> =   new EventEmitter();
  @Output() showDesc: EventEmitter<any> =   new EventEmitter();
  @Output() move: EventEmitter<any> =   new EventEmitter();

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"

  deleteTask(){
    this.http.delete(this.prod_api+"/tasks/delete?taskToDelete="+this.task.taskID).subscribe(data=>{
      this.taskToDelete.emit(this.task)
    },err=>{
      //console.log(err)
    })
    
  }


  showDescription(){
    this.showDesc.emit(this.task)
  }

  moveTask(){
    this.move.emit(this.task)
  }


  ngOnInit(): void {
  }



  warnUser(){
    this.showDialog("please right click this button")
  }

  showDialog(str): void {
    this.dialogService
        .open(str, {label: `Warning`, size: `s`})
        .subscribe();
  }

}
