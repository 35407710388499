import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ModalService } from 'src/app/Modals/_modal';
import { HttpClient } from '@angular/common/http';
import {TuiAlertService, TuiDialogContext, TuiDialogService, TuiDialogSize} from '@taiga-ui/core';
import {PolymorpheusContent} from '@tinkoff/ng-polymorpheus';

@Component({
  selector: 'app-project-collaborators',
  templateUrl: './project-collaborators.component.html',
  styleUrls: ['./project-collaborators.component.sass']
})
export class ProjectCollaboratorsComponent implements OnInit {

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  
  constructor(private http:HttpClient,private readonly dialogService: TuiDialogService,private changeDetector:ChangeDetectorRef) { }
  @Input() collaborators:number=0;
  @Input() project:any 
  collaborator: string="";
  usersArray:any
  ngOnInit(): void {
    this.http.get(this.prod_api+"/users/migrate").subscribe(data=>{
      this.usersArray = data
    })
  }

  updateProject(){
    this.project = this.http.put(this.prod_api+"/projects/update",this.project).subscribe(
        data=>{
          this.project = data
        }, err=>{
          //console.log(err)
        });
  }

  addCollaborator(name:string){
    this.project.collaborators.push(name)
    this.collaborator=""
    this.changeDetector.detectChanges()
    this.updateProject()
  }
  
  removeCollab(collab:string){
    var index = this.project.collaborators.indexOf(collab)
    this.project.collaborators.splice(index,1)
    this.changeDetector.detectChanges()
    this.updateProject()
  }

  showDialogSm(
    content: PolymorpheusContent<TuiDialogContext>, 
    ): void {
   
    this.dialogService.open(content,{
      label: `Task Collaborators`,
      size:'m',
  }).subscribe();
  }

}
