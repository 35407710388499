<div id="table-container">
    <tui-loader
[showLoader]="isLoading"
[overlay]="true"
>
    <app-toolbar-table-view [title]="title" [project]="project"></app-toolbar-table-view>
    <app-project-info [description]="description" [deadline]="deadline" [collabs]="project.collaborators.length" [project]="project"></app-project-info>
    <button id="flow-button" class="padder" (click)="createProject()">Add Sub Project</button>
    <div class="scroll">
        <ng-container *ngFor="let block of blockArray"> 
            <div id="table_container">
                <app-header [project]="block"></app-header>
                <app-table-item-container [block]="block" [projectID]="block.id" [parentProject]="project" [messageService]="messageService"></app-table-item-container>
            </div>
        
        </ng-container>
    </div>
</tui-loader>
 
</div>
