import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import {project}  from "../../Dashboard/ProjectDashboard/dash-contianer/project"
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as uuid from "uuid"
import { ToastrService } from 'ngx-toastr';

import { WebsocketService } from 'src/app/services/websockets/web-socket-service.service';
import { FormControl } from '@angular/forms';
import {TUI_DEFAULT_MATCHER, tuiPure} from '@taiga-ui/cdk';



@Component({
  selector: 'app-table-view',
  templateUrl: './table-view.component.html',
  styleUrls: ['./table-view.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableViewComponent implements OnInit {

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"

  title:string=""
  description:string=""
  @Input() deadline:Date=new Date()
  collabs:number=0
  project:any
  projectID:string=""

  blockArray:any
  newProject:any
  apiObject:any
  sub:any
  currentId = uuid.v4()
  projectsArray:any 
  username = localStorage.getItem('userId')
  isLoading = false
  item:readonly string[] = [
    `Sunday`,
    `Monday`,
    `Tuesday`,
    `Wednesday`,
    `Thursday`,
    `Friday`,
    `Saturday`,
    `Everyday`
];

  search: string | null = ``;
 
    readonly control = new FormControl([this.item[0]]);
 
    @tuiPure
    filter(search: string | null): readonly string[] {
        return this.item.filter(item => TUI_DEFAULT_MATCHER(item, search || ``));
    }
  
  constructor(private service:WebsocketService, private http:HttpClient,private Activatedroute:ActivatedRoute,private toastr:ToastrService,public messageService: WebsocketService, private readonly changeDetector: ChangeDetectorRef) {}


 

  ngOnInit(): void {
    this.isLoading = true
    this.sub = this.Activatedroute.queryParamMap
    .subscribe(params => {
    this.projectID = params.get('id')||"no such id in the database";     
    //console.log(this.projectID)
   
    });




    this.project = this.http.get(this.prod_api+"/projects/personal/id?ProjectID="+this.projectID).subscribe(data=>{
      this.project = data
      //console.log(this.project)
      //console.log(this.project.title)
      this.title = this.project.title
      this.description = this.project.description
      this.collabs = this.project.collaborators.length
      this.changeDetector.detectChanges();
      
      this.project.collaborators.push(this.username)
      this.createProjectRoom()

    })
        
    this.apiObject = this.http.get(this.prod_api+"/projects/subprojects?MainProjectToken="+this.projectID).subscribe(
      data=>{
        this.blockArray = data
        this.isLoading = false
        this.changeDetector.detectChanges();
        
      }, err=>{
        //console.log(err)
      });  

  }

  createProjectRoom(){
    const chatRoom = {
      roomId:this.projectID,
      from:this.project.title,
      to:this.project.title,
      time:new Date().toTimeString(),
      lastMessage:"New Project Created",
      participants:this.project.collaborators.concat([this.username])
  }

  this.service.newProject(chatRoom);
  }

  createProject()
  {
    this.isLoading = true
    //this.messageService.disconnect()
     const Project:project = {
       "id":uuid.v4(),
       "subProject":this.project.id,
       "searchableID":uuid.v4(),
       "project_color":"",
       "owner":this.project.owner,
       "title":"Project Title",
       "description":"just a basic description of the project as a whole, this can contain links to notes and other parts of the project that really matter etc",
       "collaborators":[],
       "deadline":new Date(),
       "dateCreated":new Date(),
       "dateStart":new Date(),
       "archived":false,
       "deleted":false,
       "complete":false,
       "additionalArray":[],
       "additionalString":""
     }

      this.newProject = this.http.post(this.prod_api+"/projects/new",Project).subscribe(data=>{
          this.newProject = data
          this.blockArray.push(this.newProject)
          this.changeDetector.detectChanges();
          this.toastr.success("Successfully Created Project")
          this.isLoading = false
      },err=>{
        this.isLoading = false
        //console.log(err)
        this.toastr.error("Failed to Created project")
      }) 
  }

}
