import { HttpClient, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { Component, OnInit,Input,ChangeDetectionStrategy, Inject, ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from 'src/app/Modals/_modal';
import { FileServiceService } from 'src/app/services/files/file-service.service';
import { linkArray } from './temp_link_holder';
import {TuiAlertService, TuiDialogContext, TuiDialogService, TuiDialogSize} from '@taiga-ui/core';
import {PolymorpheusContent} from '@tinkoff/ng-polymorpheus';
import { TuiHideSelectedPipe } from '@taiga-ui/kit';
import mixpanel from 'mixpanel-browser'
import { LoginService } from 'src/app/services/loginService/login.service';
import { FormControl, FormGroup } from '@angular/forms';
mixpanel.init('000706f38f6320cb7267e8d4bf0a7895', {debug: true});


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit {

  linkArray = linkArray
  user:any = {bio:"hard working average joe",email:"johndoe@gmail.com",membershipType:"FREE TRIAL"}
  username = localStorage.getItem("userId")
  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  apiObject:any

  @Input() service:any

  selectedFiles: any;
  currentFileUpload: any;
  progress:number = 0;
  selectedFile = null;
  changeImage = false;
  filename="no file selected"
  isUploading:boolean = false
  isVisible:boolean = true
  badgeCount:number=0
  isDisabled = localStorage.getItem("isDisabled")
  disabled = true
  imagebase64:any =""
  currentChatMessages: any[]=[]
  chatRooms: any[]=[]
  customlinks:any[] =[]
  name:string=""
  value:string=""
  open_moodmenu=false
  score =0
  bio:string="Hey, there I am using Flowspace"
  disableTooltips
  hideTemplates
  hideCompleteTasks

  settingsForm = new FormGroup({
    disableTooltips: new FormControl(false),
    hideTemplates: new FormControl(false),
    hideCompleteTasks: new FormControl(false),
});

defaultViewForm = new FormGroup({
  project: new FormControl(false),
  daily: new FormControl(false),
  time: new FormControl(false),
  calendar: new FormControl(false),
  notes: new FormControl(false),
  vision: new FormControl(false),
  chat: new FormControl(false),
  overdue: new FormControl(false),
  default: new FormControl(false),
});

  constructor(
    private Loginservice:LoginService,
    private http:HttpClient,
    private uploadService: FileServiceService,
    private toastr:ToastrService,
    @Inject(TuiAlertService) private readonly alertService: TuiAlertService, 
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    private readonly changeDetector: ChangeDetectorRef) { 
    

  }

  ngOnInit(): void {
    if(this.username!=null){
      localStorage.setItem(this.isDisabled,"false")
      this.disabled = false
      this.Loginservice.updateFlag(false)
    }else{
      this.isDisabled = "true"
      this.disabled = true
      this.Loginservice.updateFlag(true)
    }

    this.Loginservice.flag.subscribe(data=>{
      console.warn(data)
      this.disabled = data
      this.changeDetector.detectChanges()
    })
    setTimeout(() => {
      this.setup()
    }, 1000);

    this.score = parseInt(localStorage.getItem('mood-score'))
    this.service.bigChats.subscribe(data=>{
      //console.log(data)
      this.badgeCount = 0;
      this.badgeCount = data
      this.changeDetector.detectChanges();
    })

    if(localStorage.getItem("currentCustomLinks") != null){
      this.customlinks = JSON.parse(localStorage.getItem("currentCustomLinks"))
      //console.log(this.customlinks)
    }else{
      this.setCustomLinks()
      this.customlinks = JSON.parse(localStorage.getItem("currentCustomLinks"))
      //console.log(this.customlinks)
    }

    this.disableTooltips = (localStorage.getItem("isDisabled")!=null)?true:false
    this.hideTemplates=(localStorage.getItem("hideTemplates")!=null)?true:false
    this.hideCompleteTasks=(localStorage.getItem("hideCompleteTasks")!=null)?true:false

    console.log(this.disableTooltips,this.hideTemplates,this.hideCompleteTasks)

    this.settingsForm = new FormGroup({
      disableTooltips: new FormControl(this.disableTooltips),
      hideTemplates: new FormControl(this.hideTemplates),
      hideCompleteTasks: new FormControl(this.hideCompleteTasks)
  });

  this.setViewFormValues()

  }

  setViewFormValues(){
    const defaultView = localStorage.getItem('view')

    switch(defaultView){
      case "project":
      this.defaultViewForm = new FormGroup({
        project: new FormControl(true),
        daily: new FormControl(false),
        time: new FormControl(false),
        calendar: new FormControl(false),
        notes: new FormControl(false),
        vision: new FormControl(false),
        chat: new FormControl(false),
        overdue: new FormControl(false),
        default: new FormControl(false),
      });
      break

      case "daily":
        this.defaultViewForm = new FormGroup({
          project: new FormControl(false),
          daily: new FormControl(true),
          time: new FormControl(false),
          calendar: new FormControl(false),
          notes: new FormControl(false),
          vision: new FormControl(false),
          chat: new FormControl(false),
          overdue: new FormControl(false),
          default: new FormControl(false),
        });
        break
      case "time":
          this.defaultViewForm = new FormGroup({
            project: new FormControl(false),
            daily: new FormControl(false),
            time: new FormControl(true),
            calendar: new FormControl(false),
            notes: new FormControl(false),
            vision: new FormControl(false),
            chat: new FormControl(false),
            overdue: new FormControl(false),
            default: new FormControl(false),
          });
          break
      case "calendar":
          this.defaultViewForm = new FormGroup({
            project: new FormControl(false),
            daily: new FormControl(false),
            time: new FormControl(false),
            calendar: new FormControl(true),
            notes: new FormControl(false),
            vision: new FormControl(false),
            chat: new FormControl(false),
            overdue: new FormControl(false),
            default: new FormControl(false),
          });
          break
      case "notes":
          this.defaultViewForm = new FormGroup({
            project: new FormControl(false),
            daily: new FormControl(false),
            time: new FormControl(false),
            calendar: new FormControl(false),
            notes: new FormControl(true),
            vision: new FormControl(false),
            chat: new FormControl(false),
            overdue: new FormControl(false),
            default: new FormControl(false),
          });
          break
      case "vision":
          this.defaultViewForm = new FormGroup({
            project: new FormControl(false),
            daily: new FormControl(false),
            time: new FormControl(false),
            calendar: new FormControl(false),
            notes: new FormControl(false),
            vision: new FormControl(true),
            chat: new FormControl(false),
            overdue: new FormControl(false),
            default: new FormControl(false),
          });
          break
      case "chat":
          this.defaultViewForm = new FormGroup({
            project: new FormControl(false),
            daily: new FormControl(false),
            time: new FormControl(false),
            calendar: new FormControl(false),
            notes: new FormControl(false),
            vision: new FormControl(false),
            chat: new FormControl(true),
            overdue: new FormControl(false),
            default: new FormControl(false),
          });
          break
      case "overdue":
          this.defaultViewForm = new FormGroup({
            project: new FormControl(false),
            daily: new FormControl(false),
            time: new FormControl(false),
            calendar: new FormControl(false),
            notes: new FormControl(false),
            vision: new FormControl(false),
            chat: new FormControl(false),
            overdue: new FormControl(true),
            default: new FormControl(false),
          });
          break
      case null:
          this.defaultViewForm = new FormGroup({
            project: new FormControl(false),
            daily: new FormControl(false),
            time: new FormControl(false),
            calendar: new FormControl(false),
            notes: new FormControl(false),
            vision: new FormControl(false),
            chat: new FormControl(false),
            overdue: new FormControl(false),
            default: new FormControl(true),
          });
          break
  
      
    }
  }

  updateScore(){
    this.score = parseInt(localStorage.getItem('mood-score'))
  }

  setup(){
    //console.log(this.username)
    this.http.get(this.prod_api+"/users/get_users?OwnerToken="+this.username).subscribe(data=>{
      if(data!=null){
        this.user = data
        this.bio = this.user.bio
        mixpanel.track('user login/refreshed')
        mixpanel.identify(this.username);
        mixpanel.people.set({ "Name": this.username });
        mixpanel.people.set({ "Email": this.user.email });
        this.service.updateUserChatContainer(this.user)
        this.changeDetector.detectChanges();
      }
      
      //console.log(this.user)
     

      this.service.chatRooms.subscribe(data=>{
        if(data != undefined || data != null){
          //console.log(data)
          data.forEach((element:any)=>{
            this.http.get(this.prod_api+"/chatterbox/getMessages?roomId="+element.roomId).subscribe((data:any)=>{
              data.forEach(element => {
                if((element.isRead == null || element.isRead == false) && (element.from != this.username)){
                  this.badgeCount++
                  this.changeDetector.detectChanges();
                }
              });
            },err=>{
              //console.log(err)
            })
          })
        }
      
      })

      console.log(this.user)
      if(this.user.profile_pic){
        console.log(this.user.profile_pic)
        this.imagebase64 = this.user.profile_pic
        this.changeDetector.detectChanges()
      }else{
        console.log("no image")
        this.imagebase64 = "https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png"
      }

    },err=>{
      //console.log(err)
    })
  }

  showNotification(): void {
    this.alertService.open(`Please Refresh To See Changes`, {label: `Settings Updated`}).subscribe();
  }

  updateBio(){
    //console.log(this.bio)
    this.user.bio = this.bio
    this.http.put(this.prod_api+"/users/update",this.user).subscribe(
        data=>{
          this.user = data
        }, err=>{
          //console.log(err)
        });
        
  }

  updateValue(){
    this.badgeCount = 0
    
  }

  updateEmail(){
    //console.log(this.user.email)
    var elem:any = document.getElementById('email')
    var innerHtml:string = elem.innerText
    //console.log(innerHtml)
    this.user.email = innerHtml
    this.http.put(this.prod_api+"/users/update",this.user).subscribe(
        data=>{
          this.user = data
        }, err=>{
          //console.log(err)
        });
        
  }

  upgradeToPremium(){
    //console.log(this.user.username)
  }

  toggleTrue(){
    this.isVisible = true
  }

  toggleFalse(){
    this.isVisible = false
  }


  showDialog(
    content: PolymorpheusContent<TuiDialogContext>, 
    header: PolymorpheusContent,
    size: TuiDialogSize,
    label:string
    ): void {
    this.http.get(this.prod_api+"/users/get_users?OwnerToken="+this.username).subscribe(data=>{
      this.user = data
    },err=>{
      //console.log(err)
    })
    this.dialogService.open(content,{
      label: label,
      header,
      size:'auto',
  }).subscribe();
}



  changedImage(event: { target: { files: null[]; }; }) {
    this.selectedFile = event.target.files[0];
  }

  upload() {
    this.isUploading = !this.isUploading
    this.progress = 0;
    this.currentFileUpload = this.selectedFiles.item(0);
    this.pushFileToStorage(this.currentFileUpload).subscribe((event: any) => {
      if (event.type === HttpEventType.UploadProgress) {
        this.progress = Math.round(100 * event.loaded / event.total);
      } else if (event instanceof HttpResponse) {
        this.toastr.success('File Successfully Uploaded');
        this.isUploading = !this.isUploading
      }
      this.selectedFiles = undefined;
     }
    );
  }

uploadImage(event: any) {
this.isUploading = true;
var files = event.target.files;
const reader = new FileReader();
reader.readAsDataURL(files.item(0));
reader.onload = () => {
const formData = new FormData();
formData.append("image", files.item(0));
formData.append("token", "Yauj2ne4aVEjPjcjXf3vFhw93APcr7hm");

this.http.get("https://api.gofile.io/getServer").subscribe((data: any) => {
var url = "https://" + data.data.server + ".gofile.io/uploadFile";
this.http.post(url, formData, {
reportProgress: true,
}).subscribe((data: any) => {

this.http.get("https://api.gofile.io/getContent?contentId=" + data.data.parentFolder + "&token=Yauj2ne4aVEjPjcjXf3vFhw93APcr7hm").subscribe((dataFileLink: any) => {
var parent = data.data.fileId;
console.log(dataFileLink.data.contents[parent]);

const options = {
'contentId': dataFileLink.data.contents[parent].id,
'token': 'Yauj2ne4aVEjPjcjXf3vFhw93APcr7hm',
'option': 'directLink',
'value': 'true',
};

// This is where I keep getting the 401:
this.http.put("https://api.gofile.io/setOption", options).subscribe((data: any) => {
console.log(data);
if (data.status === 'ok') {
console.log(data.data)
this.user.profile_pic = data.data;
this.imagebase64 = data.data;
this.http.put(this.prod_api + "/users/update", this.user).subscribe(
data => {
this.user = data;
this.isUploading = false;
this.changeDetector.detectChanges();
}, err => {
console.log(err);
});
this.changeDetector.detectChanges();
}
})

});
});
});
};

}

  selectFile(event: any) {
    //console.log("running this code and doing it well")
    this.selectedFiles = event.target.files;
    this.upload()
  }

  pushFileToStorage(file: File):any{
    const data: FormData = new FormData();
    data.append('title', file.name);
    data.append('image', file);
    data.append('id',this.username)
    const newRequest = new HttpRequest('POST', this.prod_api+'/users/photos', data, {
      reportProgress: true,
      responseType: 'text'
    });
    return this.http.request(newRequest);
  }

  logout(){
    localStorage.removeItem('userId')
  }

  set(index:number){
    localStorage.setItem("wallpaper",index.toString())
    this.showNotification()
  } 

  unset(){
    localStorage.removeItem("wallpaper")
    this.showNotification()
  }

  setView(view:string){
    localStorage.setItem("view",view)
    this.showNotification()
    this.setViewFormValues()
  }

  unsetView(){
    localStorage.removeItem("view")
    this.showNotification()
    this.setViewFormValues()
  }

  setDisabled(value:boolean){
    if(this.disableTooltips==false){
      localStorage.setItem("isDisabled",'true')
      this.disableTooltips = true
      this.settingsForm = new FormGroup({
        disableTooltips: new FormControl(this.disableTooltips),
        hideTemplates: new FormControl(this.hideTemplates),
        hideCompleteTasks: new FormControl(this.hideCompleteTasks)
    });
      this.showNotification()
    }else{
      localStorage.removeItem("isDisabled")
      this.disableTooltips = false
      this.settingsForm = new FormGroup({
        disableTooltips: new FormControl(this.disableTooltips),
        hideTemplates: new FormControl(this.hideTemplates),
        hideCompleteTasks: new FormControl(this.hideCompleteTasks)
    });
      this.showNotification()
    }
   
  }


  setTemplatesDisabled(value:boolean){
    if(this.hideTemplates==false){
      localStorage.setItem("hideTemplates",'true')
      this.hideTemplates = true
      this.settingsForm = new FormGroup({
        disableTooltips: new FormControl(this.disableTooltips),
        hideTemplates: new FormControl(this.hideTemplates),
        hideCompleteTasks: new FormControl(this.hideCompleteTasks)
    });
      this.showNotification()
    }else{
      localStorage.removeItem("hideTemplates")
      this.hideTemplates = false
      this.settingsForm = new FormGroup({
        disableTooltips: new FormControl(this.disableTooltips),
        hideTemplates: new FormControl(this.hideTemplates),
        hideCompleteTasks: new FormControl(this.hideCompleteTasks)
    });
      this.showNotification()
    }
   
  }
  setCompleteTasksDisabled(value:boolean){
    if(this.hideCompleteTasks==false){
      localStorage.setItem("hideCompleteTasks",'true')
      this.hideCompleteTasks = true
      this.settingsForm = new FormGroup({
        disableTooltips: new FormControl(this.disableTooltips),
        hideTemplates: new FormControl(this.hideTemplates),
        hideCompleteTasks: new FormControl(this.hideCompleteTasks)
    });
      this.showNotification()
    }else{
      localStorage.removeItem("hideCompleteTasks")
      this.hideCompleteTasks = false
      this.settingsForm = new FormGroup({
        disableTooltips: new FormControl(this.disableTooltips),
        hideTemplates: new FormControl(this.hideTemplates),
        hideCompleteTasks: new FormControl(this.hideCompleteTasks)
    });
      this.showNotification()
    }
   
  }

  setCustomLinks(){
    localStorage.setItem('currentCustomLinks',JSON.stringify(this.customlinks))
    //console.log(localStorage.getItem("currentCustomLinks"))
  }

  addLink(){
    this.customlinks.push({"value":this.value,"htmlid":this.name})
    this.showNotification()
    localStorage.setItem("currentCustomLinks",JSON.stringify(this.customlinks))
  }

  resetLinks(){
    this.customlinks = []
    this.showNotification()
    localStorage.setItem("currentCustomLinks",JSON.stringify(this.customlinks))
  }

  unsetDisabled(){
    localStorage.removeItem("isDisabled")
    this.showNotification()
  }

  remove(link:any){
    var index = this.customlinks.indexOf(link);
    if (index > -1) {
      this.customlinks.splice(index, 1);
    }
    localStorage.setItem("currentCustomLinks",JSON.stringify(this.customlinks))
  }

}
