import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';

import { ModalService } from 'src/app/Modals/_modal';
import * as uuid from "uuid"

import { TuiDialogService,TuiDialogContext } from '@taiga-ui/core';
import {PolymorpheusContent} from '@tinkoff/ng-polymorpheus';

@Component({
  selector: 'app-time-slot',
  templateUrl: './time-slot.component.html',
  styleUrls: ['./time-slot.component.sass']
})
export class TimeSlotComponent implements OnInit {

  @Input() slot
  @Input() defaultProject:any
  currentId = uuid.v4()
  months=["January","Feburary","March","April","May","June","July","August","September","October","November","December"]
  time
  slot_type
  tasks
  newTask:any
  style

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  today = new Date()
  open_sidebar: boolean;

  constructor(private http:HttpClient,private modalService:ModalService,@Inject(TuiDialogService) private readonly dialogService: TuiDialogService) { }

  ngOnInit(): void {
    this.slot_type = "slot"
    if(this.slot.slot!=""){
      this.time=this.slot.slot
      this.slot_type = "time"
    }else{
      this.time =""
    }

    this.tasks = this.slot.tasks

    if(this.today.getDay()=== this.slot.day){
      this.slot_type="today_slot"
    }
  }


  toggle(open: boolean): void {
    this.open_sidebar = open;
}

onActiveZone(event){
//console.log(event)
this.open_sidebar = event
}

  AddNewTask(){
    var today = new Date(this.slot.slotDate);
    var tomorrow = new Date();
    tomorrow.setDate(today.getDate());
    const Task = {
      taskID:uuid.v4(),
      searchableID:uuid.v4(),
      title:"Task Title",
      description: "This is just a simple description of the task at hand, here you can store links, and store reminders of some small and important stuffs",
      taskType:"Reminder",
      priorityType:"IMPORTANT_NOT_URGENT",
      layout_x:0.000,
      layout_y:0.000,
      projectID:this.defaultProject.id,
      owner:this.defaultProject.owner,
      dueDate:tomorrow,
      startDate:tomorrow,
      timeSpent:0,
      isHabit:true,
      complexityType:"MEDIUM",
      startCondition:"START AT 17:00",
      stopping_condition:"STOP_ON_COMPLETE",
      subTasks:[],
      custom_status:null,
      comments:[],
      labels:[],
      timeString:this.slot.value,
      collaborators:[],
      currentStatus:"TODO"
    }
    ;
    this.newTask = this.http.post(this.prod_api+"/tasks/create",Task).subscribe(data=>{
      this.newTask = data
     // //console.log(this.newTask)
      this.slot.tasks.push(this.newTask)
      
    },err=>{
      //console.log(err)
    })

  
  }



}
