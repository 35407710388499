import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { project } from 'src/app/Dashboard/ProjectDashboard/dash-contianer/project';
import * as uuid from "uuid"


@Component({
  selector: 'app-kanban',
  templateUrl: './kanban.component.html',
  styleUrls: ['./kanban.component.sass']
})



export class KanbanComponent implements OnInit {

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  
  
  blockArray:any
  project:any

  title:string=""
  description:string=""
  deadline:Date=new Date()
  projectID:string=""
  newProject:any
  apiObject:any
  sub:any
  collabs:number = 0
  currentId:string = uuid.v4()
  
  constructor(private http:HttpClient,private Activatedroute:ActivatedRoute,private toastr:ToastrService,) {}

  
  ngOnInit(): void {
    
    this.sub = this.Activatedroute.queryParamMap
    .subscribe(params => {
    this.projectID = params.get('id')||"no such id in the database";     
    //console.log(this.projectID)
 
    });

    this.project = this.http.get(this.prod_api+"/projects/personal/id?ProjectID="+this.projectID).subscribe(data=>{
      this.project = data
      //console.log(this.project)
      //console.log(this.project.title)
      this.title = this.project.title
      this.description = this.project.description
      this.collabs = this.project.collaborators.length
    })
        
    this.apiObject = this.http.get(this.prod_api+"/projects/subprojects?MainProjectToken="+this.projectID).subscribe(
      data=>{
        this.blockArray = data
        
      }, err=>{
        //console.log(err)
      });   
   
  }

  createProject()
  {
  
     const Project:project = {
       "id":uuid.v4(),
       "subProject":this.project.id,
       "searchableID":uuid.v4(),
       "project_color":"",
       "owner":this.project.owner,
       "title":"Project Title",
       "description":"just a basic description of the project as a whole, this can contain links to notes and other parts of the project that really matter etc",
       "collaborators":[],
       "deadline":new Date(),
       "dateCreated":new Date(),
       "dateStart":new Date(),
       "archived":false,
       "deleted":false,
       "complete":false,
       "additionalArray":[],
       "additionalString":""
     }

     this.newProject = this.http.post(this.prod_api+"/projects/new",Project).subscribe(data=>{
         this.newProject = data
         this.blockArray.push(this.newProject)
         this.toastr.success("Successfully Created Project")
     },err=>{
       //console.log(err)
       this.toastr.error("Failed to Created project")
     })


    
    
  }

}
