<div id="toolbar_container">
    <div id="back" routerLink="/project"><img src="../../../assets/back_arrow.png"></div>
    <div id="title">{{title}}</div>
    <div id="buttons">
        <tui-hosted-dropdown
        
        [sided]="false"
        [content]="dropdown_menu"
        [(open)]="open"
        id="status" 
        >
        <div id="button_container">
            <div id="title_tooblbar_button"><img src="../../../assets/lrg_context.png"/></div>
            <div id="back_toobar_button">Global Actions</div>
        </div>

    </tui-hosted-dropdown>

        <div id="button_container"(click)="showDialogCollab(history)">
            <div id="title_tooblbar_button"><img src="../../../assets/notifications.png" /></div>
            <div id="back_toobar_button" badge="{{notifications.length}}">Notifications</div>
        </div>

        <div id="button_container" (click)="showDialogCollab(files)">
            <div id="title_tooblbar_button"><img src="../../../assets/files.png"/></div>
            <div id="back_toobar_button">Files</div>
        </div>

        <!-- <div id="button_container" routerLink="/board" [queryParams]="{id:project.id}">
            <div id="title_tooblbar_button"><img src="../../../assets/boardView.png"/></div>
            <div id="back_toobar_button">Board View</div>
        </div> -->

        <div id="button_container"  routerLink="/table" [queryParams]="{id:project.id}">
            <div id="title_tooblbar_button"><img src="../../../assets/tableView.png"/></div>
            <div id="back_toobar_button">Table View</div>
        </div>
     </div>
</div>


<ng-template #files let-observer>
    <div id="fileholder">
        <h1>Project Files:</h1>
        <ng-container *ngFor="let model of project.fileArray">
        <div id="container" *ngIf="!model.isDeleted">
            <img src="../../../assets/files.png" alt="dp"  width="13.5px" height="15px" id="img">
            <div id="filename">{{model.name}}</div>
            <div id="description">Size: {{rounding(model.filesize/1000000)}}MB</div>
            <div id="button_template_card"><img src="../../../../assets/download.png" alt="" (click)="downloadFile(model)"></div>
            <div id="button_template_card"><img src="../../../../assets/close_small.png" alt="" (click)="deleteFile(model)"></div>
        </div>
        </ng-container>

    <div id="input-container">
        <div id="flow-button-container">
            <label id="flow-button-label">
                <input type="file" [(ngModel)]="filename" (change)="selectFile($event)"/>
                    Select File
            </label>
        </div>
        <div>{{filename}}</div>
    </div>
    <button id="flow-button" *ngIf="isUploading"  (click)="upload()">Save File</button>
    <app-project-progress-bar [progress]="progress"  *ngIf="!isUploading"></app-project-progress-bar>
    </div>

       
    </ng-template>

<ng-template #history let-observer>
    <div id="historyholder">
        <h1>Project History:</h1>
        <div id="container_hisstry" *ngFor="let model of notifications">
            <img src="../../../assets/notifications.png" alt="dp"  width="13.5px" height="15px" id="img">
            <div id="filename">{{model.content}}</div>
            <div id="description">{{model.from}}</div>
            <div id="description">{{model.date}}</div>
            <div id="description">{{model.time.split(" ")[0]}}</div>
            <div id="button_template_card"><img src="../../../../assets/close_small.png" alt=""></div>
        </div>
    </div>
 
</ng-template>

<ng-template
    #dropdown_menu
    let-close="close"
>
    <tui-data-list>
        <tui-opt-group>
            <button
                *ngFor="let item of items"
                tuiOption
                (click)="MenuItem(item)"
            >
                {{ item }}
            </button>
           
        </tui-opt-group>
        <tui-opt-group>
            <button
                tuiOption
                (click)="deleteProject()"
            >
                Delete
            </button>
        </tui-opt-group>
    </tui-data-list>
</ng-template>
