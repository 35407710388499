import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-moodtracker',
  templateUrl: './moodtracker.component.html',
  styleUrls: ['./moodtracker.component.sass']
})
export class MoodtrackerComponent implements OnInit {

  constructor() { }
  localMoodScore=0;
  HasResetWeekly = false;
  today = new Date();

  ngOnInit(): void {
    if(localStorage.getItem("mood-score")===null){
      localStorage.setItem("mood-score","0")
    }

    if(localStorage.getItem("reset-mood-score")===null){
      localStorage.setItem("reset-mood-score","false")
    }

    if(this.today.getDay()===1){
      if(localStorage.getItem("reset-mood-score")==="false"){
        localStorage.setItem("mood-score","0")
        localStorage.setItem("reset-mood-score","true")
      }else{
        //console.log("already reset the tracker value")
      }
    }else if(this.today.getDay()!=1){
      localStorage.setItem("reset-mood-score","false")
    }

    

    this.localMoodScore = parseInt(localStorage.getItem("mood-score"))

  }

  modifyScore(num:number){
    this.localMoodScore+=num
    localStorage.setItem("mood-score",this.localMoodScore.toString())
  }

}
