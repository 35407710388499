<div id="{{style}}" (click)="emitDay()">
    {{day}}
    <div id="month">{{monthStr}}</div>
    <div id="flexe">
        <div id="mark" *ngIf="mark1"></div>
        <div id="mark" *ngIf="mark2"></div>
        <div id="mark" *ngIf="mark3"></div>
        <div id="mark" *ngIf="mark4"></div>
        <div id="mark" *ngIf="mark5"></div>
        <div id="mark" *ngIf="mark6"></div>
    </div>
</div>


