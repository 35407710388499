<div id="buttons">
    <tui-hosted-dropdown
    
    [sided]="false"
    [content]="dropdown_menu"
    [(open)]="open"
    id="status" 
    >
    <div id="container">...</div>
</tui-hosted-dropdown>


<ng-template #dropdown_menu
    let-close="close"
>
    <tui-data-list>
        <tui-opt-group>
            <button
                *ngFor="let item of items"
                tuiOption
                (click)="MenuItem(item)"
            >
                {{ item }}
            </button>
           
        </tui-opt-group>
        <tui-opt-group>
            <button
                tuiOption
                (click)="deleteProject()"
            >
                Delete
            </button>
        </tui-opt-group>
    </tui-data-list>
</ng-template>


