import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit,OnDestroy, Output,EventEmitter, HostListener, ChangeDetectionStrategy, Inject, ChangeDetectorRef, Injector } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { NoteEditorServiceService } from 'src/app/services/note_editor_service/note-editor-service.service';
import * as uuid from "uuid"
import { ModalService } from 'src/app/Modals/_modal/modal.service';
import {
  defaultEditorExtensions,
  TuiEditorTool,
  TUI_EDITOR_EXTENSIONS,
} from '@taiga-ui/addon-editor';

import {DomSanitizer, SafeHtml, SafeStyle} from '@angular/platform-browser';
import {defaultEditorColors} from '@taiga-ui/addon-editor';

import * as pdfMake from "pdfmake/build/pdfmake";  
import * as pdfFonts from "pdfmake/build/vfs_fonts";  
import { FormControl, Validators } from '@angular/forms';
import { WebsocketService } from 'src/app/services/websockets/web-socket-service.service';
import { TuiDestroyService, tuiPure } from '@taiga-ui/cdk';
declare var require: any;
const htmlToPdfmake = require("html-to-pdfmake");
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-notebook-wysiwyg',
  templateUrl: './notebook-wysiwyg.component.html',
  styleUrls: ['./notebook-wysiwyg.component.sass'],
  providers: [
    {
      provide: TUI_EDITOR_EXTENSIONS,
      deps: [Injector],
      useFactory: (injector: Injector) => [
          import('@taiga-ui/addon-editor/extensions/starter-kit')
              .then(({StarterKit}) => StarterKit)
              .then(extension => extension.configure({heading: {levels: [1, 2]}})),
          import('@tiptap/extension-text-align').then(({default: TextAlign}) =>
              TextAlign.configure({types: ['heading', 'paragraph']}),
          ),
          import('@tiptap/extension-text-style').then(
              ({default: TextStyle}) => TextStyle,
          ),
          import('@tiptap/extension-underline').then(
              ({default: Underline}) => Underline,
          ),
          import('@tiptap/extension-subscript').then(
              ({default: Subscript}) => Subscript,
          ),
          import('@tiptap/extension-superscript').then(
              ({default: Superscript}) => Superscript,
          ),
          import('@taiga-ui/addon-editor/extensions/font-color').then(
              ({FontColor}) => FontColor,
          ),
          import('@taiga-ui/addon-editor/extensions/link').then(
              ({TuiLink}) => TuiLink,
          ),
          import('@taiga-ui/addon-editor/extensions/jump-anchor').then(
              ({TuiJumpAnchor}) => TuiJumpAnchor,
          ),
          import('@taiga-ui/addon-editor/extensions/file-link').then(
              ({TuiFileLink}) => TuiFileLink,
          ),
          import('@taiga-ui/addon-editor/extensions/background-color').then(
              ({BackgroundColor}) => BackgroundColor,
          ),
          import('@taiga-ui/addon-editor/extensions/table').then(({TuiTable}) =>
              TuiTable.configure({resizable: true}),
          ),
          import('@tiptap/extension-table-row').then(
              ({default: TableRow}) => TableRow,
          ),
          import('@tiptap/extension-table-cell').then(
              ({default: TableCell}) => TableCell,
          ),
          import('@tiptap/extension-table-header').then(
              ({TableHeader}) => TableHeader,
          ),
          import('@taiga-ui/addon-editor/extensions/indent-outdent').then(
              ({TuiTabExtension}) => TuiTabExtension,
          ),
          import('@taiga-ui/addon-editor/extensions/table-cell-background').then(
              ({TableCellBackground}) => TableCellBackground,
          ),
          import('@taiga-ui/addon-editor/extensions/details').then(
              ({TuiDetailsContent}) => TuiDetailsContent,
          ),
          import('@taiga-ui/addon-editor/extensions/details').then(
              ({TuiDetails}) => TuiDetails,
          ),
          import('@taiga-ui/addon-editor/extensions/details').then(
              ({TuiSummary}) => TuiSummary,
          ),
          import('@taiga-ui/addon-editor/extensions/font-size').then(
              ({TuiFontSize}) => TuiFontSize,
          ),
          import('@taiga-ui/addon-editor/extensions/youtube').then(
            ({Youtube}) => Youtube,
          ),
          import('@taiga-ui/addon-editor/extensions/image-editor').then(
              ({createImageEditorExtension}) =>
                  createImageEditorExtension(injector),
          ),
          import('@tiptap/extension-focus').then(({FocusClasses}) =>
              FocusClasses.configure({
                  className: 'has-focus',

                  /**
                   * @description:
                   * Apply the class to 'all', the 'shallowest' or the 'deepest' node.
                   *
                   * Default: 'all'
                   */
                  mode: 'shallowest',
              }),
          ),
      ],
  }
],
changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotebookWYSIWYGComponent implements OnInit {

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  control =  `<h1>Start Typing...</h1><p>Your Epic story awaits</p>`
  note:any
  notesArray:any
  updatedNote:any
  selectedFiles: any;
  currentId = uuid.v4()
  editable:boolean = true
  index:number = 0
  id:any
  bodyText:string=""
  username = localStorage.getItem('userId')
  @Input() notebook
  color = `#ffdd2d`;
  color_highlight = `#ffdd2d`;
  regExUrl = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g
  isLoading = false



  status ="Unsaved"

  @Output() autosave:EventEmitter<any> = new EventEmitter()


  constructor(
    private http:HttpClient,
    private service:NoteEditorServiceService,
    public modalService:ModalService,
    public socketService:WebsocketService,
    private changeDetector:ChangeDetectorRef,
    @Inject(DomSanitizer) private readonly sanitizer: DomSanitizer
    ) {}

      /**
     * TUI_SANITIZER doesn't support iframe inside content
     */
      @tuiPure
      safe(content: string): SafeHtml {
          return this.sanitizer.bypassSecurityTrustHtml(content);
      }
      
  ngOnInit(): void {

    this.service.editorIndex.subscribe(data=>{
      this.index = data
      this.note = this.notesArray[this.index]
      this.control = this.note.content
      this.changeDetector.detectChanges()
      //console.log(this.index)
    })

    

     this.service.editorNote.subscribe(data=>{
       this.notesArray = data
      // //console.log("this is working even though it is ugly************************************")
       this.note = this.notesArray[this.index]
       this.control = this.note.content
       //console.log(this.note)
       this.http.get(this.prod_api+"/notebooks/notebook_id?SearchableID="+this.note.notebookID).subscribe(dara=>{
        this.notebook = dara
        //console.log("got the notebook")
       },err=>{
        //console.log(err)
       })

     })  



    this.socketService.socket.on("textUpdate",(newText:string)=>{
      console.log(newText)
      this.control = newText
      this.changeDetector.detectChanges()
      //this.socketService.showNotification("updated Text","A user just made changes to the document you are working on")
    })
  

  }

  saveOnWord(){
    this.autosave.emit(this.index)
  }

  open=false
  open_highlight=false
  readonly palette = defaultEditorColors;
  toggleOpen(){
    this.open = !this.open
  }


  get background(): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(this.color);
  }

  onTextUpdate(){

    //console.log("update",this.notebook)
    //console.log(this.note)
    if(this.notebook == undefined || null){
      //console.log("getting the noteboook")
      this.http.get(this.prod_api+"notebooks/notebook_id?SearchableID="+this.note.notebookID).subscribe(dara=>{
        this.notebook = dara
        //console.log("got the notebook")
        if(this.notebook.collaborators!=null||undefined){
          this.notebook.collaborators.forEach(member=>{
            if(member!=this.username){
              this.socketService.socket.emit("textUpdate",document.getElementById("container_content_editable").innerHTML,member)
            }
        })
        }
       },err=>{
        //console.log(err)
       })
    }
   

  }
  
  ngOnDestroy(){
    clearInterval(this.id)
  }


  public eventPrevent(event){
    event.preventDefault()
  }

  selection:any
  range: any

  saveRange_Selection(){
    this.selection = window.getSelection()?.getRangeAt(0)
    this.range = this.selection?.cloneRange();
  }

  public highlightContent(color:string): void {
    const marker = document.createElement('span');
    marker.style.backgroundColor = color
    marker.setAttribute('id','highlighter_new_update');
    this.range?.surroundContents(marker);
    //console.log("should have highlighted")
    return;
  }

  public colorContent(color:string): void {
    const marker = document.createElement('span');
    marker.style.color = color
    marker.setAttribute('id','highlight_new_update');
    this.range?.surroundContents(marker);
    //console.log("should have highlighted")
    return;
  }

  public boldContent(): void {
    const selectionRange = window.getSelection()?.getRangeAt(0);
    const range = selectionRange?.cloneRange();
    const marker = document.createElement('strong');
    range?.surroundContents(marker);
    return;
  }


  public italicContent(): void {
    const selectionRange = window.getSelection()?.getRangeAt(0);
    const range = selectionRange?.cloneRange();
    const marker = document.createElement('i');
    range?.surroundContents(marker);
    return;
  }


  public underlineContent(): void {
    const selectionRange = window.getSelection()?.getRangeAt(0);
    const range = selectionRange?.cloneRange();
    const marker = document.createElement('u');
    range?.surroundContents(marker);
    return;
  }

  public strikeContent(): void {
    const selectionRange = window.getSelection()?.getRangeAt(0);
    const range = selectionRange?.cloneRange();
    const marker = document.createElement('s');
    range?.surroundContents(marker);
    return;
  }

  public h1Content(): void {
    const selectionRange = window.getSelection()?.getRangeAt(0);
    const range = selectionRange?.cloneRange();
    const marker = document.createElement('h1');
    range?.surroundContents(marker);
    return;
  }

  public h2Content(): void {
    const selectionRange = window.getSelection()?.getRangeAt(0);
    const range = selectionRange?.cloneRange();
    const marker = document.createElement('h2');
    range?.surroundContents(marker);
    return;
  }

  public h3Content(): void {
    const selectionRange = window.getSelection()?.getRangeAt(0);
    const range = selectionRange?.cloneRange();
    const marker = document.createElement('h3');
    range?.surroundContents(marker);
    return;
  }

  public ContentTwelve(): void {
    const selectionRange = window.getSelection()?.getRangeAt(0);
    const range = selectionRange?.cloneRange();
    var marker = document.createElement('span');
    marker.style.fontSize = "12px";
    range?.surroundContents(marker);
    return;
  }

  public ContentFourteen(): void {
    const selectionRange = window.getSelection()?.getRangeAt(0);
    const range = selectionRange?.cloneRange();
    var marker = document.createElement('span');
    marker.style.fontSize = "14px";
    range?.surroundContents(marker);
    return;
  }

  public ContentEighteen(): void {
    const selectionRange = window.getSelection()?.getRangeAt(0);
    const range = selectionRange?.cloneRange();
    var marker = document.createElement('span');
    marker.style.fontSize = "18px";
    range?.surroundContents(marker);
    return;
  }

  public ContentTwenty(): void {
    const selectionRange = window.getSelection()?.getRangeAt(0);
    const range = selectionRange?.cloneRange();
    var marker = document.createElement('span');
    marker.style.fontSize = "20px";
    range?.surroundContents(marker);
    return;
  }

  public ContentTwentyFour(): void {
    const selectionRange = window.getSelection()?.getRangeAt(0);
    const range = selectionRange?.cloneRange();
    var marker = document.createElement('span');
    marker.style.fontSize = "24px";
    range?.surroundContents(marker);
    return;
  }

  public bulletContent(): void {
     const selectionRange = window.getSelection()?.getRangeAt(0);
     const range = selectionRange;
     const list = document.createElement('ul');
     const selection = window.getSelection()?.toString();
     selection?.split(/\n+/).forEach((elem:string)=>{
      var item = document.createElement("li")
      item.appendChild(document. createTextNode(elem)) 
      list.appendChild(item)
     })
    
     range?.deleteContents();
     range?.insertNode(list);

    return;
  }


  activeItemIndex = 0;
 
  items = Array.from({length: 5}, (_, i) => `Item #${i}`);

  add(): void {
      this.items = this.items.concat(`Item #${Date.now()}`);
  }

  remove(removed: string): void {
      const index = this.items.indexOf(removed);

      this.items = this.items.filter(item => item !== removed);

      if (index <= this.activeItemIndex) {
          this.activeItemIndex = Math.max(this.activeItemIndex - 1, 0);
      }
  }




  public numberContent(): void {
    const selectionRange = window.getSelection()?.getRangeAt(0);
     const range = selectionRange;
     const list = document.createElement('ol');
     const selection = window.getSelection()?.toString();
     selection?.split(/\n+/).forEach((elem:string)=>{
      var item = document.createElement("li")
      item.appendChild(document. createTextNode(elem)) 
      list.appendChild(item)
     })
    
     range?.deleteContents();
     range?.insertNode(list);
   
    return;
  }


  encodeImageFileAsURL(event:any) {
    var filesSelected = event.target.files
    var fileToLoad = filesSelected[0];
    var fileReader = new FileReader();

      fileReader.onload = function(fileLoadedEvent) {
        const srcData = fileLoadedEvent.target!.result; // <--- data: base64

        var newImage = document.createElement('img');
        newImage.src = srcData?.toString() || "";
        newImage.width =500

        document.getElementById("container_content_editable")?.appendChild(newImage);
        //alert("Converted Base64 version is " + document.getElementById("Content")?.innerHTML);
        //console.log("Converted Base64 version is " + document.getElementById("container_content_editable")?.innerHTML);
      }
      fileReader.readAsDataURL(fileToLoad);
    
  }

  
  

  saveContent(){
    this.status = "Unsaved 😬😬"
    //console.log(this.index)
    this.editable = false
    this.editable = true
    this.note.content = this.control

    this.http.put(this.prod_api+"/notes/update_note",this.note).subscribe(
        data=>{
          // this.note = data
          // this.notesArray[this.index] = data
          //console.log(this.note)
         // this.socketService.showNotification("Note Saved","contents of the note have been saved")
          this.status = "Saved 😊"
          this.changeDetector.detectChanges()
          
         // this.service.updateProject(this.notesArray)
        }, err=>{
          //console.log(err)
         // this.socketService.showNotification("Note Failed To Save","NOTE DID NOT SAVE")
          this.status ='Failed to save 💔'
          this.changeDetector.detectChanges()
        });
  }  

  updateNoteContent(){
    this.notesArray[this.index] = this.note
  }

  saveContentCTRL(event:Event){
    this.isLoading = true
    event.preventDefault()
    //console.log(this.index)
    this.editable = false
    this.note.content = this.control
    console.log(this.control)
    this.editable = true
    this.http.put(this.prod_api+"/notes/update_note",this.note).subscribe(
        data=>{
          this.note = data
          this.notesArray[this.index] = data
          //console.log(this.note)
          this.socketService.showNotification("Note Saved","contents of the note have been saved")
          this.isLoading = false
          this.changeDetector.detectChanges()
          this.status = "Saved 😊"
          this.changeDetector.detectChanges()
         // this.service.updateProject(this.notesArray)
        }, err=>{
          this.isLoading = false
          //console.log(err)
          this.changeDetector.detectChanges()
          this.socketService.showNotification("Note Failed To Save","NOTE DID NOT SAVE")
          this.status ='Failed to save 💔'
          this.changeDetector.detectChanges()

        });
  }  


  

  public checkLinks(){
    //console.log('checking links')
    const urls = document.getElementById("container_content_editable")?.innerText.match(this.regExUrl)
    var collection = document.getElementsByClassName('anchor')
    Array.from(collection).forEach(elem=>{
      //console.log(elem.outerHTML)
      //console.log(elem.innerHTML)
      var newdoc = document.getElementById("container_content_editable")?.innerHTML.replace(elem.outerHTML,elem.innerHTML)
      document.getElementById("container_content_editable").innerHTML= newdoc
    })
    //console.log("done cleaning document")
    //console.log(urls)
    if(urls!=null){
      urls.forEach((elem:any)=>{
            var newdoc = document.getElementById("container_content_editable").innerHTML.replace(elem,"<a class='anchor' contenteditable='false' target='_blank' href='"+elem+"'>"+elem+"</a>")
            //console.log('replaced successfully')
            document.getElementById("container_content_editable").innerHTML = newdoc
            
            
      })
      //console.log( document.getElementById("container_content_editable")?.innerHTML)
    }
    this.socketService.showNotification("Found and Highlighted Links","Found "+urls.length+" links in the docuemnt")
  }


  link() {
    const selectionRange = window.getSelection()?.getRangeAt(0);
    const range = selectionRange;
    const list = document.createElement('span');
    const selection = window.getSelection()?.toString();
    var item = document.createElement("span")
    item.id = this.bodyText
    item.contentEditable='false'
    item.style.color='#08f'
    item.style.textDecoration='underline'
    
    if(selection!=""){
      item.appendChild(document. createTextNode(selection)) 
      item.onclick =()=>{
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', item.id);
        range?.deleteContents();
        range?.insertNode(link);
        link.click();
        link.remove();
      }
    }else{
         item.appendChild(document.createTextNode(this.bodyText))
         item.contentEditable='true'
         document.getElementById("container_content_editable").appendChild(item)
         return
    }
    
    list.appendChild(item)
    range?.deleteContents();
    range?.insertNode(list);
  
   return;
  }

  public downloadAsPDF() {
    // var html = htmlToPdfmake(document.getElementById("container_content_editable").innerHTML);
    // const documentDefinition = { content: html };
    // pdfMake.createPdf(documentDefinition).open();
    const API_KEY = "katlehosebiloane58@gmail.com_4620641d71c11e471f03c74c09a55de77c677881df7a5a859c88d3169070d4843b459b59"
   var body = {
      "html": document.getElementById("container_content_editable").outerHTML,
      "name": this.note.title+".pdf",
      "margins": "35px 35px 35px 35px",
      "paperSize": "Letter",
      "orientation": "Portrait",
      "printBackground": true,
      "header": "",
      "footer": "",
      "mediaType": "screen",
      "async": false,
      "encrypt": false
  }

  this.http.post("https://api.pdf.co/v1/pdf/convert/from/html",body,{
    headers:{
      "x-api-key": API_KEY,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":"true"
      // "Content-Length": Buffer.byteLength(jsonPayload, 'utf8')
  }
  }).subscribe((data:any)=>{
    window.open(data.url, "_blank");
  })
     
  }

  
  onPaste(e) {
    // Stop data actually being pasted into div
    e.preventDefault();
  
    // Get pasted data via clipboard API
    const clipboardData = e.clipboardData;
    const pastedText = clipboardData.getData('text');
  
    // Insert the value on cursor position
    window.document.execCommand('insertText', false, pastedText);
  }

  
  

}
