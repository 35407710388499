<div id="container" *ngIf="isVisible"  >
 
    <div id="title" routerLink="/editor" [queryParams]="{id:null,title:note.title}">{{note.title}}</div>
    <div id="title_info">{{note.notebookID}}</div>
    <div id="title_info">{{dateString}}</div>
    <div id="title_info">{{note.noteOwner}}</div>
      <!-- <tui-hosted-dropdown
        [content]="dropdown_menu"
        [(open)]="open"
      > -->
      <div (click)="deleteNote()"> 
          <tui-svg
            src="tuiIconTrash"
            class="icon"
          >
          </tui-svg>
      </div>
      <!-- </tui-hosted-dropdown> -->
</div>


<!-- <ng-template #dropdown_menu
    let-close="close"
>
    <tui-data-list>
        <tui-opt-group>
            <button
                *ngFor="let item of items"
                tuiOption
                (click)="handleMenuItemClick(item.index)"
            >
                {{ item.name }}
            </button>
           
        </tui-opt-group>
       
    </tui-data-list>
</ng-template> -->

