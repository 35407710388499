import { HttpClient } from '@angular/common/http';
import { Component, HostListener, Input, OnInit,ChangeDetectionStrategy, ViewChild, Inject, ElementRef, ChangeDetectorRef, Output, EventEmitter, Renderer2 } from '@angular/core';
import * as uuid from "uuid"
import { ModalService } from "src/app/Modals/_modal/modal.service";
import {TuiDay, TuiDayRange} from '@taiga-ui/cdk';
import {FormControl, FormGroup} from '@angular/forms';
import {
  defaultEditorExtensions,
  TUI_EDITOR_EXTENSIONS,
} from '@taiga-ui/addon-editor';
import {TuiAlertService, TuiDialogContext, TuiDialogService, TuiDialogSize, TuiHostedDropdownComponent} from '@taiga-ui/core';
import {PolymorpheusContent} from '@tinkoff/ng-polymorpheus';
import { tuiCreateDefaultDayRangePeriods } from '@taiga-ui/kit';
import { WebsocketService } from 'src/app/services/websockets/web-socket-service.service';
import { Client, Databases } from 'appwrite';

@Component({
  selector: 'app-table-item',
  templateUrl: './table-item.component.html',
  styleUrls: ['./table-item.component.sass'],
  providers: [
    {
      provide: TUI_EDITOR_EXTENSIONS,
      useValue: defaultEditorExtensions,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableItemComponent implements OnInit {
  control:string
  index_for_update:number;
  isShown: boolean = false ; 
  isNerd: boolean = false ; 
  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  showDesc:boolean = false
  showCal:boolean = false
  bodyText: string="";
  collaborator: string="";
  currentId = uuid.v4()
  descId = uuid.v4()
  username = localStorage.getItem('userId')
  status_marker = "TODO"
  status_marker_text = "TODO_TEXT"
  @Input() task:any
  @Input() title:string=""
  numsubtask:number=0
  @Input() numcollabs:number=0
  @Input() subTaskArray:[] = []
  @Input() parent_project:any
  @Input() isOnCalendar = false
  dueDate:Date=new Date()
  numComments:number= 0
  isComplete:boolean = false
  isChecked:string=""
  time:number = 0
  isVisible:boolean = true;
  prio: string;
  svgFill: string;
  dateValue:any
  apiObject:any
  projectsArray:any
  regExTimeString = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/
  regExUrlReal = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g
  @Input() taskArray:any
  @Input() blockTaskArray:any
  @Input() index:number;

  @ViewChild('comment_list') content: ElementRef;
  @ViewChild('titleRef') titleRef;

  hoveredItem: TuiDay | null = null;
  value: TuiDayRange | null = null;
 
  items = tuiCreateDefaultDayRangePeriods();




  Recurring = new FormGroup({
    testValue1: new FormControl(false),
    testValue2: new FormControl(false),
    testValue3: new FormControl(false),
    testValue4: new FormControl(false),
    testValue5: new FormControl(false),
    testValue6: new FormControl(false),
    testValue7: new FormControl(false),
});


completeForm = new FormGroup({
  completeControl: new FormControl()
})

isLoading = false
taskStringified=""
showComplete = true;

client:any
databases:any


  constructor(
    private http:HttpClient,
    public modalService:ModalService,
    private messageService:WebsocketService,
    @Inject(TuiAlertService) private readonly alertService: TuiAlertService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    public changeDetector:ChangeDetectorRef,
    private renderer: Renderer2) { }



 


ngOnInit(): void {

  this.client = new Client();

  this.client.setEndpoint('http://server.plugza.co/v1').setProject('6495cbda253f947a994b');
  
  this.databases = new Databases(this.client);

  const promise = this.databases.createDocument('flowspace', 'task', this.task.taskID, 
  {
    "id":this.task.taskID,
    "StringRep":JSON.stringify(this.task)
  });

  promise.then(function (response) {
      console.log(response); // Success
  }, function (error) {
      console.log(error); // Failure
  });

  this.client.subscribe("databases.flowspace.collections.task.documents."+this.task.taskID, (response: any) => {
    // Callback will be executed on changes for documents A and all files.
    console.log("got the change")
    this.task = JSON.parse(response.payload.StringRep)
    this.changeDetector.detectChanges()
});

  this.showComplete = (localStorage.getItem("hideCompleteTasks")!=null && this.task.currentStatus=='COMPLETE')?false:true


  if(this.index != (0||null||undefined) && this.task.layout_y==0){ 
    this.task.layout_y = this.index
    this.updateTask()
  }

   this.index_for_update = this.blockTaskArray.indexOf(this.task)
   


   switch (this.task.currentStatus) {
     case "TODO":
          
          this.status_marker = "TODO"
          this.status_marker_text = "TODO_TEXT"
          this.isComplete = false
          this.completeForm = new FormGroup({
            completeControl: new FormControl(false),
          });
          
          this.isChecked=""
          //console.log("this task is marked as todo the code above has run")
          break;
     case "IN PROGRESS":
          this.status_marker = "INPROGRESS"
          this.status_marker_text = "INPROGRESS_TEXT"
          this.isComplete = false
          this.completeForm = new FormGroup({
            completeControl: new FormControl(false),
          });
          this.isChecked=""
          break;
     case "BACK BURNER":
          this.status_marker = "BACKBURNER"
          this.status_marker_text = "BACKBURNER_TEXT"
          this.isComplete = false
          this.completeForm = new FormGroup({
            completeControl: new FormControl(false),
          });
          this.isChecked="unchecked"
          break;
     case "COMPLETE":
          this.status_marker = "COMPLETE"
          this.status_marker_text = "COMPLETE_TEXT"
          this.isComplete = true
          this.completeForm = new FormGroup({
            completeControl: new FormControl(true),
          });
          this.isChecked="checked"
          break;   
   }

   this.DisplayPriority()

    this.taskArray.forEach((element:any) => {
      if(element.additionalString == this.task.taskID){
        this.numsubtask++
      }
    });
    
  
    
    this.dueDate = new Date(this.task.dueDate)
    this.time = this.task.timeSpent
    this.numComments = this.task.comments.length
    this.bodyText = '';
    ////console.log(this.task)

    var d = new Date(this.task.startDate);
    var dd = new Date(this.task.dueDate);
   

    this.apiObject = this.http.get(this.prod_api+"/projects/personal?OwnerToken="+this.username).subscribe(
      data=>{
        this.projectsArray = data;
      }, err=>{
        //console.log(err);
      }
    );

   
  }

  showNotification(heading:string,content:string): void {
    this.alertService.open(content, {label: heading}).subscribe();
  }

  itemSelected(item:number) {
    switch (item) {
      case 0:
           this.task.priority = "IMPORTANT_URGENT"
           this.DisplayPriority()
           const m_ = {
            roomId:this.parent_project.id,
            to:null,
            attachments:'',
            content: this.task.title+", Task Priority Set To 'Highest'",
            time: new Date().toTimeString().split(/\s+/)[0],
            from: this.username,
            date: new Date().toDateString(),
            type: "notification",
            id: uuid.v4()
          }
          this.messageService.sendMessage(m_,{roomId:this.parent_project.id,participants:this.parent_project.collaborators,lastMessage:""});
          this.updateTask()
          
           break;
      case 1:
          this.task.priority = "IMPORTANT_NOT_URGENT"
          this.DisplayPriority()
          const m = {
            roomId:this.parent_project.id,
            to:null,
            attachments:'',
            content: this.task.title+", Task Priority Set To 'High'",
            time: new Date().toTimeString().split(/\s+/)[0],
            from: this.username,
            date: new Date().toDateString(),
            type: "notification",
            id: uuid.v4()
          }
          this.messageService.sendMessage(m,{roomId:this.parent_project.id,participants:this.parent_project.collaborators,lastMessage:""});
          this.updateTask()
         
          break
      case 2:
          this.task.priority = "NOT_IMPORTANT_URGENT"
          this.DisplayPriority()
          const mW = {
            roomId:this.parent_project.id,
            to:null,
            attachments:'',
            content: this.task.title+", Task Priority Set To 'Medium'",
            time: new Date().toTimeString().split(/\s+/)[0],
            from: this.username,
            date: new Date().toDateString(),
            type: "notification",
            id: uuid.v4()
          }
          this.messageService.sendMessage(mW,{roomId:this.parent_project.id,participants:this.parent_project.collaborators,lastMessage:""});
          this.updateTask()
        
          break
      case 3:
          this.task.priority = "NOT_IMPORTANT_NOT_URGENT"
          this.DisplayPriority()
          const ma = {
            roomId:this.parent_project.id,
            to:null,
            attachments:'',
            content: this.task.title+", Task Priority Set To 'Low'",
            time: new Date().toTimeString().split(/\s+/)[0],
            from: this.username,
            date: new Date().toDateString(),
            type: "notification",
            id: uuid.v4()
          }
          this.messageService.sendMessage(ma,{roomId:this.parent_project.id,participants:this.parent_project.collaborators,lastMessage:""});
          this.updateTask()
          
        
          break
    }
  }

checknum(numver:any){
    this.numsubtask--
  }



updateTitle(){
    this.isLoading = true
    this.task.title = this.titleRef.nativeElement.innerText
    this.http.put(this.prod_api+"/tasks/update",this.task).subscribe(
        data=>{
          //console.log(this.index_for_update)
          this.blockTaskArray[this.index_for_update] = data
          this.task = data
          this.isLoading = false
          this.updated(this.task)
          this.databases.updateDocument('flowspace', 'task', this.task.taskID,{
            "id":this.task.taskID,
            "StringRep":JSON.stringify(this.task)
          }).then(function (response) {
              console.log(response); // Success
          }, function (error) {
              console.log(error); // Failure
          });
          this.changeDetector.detectChanges()
        }, err=>{
          //console.log(err)
          this.messageService.showNotification("Update Failed","Failed to update Task, Check network connection")
          this.isLoading = false
          this.changeDetector.detectChanges()
          this.changeDetector.detectChanges()
        });
        
  }

updateDescription(){
    this.isLoading = true
    this.http.put(this.prod_api+"/tasks/update",this.task).subscribe(
        data=>{
          this.blockTaskArray[this.index_for_update] = data
          this.task = data
          this.isLoading = false
          this.updated(this.task)
          this.databases.updateDocument('flowspace', 'task', this.task.taskID,{
            "id":this.task.taskID,
            "StringRep":JSON.stringify(this.task)
          }).then(function (response) {
              console.log(response); // Success
          }, function (error) {
              console.log(error); // Failure
          });
          this.changeDetector.detectChanges()
          this.changeDetector.detectChanges()
        }, err=>{
          //console.log(err)
          this.messageService.showNotification("Update Failed","Failed to update Task, Check network connection")
          this.isLoading = false
          this.changeDetector.detectChanges()
          this.changeDetector.detectChanges()
        });
       
  }

updateDate(dateRange:TuiDayRange){

    //console.log("event fire")
    this.isLoading = true
    this.task.startDate = new Date(dateRange.from.year,dateRange.from.month,dateRange.from.day+1)
    this.task.dueDate = new Date(dateRange.to.year,dateRange.to.month,dateRange.to.day+1)
    //console.log(this.task.dueDate)
    //console.log(this.task.startDate)
    this.http.put(this.prod_api+"/tasks/update",this.task).subscribe(
         data=>{
          this.isLoading = false
          this.databases.updateDocument('flowspace', 'task', this.task.taskID,{
            "id":this.task.taskID,
            "StringRep":JSON.stringify(this.task)
          }).then(function (response) {
              console.log(response); // Success
          }, function (error) {
              console.log(error); // Failure
          });
          this.changeDetector.detectChanges()
           this.task = data
             this.messageService.sendMessage({
              from:this.username,
              roomId:this.parent_project.id,
              attachments:'',
              content:this.task.title+", due date changed",
              date:new Date().toDateString(),
              time:new Date().toTimeString(),
              id:uuid.v4()
            },{roomId:this.parent_project.id,participants:this.parent_project.collaborators,lastMessage:this.task.title+", due date changed"});
          this.showNotification("Updated Task Date","this tasks date was successfully updated")
         }, err=>{
           //console.log(err)
           this.showNotification("Failed To Update Task","please refresh or check you network connection")
         });
  }

completed(task:any) {
   const m = {
    roomId:this.parent_project.id,
    to:null,
    attachments:'',
    content: this.task.title+', was completed',
    time: new Date().toTimeString().split(/\s+/)[0],
    from: this.username,
    date: new Date().toDateString(),
    type: "notification",
    id: uuid.v4()
  }
  if(this.messageService != null && this.messageService!= undefined){
    this.messageService.sendMessage(m,{roomId:this.parent_project.id,participants:this.parent_project.collaborators,lastMessage:""});
  }
}

updated(task:any) {
  const m = {
    roomId:this.parent_project.id,
    to:null,
    attachments:'',
    content: task.title+", title was updated",
    time: new Date().toTimeString().split(/\s+/)[0],
    from: this.username,
    date: new Date().toDateString(),
    type: "notification",
    id: uuid.v4()
  }
  
  this.messageService.sendMessage(m,{roomId:this.parent_project.id,participants:this.parent_project.collaborators,lastMessage:""});
  this.isLoading = false
  this.changeDetector.detectChanges()
}


updateTask(){
    this.http.put(this.prod_api+"/tasks/update",this.task).subscribe(
        data=>{
          this.task = data
          this.blockTaskArray[this.index_for_update] = data
          this.isLoading = false

          this.databases.updateDocument('flowspace', 'task', this.task.taskID,{
            "id":this.task.taskID,
            "StringRep":JSON.stringify(this.task)
          }).then(function (response) {
              console.log(response); // Success
          }, function (error) {
              console.log(error); // Failure
          });
          this.changeDetector.detectChanges()
        }, err=>{
          //console.log(err)
          this.messageService.showNotification("Update Failed","Failed to update Task, Check network connection")
          this.isLoading = false
          this.changeDetector.detectChanges()
        });
      

  }


  removeTask(task:any){
    this.isLoading = true
    this.isVisible = false
    const m = {
      roomId:this.parent_project.id,
      to:null,
      attachments:'',
      content: task.title+", title was deleted",
      time: new Date().toTimeString().split(/\s+/)[0],
      from: this.username,
      date: new Date().toDateString(),
      type: "notification",
      id: uuid.v4()
    }
    this.messageService.sendMessage(m,{roomId:this.parent_project.id,participants:this.parent_project.collaborators,lastMessage:""});
  }
  
  toggleShow(){
    this.isShown = !this.isShown

  }

  toggleNerd(){
    this.isNerd = !this.isNerd

  }

  toggleDesc(){
    this.showDesc = !this.showDesc
  }

  toggleCal(){
    this.showCal = !this.showCal
  }

  toggleComplete(){
    this.isLoading = true
    if(this.isComplete===true){
      this.task.currentStatus = "TODO"
      this.status_marker = "TODO"
      this.status_marker_text = "TODO_TEXT"
      this.isComplete = false
      this.completeForm = new FormGroup({
        completeControl: new FormControl(false),
      });
      this.isChecked=""

      const m_ = {
       roomId:this.parent_project.id,
       to:null,
       attachments:'',
       content: this.task.title+", Task Status Set To 'TODO'",
       time: new Date().toTimeString().split(/\s+/)[0],
       from: this.username,
       date: new Date().toDateString(),
       type: "notification",
       id: uuid.v4()
     }
     if(this.messageService!=null){
      this.messageService.sendMessage(m_,{roomId:this.parent_project.id,participants:this.parent_project.collaborators,lastMessage:""});
     }
     this.updateTask()
      return
    }else if(this.isComplete===false){
      this.task.currentStatus = "COMPLETE"
      this.status_marker = "COMPLETE"
      this.status_marker_text = "COMPLETE_TEXT"
      this.isComplete = true
      this.completeForm = new FormGroup({
        completeControl: new FormControl(true),
      });
      this.isChecked="checked"
      //console.log(this.task.title)

      const ma = {
        roomId:this.parent_project.id,
        to:null,
        attachments:'',
        content: this.task.title+", Task Status Set To 'COMPLETE'",
        time: new Date().toTimeString().split(/\s+/)[0],
        from: this.username,
        date: new Date().toDateString(),
        type: "notification",
        id: uuid.v4()
      }
      if(this.messageService!=null){
        this.messageService.sendMessage(ma,{roomId:this.parent_project.id,participants:this.parent_project.collaborators,lastMessage:""});
      }

      this.updateTask()
    }
    
  }


submitComment(){
  this.isLoading = true
    const date = new Date()
    //console.log(this.task.comments)
    var comment ={  
       creator:localStorage.getItem("userId"),
       time:date,
       content:this.bodyText,
       attachment:"",
       additionalArray:[],
       additionalString:""
      }
    this.task.comments.push(comment)
    this.bodyText=""

    const m = {
      roomId:this.parent_project.id,
      to:null,
      attachments:'',
      content: comment.creator+", commented on a "+ this.task.title,
      time: new Date().toTimeString().split(/\s+/)[0],
      from: this.username,
      date: new Date().toDateString(),
      type: "notification",
      id: uuid.v4()
    }


    this.messageService.sendMessage(m,{roomId:this.parent_project.id,participants:this.parent_project.collaborators,lastMessage:""});
    this.updateTask()
    this.scrollToBottom()

  

}

selectAll(){
  window.document.execCommand('selectAll',false,null)
}

replaceTask(event:any){
  this.task = event
  //console.log("updated current task")
  //console.log(this.task)
  this.updateTask()
  this.changeDetector.detectChanges()
}

addCollaborator(){
  this.isLoading = true
  this.task.collaborators.push(this.collaborator)
  this.collaborator=""
  const m = {
    roomId:this.parent_project.id,
    to:null,
    attachments:'',
    content: this.username+", added a collaborator",
    time: new Date().toTimeString().split(/\s+/)[0],
    from: this.username,
    date: new Date().toDateString(),
    type: "notification",
    id: uuid.v4()
  }
  this.messageService.sendMessage(m,{roomId:this.parent_project.id,participants:this.parent_project.collaborators,lastMessage:""});
  this.updateTask()

}

removeCollab(collab:string){
  this.isLoading = true
  this.task.collaborators.slice(this.task.collaborators.indexOf(collab),1);
  const m = {
    roomId:this.parent_project.id,
    to:null,
    attachments:'',
    content: this.username+", removed a collaborator",
    time: new Date().toTimeString().split(/\s+/)[0],
    from: this.username,
    date: new Date().toDateString(),
    type: "notification",
    id: uuid.v4()
  }
  this.messageService.sendMessage(m,{roomId:this.parent_project.id,participants:this.parent_project.collaborators,lastMessage:""});
  this.updateTask()
}



deleteTask(){
  this.isLoading = true
  this.http.delete(this.prod_api+"/tasks/delete?taskToDelete="+this.task.taskID).subscribe(data=>{
    this.isVisible = false
    this.isLoading = false
    this.isShown = false
    this.showCal = false
    this.showDesc = false
    //console.log(this.taskArray.indexOf(this.task))
    this.taskArray = this.taskArray.splice(this.taskArray.indexOf(this.task),1)
    this.blockTaskArray = this.blockTaskArray.splice(this.index_for_update,1)
    //console.log(this.taskArray)
    this.changeDetector.detectChanges()
    this.changeDetector.detectChanges()
    
  },err=>{
    //console.log(err)
  })
  
}


  handleMenuItemClick(event:any) {
    this.isLoading = true
    switch (event) {
      case "TODO":
           this.task.currentStatus = "TODO"
           this.status_marker = "TODO"
           this.status_marker_text = "TODO_TEXT"
           this.isComplete = false
           this.isChecked=""
           const m_ = {
            roomId:this.parent_project.id,
            to:null,
            attachments:'',
            content: this.task.title+", Task Status Set To 'TODO'",
            time: new Date().toTimeString().split(/\s+/)[0],
            from: this.username,
            date: new Date().toDateString(),
            type: "notification",
            id: uuid.v4()
          }
          if(this.messageService != null){
            this.messageService.sendMessage(m_,{roomId:this.parent_project.id,participants:this.parent_project.collaborators,lastMessage:""});
          }
          this.open_status = !this.open_status;

          this.updateTask()
          break;
      case "IN PROGRESS":
          this.task.currentStatus = "IN PROGRESS"
          this.status_marker = "INPROGRESS"
          this.status_marker_text = "INPROGRESS_TEXT"
          this.isComplete = false
          this.isChecked=""
          const m = {
            roomId:this.parent_project.id,
            to:null,
            attachments:'',
            content: this.task.title+", Task Status Set To 'IN PROGRESS'",
            time: new Date().toTimeString().split(/\s+/)[0],
            from: this.username,
            date: new Date().toDateString(),
            type: "notification",
            id: uuid.v4()
          }
          
          if(this.messageService!=null){
            this.messageService.sendMessage(m,{roomId:this.parent_project.id,participants:this.parent_project.collaborators,lastMessage:""});

          }
          this.open_status = !this.open_status;

          this.updateTask()
         
          break
      case "BACK BURNER":
          this.task.currentStatus = "BACK BURNER"
          this.status_marker = "BACKBURNER"
          this.status_marker_text = "BACKBURNER_TEXT"
          this.isComplete = false
          this.isChecked=""
          const mB = {
            roomId:this.parent_project.id,
            to:null,
            attachments:'',
            content: this.task.title+", Task Status Set To 'BACK BURNER'",
            time: new Date().toTimeString().split(/\s+/)[0],
            from: this.username,
            date: new Date().toDateString(),
            type: "notification",
            id: uuid.v4()
          }
          
          if(this.messageService!=null){
            this.messageService.sendMessage(mB,{roomId:this.parent_project.id,participants:this.parent_project.collaborators,lastMessage:""});

          }
          this.open_priority = !this.open_priority;

          this.updateTask()
        
          break
      case "COMPLETE":
          this.task.currentStatus = "COMPLETE"
          this.status_marker = "COMPLETE"
          this.status_marker_text = "COMPLETE_TEXT"
          this.isComplete = true
          this.isChecked="checked"
          //console.log(this.task.title)
          const ma = {
            roomId:this.parent_project.id,
            to:null,
            attachments:'',
            content: this.task.title+", Task Status Set To 'COMPLETE'",
            time: new Date().toTimeString().split(/\s+/)[0],
            from: this.username,
            date: new Date().toDateString(),
            type: "notification",
            id: uuid.v4()
          }
          if(this.messageService!= null){
            this.messageService.sendMessage(ma,{roomId:this.parent_project.id,participants:this.parent_project.collaborators,lastMessage:""});
          }
          this.open_status = !this.open_status;

          this.updateTask()
          
        
          break
    }
  }

  DisplayPriority(){
    switch(this.task.priority){
      case "IMPORTANT_NOT_URGENT":
        this.prio = "High"
        this.svgFill = "#EE5521"
        break
        case "IMPORTANT_URGENT":
          this.prio = "Highest"
          this.svgFill = "firebrick"
          break
        case "NOT_IMPORTANT_URGENT":
          this.prio = "Medium"
          this.svgFill = "yellow"
          break
        case "NOT_IMPORTANT_NOT_URGENT":
          this.prio = "Low"
          this.svgFill = "#08f"
          break
        case null:
          this.prio="Low"
          this.svgFill = "#08f"
          break
    }
      
  }



  showDialog(str): void {
    this.dialogService
        .open(str, {label: `Error`, size: `s`})
        .subscribe();
  }

  public checkTime(){
    //console.log('checking links')
    //console.log(document.getElementById("time"+this.task.taskID).innerText)
    const urls = document.getElementById("time"+this.task.taskID)?.innerText.trim().match(this.regExTimeString)
    if(urls!=null){
      //console.log(document.getElementById("time"+this.task.taskID).innerText)
     // document.getElementById("time"+this.task.taskID).innerText = ""
      this.task.timeString = urls[0]
      document.getElementById("time"+this.task.taskID).innerHTML.replace(urls[0],"<a class='anchor' contenteditable='false' target='_blank'>"+urls[0]+"</a>")
    this.isLoading = true
    this.updateTask()
    }else{
      //console.log("not yet a time string")
    }
    

  }

  public checkLinks(){
    //console.log('checking links')
    const urls = document.getElementById(this.descId)?.innerText.match(this.regExUrlReal)
    var collection = document.getElementsByClassName('anchor')
    Array.from(collection).forEach(elem=>{
      //console.log(elem.outerHTML)
      //console.log(elem.innerHTML)
      var newdoc = document.getElementById(this.descId)?.innerHTML.replace(elem.outerHTML,elem.innerHTML)
      document.getElementById(this.descId).innerHTML= newdoc
    })
    //console.log("done cleaning document")
    //console.log(urls)
    if(urls!=null){
      urls.forEach((elem:any)=>{
            var newdoc = document.getElementById(this.descId).innerHTML.replace(elem,"<a class='anchor' contenteditable='false' target='_blank' href='"+elem+"'>"+elem+"</a>")
            //console.log('replaced successfully')
            document.getElementById(this.descId).innerHTML = newdoc
            
            
      })
      //console.log( document.getElementById(this.descId)?.innerHTML)
    }
    this.messageService.showNotification("Found and Highlighted Links","Found "+urls.length+" links in the docuemnt")
 
  }

  showDialogSm(
    content: PolymorpheusContent<TuiDialogContext>, lable:string
    ): void {
   
    this.dialogService.open(content,{
      label: lable,
      size:'auto',
  }).subscribe();
}


open_status = false;
open_priority = false;
open_menu = false;
open_sidebar = false;

closeComments(){
  this.open_sidebar = false
}

onClickStatus(): void {
    this.open_status = !this.open_status;
}
onClickPriority(): void {
    this.open_priority = !this.open_priority;
}
onClickMenu(): void {
    this.open_menu = !this.open_menu;
}

toggle(open: boolean): void {
  this.open_sidebar = open;
  this.scrollToBottom()
}





// recurring task setup
getDaysBetweenDates(start, end, dayName) {
  var result = [];
  var days = {sun:0,mon:1,tue:2,wed:3,thu:4,fri:5,sat:6};
  var day = days[dayName.toLowerCase().substr(0,3)];
  // Copy start date
  var current = new Date(start);
  //console.log("the start date is: "+current)
  //console.log("the end date is: "+end)
  // Shift to next of required days
  current.setDate(current.getDate() + (day - current.getDay() + 7) % 7);
  // While less than end date, add dates to result array
  while (current <= end) {
    result.push(new Date(+current));
    current.setDate(current.getDate() + 7);

  }
  return result;  
}


createRecurringTasks(){
  const days = this.getSelectedDays()
  const dueDate = new Date(this.task.dueDate)
  const startDate = new Date(this.task.startDate)
  days.forEach(e=>{
    //console.log(e)
    this.getDaysBetweenDates(startDate,dueDate,e).forEach(e=>{
      //console.log(e)
      this.AddNewTask(e)
    })
  })

  this.alertService.open(`Recurring tasks Created Successfully for ${this. task.title}`, {label: `Recurring Tasks Created Successfully`}).subscribe();
  
}

getSelectedDays(){
  var days=[]

  if(this.Recurring.value.testValue1){
    days.push('MON')
  }
  if(this.Recurring.value.testValue2){
    days.push('TUE')
  }
  if(this.Recurring.value.testValue3){
    days.push('WED')
  }
  if(this.Recurring.value.testValue4){
    days.push('THU')
  }
  if(this.Recurring.value.testValue5){
    days.push('FRI')
  }
  if(this.Recurring.value.testValue6){
    days.push('SAT')
  }
  if(this.Recurring.value.testValue7){
    days.push('SUN')
  }

  //console.log(days)
  return days

}

AddNewTask(date:any){
  var task_recc = this.task
  task_recc.dueDate = date
  task_recc.taskID = uuid.v4()
  task_recc.searchableID = uuid.v4()
  task_recc.id = uuid.v4()
  this.isLoading = true
  this.http.post(this.prod_api+"/tasks/create",task_recc).subscribe(data=>{
    this.isLoading = false
    this.changeDetector.detectChanges()
    // //console.log(data)
  },err=>{
    //console.log(err)
  })


}

moveTask(id:string){
  this.isLoading = true
  this.task.projectID = id
  this.updateTask()
  this.showNotification("Sucessfully Moved Task","This Task as succesfully moved")
}



@ViewChild(TuiHostedDropdownComponent)component: TuiHostedDropdownComponent;
 
    readonly dropdown_items = [`Description`];
    readonly status_items = [`TODO`, `IN PROGRESS`,`COMPLETE`];
 

    MenuItem(name:string){
      if(name=="Description"){
        this.toggleDesc()
      }
      if(name=="Move"){
        this.moveTask(this.task)
      }
    }


    scrollToBottom(){
      try {
        this.content.nativeElement.scrollToBottom
      } finally{
        //console.log("tried")
      }
    }


  AddNewSubTask(){
      const Task = {
        taskID:uuid.v4(),
        searchableID:uuid.v4(),
        title:"Task Title",
        description: "This is just a simple description of the task at hand, here you can store links, and store reminders of some small and important stuffs",
        taskType:"Reminder",
        priorityType:"IMPORTANT_NOT_URGENT",
        layout_x:0.000,
        layout_y:0.000,
        projectID:this.task.projectID,
        owner:this.username,
        dueDate:this.task.dueDate,
        startDate:this.task.startDate,
        timeSpent:0,
        isHabit:true,
        complexityType:"MEDIUM",
        startCondition:"START AT 17:00",
        stopping_condition:"STOP_ON_COMPLETE",
        subTasks:[],
        custom_status:null,
        additionalString:this.task.taskID,
        comments:[],
        labels:[],
        collaborators:[],
        currentStatus:"TODO"
      }
     this.isLoading = true
     this.http.post(this.prod_api+"/tasks/create",Task).subscribe(data=>{
        var newTask:any = data
        this.taskArray.push(newTask)
        //console.log(newTask.additionalString)
        //console.log(this.task.taskID)
        this.isLoading = false
        this.changeDetector.detectChanges();
        this.changeDetector.detectChanges();
      },err=>{
        //console.log(err)
      })  
    }

 Copy(){
  const selBox = document.createElement('textarea');
  selBox.style.position = 'fixed';
  selBox.style.left = '0';
  selBox.style.top = '0';
  selBox.style.opacity = '0';
  selBox.value = JSON.stringify(this.task);
  document.body.appendChild(selBox);
  selBox.focus();
  selBox.select();
  document.execCommand('copy');
  document.body.removeChild(selBox);
  this.messageService.showNotification("Copied","Successfully Copied the task to your clipboard")
}

}
