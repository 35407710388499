import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';


@Component({
  selector: 'app-month-year',
  templateUrl: './month-year.component.html',
  styleUrls: ['./month-year.component.sass']
})
export class MonthYearComponent implements OnInit {

  constructor() { }

  @Input() now:any
  @Output() ForwardOne = new EventEmitter<Date>()
  @Output() PrevOne = new EventEmitter<Date>()

  months=["January","Feburary","March","April","May","June","July","August","September","October","November","December"]

  ngOnInit(): void {
  }

  NextMonth(){
    this.now.setMonth(this.now.getMonth()+1)
    this.now.setDate(2)
    this.ForwardOne.emit(this.now)
    //console.log(this.now)
  }

  PrevMonth(){
    this.now.setMonth(this.now.getMonth()-1)
    this.now.setDate(1)
    this.PrevOne.emit(this.now)
    //console.log(this.now)
  }

}
