<tui-loader
*ngIf="isVisible"
class="inline-flex tui-space_right-2"
    [showLoader]="isLoading"
    [inheritColor]="true"
    [overlay]="true"
>
<div id="container" #container *ngIf="showComplete">
    <tui-svg
      src="tuiIconDragLarge"
      [ngStyle]="{'color':task.color}"
      class="icon">
    </tui-svg>
    
    <form [formGroup]="completeForm" *ngIf="!isOnCalendar">
      <tui-checkbox
      formControlName="completeControl"
      size="l"
      [ngStyle]="{'color':task.color}"
      class="tui-space_bottom-3"
      (click)="toggleComplete()"
      ></tui-checkbox>
    </form>
    <!-- <div id="marker"[ngStyle]="{'background-color':task.color}" ></div> -->
    <div #titleRef  contenteditable="true" (click)="selectAll()" id="{{task.taskID}}" class="title_updated_content"  (blur)="updateTitle()">{{task.title}}</div>
    <div contenteditable="true" (click)="selectAll()" id="time{{task.taskID}}" class="time_t" data-text="15:25" (blur)="checkTime()" *ngIf="task.timeString!=null">{{task.timeString}}</div>
    <div contenteditable="true" (click)="selectAll()" id="time{{task.taskID}}" class="time_t" data-text="15:25" (blur)="checkTime()" *ngIf="task.timeString==null"></div>
   
    <tui-hosted-dropdown
    [sided]="false"
    [content]="status_dropdown"
    [(open)]="open_status"
    id="status" 
>
  <div id="status"  class="{{status_marker_text}}">{{task.currentStatus}}</div>

</tui-hosted-dropdown>


<tui-hosted-dropdown
  [sided]="false"
  [content]="prority_menu"
  [(open)]="open_priority"
  id="status" 
>
<div id="priority" (click)="openPriority($event)">
  <!--big svg-->
  <div id="svg">
    <svg [attr.fill]="svgFill" [attr.stroke]="svgFill" [style.fill]="svgFill"  [style.stoke]="svgFill" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.882351 15C0.395042 15 0 14.605 0 14.1176V0.882393C0 0.395084 0.395042 2.10926e-05 0.882351 2.10926e-05H6.51175C6.84718 -0.00229183 7.15492 0.185774 7.30586 0.485335L7.94115 1.76474H12.3529C12.8402 1.76474 13.2353 2.15979 13.2353 2.64709V9.7059C13.2353 10.1932 12.8402 10.5882 12.3529 10.5882H7.59704C7.26478 10.5872 6.96126 10.3997 6.81175 10.103L6.17645 8.82355H1.7647V14.1176C1.7647 14.605 1.36966 15 0.882351 15Z"/>
      </svg>
  </div>
  {{prio}}</div>

</tui-hosted-dropdown>


    <div id="sub-tasks" (click)="toggleShow()">{{numsubtask}} Sub-Tasks</div>
   
    <app-collaborators-item [collabs]="0" id="collabs" [project]="task"  [messageService]="messageService" [parent_project]="parent_project" ></app-collaborators-item>
    <app-comments-item [numComments]="task.comments.length" id="comments"  (click)="toggle(true)" (tuiActiveZoneChange)="toggle($event)">
      <div  *tuiSidebar="open_sidebar; direction: 'right'; autoWidth:false">
        <div id="comment-holder">
          <div id="comment-list"  #comment_list>
            <div id="comment-iten-container" *ngFor="let comment of task.comments" >
              <div id="{{comment.time+'_'+comment.creator}}" (mouseenter)="checkLinksClick(comment.time+'_'+comment.creator)">
                {{comment.content}}
              </div>
              <div id="comment-item-name">
                {{comment.creator}}
              </div>
            </div>
          </div>
        
          <div id="inputbox">
            <textarea placeholder="Type Your Comment Here..." [(ngModel)]="bodyText" id="comment-input"></textarea>
            <div id="flexer">
              <button id="submitComment" (click)="submitComment()">Submit Comment</button>
              <button id="submitComment" (click)="closeComments()">Close Comments</button>
            </div>
          </div>
        </div>
      
      </div>
    </app-comments-item>
   
  
    <app-time-item [timeSpent]='time' [task]="task" id="timeSpent"></app-time-item>
    <app-calendar-item  (click)="toggleCal()"  [dateValue]="dateValue" [task]="task" id="date" [messageService]="messageService" [parent_project]="parent_project" ></app-calendar-item>
    <tui-hosted-dropdown
    
    [sided]="false"
    [content]="dropdown_menu"
    [(open)]="open_menu"
>
    <button
        tuiButton
        type="button"
        appearance="flat"
        size="s"
        iconRight="tuiIconChevronRight"
        [pseudoActive]="open || null"
    >
        
    </button>
</tui-hosted-dropdown>

</div>
</tui-loader>

<!-- This is where I put all my templates for context menu's -->

<ng-template
    #dropdown_menu
    let-close="close"
>
    <tui-data-list>
        <tui-opt-group>
            <button
                *ngFor="let item of dropdown_items"
                tuiOption
                (click)="MenuItem(item)"
            >
                {{ item }}
            </button>
            <button
                tuiOption
                (click)="showDialogSm(projects,'Projects')"
            >
                Move
            </button>
            <button
                tuiOption
                (click)="toggleNerd()"
            >
                Nerd Stats 🥸🥸
            </button>
        </tui-opt-group>
        <tui-opt-group>
            <button
                tuiOption
                (click)="deleteTask()"
            >
                Delete
            </button>
        </tui-opt-group>
    </tui-data-list>
</ng-template>


<ng-template
    #status_dropdown
    let-close="close"
>
    <tui-data-list>
        <tui-opt-group>
            <button
                *ngFor="let item of status_items"
                tuiOption
                (click)="handleMenuItemClick(item)"
            >
                {{ item }}
            </button>
        </tui-opt-group>
        <tui-opt-group>
            <button
                tuiOption
                (click)="handleMenuItemClick('BACK BURNER')"
            >
                BACK BURNER
            </button>
        </tui-opt-group>
    </tui-data-list>
</ng-template>

<ng-template
  #prority_menu
  let-close="close"
>
  <div class="items">
    <div class="flexer">
      <div id="svg_">
        <svg [attr.fill]="'firebrick'" [attr.stroke]="'firebrick'" [style.fill]="'firebrick'"  [style.stoke]="'firebrick'" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.882351 15C0.395042 15 0 14.605 0 14.1176V0.882393C0 0.395084 0.395042 2.10926e-05 0.882351 2.10926e-05H6.51175C6.84718 -0.00229183 7.15492 0.185774 7.30586 0.485335L7.94115 1.76474H12.3529C12.8402 1.76474 13.2353 2.15979 13.2353 2.64709V9.7059C13.2353 10.1932 12.8402 10.5882 12.3529 10.5882H7.59704C7.26478 10.5872 6.96126 10.3997 6.81175 10.103L6.17645 8.82355H1.7647V14.1176C1.7647 14.605 1.36966 15 0.882351 15Z"/>
          </svg>
      </div>
      <p (click)="itemSelected(0); $event.stopPropagation()">Highest</p>
    </div>
  <div class="flexer">
    <div id="svg_">
      <svg [attr.fill]="'orange'" [attr.stroke]="'orange'" [style.fill]="'orange'"  [style.stoke]="'orange'" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.882351 15C0.395042 15 0 14.605 0 14.1176V0.882393C0 0.395084 0.395042 2.10926e-05 0.882351 2.10926e-05H6.51175C6.84718 -0.00229183 7.15492 0.185774 7.30586 0.485335L7.94115 1.76474H12.3529C12.8402 1.76474 13.2353 2.15979 13.2353 2.64709V9.7059C13.2353 10.1932 12.8402 10.5882 12.3529 10.5882H7.59704C7.26478 10.5872 6.96126 10.3997 6.81175 10.103L6.17645 8.82355H1.7647V14.1176C1.7647 14.605 1.36966 15 0.882351 15Z"/>
        </svg>
    </div>
    
    <p (click)="itemSelected(1); $event.stopPropagation()">High</p>
  </div>
  <div class="flexer">
    <div id="svg_">
      <svg [attr.fill]="'yellow'" [attr.stroke]="'yellow'" [style.fill]="'yellow'"  [style.stoke]="'yellow'" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.882351 15C0.395042 15 0 14.605 0 14.1176V0.882393C0 0.395084 0.395042 2.10926e-05 0.882351 2.10926e-05H6.51175C6.84718 -0.00229183 7.15492 0.185774 7.30586 0.485335L7.94115 1.76474H12.3529C12.8402 1.76474 13.2353 2.15979 13.2353 2.64709V9.7059C13.2353 10.1932 12.8402 10.5882 12.3529 10.5882H7.59704C7.26478 10.5872 6.96126 10.3997 6.81175 10.103L6.17645 8.82355H1.7647V14.1176C1.7647 14.605 1.36966 15 0.882351 15Z"/>
        </svg>
    </div>
    
      <p (click)="itemSelected(2); $event.stopPropagation()">Medium</p>
  </div>
  <div class="flexer">
    <div id="svg_">
      <svg [attr.fill]="'#08f'" [attr.stroke]="'#08f'" [style.fill]="'#08f'"  [style.stoke]="'#08f'" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.882351 15C0.395042 15 0 14.605 0 14.1176V0.882393C0 0.395084 0.395042 2.10926e-05 0.882351 2.10926e-05H6.51175C6.84718 -0.00229183 7.15492 0.185774 7.30586 0.485335L7.94115 1.76474H12.3529C12.8402 1.76474 13.2353 2.15979 13.2353 2.64709V9.7059C13.2353 10.1932 12.8402 10.5882 12.3529 10.5882H7.59704C7.26478 10.5872 6.96126 10.3997 6.81175 10.103L6.17645 8.82355H1.7647V14.1176C1.7647 14.605 1.36966 15 0.882351 15Z"/>
        </svg>
    </div>
  
      <p (click)="itemSelected(3); $event.stopPropagation()">Low</p>
  </div>
    
    
    
  </div>
</ng-template>



<div id="strip_container"  *ngIf="showDesc" >
  <div id="{{descId}}" class="desc" (blur)="updateDescription()">
    <tui-editor
    (blur)="updateDescription()"
    class="desc"
    [(ngModel)]="task.description"
  >
  </tui-editor>
  <Button id="flow-button" (click)="updateDescription()">Save</Button>
  </div>
</div>


<div id="strip_container"  *ngIf="showCal">
  <div id="strip"></div>
  <div id="flexe">
    
    <div id="calendar_wrapper">
      <h3>Please Select A start and End Date</h3>
      <tui-calendar-range [items]="items" (valueChange)="updateDate($event)"  [value]="value"></tui-calendar-range>
    </div>
    <div>
      
      <div id="calendar_wrapper">
        <h3>Set This up as a recurring task</h3>
        <form [formGroup]="Recurring">
        <div id="flexe" class="dayCheck">
            <tui-checkbox
            formControlName="testValue1"
            size="l"
            class="tui-space_bottom-3">
      </tui-checkbox> 
      <div id="day_text">  Monday</div>
        </div>

        <div id="flexe" class="dayCheck">
          <tui-checkbox
          formControlName="testValue2"
          size="l"
          class="tui-space_bottom-3">
    </tui-checkbox>  <div id="day_text">Tuesday</div>
        </div>

        <div id="flexe" class="dayCheck">
          <tui-checkbox
          formControlName="testValue3"
          size="l"
          class="tui-space_bottom-3">
    </tui-checkbox>  <div id="day_text">Wednesday</div>
        </div>

        <div id="flexe" class="dayCheck">
          <tui-checkbox
          formControlName="testValue4"
          size="l"
          class="tui-space_bottom-3">
    </tui-checkbox> <div id="day_text">Thursday</div>
        </div>

        <div id="flexe" class="dayCheck">
          <tui-checkbox
          formControlName="testValue5"
          size="l"
          class="tui-space_bottom-3">
    </tui-checkbox> <div id="day_text">Friday</div>
        </div>

        <div id="flexe" class="dayCheck">
          <tui-checkbox
          formControlName="testValue6"
          size="l"
          class="tui-space_bottom-3">
    </tui-checkbox> <div id="day_text">Saturday</div>
        </div>

        <div id="flexe" class="dayCheck">
          <tui-checkbox
          formControlName="testValue7"
          size="l"
          class="tui-space_bottom-3">
    </tui-checkbox> <div id="day_text">Sunday</div>
        </div>
      </form>
          <button id="flow-button" (click)="createRecurringTasks()">Set Up Recurring Tasks</button>
      </div>

    </div>
  </div>

  
</div>

<div id="strip_container"  *ngIf="isShown">
  <div id="strip"></div>
  <div id="extra_task_information_container">
    <button id="flow-button-subTask" (click)="AddNewSubTask()">Add Sub-Task</button>
    <div *ngFor="let subTask of taskArray"> 
      <app-table-item *ngIf="task.taskID===subTask.additionalString"  [blockTaskArray]="[]" [taskArray]="taskArray" [task]="subTask" [title]="subTask.title"  [numcollabs]="subTask.collaborators.length" [subTaskArray]="subTask.subTasks" [parent_project]="parent_project" [messageService]="messageService"></app-table-item>
    </div>
  </div>
</div>

<div id="strip_container"  *ngIf="isNerd">
  <div id="strip"></div>
  <div id="extra_task_information_container">
    <button id="flow-button-subTask" (click)="Copy()">Copy To Clipboard</button>
    <div id="task_cont">
      <pre id="task_cont">{{this.task |json}}</pre>

    </div>
  </div>
</div>



<ng-template  #template let-observer >
  <div id="comment-iten-container" *ngFor="let comment of task.comments" >
    <div id="{{comment.time+'_'+comment.creator}}" (mouseenter)="checkLinksClick(comment.time+'_'+comment.creator)">
      {{comment.content}}
    </div>
    <div id="comment-item-name">
      {{comment.creator}}
    </div>
  </div>
  <div id="inputbox">
    <input [(ngModel)]="bodyText" id="comment-input"/>
    <div id="flexer">
      <button id="flow-button" (click)="submitComment()">Submit Comment</button>
      <div id="spacer"></div>
      <a href="https://server.flowspaceproducitivity.com:3000" target="_blank"><button id="flow-button">Attach File</button></a>
    </div>
  </div>
</ng-template>


<ng-template  #projects let-observer >
  <div id="project-scroller">
    <div *ngFor="let project of projectsArray">
      <div id="mainProject" *ngIf="(project.subProject === '') && (project.complete === false && project.archived === false)">
        <p id="title_project">{{project.title}}</p>
        <div id="flexe">
          <div id="strip"></div>
          <div>
            <div id="subProjects"  *ngFor="let subproject of projectsArray">
              <div *ngIf="(subproject.subProject === project.id) ">
                <p id="title_supProject" (click)="moveTask(subproject.id)">{{subproject.title}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
  </div>
 </ng-template>

