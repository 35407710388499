import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-template-header',
  templateUrl: './template-header.component.html',
  styleUrls: ['./template-header.component.sass']
})
export class TemplateHeaderComponent implements OnInit {

  constructor() { }
  @Input() title:string
  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  
  ngOnInit(): void {
  }

}
