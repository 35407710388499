import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-task-mark',
  templateUrl: './task-mark.component.html',
  styleUrls: ['./task-mark.component.sass']
})
export class TaskMarkComponent implements OnInit {

  constructor() { }
  @Input() task:any
  
  ngOnInit(): void {
  }

}
