
<div id="container" *ngIf="isVisible">
    <form [formGroup]="isComplete" id="padder_huge">
        <tui-checkbox
        (click)="toggleComplete()"
        formControlName="Completevalue"
        size="l"
        class="tui-space_bottom-3">
        </tui-checkbox> 
    </form>
    <div id="padder">
        <div id="padder_huge">
            <div contenteditable="true" id="{{goal.goalID}}" class="goal_input" (blur)="updateName()">{{goal.name}}</div>
            <div
            tuiGroup
            class="group"
            [collapsed]="true">
        <button
        tuiButton
        type="button"
        appearance="outline"
        size="m"
        id="{{showWhy?'highlight':''}}"
        (click)="toggleWhy()">Why</button>

        <button
        tuiButton
        type="button"
        appearance="outline"
        id="{{showHow?'highlight':''}}"
        size="m" (click)="toggleHow()">How</button>

        <button
        tuiButton
        type="button"
        appearance="outline"
        id="{{showUnit?'highlight':''}}"
        size="m" (click)="toggleUnits()">Units</button>

        <button
        tuiButton
        type="button"
        appearance="outline"
        id="{{showTrack?'highlight':''}}"
        size="m" (click)="toggleTrack()">Tracking</button>
        
        <button
        tuiButton
        type="button"
        appearance="outline"
        id="{{showSub?'highlight':''}}"
        size="m" (click)="toggleSub()">Subs</button>
    </div>

            <app-project-progress-bar [progress]="percentageComplete"></app-project-progress-bar>

        </div>
        <div id="padder-left-huge" *ngIf="showDue">
            <form
            class="b-form"
            [formGroup]="testForm"
            >
    
            <tui-input-date
                tuiUnfinishedValidator="Finish filling the field"
                formControlName="testValue"
                tuiTextfieldSize="m"
                (focusedChange)="startUpdate($event)"
            >
                Complete By:
            </tui-input-date>
            <tui-error
                formControlName="testValue"
            ></tui-error>
        </form>
    
        </div>
        <div id="padder-date-specific" *ngIf="!showDue" (click)="toggleDue()">
           <div> {{goal.dueDate}}</div>
        </div>

        <button  
        tuiIconButton
        type="button"
        appearance="outline"
        [icon]="trash"
        size="m"
        id="padder-left-huge"
        (focusedChange)="DeleteTask()"></button>
    </div>
    
</div>
    <div id="strip_container"  *ngIf="showWhy">
        <tui-editor
        [(ngModel)]="goal.why"
        [tools]="builtInTools"
         (keydown.control.s)="updateGoalFast($event)"
        class="editor"
      >
      </tui-editor>
      </div>


      <div id="strip_container"  *ngIf="showHow">
        <tui-editor
        [(ngModel)]="goal.how"
        [tools]="builtInTools"
         (keydown.control.s)="updateGoalFast($event)"
        class="editor"
      >
      </tui-editor>
      </div>


      <div id="strip_container"  *ngIf="showUnit">
        <div id="{{descId}}" class="desc" spellcheck="true">
            This is where you put down a specific number so that you know when you hit your goal. so that you can track your goal (eg. 60 is the number of gym sessions you will have if you consistently go to the gym 5 days a week for 3 months)
            <br/>
            <br/>
            <br/>
            <form [formGroup]="unitForm">
                <tui-input-number formControlName="unit">Enter Total Units</tui-input-number>
            </form>
            <br/>
            <br/>
            <button id="flow-button" (click)="saveUnits()">save</button>
        </div>
      </div>


      <div id="strip_container"  *ngIf="showTrack">
        <div id="{{descId}}" class="desc" spellcheck="true">
            This is where you put down your progress toward your goal, (make a habit of adding to it as often as you can)
            <br/>
            <br/>
           <b id="special">Your Current Progress is <b>{{goal.progress}} units</b></b>
            <br/>
            <br/>
            <form [formGroup]="progressForm">
                <tui-input-number formControlName="progress">Enter Today's Progress</tui-input-number>
            </form>
            <br/>
            <br/>
            <button id="flow-button" (click)="saveProgress()">save</button>
        </div>
      </div>

      <div id="strip_container"  *ngIf="showSub">
        <div>
            <button (click)="createGoal()" id="flow-button">Add Goal</button>
            <ng-container *ngFor="let g of all_goals">
                <app-goals-item *ngIf="(g.parentGoal!=null)&&(g.parentGoal==goal.goalID)" [all_goals]="all_goals" [goal]="g"></app-goals-item>
            </ng-container>
        </div>
       
      </div>
   
   



