<div id="container">
    <div id="top_content">
        <div id="toolbar"><app-dashboard-toolbar [username]="username"></app-dashboard-toolbar></div>
        <div id="search_archive">
            <app-search-archive></app-search-archive>
        </div>
        <div id="templates_header" id="templates" (click)="hideTemplates()"><app-template-header [title]="'Flowspace Templates'"></app-template-header></div>
        <div id="templates_holder" *ngIf="isVisible" >
            <ng-container *ngFor="let temp of templateArray" id="templates_holder">
                <app-project-template-card [template]="temp"></app-project-template-card>
            </ng-container>
          
        </div>
        <div id="templates_header" (click)="hideShared_()"><app-template-header  id="shared_" [title]="'Your Shared Projects'"></app-template-header></div>
        <div id="templates_holder" *ngIf="hideShared" >
            <ng-container *ngFor="let temp of sharedsArray" id="templates_holder">
                <app-project-template-card [template]="null" [project]="temp" *ngIf="(!temp.archived) || (!temp.complete)"></app-project-template-card>
            </ng-container>
          
        </div>
    </div>
    <app-project-header></app-project-header>
    <button id="flow-button" class="padder" (click)="createProject()">Add Project</button>
    <div class="scroll">
                <tui-loader
        [showLoader]="isLoading"
        [overlay]="true"
        >
        <ng-container *ngFor="let project of projectsArray">
            <ng-container *ngIf="(project.subProject === '') && (project.complete === false && project.archived === false)">
               <app-project-tile [project]="project"  [username]="username" [projectRef]="project" [projectName]="project.title" [projectDescription]="project.description" [projectDeadline]="project.deadline" [projectID]="project.id" [numcollabs]="project.collaborators.length" [percentageComplete]="project.percentageComplete"></app-project-tile>
            </ng-container>
        </ng-container>
        </tui-loader>
    </div>
    
</div>