<div id="contianer">
   <div id="archived" routerLink="/archived_project">
        <div id="image_archieved"></div>
       <div id="text_archived">Archived</div>
   </div>

   <div id="shared" routerLink="/shared_project">
    <div id="image_archieved"></div>
   <div id="text_archived">Shared</div>
</div>
</div>

