<div id="container">
    <div id="top_content">
        <div id="toolbar"><app-dashboard-toolbar [username]="username"></app-dashboard-toolbar></div>
        <div id="search_archive">
            <app-search-archive></app-search-archive>
        </div>
        <div id="templates_header" (click)="hideShared_()"><app-template-header [title]="'Your Shared Archived Projects'"></app-template-header></div>
        <div id="templates_holder" *ngIf="hideShared" >
            <ng-container *ngFor="let temp of sharedsArray" id="templates_holder">
                <app-project-template-card *ngIf="(temp.subProject === '') && (temp.archived===true || temp.complete === true)" [template]="null" [project]="temp" [isArchive]="true"></app-project-template-card>
            </ng-container>
          
        </div>
    </div>
    <app-project-header></app-project-header>
    <div class="scroll">
        <tui-loader
[showLoader]="isLoading"
[overlay]="true"
>
        <app-archived-projects-container></app-archived-projects-container>
        </tui-loader>
    </div>
    
</div>