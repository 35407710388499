import { Component, OnInit, Input } from '@angular/core';
import { project } from 'src/app/Dashboard/ProjectDashboard/dash-contianer/project';

@Component({
  selector: 'app-board-container',
  templateUrl: './board-container.component.html',
  styleUrls: ['./board-container.component.sass']
})
export class BoardContainerComponent implements OnInit {

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  
  constructor() { }
  @Input() blockArray: project[]=[]
  ngOnInit(): void {
  }

}
