import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginServiceService {

  private user = new BehaviorSubject<string>("");
  subProjects = this.user.asObservable();
  constructor() { }

  updateBlocks(message: string) {
    this.user.next(message)
    //console.log(this.user)
    //console.log(this.subProjects)
    }
}
