<div id="column_container">
<app-board-column-header [title]="block.title" [project]="block"></app-board-column-header>
<tui-loader
[showLoader]="isLoading"
[overlay]="true"
>
<button id="flow-button" (click)="AddNewTask()">Add Task</button>
<div *ngFor="let task of taskArray">
    <app-board-item [title]="task.title" [task]="task"></app-board-item>
</div>
</tui-loader>
</div>
