import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chat-recieved',
  templateUrl: './chat-recieved.component.html',
  styleUrls: ['./chat-recieved.component.sass']
})
export class ChatRecievedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
