import { HttpClient, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { Component, OnInit,Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-project-title-image',
  templateUrl: './project-title-image.component.html',
  styleUrls: ['./project-title-image.component.sass']
})
export class ProjectTitleImageComponent implements OnInit {

  constructor(private http:HttpClient,private toastr:ToastrService) { }

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"

  @Input() title=""
  @Input() project:any

  selectedFiles: any;
  currentFileUpload: any;
  progress:number = 0;
  selectedFile = null;
  changeImage = false;
  filename="no file selected"
  isUploading:boolean = true

  imagebase64:any =""

  ngOnInit(): void {
    if(this.title==""){
      this.title = "some long title"
    }
  }


  updateTitle(){
    //console.log(this.project.id)
    var elem:any = document.getElementById(this.project.id)
    var innerHtml:string = elem.innerText
    //console.log(innerHtml)
    //console.log("**********************working welll********************")
    this.project.title = innerHtml
    this.project = this.http.put(this.prod_api+"/projects/update",this.project).subscribe(
        data=>{
          this.project = data
          //console.log(this.project.title)
        }, err=>{
          //console.log(err)
        });
  }

  selectAll(){
    window.document.execCommand('selectAll',false,null)
  }

  upload() {
    this.isUploading = !this.isUploading
    this.progress = 0;
    this.currentFileUpload = this.selectedFiles.item(0);
    this.pushFileToStorage(this.currentFileUpload).subscribe((event: any) => {
      if (event.type === HttpEventType.UploadProgress) {
        this.progress = Math.round(100 * event.loaded / event.total);
      } else if (event instanceof HttpResponse) {
        this.toastr.success('File Successfully Uploaded');
        this.isUploading = !this.isUploading
      }
      this.selectedFiles = undefined;
     }
    );
  }
  
  selectFile(event: any) {
    //console.log("running this code and doing it well")
    this.selectedFiles = event.target.files;
    this.upload()
  }
  
  
  pushFileToStorage(file: File):any{
    const data: FormData = new FormData();
    data.append('title', file.name);
    data.append('image', file);
    data.append('id',this.project.id)
    //console.log(this.project)
    const newRequest = new HttpRequest('POST', this.dev_api+'/projects/photos', data, {
      reportProgress: true,
      responseType: 'text'
    });
    return this.http.request(newRequest);
  }
  

}
