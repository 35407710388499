<div id="toolbar">
    <div  id="second">
    <app-view-switcher></app-view-switcher>
    </div>
    <div id="inner-toolbar">
        <div id="buttons" *ngFor="let button of buttonArray">
            <div id="button_container" routerLink="/{{button.router}}">
                <div id="title_tooblbar_button"><img src="{{button.image}}"/></div>
                <div id="back_toobar_button">{{button.userid}}</div>
            </div>
         </div>
    </div>
</div>
<tui-loader
[showLoader]="isLoading"
[overlay]="true"
>
<div id="tasks">
    <h1>Overdue Tasks: </h1>
    <p>This will show all your overdue tasks for the past 12 months: </p>
    <div>
        <div *ngFor="let task_ of taskArray">
            <ng-container>
                <app-table-item *ngIf="task_.currentStatus!='COMPLETE'" [task]="task_" [title]="task_.title"  [numcollabs]="task_.collaborators.length" [subTaskArray]="task_.subTasks" [parent_project]="defaultProject" [messageService]="messageService"></app-table-item>
            </ng-container>
        </div>
    </div>
</div>
</tui-loader>
