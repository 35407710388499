import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, Output,EventEmitter, ChangeDetectorRef } from '@angular/core';
import * as uuid from "uuid"

@Component({
  selector: 'app-subtask-item',
  templateUrl: './subtask-item.component.html',
  styleUrls: ['./subtask-item.component.sass']
})
export class SubtaskItemComponent implements OnInit {

  isCompleted:boolean = false
  dev_api ="http://localhost:443"
  isLoading = false
  prod_api ="https://server.flowspaceproducitivity.com"

  constructor(private http:HttpClient,private changeDetector:ChangeDetectorRef) { }

  @Input() title:string=""
  @Input() task:any
  @Input() subTask:any
  @Input() messageService:any
  @Input() parent_project:any

  currentRef=""
  currentId:string =""
  cursor:number=0
  username = localStorage.getItem("userId")

  @Output() changeNum: EventEmitter<any> =   new EventEmitter();
  @Output() update: EventEmitter<any> =   new EventEmitter();
  @Output() refresh: EventEmitter<any> =   new EventEmitter();

  ngOnInit(): void {
    this.currentId = this.task.taskID + this.title + uuid.v4()
    if(this.subTask.isComplete){
        this.isCompleted = true
    }
  }

  toggleComplete(){
    this.isLoading = true
    this.isCompleted = !this.isCompleted
    //console.log("this is the value of isCompleted", this.isCompleted)
    //console.log(this.task.realsubTasks)
    this.task.realsubTasks[this.subTask.index].isComplete = this.isCompleted
    this.task = this.http.put(this.prod_api+"/tasks/update",this.task).subscribe(
      (data:any)=>{
        this.task = data
        this.isLoading= false
        //console.log(data)
        if(this.isCompleted){
          this.messageService.sendMessage({from:this.username,
            roomId:this.parent_project,
            attachments:'',
            content:this.task.title+', was completed',
            date:new Date().toDateString(),
            time:new Date().toTimeString(),
            id:uuid.v4()
          });
        }
    
      }, (err:any)=>{
        //console.log(err)
      });
  }

  changeNumber(){
    this.changeNum.emit(-1)
  }

  updateTask(){
    this.update.emit(this.task)
    this.refresh.emit()
  }

  updateTitle(){
    this.isLoading = true
    //console.log("updating title")
    var elem:any = document.getElementById(this.currentId)
    var innerHtml:string = elem.innerText
    //console.log("**********************************************")
    //console.log(innerHtml)
    this.task.realsubTasks[this.subTask.index].title = innerHtml
   

    this.task = this.http.put(this.prod_api+"/tasks/update",this.task).subscribe(
        (data:any)=>{
          this.task = data
          //console.log(data)
          this.isLoading = false
          this.isLoading = false
          this.isLoading = false
          this.isLoading = false
          this.changeDetector.detectChanges()
          this.updateTask()
          this.refresh.emit()

          
          //console.log(this.isLoading)
          // const m = {
          //   roomId:this.parent_project.id,
          //   to:null,
          //   attachments:'',
          //   content: this.task.title+", title was updated",
          //   time: new Date().toTimeString().split(/\s+/)[0],
          //   from: this.username,
          //   date: new Date().toDateString(),
          //   type: "notification",
          //   id: uuid.v4()
          // }
          // this.messageService.sendMessage(m,{roomId:"no such room id"},"no one ever");
        }, (err:any)=>{
          //console.log(err)
        });

  }

  removeItem(){
    this.isLoading = true
    this.task.realsubTasks[this.task.realsubTasks.indexOf(this.subTask)].isVisible = false

    this.task = this.http.put(this.prod_api+"/tasks/update",this.task).subscribe(
      (data:any)=>{
        this.task = data
        //console.log(data)
        this.changeNumber()
        const m = {
          roomId:this.parent_project.id,
          to:null,
          attachments:'',
          content: this.task.title+", title was deleted",
          time: new Date().toTimeString().split(/\s+/)[0],
          from: this.username,
          date: new Date().toDateString(),
          type: "notification",
          id: uuid.v4()
        }
        this.messageService.sendMessage(m,{roomId:"no such room id"},"no one ever");
        this.isLoading = false
        this.changeDetector.detectChanges()
        this.refresh.emit()

       
      }, (err:any)=>{
        //console.log(err)
      });
  }


  selectAll(){
    window.document.execCommand('selectAll',false,null)
  }

}
