import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-project-progress-bar',
  templateUrl: './project-progress-bar.component.html',
  styleUrls: ['./project-progress-bar.component.sass']
})
export class ProjectProgressBarComponent implements OnInit {

  constructor() { }

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  
  @Input() progress:number = 0;
  ngOnInit(): void {
  }

}
