import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-day-column',
  templateUrl: './day-column.component.html',
  styleUrls: ['./day-column.component.sass']
})
export class DayColumnComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
