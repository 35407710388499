import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shared-projects-container',
  templateUrl: './shared-projects-container.component.html',
  styleUrls: ['./shared-projects-container.component.sass']
})
export class SharedProjectsContainerComponent implements OnInit {

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"

  projectsArray:any
  apiObject:any
  newProject:any
  username:any

  constructor(private http:HttpClient) {   }


  
  ngOnInit(): void {
    this.username = localStorage.getItem("userId")
    this.apiObject = this.http.get(this.prod_api+"/projects/shared?MyToken="+this.username).subscribe(
      data=>{
        //console.log(data);
        this.projectsArray = data;
        //console.log(this.projectsArray)

      }, err=>{
        //console.log(err);
      }
    );
  }


}
