import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as bcrypt from "bcryptjs"
import { HttpClient } from '@angular/common/http';
import { UniqueSelectionDispatcher } from '@angular/cdk/collections';
import * as uuid from "uuid"
import {PolymorpheusComponent} from '@tinkoff/ng-polymorpheus';
import {TuiDialogService} from '@taiga-ui/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import mixpanel from 'mixpanel-browser';
import { LoginService } from 'src/app/services/loginService/login.service';
import * as auth from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { environment } from 'src/environments/environment';
mixpanel.init('000706f38f6320cb7267e8d4bf0a7895'); 

@Component({
  selector: 'app-login-container',
  templateUrl: './login-container.component.html',
  styleUrls: ['./login-container.component.sass'],
  providers: [
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase }
],
})
export class LoginContainerComponent implements OnInit {

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  hide_icon: string = "hide";
  currentId = uuid.v4()
  isLoading = false
  auth2: any;
  @ViewChild('loginRef', { static: true }) loginElement!: ElementRef;
  
  constructor(private service:LoginService, 
    private router:Router,
    private http:HttpClient, 
    public fbAuth: AngularFireAuth,
    @Inject(TuiDialogService) private readonly dialogService) { }

  title:string=""
  password:string=""
  profileId:string=''
  currentUser:any
  localhash:any
  show: boolean = false;
  isOld = false


  ngOnInit(): void {

    // this.googleAuthSDK()    

    if(localStorage.getItem('userId')!=null){
      if(localStorage.getItem('view')!=null){
        this.router.navigate(
          ['/'+localStorage.getItem('view')]
        )
      }else{
        this.router.navigate(
          ['/project']
        )
      } 
    }else{
      this.service.updateFlag(true)

      this.router.navigate(
        ['/login']
      )
    }


   
  }


createUser(user:any){
  //this.isLoading = true
  localStorage.setItem("userId",user.name)
  this.http.put(this.prod_api+"/users/update",user).subscribe(data=>{
    //console.log(data)
    this.getUser()
    this.isLoading = false
  },err=>{
    this.isLoading = false
    //console.log(err)
  })
}

  getUser(){
    //this.isLoading = true
    this.http.get(this.prod_api+"/users/get_users?OwnerToken="+this.title).subscribe(data=>{
      this.currentUser = data
      if(this.currentUser==null){
        this.isLoading = false
        this.showDialog()
      }else{
        this.isLoading = false
        //console.log(this.currentUser)
        this.localhash = bcrypt.hashSync(this.profileId)
        const that = this
        bcrypt.compare(this.profileId, this.currentUser.password, function(err, result) {
          if (result) {
             // password is valid
             
            that.updateUsername()
         }else{
          
          that.showDialog()
         }
      });
      }
    
    })
  }

  getUserMannual(){
    //this.isLoading = true
    this.http.get(this.prod_api+"/users/get_users?OwnerToken="+this.title).subscribe(data=>{
      this.currentUser = data
      if(this.currentUser==null){
        this.isLoading = false
        this.showDialog()
      }else{
        this.isLoading = false
        //console.log(this.currentUser)
        this.localhash = bcrypt.hashSync(this.password)
        //console.log(this.password)
        const that = this
        bcrypt.compare(that.password, that.currentUser.password, function(err, result) {
          //console.log(result)
          if (result) {
             // password is valid
             
            that.updateUsername()
         }else{
          
          that.showDialog()
         }
      });
      }
    
    })
  }

  updateUsername(){
    console.log("this code runs")
    this.router.navigate(
      ['/project']
    )

    // setting new item
    localStorage.setItem('userId', this.title);
  

    const user = {
      username: this.title,
      password: this.password,
      membershipType:"FREE"
    }

  }

  password_() {
    this.show = !this.show;
    if(this.show){
      this.hide_icon="show"
    }else{
      this.hide_icon="hide"
    }
  }

  showDialog(): void {
    this.dialogService
        .open(`Username Or Password Invalid`, {label: `Error`, size: `s`})
        .subscribe();
  }

  toggleOld(){
    this.isOld = !this.isOld
  }

  successLoginCallback(event:any){
    console.log(event)
    let profile = event.authResult.additionalUserInfo.profile;
    //console.log('Token || ' + googleAuthUser.getAuthResponse().id_token);
    //console.log('ID: ' + profile.getId());
    //console.log('Name: ' + profile.getName());
    //console.log('Image URL: ' + profile.getImageUrl());
    //console.log('Email: ' + profile.getEmail());
    var user ={
      username: profile.name,
      email: profile.email,
      password: profile.id,
      profile_pic: profile.picture
    }
    this.title = profile.name
    const hashed = bcrypt.hashSync(user.password);
    user.password = hashed
    this.profileId = profile.id
    this.createUser(user);
    this.service.updateFlag(false)
    this.isLoading = false
  }

  errorLoginCallback(event:any){
      console.log(event)
  }

  callLogin() {
    //this.isLoading = true
    mixpanel.track('Sign Up', {
      'source': "flowspace login",
      'Opted out of email': true,
    });

    
    // this.auth2.attachClickHandler(this.loginElement.nativeElement, {},
    //   (googleAuthUser: any) => {

    //     //Print profile details in the console logs

    //     let profile = googleAuthUser.getBasicProfile();
    //     //console.log('Token || ' + googleAuthUser.getAuthResponse().id_token);
    //     //console.log('ID: ' + profile.getId());
    //     //console.log('Name: ' + profile.getName());
    //     //console.log('Image URL: ' + profile.getImageUrl());
    //     //console.log('Email: ' + profile.getEmail());
    //     var user ={
    //       username: profile.getName(),
    //       email: profile.getEmail(),
    //       password: profile.getId()
    //     }
    //     this.title = profile.getName()
    //     const hashed = bcrypt.hashSync(user.password);
    //     user.password = hashed
    //     this.profileId = profile.getId()
    //     this.createUser(user);
    //     this.service.updateFlag(false)
    //     this.isLoading = false

    //   }, (error: any) => {
    //     alert(JSON.stringify(error, undefined, 2));
    //   });

  }


  // callLogin() {
  //   //this.isLoading = true
  //   mixpanel.track('Sign Up', {
  //     'source': "flowspace login",
  //     'Opted out of email': true,
  //   });

  //   this.auth2.attachClickHandler(this.loginElement.nativeElement, {},
  //     (googleAuthUser: any) => {

  //       //Print profile details in the console logs

  //       let profile = googleAuthUser.getBasicProfile();
  //       //console.log('Token || ' + googleAuthUser.getAuthResponse().id_token);
  //       //console.log('ID: ' + profile.getId());
  //       //console.log('Name: ' + profile.getName());
  //       //console.log('Image URL: ' + profile.getImageUrl());
  //       //console.log('Email: ' + profile.getEmail());
  //       var user ={
  //         username: profile.getName(),
  //         email: profile.getEmail(),
  //         password: profile.getId()
  //       }
  //       this.title = profile.getName()
  //       const hashed = bcrypt.hashSync(user.password);
  //       user.password = hashed
  //       this.profileId = profile.getId()
  //       this.createUser(user);
  //       this.service.updateFlag(false)
  //       this.isLoading = false

  //     }, (error: any) => {
  //       alert(JSON.stringify(error, undefined, 2));
  //     });

  // }

  // googleAuthSDK() {
  //   (<any>window)['googleSDKLoaded'] = () => {
  //     (<any>window)['gapi'].load('auth2', () => {
  //       this.auth2 = (<any>window)['gapi'].auth2.init({
  //         client_id: '244753305093-srfq2ntu3qvaeonv86djan8cer80mc99.apps.googleusercontent.com',
  //         plugin_name:'login',
  //         cookiepolicy: 'single_host_origin',
  //         scope: 'profile email'
  //       });
  //       this.callLogin();
  //     });
  //   }

  //   (function (d, s, id) {
  //     var js, fjs = d.getElementsByTagName(s)[0];
  //     if (d.getElementById(id)) { return; }
  //     js = d.createElement('script');
  //     js.id = id;
  //     js.src = "https://apis.google.com/js/platform.js?onload=googleSDKLoaded";
  //     fjs?.parentNode?.insertBefore(js, fjs);
  //   }(document, 'script', 'google-jssdk'));
  // }

}
