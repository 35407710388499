<div id="container">
    <div id="chatlist">
        <div id="header_chatlist">
            <div id="text">
                Chats
            </div>      
            <button id="plus"  (click)="showDialog(template)"><img src="assets/plus.png"></button>   
             
        </div>
        <input id="comment-input"  style="padding-left:15px;margin-bottom:25px" placeholder="Search Chats" type="text" [(ngModel)]="searchText"> 
        <tui-loader
        [showLoader]="isLoading"
        [overlay]="true"
        >
        <div id="chats__">
            <ng-container *ngFor="let chat of chatRooms">
                <app-chat-contact  *ngIf="chat.to.toLowerCase().includes(searchText.toLowerCase())"  (updateMessages)="updateChats($event)" [chat]="chat" [chatRooms]="chatRooms" [usersArray]="usersArray" [service]="service" [room]="chat" [title]="isName(chat)" [time]="chat.time" [roomId]="chat.roomId" [content]="chat.lastMessage" ></app-chat-contact>
            </ng-container>
        </div>
        </tui-loader>
   
    </div>


    <div id="chatroom">

        <div id="header">
            <div id="container_header">
                <div id="profile">
                    <div [ngStyle]="{'background-image': 'url(data:image/png;base64,' + profile + ')'}" width="60px" height="60px"  id="dp"></div>
                </div>
                <div id="details">
                    <div id="name"><b>{{title}}</b></div>
                    <div id="detail">{{members}}</div>
                </div>
                <div id="top">
                    <!-- <div> <img  id="button_ctrl" src="../../../assets/video_call.png" alt="" width="35px"></div>
                    <div> <img   id="button_ctrl" src="../../../assets/audio_call.png" alt="" width="35px"></div> -->
                </div>
                </div>
        </div>

        <div id="chats_container" #container [scrollTop]="container.scrollHeight">
           <div id="messages">
            <ng-container  *ngFor="let m of chats"  >
                <app-message [m]="m" [chat]="currentRoom" (reply)="setupReply($event)"></app-message>
             </ng-container>
           </div>
        </div>
 
        <tui-loader 
        [overlay]="true"
        [showLoader]="isLoadingFile">
        <div>
            <div *ngIf="reply==null" id="reply_"> 
                <div id="cancel_" (click)="reply=null">
                    cancel
                </div>
                <div id="content_">
                    this is the placeholder
                </div>
                
            </div>
            <div *ngIf="reply!=null" id="reply"> 
                <div id="cancel" (click)="reply=null">
                    cancel
                </div>
                <div id="content">
                    {{reply.content}}
                </div>
                
            </div>
            <div id="controls">
                <div id="button_control">
                    <button id="button-flow" (click)="isEmojiPickerVisible = !isEmojiPickerVisible;">
                        <tui-svg
                        src="tuiIconHeartLarge"
                        class="icon">
                      </tui-svg>
                    </button>
                </div>

               <div id="button_control">
                <label id="button-flow" for="chatImageUploader"> 
                    <tui-svg
                    src="tuiIconUploadLarge"
                    class="icon">
                  </tui-svg>
                  <input  id="chatImageUploader" type="file" (change)="uploadImage($event)">
                </label>
               </div>

             <div id="button_text_area">
                <!-- <input placeholder="Type a message" style="padding-left:15px"   autocomplete="on" autocorrect="on" autocapitalize="on" spellcheck="true" [(ngModel)]="text" disabled="{{isDisabed}}" id="comment-input" (keyup.enter)="updateMessages()"/> -->
                <textarea placeholder="Type a message" cols="40" rows="5"   autocomplete="on" autocorrect="on" autocapitalize="on" spellcheck="true" [(ngModel)]="text" disabled="{{isDisabed}}" id="comment-input-textarea" (keyup.enter)="updateMessages()"></textarea>  
             </div>

             <div id="button_control">
                <button id="button-flow" (click)="updateMessages()">Send</button>
             </div>
            </div>
        </div>
         
        </tui-loader>
        
    </div>
</div>

<emoji-mart class="emoji-mart" *ngIf="isEmojiPickerVisible" (emojiSelect)="addEmoji($event)" [style]="{ position: 'absolute', bottom: '20px', left: '20px' }" title="Choose your emoji"></emoji-mart>

<ng-template #template let-observer>
    <h2>Select A User To Start A Chat:</h2>
    <input placeholder="search"   id="comment-input_serach" [(ngModel)]="searchtext"/>
    <div id="item-container">
        <div *ngFor="let user of usersArray" >
            <div *ngIf="user.username!=''">
                <div id="flexer" *ngIf="user.username.toLowerCase().includes(searchtext.toLowerCase())"  (click)="selectUser(user.username)">
                    <tui-avatar
                    text="{{user.username}}"
                    size="m"
                    [autoColor]="true"
                ></tui-avatar>
                    <div>
                        <div id="name">
                            {{user.username}}
                        </div>
                        <div id="bio">
                            {{user.bio}}
                        </div>
                    </div>
                      
                </div>
            </div>
        </div>
    </div>
   
</ng-template>


<div *ngIf="AudioCalling" id="call-box">
    <div id="top-call-section">
        <div>
            <div [ngStyle]="{'background-image': 'url(data:image/png;base64,' + profile + ')'}" width="600px" height="600px"  id="dp_call"></div>
        </div>
        <div id="name">{{title}}</div>
    </div>
    <div id="bottom-call-section">
        <button id="end-call">End Call</button>
    </div>
    
</div>


<!-- <div *ngIf="VideoCalling" id="video-call-box">
    <div id="video-top-call-section">
        <div>
            <div [ngStyle]="{'background-image': 'url(data:image/png;base64,' + profile + ')'}" width="600px" height="600px"  id="dp_call"></div>
        </div>
        <div id="name">{{title}}</div>
    </div>
    <div id="bottom-call-section">
        <button id="end-call">End Call</button>
    </div>
    
</div> -->
