    <div id="container" (click)="selectNote()" *ngIf="isVisible" class="{{highlight}}" >
        <img src="{{icon}}" width="20px" height="20px" alt="" id="icon"> 
        <div id="{{currentId}}" class="title" contenteditable="true" (blur)="updateTitle()">{{notesArray[index].title}}</div>
        <div id="context" (click)="toggleShow()" >  
          <tui-svg *ngIf="!isShown"
          src="tuiIconChevronDown"
          class="icon">
        </tui-svg>
          <tui-svg *ngIf="isShown"
          src="tuiIconChevronUp"
          class="icon">
        </tui-svg>
      </div>
        <div id="context"  (click)="showDialog(template)" >  
          <tui-svg
          src="tuiIconUsers"
          class="icon">
        </tui-svg>
      </div>
        <div id="context" (click)="deleteNote()">  
          <tui-svg
          src="tuiIconTrash"
          class="icon">
        </tui-svg>
      </div>
    </div>
    <div id="strip_container" *ngIf="isShown">
        <div id="strip"></div>
        <div id="extra_task_information_container">
          <button id="flow-button-subTask" (click)="addSubNote()">Add Sub-Note</button>
          <tui-loader
          [showLoader]="isLoading"
          [overlay]="true"
          >
           <div *ngFor="let sub of notesArray">
            <ng-container *ngIf="sub.notePagesID == notesArray[index].id">
              <app-note-item [index]="notesArray.indexOf(sub)" [notesArray]="notesArray" (currentSubNote)="updatedText($event)" [icon]="'../../../assets/subnote_icon.png'"></app-note-item>
            </ng-container>
          </div> 
         </tui-loader>
      
        </div>
      </div>


<ng-template #template let-observer>
  <h2>Select A User To Share A Copy:</h2>
  <input placeholder="search"   id="comment-input_serach" [(ngModel)]="searchtext"/>
  <div id="item-container">
      <div *ngFor="let user of usersArray" >
          <div *ngIf="user.username!=''">
              <div id="flexer" *ngIf="user.username.toLowerCase().includes(searchtext.toLowerCase())"  (click)="selectUser(user.username)">
                  <tui-avatar
                  text="{{user.username}}"
                  size="m"
                  [autoColor]="true"
              ></tui-avatar>
                  <div>
                      <div id="name">
                          {{user.username}}
                      </div>
                      <div id="bio">
                          {{user.bio}}
                      </div>
                  </div>
                    
              </div>
          </div>
      </div>
  </div>
 
</ng-template>
