import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-project-info',
  templateUrl: './project-info.component.html',
  styleUrls: ['./project-info.component.sass']
})
export class ProjectInfoComponent implements OnInit {

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  
  constructor() { }
  @Input() deadline:Date=new Date()
  @Input() description:string=""
  @Input() collabs:number=0
  @Input() project:any

  ngOnInit(): void {
    //console.log(this.project)
  }

}
