import { TuiRootModule, TuiDialogModule, TuiAlertModule, TUI_SANITIZER } from "@taiga-ui/core";
import { NgDompurifySanitizer } from "@tinkoff/ng-dompurify";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import {ModalModule}  from "./Modals/_modal/modal.module"
import { ToastrModule } from 'ngx-toastr';
import { ReactiveFormsModule } from '@angular/forms';
import { CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
// Import library module
import {DragDropModule}  from '@angular/cdk/drag-drop'
import { BadgeModule } from "./directives/badge.module";
import {
  TuiInputDateTimeModule,
  TuiInputDateModule,
  TuiCalendarRangeModule,
  TuiSelectModule,
  TuiDataListWrapperModule,
  TuiTabsModule,
  TuiBadgeModule,
  TuiAvatarModule,
  TuiInputModule,
  TuiCheckboxBlockModule,
  TuiMultiSelectModule,
  TuiCheckboxModule,
  TuiInputNumberModule,
  TuiRadioBlockModule,
  TUI_CALENDAR_DATE_STREAM
} from '@taiga-ui/kit';

import {TuiEditorModule} from '@taiga-ui/addon-editor'
import {TuiSidebarModule} from '@taiga-ui/addon-mobile';

import {
  TuiThemeNightModule, 
  TuiModeModule,
  TuiCalendarModule,
  TuiDataListModule,
  TuiNotificationModule,
  TuiLoaderModule,
  TuiDropdownModule,
  TuiSvgModule,
  TuiButtonModule,
  TUI_ICONS_PATH, 
  tuiIconsPathFactory,
  TuiHostedDropdownModule,
  TuiGroupModule,
  
  
} from '@taiga-ui/core'

import {TuiPanModule,TuiZoomModule,TuiActiveZoneModule} from '@taiga-ui/cdk'
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { TippyModule, tooltipVariation, popperVariation } from '@ngneat/helipopper';
import { NgxPanZoomModule } from 'ngx-panzoom';


import { AppComponent } from './app.component';
import { SidebarComponent } from './container/sidebar/sidebar.component';
import { MenuButtonComponent } from './container/menu-button/menu-button.component';
import { LinkButtonComponent } from './container/link-button/link-button.component';
import { ContextMenuComponent } from './container/context-menu/context-menu.component';
import { SettingsModalComponent } from './container/settings-modal/settings-modal.component';
import { ViewSwitcherComponent } from './calendar/view-switcher/view-switcher.component';
import { CalendarDayBlockComponent } from './calendar/calendar-day-block/calendar-day-block.component';
import { FlowspaceComponent } from './container/flowspace/flowspace.component';
import { TableItemComponent } from './TableView/table-item/table-item.component';
import { TableViewComponent } from './TableView/table-view/table-view.component';
import { CollaboratorsItemComponent } from './TableView/collaborators-item/collaborators-item.component';
import { CommentsItemComponent } from './TableView/comments-item/comments-item.component';
import { TimeItemComponent } from './TableView/time-item/time-item.component';
import { CalendarItemComponent } from './TableView/calendar-item/calendar-item.component';
import { SubtaskItemComponent } from './TableView/subtask-item/subtask-item.component';
import { SubtaskCommentsComponent } from './TableView/subtask-comments/subtask-comments.component';
import { SubtaskTimeComponent } from './TableView/subtask-time/subtask-time.component';
import { SubtaskContextMenuComponent } from './TableView/subtask-context-menu/subtask-context-menu.component';
import { HeaderComponent } from './TableView/header/header.component';
import { HeaderTitleComponent } from './TableView/header-title/header-title.component';
import { HeaderContextComponent } from './TableView/header-context/header-context.component';
import { ProjectInfoComponent } from './TableView/project-info/project-info.component';
import { ProjectDeadlineComponent } from './TableView/project-deadline/project-deadline.component';
import { ProjectCollaboratorsComponent } from './TableView/project-collaborators/project-collaborators.component';
import { ProjectDescriptionComponent } from './TableView/project-description/project-description.component';
import { ProjectAddBlockComponent } from './TableView/project-add-block/project-add-block.component';
import { TableItemContextComponent } from './TableView/table-item-context/table-item-context.component';
import {HttpClientModule, HTTP_INTERCEPTORS} from "@angular/common/http";
import { ToolbarTableViewComponent } from './TableView/toolbar-table-view/toolbar-table-view.component';
import { TableViewToolbarButtonComponent } from './TableView/table-view-toolbar-button/table-view-toolbar-button.component';
import { DashContianerComponent } from './Dashboard/ProjectDashboard/dash-contianer/dash-contianer.component';
import { DashboardToolbarComponent } from './Dashboard/ProjectDashboard/dashboard-toolbar/dashboard-toolbar.component';
import { TemplateHeaderComponent } from './Dashboard/ProjectDashboard/template-header/template-header.component';
import { ProjectTemplateCardComponent } from './Dashboard/ProjectDashboard/project-template-card/project-template-card.component';
import { SearchArchiveComponent } from './Dashboard/ProjectDashboard/search-archive/search-archive.component';
import { ProjectHeaderComponent } from './Dashboard/ProjectDashboard/project-header/project-header.component';
import { SubProjectsComponent } from './Dashboard/ProjectDashboard/sub-projects/sub-projects.component';
import { ProjectCollaboratorsComponentComponent } from './Dashboard/ProjectDashboard/project-collaborators-component/project-collaborators-component.component';
import { ProjectProgressBarComponent } from './Dashboard/ProjectDashboard/project-progress-bar/project-progress-bar.component';
import { ProjectTitleImageComponent } from './Dashboard/ProjectDashboard/project-title-image/project-title-image.component';
import { ProjectContextComponent } from './Dashboard/ProjectDashboard/project-context/project-context.component';
import { ProjectCommentsComponent } from './Dashboard/ProjectDashboard/project-comments/project-comments.component';
import { ProjectFilesComponent } from './Dashboard/ProjectDashboard/project-files/project-files.component';
import { ProjectTileComponent } from './Dashboard/ProjectDashboard/project-tile/project-tile.component';
import { BoardItemComponent } from './Kanban/board-item/board-item.component';
import { BoardContainerComponent } from './Kanban/board-container/board-container.component';
import { BoardColumnComponent } from './Kanban/board-column/board-column.component';
import { BoardSubItemComponent } from './Kanban/board-sub-item/board-sub-item.component';
import { KanbanComponent } from './Kanban/kanban/kanban.component';
import { BoardColumnHeaderComponent } from './Kanban/board-column-header/board-column-header.component';
import { BoardColumnHeaderTitleComponent } from './Kanban/board-column-header-title/board-column-header-title.component';
import { BoardColumnHeaderContextComponent } from './Kanban/board-column-header-context/board-column-header-context.component';
import { BoardItemContextComponent } from './Kanban/board-item-context/board-item-context.component';
import { CalendarContainerComponent } from './calendar/calendar-container/calendar-container.component';
import { CalendarDayLabelComponent } from './calendar/calendar-day-label/calendar-day-label.component';
import { TaskMarkComponent } from './calendar/task-mark/task-mark.component';
import { MonthYearComponent } from './calendar/month-year/month-year.component';
import { NotesDashContainerComponent } from './Dashboard/NotesDashboard/notes-dash-container/notes-dash-container.component';
import { NotebookCoverTileComponent } from './Dashboard/NotesDashboard/notebook-cover-tile/notebook-cover-tile.component';
import { RecentNoteTileComponent } from './Dashboard/NotesDashboard/recent-note-tile/recent-note-tile.component';
import { NoteContainerComponent } from './Notebook/note-container/note-container.component';
import { FolderItemComponent } from './Notebook/folder-item/folder-item.component';
import { NoteItemComponent } from './Notebook/note-item/note-item.component';
import { TabComponent } from './Notebook/tab/tab.component';
import { TabContainerComponent } from './Notebook/tab-container/tab-container.component';
import { NotebookHeaderComponent } from './Notebook/notebook-header/notebook-header.component';
import { NotebookTitleComponent } from './Notebook/notebook-title/notebook-title.component';
import { NotebookContextComponent } from './Notebook/notebook-context/notebook-context.component';
import { NotebookToolbarButtonComponent } from './Notebook/notebook-toolbar-button/notebook-toolbar-button.component';
import { NotebookToolbarComponent } from './Notebook/notebook-toolbar/notebook-toolbar.component';
import { NotebookWYSIWYGComponent } from './Notebook/notebook-wysiwyg/notebook-wysiwyg.component';
import { RouterModule } from '@angular/router';
import { ChatContainerComponent } from './Chat/chat-container/chat-container.component';
import { ChatSidebarComponent } from './Chat/chat-sidebar/chat-sidebar.component';
import { ChatContactComponent } from './Chat/chat-contact/chat-contact.component';
import { ChatSentComponent } from './Chat/chat-sent/chat-sent.component';
import { ChatRecievedComponent } from './Chat/chat-recieved/chat-recieved.component';
import { ApiComponent } from './api/api.component';
import { TableItemContainerComponent } from './TableView/table-item-container/table-item-container.component';
import { LoginContainerComponent } from './login/login-container/login-container.component';
import { CommentsContainerComponent } from './Modals/Comments/comments-container/comments-container.component';
import { CommentItemComponent } from './Modals/Comments/comment-item/comment-item.component';
import { SharedProjectsDashboardComponent } from './Dashboard/ProjectDashboard/shared-projects-dashboard/shared-projects-dashboard.component';
import { SharedProjectsContainerComponent } from './Dashboard/ProjectDashboard/shared-projects-container/shared-projects-container.component';
import { ArchivedProjectsContainerComponent } from './Dashboard/ProjectDashboard/archived-projects-container/archived-projects-container.component';
import { ArchivedProjectsDashboardComponent } from './Dashboard/ProjectDashboard/archived-projects-dashboard/archived-projects-dashboard.component';
import { MonitorInterceptor } from "./monitor.interceptor";
import { GoalTabsComponent } from "./Goals/goal-tabs/goal-tabs.component";
import { HttpCancelService } from './services/cancelIntecepter/httpcancel.service';
import { ManageHttpInterceptor } from './services/cancelIntecepter/httpInteceptor';
import { TimeViewContainerComponent } from './Time/time-view-container/time-view-container.component';
import { TimeSlotComponent } from './Time/time-slot/time-slot.component';
import { DayColumnComponent } from './Time/day-column/day-column.component';
import { DayLabelComponent } from './Time/day-label/day-label.component';
import { TimeLabelComponent } from './Time/time-label/time-label.component';
import { DailyViewContainerComponent } from './Daily/daily-view-container/daily-view-container.component';
import { DailyDayLabelComponent } from './Daily/daily-day-label/daily-day-label.component';
import { ContainerComponent } from './VisionBoard/container/container.component';
import { VboardItemComponent } from './VisionBoard/vboard-item/vboard-item.component';
import { OverdueContainerComponent } from './Overdue/overdue-container/overdue-container.component';
import { SafePipe } from './safe.pipe';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { GoalsContainerComponent } from './Goals/goals-container/goals-container.component';
import { GoalsItemContainerComponent } from './Goals/goals-item-container/goals-item-container.component';
import { GoalsItemComponent } from './Goals/goals-item/goals-item.component';
import { MoodtrackerComponent } from './MoodTracker/moodtracker/moodtracker.component';
import { MessageComponent } from './Chat/message/message.component';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { environment } from '../environments/environment';
import { provideAuth,getAuth } from '@angular/fire/auth';
import { provideDatabase,getDatabase } from '@angular/fire/database';
import { provideFirestore,getFirestore } from '@angular/fire/firestore';
import { provideMessaging,getMessaging } from '@angular/fire/messaging';
import { provideStorage,getStorage } from '@angular/fire/storage';
import { initializeApp, provideFirebaseApp } from "@angular/fire/app";

import {FirebaseUIModule, firebase, firebaseui} from 'firebaseui-angular';

const config: SocketIoConfig = { url: 'http://localhost:8988', options: {} };


const firebaseUiAuthConfig: firebaseui.auth.Config = {
  signInFlow: 'popup',
  signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  ],
  //term of service
  tosUrl: '<your-tos-link>',
  //privacy url
  privacyPolicyUrl: '<your-privacyPolicyUrl-link>',
  //credentialHelper:             firebaseui.auth.CredentialHelper.ACCOUNT_CHOOSER_COM
  credentialHelper: firebaseui.auth.CredentialHelper.NONE
};

@NgModule({
  declarations: [
    GoalTabsComponent,
    AppComponent,
    SidebarComponent,
    MenuButtonComponent,
    LinkButtonComponent,
    ContextMenuComponent,
    SettingsModalComponent,
    ViewSwitcherComponent,
    CalendarDayBlockComponent,
    FlowspaceComponent,
    TableViewComponent,
    CollaboratorsItemComponent,
    CommentsItemComponent,
    TimeItemComponent,
    CalendarItemComponent,
    SubtaskItemComponent,
    SubtaskCommentsComponent,
    SubtaskTimeComponent,
    SubtaskContextMenuComponent,
    HeaderComponent,
    HeaderTitleComponent,
    HeaderContextComponent,
    ProjectInfoComponent,
    ProjectDeadlineComponent,
    ProjectCollaboratorsComponent,
    ProjectDescriptionComponent,
    ProjectAddBlockComponent,
    TableItemContextComponent,
    TableItemComponent,
    ToolbarTableViewComponent,
    TableViewToolbarButtonComponent,
    DashContianerComponent,
    DashboardToolbarComponent,
    TemplateHeaderComponent,
    ProjectTemplateCardComponent,
    SearchArchiveComponent,
    ProjectHeaderComponent,
    SubProjectsComponent,
    ProjectCollaboratorsComponentComponent,
    ProjectProgressBarComponent,
    ProjectTitleImageComponent,
    ProjectContextComponent,
    ProjectCommentsComponent,
    ProjectFilesComponent,
    ProjectTileComponent,
    BoardItemComponent,
    BoardContainerComponent,
    BoardColumnComponent,
    BoardSubItemComponent,
    KanbanComponent,
    BoardColumnHeaderComponent,
    BoardColumnHeaderTitleComponent,
    BoardColumnHeaderContextComponent,
    BoardItemContextComponent,
    CalendarContainerComponent,
    CalendarDayLabelComponent,
    TaskMarkComponent,
    MonthYearComponent,
    NotesDashContainerComponent,
    NotebookCoverTileComponent,
    RecentNoteTileComponent,
    NoteContainerComponent,
    FolderItemComponent,
    NoteItemComponent,
    TabComponent,
    TabContainerComponent,
    NotebookHeaderComponent,
    NotebookTitleComponent,
    NotebookContextComponent,
    NotebookToolbarButtonComponent,
    NotebookToolbarComponent,
    NotebookWYSIWYGComponent,
    ChatContainerComponent,
    ChatSidebarComponent,
    ChatContactComponent,
    ChatSentComponent,
    ChatRecievedComponent,
    ApiComponent,
    TableItemContainerComponent,
    LoginContainerComponent,
    CommentsContainerComponent,
    CommentItemComponent,
    SharedProjectsDashboardComponent,
    SharedProjectsContainerComponent,
    ArchivedProjectsContainerComponent,
    ArchivedProjectsDashboardComponent,
    TimeViewContainerComponent,
    TimeSlotComponent,
    DayColumnComponent,
    DayLabelComponent,
    TimeLabelComponent,
    DailyViewContainerComponent,
    DailyDayLabelComponent,
    ContainerComponent,
    VboardItemComponent,
    OverdueContainerComponent,
    SafePipe,
    GoalsContainerComponent,
    GoalsItemContainerComponent,
    GoalsItemComponent,
    MoodtrackerComponent,
    MessageComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ModalModule,
    FormsModule,
    BadgeModule,
    TuiBadgeModule,
    PickerModule,
    TuiLoaderModule,
    TuiRadioBlockModule,
    TuiAvatarModule,
    TuiInputModule,
    TuiDropdownModule,
    TuiSvgModule,
    TuiDataListModule,
    TuiPanModule,
    TuiZoomModule,
    TuiMultiSelectModule,
    TuiEditorModule,
    TuiInputNumberModule,
    TuiButtonModule,
    TuiCheckboxModule,
    TuiSidebarModule,
    TuiSidebarModule,
    TuiActiveZoneModule,
    NgxPanZoomModule,
    TuiHostedDropdownModule,
    TippyModule.forRoot({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: tooltipVariation,
        popper: popperVariation,
      }
    }),
    RouterModule.forRoot([
      {path:'',component:LoginContainerComponent},
      {path:'login',component:LoginContainerComponent},
      {path:'project',component:DashContianerComponent},
      {path:'shared_project',component:SharedProjectsDashboardComponent},
      {path:'archived_project',component:ArchivedProjectsDashboardComponent},
      {path:'calendar',component:CalendarContainerComponent},
      {path:'time',component:TimeViewContainerComponent},
      {path:'daily',component:DailyViewContainerComponent},
      {path:'notes',component:NotesDashContainerComponent},
      {path:'editor',component:NoteContainerComponent},
      {path:'editor/:id',component:NoteContainerComponent},
      {path:'table',component:TableViewComponent},
      {path:'table/:id',component:TableViewComponent},
      {path:'board',component:KanbanComponent},
      {path:'goals',component:GoalsContainerComponent},
      {path:'chat',component:ChatContainerComponent},
      {path:'api',component:ApiComponent},
      {path:'vision',component:ContainerComponent},
      {path:'vision/:id',component:ContainerComponent},
      {path:'overdue',component:OverdueContainerComponent}
    ]),
      BrowserAnimationsModule,
      FormsModule,
      ToastrModule.forRoot(),
      ReactiveFormsModule,
      DragDropModule,
      TuiRootModule,
      TuiDialogModule,
      TuiAlertModule,
      TuiInputDateTimeModule,
      TuiThemeNightModule,
      TuiModeModule,
      TuiInputDateModule,
      TuiCalendarModule,
      TuiCalendarRangeModule,
      TuiSelectModule,
      TuiDataListModule,
      TuiDataListWrapperModule,
      TuiNotificationModule,
      TuiTabsModule,
      TuiCheckboxBlockModule,
      TuiGroupModule,
      AngularFireModule.initializeApp(environment.firebase),
      AngularFireAuthModule,
      FirebaseUIModule.forRoot(firebaseUiAuthConfig),
      
],
schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  providers: [
    HttpCancelService,
    { provide: HTTP_INTERCEPTORS, useClass: ManageHttpInterceptor, multi: true },
    { 
      provide: HTTP_INTERCEPTORS, 
      useClass: MonitorInterceptor, 
      multi: true
    },
    {
      provide: TUI_SANITIZER, 
      useClass: NgDompurifySanitizer
    },
    {
        provide: TUI_ICONS_PATH,
        useValue: tuiIconsPathFactory('https://taiga-ui.dev/assets/taiga-ui/icons'),
    },
      {provide: TUI_SANITIZER, useClass: NgDompurifySanitizer}
]
}
)

export class AppModule { }
