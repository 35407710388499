import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { project } from 'src/app/Dashboard/ProjectDashboard/dash-contianer/project';
import { CleanupCrewService } from 'src/app/services/cleanup_crew/cleanup-crew.service';
import { WebsocketService } from 'src/app/services/websockets/web-socket-service.service';
import * as uuid from "uuid"
import { buttonArray } from './toolbar_buttons';

export interface dayblock{
  day:number
  isShown:boolean
  taskArray:any[]
  blockDate:Date
}

@Component({
  selector: 'app-daily-view-container',
  templateUrl: './daily-view-container.component.html',
  styleUrls: ['./daily-view-container.component.sass']
})
export class DailyViewContainerComponent implements OnInit {
  combined: any[];

  constructor(private http:HttpClient,private toastr:ToastrService, public messageService:WebsocketService,private cleanup:CleanupCrewService) { }

  isLoading = false
  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  username = localStorage.getItem("userId")

  currentId = uuid.v4()
  DayBlocks:dayblock[]=[]
  Days:string[]=['Mon','Tue','Wed','Thu','Fri','Sat','Sun']

  now:Date = new Date()
  firstDay = new Date(this.now.getFullYear(), this.now.getMonth(), 1);
  lastDay = new Date(this.now.getFullYear(), this.now.getMonth() + 1, 0);
  firstIndex:number =this.firstDay.getDay()-1

  buttonArray = buttonArray

  taskArray:any
  sharedTasks: any;


  defaultProject:any


  currentYear = this.now.getFullYear();
  currentMonth = this.now.getMonth() + 1;

  daysInCurrentMonth = this.getDaysInMonth(this.currentYear, this.currentMonth);

  months=["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]

  selectedDay = {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-15")}

  newTask:any

  today = new Date()

  currentDay = []

  ngOnInit(): void {
    this.getDefault()
    this.populate(this.now)
    var el = document.getElementById("today")
    el.scrollIntoView({ block: 'center',inline:'center', behavior: 'smooth' })

  }

  populate(currentDate:Date){
    this.isLoading = true
    this.now = currentDate
    this.firstDay = new Date(this.now.getFullYear(), this.now.getMonth(), 1);
    this.lastDay = new Date(this.now.getFullYear(), this.now.getMonth() + 1, 5);
    this.firstIndex=this.firstDay.getDay()-1

    this.currentYear = this.now.getFullYear();
    this.currentMonth = this.now.getMonth() + 1;
    this.daysInCurrentMonth = this.getDaysInMonth(this.currentYear, this.currentMonth);

    this.DayBlocks=[
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-15")},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-15")},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-15")},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-15")},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-15")},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-15")},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-15")},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-15")},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-15")},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-15")},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-15")},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-15")},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-15")},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-15")},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-15")},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-15")},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-15")},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-15")},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-15")},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-15")},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-15")},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-15")},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-15")},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-15")},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-15")},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-15")},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-15")},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-15")},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-15")},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-15")},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-15")},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-15")},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-15")},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-15")},
      {"day":0,"isShown":false,"taskArray":[],"blockDate":new Date("1920-08-15")}
    ]



    this.http.get(this.prod_api+"/tasks/range?start="+this.firstDay.toISOString()+"&end="+this.lastDay.toISOString()+"&OwnerToken="+this.username).subscribe(data=>{
      this.taskArray = data
      this.firstIndex=0
      this.http.get(this.prod_api+"/tasks/shared?MyToken="+this.username).subscribe(data=>{
        this.sharedTasks = data
        for(let i=0;i<=this.daysInCurrentMonth;i++){
          this.DayBlocks[this.firstIndex].day=i
          this.DayBlocks[this.firstIndex].isShown=true
          this.DayBlocks[this.firstIndex].blockDate=this.addDays(this.firstDay,i-1);
          this.combined = [...this.taskArray,...this.sharedTasks]
          console.warn(this.sharedTasks)
          this.combined.forEach((data: any) => {
             if(
              (new Date(data.dueDate).getUTCDate() === this.DayBlocks[this.firstIndex].blockDate.getUTCDate()) &&
              (new Date(data.dueDate).getMonth() === this.DayBlocks[this.firstIndex].blockDate.getMonth()) &&
              (new Date(data.dueDate).getFullYear() === this.DayBlocks[this.firstIndex].blockDate.getFullYear())
             ){

               this.DayBlocks[this.firstIndex].taskArray.push(data)
             }
          });

          this.firstIndex++
        }
        this.isLoading = false

        this.selectDay(this.today.getUTCDate())
      })

    })
  }

  getDaysInMonth(year:any, month:any) {
    return new Date(year, month, 0).getDate();
  }

  addDays(date:Date, days:number) {
    const copy = new Date(Number(date))
    copy.setDate(date.getDate() + days)
    return copy
  }

  createProject()
  {
    this.isLoading = true
     const Project:project = {
       "id":localStorage.getItem("userId")||"",
       "subProject":"",
       "searchableID":uuid.v4(),
       "project_color":"",
       "owner":localStorage.getItem("userId")||"",
       "title":"Default Project",
       "description":"just a basic project to contain tasks added through the calendar but have no parents, tasks from here can always to moved at your earliest convience",
       "collaborators":[],
       "deadline":new Date(),
       "dateCreated":new Date(),
       "dateStart":new Date(),
       "archived":false,
       "deleted":false,
       "complete":false,
       "additionalArray":[],
       "additionalString":""
     }

     this.http.post(this.prod_api+"/projects/new",Project).subscribe(data=>{
         this.defaultProject = data
         this.toastr.success("Successfully Created Project")
         this.isLoading = false
     },err=>{
       //console.log(err)
      // this.toastr.error("Failed to Created project")
     })
  }

  getDefault(){
    this.isLoading = true
    this.http.get(this.prod_api+"/projects/personal/id?ProjectID="+this.username).subscribe(data=>{
      this.defaultProject = data
   },err=>{
    this.createProject()
    //console.log(err)
    this.toastr.error("Failed to Get project")
   })
  }


  selectDay(day:number){
    this.isLoading = true
      this.DayBlocks.forEach(elem=>{
        if(day == elem.day){
          this.selectedDay = elem
          this.cleanup.updateBlocks(day)
        }
      })

      //console.log(this.selectedDay)
      this.isLoading = false
  }

  compareDates(date1,date2){
    //console.log(date1)
    date1 = date1.setHours(0,0,0,0)
    date2 = new Date(date2)
    date2 = date2.setHours(0,0,0,0)

    //console.log(date2)
    return date1===date2
  }


  AddNewTask(){
    this.isLoading=true
    var today = new Date(this.selectedDay.blockDate);
    var tomorrow = new Date();
    tomorrow.setDate(today.getDate());
    const Task = {
      taskID:uuid.v4(),
      searchableID:uuid.v4(),
      title:"Task Title",
      description: "This is just a simple description of the task at hand, here you can store links, and store reminders of some small and important stuffs",
      taskType:"Reminder",
      priorityType:"IMPORTANT_NOT_URGENT",
      layout_x:0.000,
      layout_y:0.000,
      projectID:this.defaultProject.id,
      owner:this.defaultProject.owner,
      dueDate:tomorrow,
      startDate:tomorrow,
      timeSpent:0,
      isHabit:true,
      complexityType:"MEDIUM",
      startCondition:"START AT 17:00",
      stopping_condition:"STOP_ON_COMPLETE",
      subTasks:[],
      custom_status:null,
      comments:[],
      labels:[],
      collaborators:[],
      currentStatus:"TODO"
    }
    ;
    this.newTask = this.http.post(this.prod_api+"/tasks/create",Task).subscribe(data=>{
      this.newTask = data
      //console.log(this.newTask)
      this.combined.push(this.newTask)
      this.isLoading = false

    },err=>{
      //console.log(err)
    })


  }

}
