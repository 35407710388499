<div class="messcon" id="{{m.id}}">
        <div id="flexer" (mouseenter)="toggleOptions(true)" (mouseleave)="toggleOptions(false)">
            <div class="{{isNative(m)}}">
                <div id="{{isSentName(m)}}">{{m.from}}</div>
                <div class="{{isRecieved(m)}}">
                    <a href="{{m.attachments}}"><img id="img_" *ngIf="m.attachments!=''&&m.object.includes('image')" src="{{m.attachments}}" class="cover"/></a>
                    <a href="{{m.attachments}}"><video id="img_" *ngIf="m.attachments!=''&&m.object.includes('video')" class="cover" controls><source src="{{m.attachments}}" type="{{m.object}}"></video></a>
                    <a href="{{m.attachments}}">
                        <div *ngIf="m.attachments!=''&&!(m.object.includes('video')||m.object.includes('image'))" class="cover_doc"></div></a>
                    <div id="chattextcontentReply" *ngIf="hasReply" (click)="scroll()">{{m.replyMess}}</div>
                    <div id="chattextcontent" (mouseenter)="checkLinks(m.id,m)" [innerHTML]="m.content">
                        <!-- {{m.content}} -->
        
                    </div>
                </div>
                <div id="flexer">
                    <span id="reactions_msg"  *ngIf="m.reactions!=null">
                        <ng-container *ngFor="let reaction of m.reactions">
                            {{reaction}}
                        </ng-container>
                    </span><div id="{{isSentTime(m)}}">{{m.date}} @ {{m.time}}</div>
                </div>
               
        </div>
        <div id="flexer" >
            <tui-hosted-dropdown
            class="options"
            
            [sided]="false"
            [content]="dropdown_menu"
            [(open)]="open_menu"
            >
            <div *ngIf="isHiddenOptions">
                React && Options
            </div>
        </tui-hosted-dropdown>
            <!-- <div class="options" *ngIf="isHiddenOptions" (click)="replyEvent()">Reply</div> -->
            <!-- <div class="options" *ngIf="isHiddenOptions">forward</div> -->
        </div>
     
    </div>
  
</div>


<ng-template
#dropdown_menu
let-close="close"
>
<div (mouseenter)="toggleOptions(true)">
    <div>
        <div>
            <div class="options_dropdown" *ngIf="isHiddenOptions" (click)="replyEvent()">Reply</div>
            <!-- <div class="options_dropdown" *ngIf="isHiddenOptions" (click)="replyEvent()">Forward</div> -->
            <div class="options_dropdown" *ngIf="isHiddenOptions" (click)="AddNewTask()">Create Task From Message</div>
        </div>
        <div id="flexer" class="reactions">
            <div class="reaction_option" (click)="updateReactions('❤️')">❤️</div>
            <div class="reaction_option" (click)="updateReactions('👍')">👍</div>
            <div class="reaction_option" (click)="updateReactions('🤣')">🤣</div>
            <div class="reaction_option" (click)="updateReactions('😭')">😭</div>
            <div class="reaction_option" (click)="updateReactions('🔥')">🔥</div>
            <div class="reaction_option" (click)="updateReactions('🙏')">🙏</div>
        </div>
    </div>

</div>

</ng-template>