import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import * as uuid from "uuid"

@Component({
  selector: 'app-board-item',
  templateUrl: './board-item.component.html',
  styleUrls: ['./board-item.component.sass']
})
export class BoardItemComponent implements OnInit {

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"

  currentId = uuid.v4()
  
  constructor(private http:HttpClient) { }

  isShown: boolean = false ; 
  @Input() title:string=""
  @Input() task:any
  isComplete:boolean = false;
  isChecked:string = ""
  isVisible:boolean = true
  status_marker = "TODO"
  status_marker_text = "TODO_TEXT"
  
  ngOnInit(): void {
    switch (this.task.currentStatus) {
      case "TODO":
           this.status_marker = "TODO"
           this.status_marker_text = "TODO_TEXT"
           this.isComplete = false
           this.isChecked=""
           break;
      case "IN PROGRESS":
           this.status_marker = "INPROGRESS"
           this.status_marker_text = "INPROGRESS_TEXT"
           this.isComplete = false
           this.isChecked=""
           break;
      case "BACK BURNER":
           this.status_marker = "BACKBURNER"
           this.status_marker_text = "BACKBURNER_TEXT"
           this.isComplete = false
           this.isChecked="unchecked"
           break;
      case "COMPLETE":
           this.status_marker = "COMPLETE"
           this.status_marker_text = "COMPLETE_TEXT"
           this.isComplete = true
           this.isChecked="checked"
           break;   
    }
  }

  toggleComplete(){
    if(this.isComplete){
      this.task.currentStatus="TODO"
      this.task = this.http.put(this.prod_api+"/tasks/update",this.task).subscribe(
        data=>{
          this.task = data
          //console.log(this.task)
          alert("Task Updated")
        }, err=>{
          //console.log(err)
        }
      );
    }else{
      this.task.currentStatus="COMPLETE"
      this.task = this.http.put(this.prod_api+"/tasks/update",this.task).subscribe(
        data=>{
          this.task = data
          //console.log(this.task)
          alert("Task Updated")
        }, err=>{
          //console.log("****************************** ERROR!!!!!!!!!!!!!!!****************************")
          //console.log(err)
        }
      );
    }
    
  }

  toggleShow(){
    this.isShown = !this.isShown
  }

  updateTitle(){
    //console.log(this.task.taskID)
    var elem:any = document.getElementById(this.task.taskID)
    var innerHtml:string = elem.innerText
    //console.log(innerHtml)
    //console.log("**********************working welll********************")
    this.task.title = innerHtml
    this.task = this.http.put(this.prod_api+"/tasks/update",this.task).subscribe(
        data=>{
          this.task = data
        }, err=>{
          //console.log(err)
        });
  }


  updateTask(){
    
    this.task = this.http.put(this.prod_api+"/tasks/update",this.task).subscribe(
        data=>{
          this.task = data
          
        }, err=>{
          //console.log(err)
        });
  }

  removeTask(task:any){
    alert("should have deleted the things")
    this.isVisible = false
  }


  addSubTask(){
    if(this.task.realsubTasks === undefined || this.task.realsubTasks === null){
      this.task.realsubTasks = []
    }
    this.task.realsubTasks.push({isComplete:false,title:"New Sub Task",index:this.task.realsubTasks.length,isVisible:true});
    this.updateTask()
  }
  
}
