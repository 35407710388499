import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import * as uuid from "uuid"

@Component({
  selector: 'app-board-sub-item',
  templateUrl: './board-sub-item.component.html',
  styleUrls: ['./board-sub-item.component.sass']
})
export class BoardSubItemComponent implements OnInit {

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  
  constructor(private http:HttpClient) { }

  @Input() title:string=""
  @Input() task:any
  @Input() subTask:any
  currentRef=""
  currentId:string =""
  cursor:number=0
  isCompleted:boolean = false



  ngOnInit(): void {
    this.currentId = this.task.taskID + this.title + uuid.v4()
    if(this.subTask.isComplete){
        this.isCompleted = true
    }
  }

  toggleComplete(){
    this.isCompleted = !this.isCompleted
    //console.log("this is the value of isCompleted", this.isCompleted)
    //console.log(this.task.realsubTasks)
    this.task.realsubTasks[this.subTask.index].isComplete = this.isCompleted
    this.task = this.http.put(this.prod_api+"/tasks/update",this.task).subscribe(
      data=>{
        this.task = data
        //console.log(data)

      }, err=>{
        //console.log(err)
      });
  }

  updateTitle(){
    var elem:any = document.getElementById(this.currentId)
    var innerHtml:string = elem.innerText
    //console.log("**********************************************")
    //console.log(innerHtml)
    this.task.realsubTasks[this.subTask.index].title = innerHtml
   

    this.task = this.http.put(this.prod_api+"/tasks/update",this.task).subscribe(
        data=>{
          this.task = data
          //console.log(data)

        }, err=>{
          //console.log(err)
        });
  }

  removeItem(){
    this.task.realsubTasks[this.task.realsubTasks.indexOf(this.subTask)].isVisible = false
    this.task = this.http.put(this.prod_api+"/tasks/update",this.task).subscribe(
      data=>{
        this.task = data
        //console.log(data)

      }, err=>{
        //console.log(err)
      });
  }

}
