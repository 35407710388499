import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, Inject, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { TuiDialogService,TuiDialogContext } from '@taiga-ui/core';
import * as uuid from "uuid"

import {PolymorpheusContent} from '@tinkoff/ng-polymorpheus';

@Component({
  selector: 'app-calendar-day-block',
  templateUrl: './calendar-day-block.component.html',
  styleUrls: ['./calendar-day-block.component.sass']
})
export class CalendarDayBlockComponent implements OnInit {

  constructor(private http:HttpClient, @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,private changeDetector:ChangeDetectorRef) { }

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  
  @Input() block:any
  currentId = uuid.v4()
  open_sidebar = false

  months=["January","Feburary","March","April","May","June","July","August","September","October","November","December"]
  @Input() defaultProject:any
  

  @Output() refresh: EventEmitter<any> =   new EventEmitter();

  styleType:string="showing"

  newTask:any
  ngOnInit(): void {
    if(this.block.isShown===false){
      this.styleType = "hidden"
    }
    
  }

  isToday(){
    ////console.log(this.block.blockDate.getUTCDate())
    if(this.block.blockDate.getUTCDate() == new Date().getUTCDate()-1){
      return "today"
    }else{
      return ""
    }
  }

onActiveZone(event){
console.log(event)
this.open_sidebar = event
this.changeDetector.detectChanges()
}

  AddNewTask(){
    var today = new Date(this.block.blockDate);
    //console.log(today)
    today.setDate(today.getDate()+1)

    var tomorrow = today;
    //console.log(tomorrow)
    const Task = {
      taskID:uuid.v4(),
      searchableID:uuid.v4(),
      title:"Task Title",
      description: "This is just a simple description of the task at hand, here you can store links, and store reminders of some small and important stuffs",
      taskType:"Reminder",
      priorityType:"IMPORTANT_NOT_URGENT",
      layout_x:0.000,
      layout_y:0.000,
      projectID:this.defaultProject.id,
      owner:this.defaultProject.owner,
      dueDate:tomorrow,
      startDate:tomorrow,
      timeSpent:0,
      isHabit:true,
      complexityType:"MEDIUM",
      startCondition:"START AT 17:00",
      stopping_condition:"STOP_ON_COMPLETE",
      subTasks:[],
      custom_status:null,
      comments:[],
      labels:[],
      collaborators:[],
      currentStatus:"TODO"
    }
    ;
    this.newTask = this.http.post(this.prod_api+"/tasks/create",Task).subscribe(data=>{
      this.newTask = data
      this.block.taskArray.push(this.newTask)
      this.block.taskArrayComplete.push(this.newTask)
      this.changeDetector.detectChanges()
      
    },err=>{
      //console.log(err)
    })

  
  }

  showDialog(content: PolymorpheusContent<TuiDialogContext>): void {
      this.dialogService.open(content,{label:"Tasks:",size:'page'}).subscribe();
}



}   
