<div id="container" (click)="showDialogSm(template)">
  <div class="tui-avatar-stack">
    <tui-avatar
        *ngFor="let user of project.collaborators"
        [autoColor]="true"
        [text]="user"
        size="xs"
    ></tui-avatar>
</div>
    <div id="text">{{collaborators}} Collaborators</div>
</div>

<ng-template  #template let-observer>
  <div id="comment-iten-container" *ngFor="let collaborator_ of project.collaborators">
    <tui-avatar
    text="{{collaborator_}}"
    size="m"
    [autoColor]="true"
></tui-avatar>
    <div>
      {{collaborator_}}
    </div>
    <button id="flow-button" (click)="removeCollab(collaborator_)">Remove</button>
  </div>
  <div id="inputbox" >
    <h2>Select A User To Add Them To The Project:</h2>
<input placeholder="search"   id="comment-input_serach" [(ngModel)]="searchtext"/>
<div id="item-container">
    <div *ngFor="let user of usersArray" >
        <div *ngIf="user.username!=''">
            <div id="flexer" *ngIf="user.username.toLowerCase().includes(searchtext.toLowerCase())"  (click)="addCollaborator(user.username)">
                <tui-avatar
                text="{{user.username}}"
                size="m"
                [autoColor]="true"
            ></tui-avatar>
                <div>
                    <div id="name">
                        {{user.username}}
                    </div>
                    <div id="bio">
                        {{user.bio}}
                    </div>
                </div>
                  
            </div>
        </div>
    </div>
</div>
  </div>
</ng-template>
