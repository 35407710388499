import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notebook-context',
  templateUrl: './notebook-context.component.html',
  styleUrls: ['./notebook-context.component.sass']
})
export class NotebookContextComponent implements OnInit {
  dev_api ="http://localhost:8080"
  prod_api ="https://server.flowspaceproducitivity.com"
  
  constructor() { }

  ngOnInit(): void {
  }

}
