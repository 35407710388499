import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';

import { project } from 'src/app/Dashboard/ProjectDashboard/dash-contianer/project';
import * as uuid from "uuid"

@Component({
  selector: 'app-board-column',
  templateUrl: './board-column.component.html',
  styleUrls: ['./board-column.component.sass']
})
export class BoardColumnComponent implements OnInit {

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  
  constructor(private http:HttpClient,) { }
  @Input() block:any
  apiObject:any
  taskArray:any
  newTask:any
  userId:string = localStorage.getItem("userId")|| "";
  currentId:string = uuid.v4()
  isLoading = false

  ngOnInit(): void {
    this.isLoading = true
    this.apiObject = this.http.get(this.prod_api+"/tasks/project?ProjectID="+this.block.id).subscribe(
      data=>{
        this.taskArray = data
        //console.log(this.taskArray)
        this.isLoading = false
        
      }, err=>{
        //console.log(err)
        this.isLoading = false
      }
    );
  }

  AddNewTask(){
    this.isLoading = true
    const Task = {
      taskID:uuid.v4(),
      searchableID:uuid.v4(),
      title:"Task Title",
      description: "This is just a simple description of the task at hand, here you can store links, and store reminders of some small and important stuffs",
      taskType:"Reminder",
      priorityType:"IMPORTANT_NOT_URGENT",
      layout_x:0.000,
      layout_y:0.000,
      projectID:this.block.id,
      owner:this.userId,
      dueDate:new Date(),
      startDate:new Date(),
      timeSpent:0,
      isHabit:true,
      complexityType:"MEDIUM",
      startCondition:"START AT 17:00",
      stopping_condition:"STOP_ON_COMPLETE",
      subTasks:[],
      custom_status:null,
      comments:[],
      labels:[],
      collaborators:[],
      currentStatus:"TODO"
    }

    
    this.newTask = this.http.post(this.prod_api+"/tasks/create",Task).subscribe(data=>{
      this.newTask = data
      //console.log(this.newTask)
      this.taskArray.push(this.newTask)
      this.isLoading = false
      
    },err=>{
      //console.log(err)
      this.isLoading = false
    })

  
  }

}
