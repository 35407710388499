import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-notebook-header',
  templateUrl: './notebook-header.component.html',
  styleUrls: ['./notebook-header.component.sass']
})
export class NotebookHeaderComponent implements OnInit {

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  @Input() title:any
  constructor() { }

  ngOnInit(): void {
  }

}
