import { Component, OnInit } from '@angular/core';

import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-api',
  templateUrl: './api.component.html',
  styleUrls: ['./api.component.sass']
})
export class ApiComponent implements OnInit {

  constructor(private http:HttpClient) { }


  readonly root_URL = 'http://localhost:8080'
  get_users: any
  get_user: any
  create_user: any
  update_user: any
  delete_user: any
  
  get_tasks: any
  get_task: any
  create_task: any
  update_task: any
  delete_task: any
  
  vision_board: any
  vision_item: any

  get_notes: any
  get_note: any
  create_note: any
  update_note: any
  delete_note: any

  get_project:any
  get_projects:any
  delete_project:any
  update_project:any
  create_project:any




  createUser(){

    const User = {
      username: "Katleho.Sebiloane",
      password: "Kelebogile#1",
      membershipType: "PAID",
      email:"katlehosebiloane58@gmail.com",
      total_hours_spent:0,
      bio:"update the things that need to be done",
      profile_pic:"alskjfa",
      loggedIn:false,
      collaboratorsTank : [],
      additionalArray : [],
      notifications: null,
      additionalString:""
    }
    const update = {
      username: "Katleho.Sebiloane",
      password: "Phumelele#1",
      membershipType: "PAID",
      email:"katlehosebiloane58@gmail.com",
      total_hours_spent:0,
      bio:"update the things that need to be done",
      profile_pic:"alskjfa",
      loggedIn:false,
      collaboratorsTank : [],
      additionalArray : [],
      notifications: null,
      additionalString:""
    }

   

    this.create_user = this.http.post('http://localhost:8080/users/post_users',User)
  }
  getAllUsers(){
    this.get_users = this.http.get('http://localhost:8080/users/migrate')
  }
  getOneUser(){
    this.get_user = this.http.get('http://localhost:8080/users/get_users?OwnerToken=Katleho.Sebiloane')
  }
  UpdateUser(){
    this.update_user = this.http.put('http://localhost:8080/users/update',{
      username: "Katleho.Sebiloane",
      password: "Phumelele#1",
      membershipType: "PAID",
      email:"katlehosebiloane58@gmail.com",
      total_hours_spent:0,
      bio:"update the things that need to be done",
      profile_pic:"alskjfa",
      loggedIn:false,
      collaboratorsTank : [],
      additionalArray : [],
      notifications: null,
      additionalString:""
    }
  )
  }
  DeleteUser(){
    this.delete_user = this.http.delete('http://localhost:8080/users/delete_users?id=Katleho.Sebiloane')
  }



  createTask(){
    const Task = {
      taskID:"uniqueID4",
      searchableID:"uniqueID4",
      title:"Read A Chapter Of Intelligent Investor",
      description: "Read A Chapter of the book the intelligent investor to get a better understanding of the stock and fx markets and how to mix both short and long term investing to get things right",
      taskType:"Reminder",
      priorityType:"IMPORTANT_NOT_URGENT",
      layout_x:0.000,
      layout_y:0.000,
      projectID:"Invest",
      owner:"Katleho.Sebiloane",
      dueDate:new Date(),
      startDate:null,
      timeSpent:0,
      isHabit:true,
      complexityType:"MEDIUM",
      startCondition:"START AT 17:00",
      stopping_condition:"STOP_ON_COMPLETE",
      subTasks:["Set Up", "Write Notes","Application Strategy","Script For Video","Results","22Seven Login And Check"],
      custom_status:null,
      comments:null,
      labels:["investing","personalFinance"],
      collaborators:["Kelebogile.Modise","Mohau.Sebiloane"]
    }

    this.create_task = this.http.post('http://localhost:8080/tasks/create',Task)
    
  }
  getAllTasks(){
    this.get_tasks = this.http.get('http://localhost:8080/tasks/migrate')
  }
  getOneTask(){
    this.get_task = this.http.get('http://localhost:8080/tasks/ID?TaskID=uniqueID')
  }
  UpdateTask(){
    this.update_task = this.http.put('http://localhost:8080/tasks/update',{
      taskID:"uniqueID",
      searchableID:"uniqueID",
      Title:"Read A Chapter Of Intelligent Investor",
      Description: "Read A Chapter of the book the intelligent investor to get a better understanding of the stock and fx markets and how to mix both short and long term investing to get things right",
      TaskType:"Reminder",
      PriorityType:"IMPORTANT_NOT_URGENT",
      layout_x:0.000,
      layout_y:0.000,
      projectID:"InvestingEducation",
      owner:"Katleho.Sebiloane",
      DueDate:null,
      StartDate:null,
      RecursionType:null,
      RecursionStart:null,
      RecursionEnd:null,
      Duration:null,
      Start:null,
      End:null,
      Repeat:null,
      Break_Inteval:null,
      isHabit:true,
      ComplexityType:"MEDIUM",
      StartCondition:"START AT 17:00",
      stopping_condition:"STOP_ON_COMPLETE",
      SubTasks:["Set Up", "Write Notes","Application Strategy","Script For Video","Results","22Seven Login And Check"],
      custom_status:null,
      comments:null,
      labels:["investing","personalFinance"],
      collaborators:["Kelebogile.Modise","Mohau.Sebiloane"]
    })
  }
  DeleteTask(){
    this.delete_task = this.http.delete("http://localhost:8080/tasks/delete?taskToDelete=uniqueID")
  }


  CreateProject(){
      const Project = {
        "id":"Work",
        "subProject":"FinancialStability",
        "searchableID":"FinancialStability",
        "owner":"Katleho.Sebiloane",
        "title":"Financial Stability",
        "description":"In the year 2022 I want to build a solid foundation for my financial future my goal for this year is simple that is to generate a consistent income and have a respectable portfolio for a person my age",
        "collaborators":["Kelebogile.Modise"],
        "deadline":null,
        "dateCreated":null,
        "dateStart":null,
        "archived":false,
        "deleted":false,
        "complete":false
      }
      this.create_project = this.http.post("http://localhost:8080/projects/new",Project)
    
  }
  getYourProjects(){
    this.get_projects = this.http.get("http://localhost:8080/projects/personal?OwnerToken=Katleho.Sebiloane")
  }
  getProject(){
    this.get_project = this.http.get("http://localhost:8080/projects/personal/id?ProjectID=FinancialStability")
  }
  UpdateProject(){
    this.update_project = this.http.put("http://localhost:8080/projects/update",{
      "id":"FinancialStability04",
      "searchableID":"FinancialStability",
      "owner":"Katleho.Sebiloane",
      "title":"Financial Stability",
      "description":"In the year 2022 I want to build a solid foundation for my financial future my goal for this year is simple that is to generate a consistent income and have a respectable portfolio for a person my age",
      "collaborators":["Kelebogile.Sebiloane"],
      "deadline":null,
      "dateCreated":null,
      "dateStart":null,
      "archived":false,
      "deleted":false,
      "complete":false,
      "percentageComplete":50.00
    })

  }
  DeleteProject()
  {
    this.delete_project = this.http.delete("http://localhost:8080/projects/delete?ProjectID=FinancialStability")
  }

  ngOnInit(): void {
  }

}
