import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { TvPtServiceService } from 'src/app/services/tableView_projectTile/tv-pt-service.service';
import { ProjectInfoServiceService } from 'src/app/services/project_info_service/project-info-service.service';

@Component({
  selector: 'app-sub-projects',
  templateUrl: './sub-projects.component.html',
  styleUrls: ['./sub-projects.component.sass']
})
export class SubProjectsComponent implements OnInit {

  constructor(){}

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  
  @Input() numsubs:number = 0
 
  ngOnInit(): void {
   
  }

}
