import { Component, OnDestroy, OnInit, VERSION } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { fromEvent, merge, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ShepherdService } from 'angular-shepherd';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Flowspace Productivity';

  readonly root_URL = 'https://admin.flowspaceproducitivity.com'
  readonly server_URL = 'https://server.flowspaceproducitivity.com'

  

  constructor(private http:HttpClient,private router:Router, private deviceService: DeviceDetectorService){}

    isMobile = true
    networkStatus: boolean = false;
    networkStatus$: Subscription = Subscription.EMPTY;
    previousState:boolean = true;
  
    defaultStepOptions:any


    ngOnInit(): void {
          this.isMobile = this.deviceService.isMobile();
          this.checkNetworkStatus();
    }


  




    ngOnDestroy(): void {
          this.networkStatus$.unsubscribe();
    }
      // To check internet connection stability
      checkNetworkStatus() {
        this.networkStatus = navigator.onLine;
        this.networkStatus$ = merge(
          of(null),
          fromEvent(window, 'online'),
          fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
            //console.log('status', status);
            if(this.previousState == false && status == true){
              location.reload()
            }
            this.networkStatus = status;
            this.previousState = status
      });
  }

}
