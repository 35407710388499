<div id="container">
    <div id="content" *ngIf="template!=null">
        <div id="title" class="truncate" tippy="{{template.title}}">{{template.title}}</div>
        <div id="description"  tippy="Template Developed by {{template.creator}}">Template Developed by {{template.creator}}</div>
    </div>
    <div id="content" *ngIf="template==null" routerLink="/table" [queryParams]="{id:project.id}">
        <div id="title" class="truncate" tippy="{{project.title}}" >{{project.title}}</div>
        <div id="description">Project Shared by {{project.owner}}</div>
    </div>
 
    <div id="toolbar"  *ngIf="template!=null"  tippy="Download this template"> 
        <div id="button_template_card"></div>
        <div id="button_template_card"><img src="../../../../assets/download.png" alt="" (click)="download()"></div>
        <div id="button_template_card"></div>
    </div>

    <div id="toolbar_project" [ngStyle]="{'background-color':project.project_color}"  *ngIf="template==null"> 
        <div id="button_template_card" (click)="unarchive()" *ngIf="isArchive==true">Unarchive</div>
        <div id="button_template_card" routerLink="/table" [queryParams]="{id:project.id}" *ngIf="!isArhive==false || isArchive==null"></div>
        <div id="button_template_card"></div>
    </div>
</div>