
<div id="container_vision">
    <div #zoomContainer (wheel)="onZoom($event)" >
        <div #canvas id="canvas">
            <div class="w3r-dot" id="adder" tabindex="1" (keydown.control.b)="placeNew()">
                <ng-container *ngFor="let item of items" >
                    <app-vboard-item  *ngIf="linkMode==false && item.link==null" (loadNewBoard)="updateBoard($event)" [item]="item" ></app-vboard-item>
                    <app-vboard-item  *ngIf="linkMode==true && item.link!=null &&item.link==boardLink" (loadNewBoard)="updateBoard($event)" [item]="item"></app-vboard-item>
                </ng-container>
            </div>
        </div>
    </div>
    
</div>

<div id="menu">
    <tui-loader
  
    [showLoader]="isloading"
    [inheritColor]="true"
    [overlay]="true"
    >
    <div id="menu_item" (click)="placeNew()">+ New Item</div>
</tui-loader>
</div>
