import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comments-container',
  templateUrl: './comments-container.component.html',
  styleUrls: ['./comments-container.component.sass']
})
export class CommentsContainerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
