import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-board-column-header-title',
  templateUrl: './board-column-header-title.component.html',
  styleUrls: ['./board-column-header-title.component.sass']
})
export class BoardColumnHeaderTitleComponent implements OnInit {

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  
  constructor() { }
  @Input() title:string=""
  ngOnInit(): void {
  }

}
