import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-comments',
  templateUrl: './project-comments.component.html',
  styleUrls: ['./project-comments.component.sass']
})
export class ProjectCommentsComponent implements OnInit {

  constructor() { }

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  
  ngOnInit(): void {
  }

}
