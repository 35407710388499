import { Component, Input, OnInit } from '@angular/core';
import { notebook } from './notebook';
import * as uuid from "uuid"
import { HttpClient } from '@angular/common/http';
import { LoginServiceService } from 'src/app/services/login_service/login-service.service';


@Component({
  selector: 'app-notes-dash-container',
  templateUrl: './notes-dash-container.component.html',
  styleUrls: ['./notes-dash-container.component.sass']
})
export class NotesDashContainerComponent implements OnInit {

  constructor(private http:HttpClient,private service:LoginServiceService) { }
  wallpaper = Math.floor(Math.random() * (8 - 1 + 1)) + 1;
  username:string=localStorage.getItem("userId") || ""
  part:string = "Notebooks"
  newNotebook:any
  apiObject:any
  title:string="Your Notebooks"
  notebookArray:any
  sharedNotebookArray:any
  sharedNotesArray:any
  dev_api:string="http://localhost:443"
  prod_api:string="https://server.flowspaceproducitivity.com"
  currentId = uuid.v4()
  isLoading = false

  ngOnInit(): void {
    
    this.wallpaper = Math.floor(Math.random() * (8 - 1 + 1)) + 1;
    this.isLoading = true
    this.apiObject = this.http.get(this.prod_api+"/notebooks/library?OwnerToken="+this.username).subscribe(
        data=>{
          ////console.log(data);
          this.notebookArray = data;
          this.isLoading = false
          ////console.log(this.notebookArray)
  
        }, err=>{
          this.isLoading = false
          ////console.log(err);
        }
      );

      ////console.log(this.apiObject)

      this.isLoading = true
    this.apiObject = this.http.get(this.prod_api+"/notebooks/shared?MyToken="+this.username).subscribe(
        data=>{
          this.isLoading = false
          ////console.log(data);
          this.sharedNotebookArray = data;
          ////console.log(this.sharedNotebookArray)
          
        }, err=>{
          this.isLoading = false
          ////console.log(err);
        }
      );

      ////console.log(this.apiObject)
      this.isLoading = true
    this.apiObject = this.http.get(this.prod_api+"/notes/notebook?NotebookID="+this.username).subscribe(
        data=>{
          ////console.log(data);
          this.isLoading = false
          this.sharedNotesArray = data;
          ////console.log(this.sharedNotebookArray)
          
        }, err=>{
          this.isLoading = false
          ////console.log(err);
        }
      );

      ////console.log(this.apiObject)


  
      
      
   
  }

  createNotebook(){
    this.isLoading = true
    const noted:notebook = {
    "id":uuid.v4(),
    "searchableID":uuid.v4(),
    "owner":this.username,
    "cover":null,
    "title":"Notebook Title",
    "dateCreated": new Date(),
    "dateModified": new Date(),
    "projectID":"",
    "collaborators":[],
    "labels":[],
    "additionalArray":[],
    "additionalString":""
    }

    this.newNotebook = this.http.post(this.prod_api+"/notebooks/create",noted).subscribe(data=>{
        this.newNotebook = data
        this.notebookArray.push(this.newNotebook)
        this.isLoading = false
        ////console.log(this.newNotebook)
    },err=>{
      ////console.log(err)
    })
  }

}
