import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-project-header',
  templateUrl: './project-header.component.html',
  styleUrls: ['./project-header.component.sass']
})
export class ProjectHeaderComponent implements OnInit {

  constructor() { }

  @Input() title:string="Your Projects"
  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  
  ngOnInit(): void {
  }

}
