import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notebook-toolbar',
  templateUrl: './notebook-toolbar.component.html',
  styleUrls: ['./notebook-toolbar.component.sass']
})
export class NotebookToolbarComponent implements OnInit {


  dev_api ="http://localhost:8080"
  prod_api ="https://server.flowspaceproducitivity.com"
  
  constructor() { }

  ngOnInit(): void {
  }

}
