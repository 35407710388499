import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, HostListener, Inject, Input, OnInit, Output } from '@angular/core';

import { last } from 'rxjs';

import { ModalService } from 'src/app/Modals/_modal/modal.service';
import * as uuid from "uuid"
import {TuiAlertService, TuiDialogContext, TuiDialogService, TuiNotification} from '@taiga-ui/core';
import {PolymorpheusContent} from '@tinkoff/ng-polymorpheus';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-chat-contact',
  templateUrl: './chat-contact.component.html',
  styleUrls: ['./chat-contact.component.sass']
})
export class ChatContactComponent implements OnInit {

  constructor(private http:HttpClient,private readonly dialogService: TuiDialogService, @Inject(TuiAlertService) protected readonly alert: TuiAlertService) { }

  @Input() title:string="null"
  username = localStorage.getItem("userId")
  chatInfo:any
  currentChatMessages:any[] = []
  @Input() roomId:string=''
  @Input() time:string=""
  @Input() content:string=""
  @Input() room:any
  @Input() service:any
  @Input() usersArray:any
  @Input() chatRooms:any
  @Input() chat:any
  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  badgeCount:number=0  
  badgeCountString:string =""  
  recipient:any
  lastMessage:string=""
  isVisible = true
  currentId = uuid.v4()
  currentid_ = uuid.v4()+"_______up_______"
  currentIdSpinner = uuid.v4()
  selectedUser:string = ""
  participants:string[]
  searchtext:string=""
  imagebase64:any ="https://images.unsplash.com/photo-1542293787938-c9e299b880cc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80"
  lastMessageValue =""
  isLoading = false

  @Output() updateMessages: EventEmitter<any> =   new EventEmitter();

  ngOnInit(): void {
    this.isLoading = true
    if(this.chat.order==null){
      this.chat.order = 1
      this.http.put(this.prod_api+"/chatterbox/updateChatMembers",this.chat).subscribe(data=>{
        //console.log(this.chat)
      },err=>{
        //console.log(err)
      })
    }
    this.time= this.time.split(/\s/)[0]
     this.http.get(this.prod_api+"/chatterbox/getMessages?roomId="+this.roomId).subscribe((data:any)=>{
      data.forEach(element => {
        this.currentChatMessages.push(element)
        this.lastMessage = element.content
        this.lastMessageValue = this.lastMessage.replace(/<br\s*\/?>/gi,' ')
        this.isLoading = false
        this.isLoading = false

      });
      this.badgeCount = 0;
      this.currentChatMessages.forEach(element=>{
        ////console.log(element)
        if((element.isRead == null || element.isRead == false) && (element.from != this.username)){
          this.badgeCount++
          this.service.totalNewChatCount++
          this.service.totalChats.next(this.service.totalNewChatCount)
        }
      })

      if(this.badgeCount == 0){
        this.badgeCountString = ""
      }else{
        this.badgeCountString = this.badgeCount.toString();
        this.move(this.chatRooms,this.chatRooms.indexOf(this.chat),0)
      }


      this.isLoading = false
      
      
    },err=>{
      //console.log(err)
    })

    this.http.get(this.prod_api+"/users/get_users?OwnerToken="+this.title).subscribe(data=>{
      this.isLoading = true
      this.recipient = data
      if(this.recipient.profile_pic!=null){
        this.imagebase64 = this.recipient.profile_pic
      }else{
        this.isLoading = false
        this.imagebase64 ="https://images.unsplash.com/photo-1542293787938-c9e299b880cc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80"
      }
      
    })

    this.service.messages.subscribe(data=>{
     // //console.log("*********************************************************************************************************")
      const allmess = data
      ////console.log(data)
      allmess.forEach(element=>{
        const obj = element
        //console.log(obj.roomId)
        //console.log(this.roomId)
        if(this.roomId === obj.roomId){
          this.move(this.chatRooms,this.chatRooms.indexOf(this.chat),0)
          //console.log("pushed a new element to the thing")
          this.currentChatMessages.push(obj)
          this.lastMessage = obj.content.replace(/<br\s*\/?>/gi,' ')
          this.lastMessageValue = this.lastMessage.replace(/<br\s*\/?>/gi,' ')
          if(obj.from != this.username){
            this.badgeCount++
            this.badgeCountString = this.badgeCount.toString()
          }
        
      }
      //console.log(this.currentChatMessages)
    })
    })

    this.participants = this.room.participants
    

    setTimeout(()=>{this.isLoading = false},8000)
  }

  move(input, from, to) {
    this.isLoading = true
    let numberOfDeletedElm = 1;
  
    const elm = input.splice(from, numberOfDeletedElm)[0];
  
    numberOfDeletedElm = 0;
  
    input.splice(to, numberOfDeletedElm, elm);
    input.forEach(elem=>{
      elem.order = this.chatRooms.indexOf(elem)
      this.http.put(this.prod_api+"/chatterbox/updateChatMembers",this.chat).subscribe(data=>{
        //console.log(this.chat)
        this.isLoading = false
      },err=>{
        //console.log(err)
        this.isLoading = false
      })
    })
  }

  updateMessageStatus(){
    
    this.currentChatMessages.forEach(element=>{
      if((element.isRead == null || element.isRead == false) && (element.from != this.username)){
        element.isRead = true;
        this.http.put(this.prod_api+"/chatterbox/update",element).subscribe(data=>{
          //console.log(data)
          //console.log("updated the message read status")
        })
      }
    })
   
}


  update(){
    this.isLoading = true
    this.updateMessageStatus()
    this.badgeCount = 0
    this.badgeCountString = "0"
     
    //console.log(this.badgeCount)
    this.http.get(this.prod_api+"/chatterbox/getMessages?roomId="+this.roomId).subscribe((data)=>{
      this.isLoading = true
      this.chatInfo = data
      if(this.room.participants.length>2){
        var strTitle:string="";
        this.room.participants.forEach(element => {
          if(element!=undefined){
            strTitle = strTitle+element+", "
          }
          
        });
        const mess = {
          title:this.room.from,
          members:strTitle,
          messages:this.currentChatMessages,
          roomId:this.roomId,
          profile:this.imagebase64,
          room: this.room
  
        }
        
        this.updateMessages.emit(mess)
      }else{
        var strTitle:string="";
        this.room.participants.forEach(element => {
          if(element!=undefined){
            strTitle = strTitle+element+", "
          }
        });
        const mess = {
          title:this.title,
          members:strTitle,
          messages:this.currentChatMessages,
          roomId:this.roomId,
          profile:this.imagebase64,
          room: this.room
  
        }
        this.updateMessages.emit(mess)
      }

      this.isLoading = false
    },err=>{
      this.isLoading = false
      //console.log(err)
    })
    
  }
 





  deleteChat() {
    if(confirm("Are You Sure You wanna delete this chat for you and all participants!!??")){
      this.isLoading = true
      this.http.delete(this.prod_api+"/chatterbox/deleteChat?id="+this.roomId).subscribe(data=>{
        //console.log(data)
        this.isLoading = false
        this.isVisible = false
      },err=>{
        //console.log(err)
      })
    }
  }

  removeMember(remed){
    this.room.participants.forEach(e=>{
      if(e === remed){
        this.room.participants.splice(this.room.participants.indexOf(e),1)
        this.isLoading = true
        this.http.put(this.prod_api+"/chatterbox/updateChatMembers",this.room).subscribe(data=>{
          this.isLoading = false
          //console.log("message updated")
        },err=>{
          //console.log(err)
        })
      }
    })
    //console.log("not found")
  }



selectUser(name:string){
  this.selectedUser = name
}

showNotification(title:string, content:string): void {
  this.alert.open(content, {label: title,status: TuiNotification.Success}).subscribe();
}
 addMember(user){
  this.isLoading = true
   this.room.participants.push(user)
   this.room.title = "Group Chat"
   this.http.put(this.prod_api+"/chatterbox/updateChatMembers",this.room).subscribe(data=>{
     this.showNotification("Added Member", "A New Member was Added to the chat")
     this.isLoading = false
   },err=>{
    this.isLoading = false
     //console.log(err)
   })
 }

 updateTitle(){
  this.isLoading = true
   this.room.to = document.getElementById("name"+this.currentId).innerHTML
   this.room.from = document.getElementById("name"+this.currentId).innerHTML
   this.http.put(this.prod_api+"/chatterbox/updateChatMembers",this.room).subscribe(data=>{
     //console.log("message updated")
     this.isLoading = false
   },err=>{
    this.isLoading = false
     //console.log(err)
   })
 }

 iscontentEditable(){
  if(this.room.participants.length>2){
    return true
  }
  
  return false
 }

 showDialog(content: PolymorpheusContent<TuiDialogContext>): void {
  this.dialogService.open(content).subscribe();
}
}


