export interface toolbar{
    value:string;
    image:string;
    name:string;
} 

export const buttonArray = [
    {
        value:"createTemplate()",
        image:"../../../assets/lrg_context.png",
        userid:"Global Actions"
    },{
        value:"global_actions",
        image:"../../../assets/sml_settings.png",
        userid:"Settings"
    },{
        value:"global_actions",
        image:"../../../assets/files.png",
        userid:"Files"
    },{
        value:"global_actions",
        image:"../../../assets/notifications.png",
        userid:"Notifications"
    },{
        value:"global_actions",
        image:"../../../assets/sml_share.png",
        userid:"Share"
    }
]