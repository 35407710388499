<div id="container">
    <div id="prog_div"><progress id="file" value="{{progress}}" max="100"></progress></div>
    <div *ngIf="progress===null">
        <div id="title">0% Complete</div>
    </div>
    <div *ngIf="progress!=null">
        <div id="title">{{progress}}% </div>
    </div>
    
</div>

