import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as uuid from "uuid"

@Component({
  selector: 'app-calendar-item',
  templateUrl: './calendar-item.component.html',
  styleUrls: ['./calendar-item.component.sass']
})
export class CalendarItemComponent implements OnInit {


  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  @Input() task:any
  date:any =""
  months=["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
  @Input() messageService:any
  username = localStorage.getItem("userId")
  @Input() parent_project:any
  style: string;
  constructor(private http:HttpClient,private toastr:ToastrService) { }

  ngOnInit(): void {
    this.date = new Date(this.task.dueDate)

    if(this.date<new Date() && this.task.currentStatus!=="COMPLETE"){
      this.style = "overdue"
    }
  }

  clear(){
    this.style=""
  }
}
