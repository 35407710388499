<div id="container">
    <app-tab></app-tab>
    <app-tab></app-tab>
    <app-tab></app-tab>
    <app-tab></app-tab>
    <app-tab></app-tab>
    <app-tab></app-tab>
    <app-tab></app-tab>
    <app-tab></app-tab>
</div>
