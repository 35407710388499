<div id="container">
  <tui-loader
[showLoader]="isLoading"
[overlay]="true"
>
    <div id="sidebar">
        <app-notebook-header [title]="title"></app-notebook-header>
        <button (click)="createNote()" id="flow-button-subTask">Add Note</button>
        <div id="details" class="scroll">
          <ng-container *ngFor="let note of NotesArray">
            <ng-container *ngIf="this.notebookId!=this.username">
              <ng-container *ngIf="note.notePagesID === null">
                <app-note-item [index]="NotesArray.indexOf(note)" [notesArray]="NotesArray" [icon]="'../../../assets/note_icon.png'" ></app-note-item>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="this.notebookId==this.username">
              <ng-container>
                <app-note-item [index]="NotesArray.indexOf(note)" [notesArray]="NotesArray" [icon]="'../../../assets/note_icon.png'" ></app-note-item>
              </ng-container>
            </ng-container>
            
          </ng-container>
        </div>
    </div>
</tui-loader>
    <div id="note_content">
    <app-notebook-wysiwyg (autosave)="autosave()"></app-notebook-wysiwyg>
    </div>
</div>
