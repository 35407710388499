<div 
 id="{{slot_type}}" 
 class="{{slot.scroll}}"
 (click)="toggle(true)"
 (tuiActiveZoneChange)="onActiveZone($event)" 
 (tuiActiveZoneChange)="toggle($event)"  >
    {{time}}
    <div *ngFor="let task of tasks" class="{{style}}">
        {{task.title}}
    </div>
    <div *tuiSidebar="open_sidebar; direction: 'right';autoWidth:true;" >
        <div class="widther">
            <h1>{{slot.slotDate.getDate()}} {{months[slot.slotDate.getMonth()]}} {{slot.slotDate.getFullYear()}}</h1>
            <button id="flow-button" (click)="AddNewTask()">Add Task</button>
            <div id="tasks" *ngFor="let task_ of slot.tasks">
                <app-table-item [isOnCalendar]="true" [blockTaskArray]="slot.tasks" [taskArray]="slot.tasks" [task]="task_" [title]="task_.title"  [numcollabs]="task_.collaborators.length" [subTaskArray]="task_.subTasks" [parent_project]="defaultProject" [messageService]="messageService"></app-table-item>
            </div>
        </div>
       
    </div>
</div>

