import { Component, OnInit } from '@angular/core';
import { GoalsNavServiceService } from 'src/app/services/goals-nav-service.service';

@Component({
  selector: 'app-goal-tabs',
  templateUrl: './goal-tabs.component.html',
  styleUrls: ['./goal-tabs.component.sass']
})
export class GoalTabsComponent implements OnInit {

  constructor(private goal_service:GoalsNavServiceService) { }

  ngOnInit(): void {

  }

  updateTypes(type){
    this.goal_service.updateType(type);
  }

}
