import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-collaborators-component',
  templateUrl: './project-collaborators-component.component.html',
  styleUrls: ['./project-collaborators-component.component.sass']
})
export class ProjectCollaboratorsComponentComponent implements OnInit {

  constructor() { }

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  
  @Input() numcollabs:number =0
  @Input() project:any
  
  collabs

  left_value = 0

  ngOnInit(): void {
    this.project.collaborators.forEach(el=>{
        if(el!=''|| el!=null){
          this.collabs.push(el)
        }
    })
  }

}
