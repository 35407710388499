<tui-loader
[showLoader]="isLoading"
[overlay]="true"
>
<div id="container"  *ngIf="isVisible" >
    <div id="container_"  (click)="update()">
        <div id="profile"badge="">
            <img src='{{imagebase64}}' style="object-fit:cover" width="40px" height="40px" id="dp" >
        </div>
        <div id="container_">
            <div id="details">
                <div class="name"  id="name{{currentId}}" [contentEditable]="iscontentEditable()" (blur)="updateTitle()">{{title}}</div>
                <div id="mess" >{{lastMessageValue}}</div>
            </div>
            <div id="short" badge="{{badgeCountString}}" ></div>
    </div>
    </div>


    <tui-hosted-dropdown
    
    [sided]="false"
    [content]="dropdown_menu"
    [(open)]="open_menu"
>
    <button
        tuiButton
        type="button"
        appearance="flat"
        size="s"
        iconRight="tuiIconMoreVer"
        [pseudoActive]="open || null"
    >
        
    </button>
</tui-hosted-dropdown>
</div>
</tui-loader>



<ng-template #users let-observer>
    <h2>Select A User To Add Them To Chat:</h2>
    <input placeholder="search"   id="comment-input_serach" [(ngModel)]="searchtext"/>
    <div id="item-container">
        <div *ngFor="let user of usersArray" >
            <div *ngIf="user.username!=''">
                <div id="flexer" *ngIf="user.username.includes(searchtext)" >
                    <tui-avatar
                    text="{{user.username}}"
                    size="m"
                    [autoColor]="true"
                ></tui-avatar>
                    <div (click)="addMember(user.username)">
                        <div id="name">
                            {{user.username}}
                        </div>
                        <div id="bio">
                            {{user.bio}}
                        </div>
                    </div>
                      
                </div>
            </div>
        </div>
    </div>
  </ng-template>


  <ng-template #remove let-observer>
    <h3>Select A User To Remove Them From Chat:</h3>
    <input placeholder="search"   id="comment-input_serach" [(ngModel)]="searchtext"/>
    <div id="item-container">
        <div *ngFor="let user of participants" >
            <div *ngIf="user.username!=''">
                <div id="flexer" *ngIf="user.includes(searchtext)" >
                    <tui-avatar
                    text="{{user}}"
                    size="m"
                    [autoColor]="true"
                ></tui-avatar>
                    <div (click)="removeMember(user)">
                        <div id="name">
                            {{user}}
                        </div>
                    </div>
                      
                </div>
            </div>
        </div>
    </div>
  </ng-template>


  <ng-template
  #dropdown_menu
  let-close="close"
>
  <tui-data-list>
      <tui-opt-group>
          <button
              tuiOption
              (click)="showDialog(users)"
          >
             Add Member
          </button>
          <button
              tuiOption
              (click)="showDialog(remove)"
          >
             Remove Member
          </button>
      </tui-opt-group>
      <tui-opt-group>
          <button
              tuiOption
              (click)="deleteChat()"
          >
              Delete
          </button>
      </tui-opt-group>
  </tui-data-list>
</ng-template>
