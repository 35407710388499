<div id="{{container_type}}" *ngIf="isDeleted" (mouseover)="toggleTrue()" (mouseleave)="toggleFalse()">
        <tui-loader
      [showLoader]="isLoading"
      [overlay]="true"
      >
    <div id="content" routerLink="/editor" [queryParams]="{id:notebook.id,title:notebook.title}" (mouseover)="toggleTrue()" [ngStyle]="{'background-image': 'url(data:image/png;base64,' + imagebase64 + ')'}">
    </div>
    </tui-loader>
 
    <div id="info">
        <div>
            <div id="{{currentId}}"  class="{{title_type}}" contenteditable="true" (blur)="updateTitle()">{{title}}</div>
        </div>
     
        <div 
        id="context" 
        [queryParams]="{id:notebook.id,title:notebook.title,searchable:notebook.searchableID}" >
        <tui-hosted-dropdown
        
        [sided]="false"
        [content]="dropdown_menu"
        [(open)]="open"
        id="status" 
        >

            <tui-svg
            src="tuiIconMoreVer"
            class="icon">
          </tui-svg>
      </tui-hosted-dropdown>
        
      </div>
    </div>
</div>


<jw-modal id="{{currentIdmodal}}">
    <h1>Notebook Collaborators:</h1>
    <div id="comment-iten-container" *ngFor="let collaborator_ of notebook.collaborators">
      <div>
        {{collaborator_}}
      </div>
      <button id="flow-button" (click)="removeCollab(collaborator_)">Remove</button>
    </div>
    <div id="inputbox">
      <input [(ngModel)]="collaborator" id="comment-input"/>
      <div id="flexer">
        <button id="flow-button" (click)="addCollaborator()">Add Collaborator</button>
        <div id="spacer"></div>
        <button id="flow-close" (click)="closeModal(currentIdmodal)">Close</button>
      </div>
    </div>

</jw-modal>

<jw-modal id="{{currentIdimage}}">
  <label (mouseover)="toggleTrue()" id="changeImage" for="Image_{{currentIdimage}}" >Change Picture</label>
  <input (mouseover)="toggleTrue()" type="file" id="Image_{{currentIdimage}}" (change)="selectFile($event)">
  <app-project-progress-bar [progress]="progress" ></app-project-progress-bar>
</jw-modal>


<ng-template #dropdown_menu
    let-close="close"
>
    <tui-data-list>
        <tui-opt-group>
            <button
                tuiOption
                (click)="openModal(currentIdimage)"
            >
                Change Picture
            </button>
            <button
                tuiOption
                (click)="openModal(currentIdmodal)"
            >
                Share Notebook
            </button>
            <button
                tuiOption
                (click)="deleteNotebook()"
            >
                Delete Notebook
            </button>
           
        </tui-opt-group>
       
    </tui-data-list>
</ng-template>



