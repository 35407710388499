import { HttpClient } from '@angular/common/http';
import { Component, OnInit,Input, Inject, ChangeDetectorRef } from '@angular/core'
import * as uuid from "uuid";
import { ToastrService } from 'ngx-toastr';
import { WebsocketService } from 'src/app/services/websockets/web-socket-service.service';
import {TuiAlertService, TuiDialogContext, TuiDialogService, TuiDialogSize} from '@taiga-ui/core';
import {PolymorpheusContent} from '@tinkoff/ng-polymorpheus';
import { FormGroup, FormControl } from '@angular/forms';
import {TuiDayRange} from '@taiga-ui/cdk';

@Component({
  selector: 'app-table-item-container',
  templateUrl: './table-item-container.component.html',
  styleUrls: ['./table-item-container.component.sass']
})
export class TableItemContainerComponent implements OnInit {
  input: any;

  constructor(private http:HttpClient,private toastr:ToastrService,@Inject(TuiAlertService) private readonly alertService: TuiAlertService,@Inject(TuiDialogService) private readonly dialogService: TuiDialogService,private readonly changeDetector: ChangeDetectorRef ) { }

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"

  draggingIndex:number;

  @Input() projectID:string =""
  @Input() parentProject:any
  @Input() messageService: any
  @Input() block: any
  taskArray:any
  apiObject:any
  newTask:any
  userId:string = localStorage.getItem("userId")|| "";
  currentId = uuid.v4();
  stompClient:any;
  received:any[] = [];
  sent:any[] = [];
  hiddenMode:boolean = false
  hide_icon="hide"
  items=[]
  testForm = new FormGroup({
    testValue: new FormControl(),
  });
  selectedCollab

  isloading= true;

  ngOnInit(): void {

    
    this.apiObject = this.http.get(this.prod_api+"/tasks/project?ProjectID="+this.projectID).subscribe(
      (data:any)=>{
        this.taskArray = data.sort((p1, p2) => (p1.layout_y > p2.layout_y) ? 1 : (p1.layout_y < p2.layout_y) ? -1 : 0);
        this.block.project_color = this.parentProject.project_color
        this.changeDetector.detectChanges();
      
        this.taskArray.forEach(elem=>{
            elem.color = this.parentProject.project_color
            //console.log(elem.color)
        })
        this.http.put(this.prod_api+"/tasks/bulk_update",this.taskArray).subscribe(data=>{
          this.taskArray = data
        })
        this.updateProject()
        
       this.isloading  = false
        
      }, err=>{
        //console.log(err)
      }
    );

    this.items = this.parentProject.collaborators

    

    
    
    
    
    
  }

  updateProject(){
    this.http.put(this.prod_api+"/projects/update",this.block).subscribe(data=>{
      //this.showNotification()
      this.changeDetector.detectChanges();
    },(err)=>{
      this.alertService.open(`Project Color Update Failed`, {label: `Update Failed`}).subscribe();
    })
  }

  showNotification(): void {
    this.alertService.open(`Assiged All Tasks Successfully`, {label: `Assignment Complete`}).subscribe();
  }

  hideComplete(){
    //console.log("ping")
    this.hiddenMode = !this.hiddenMode
    if(this.hiddenMode){
      this.hide_icon="show"
      return
    }else{
      this.hide_icon="hide"
      return
    }
   
  }

  completeAll(){
    this.taskArray.forEach(elem=>{
      elem.currentStatus = "COMPLETE"
    })
    this.http.put(this.prod_api+"/tasks/bulk_update",this.taskArray).subscribe(data=>{
      this.taskArray = data
      this.changeDetector.detectChanges();
    })
  }


  selectUser(){
    this.selectedCollab = this.testForm.value.testValue
    this.assignAll()
  }


  assignAll(){
    this.taskArray.forEach(elem=>{
      elem.collaborators = []
      elem.collaborators.push(this.selectedCollab)
    })
    this.http.put(this.prod_api+"/tasks/bulk_update",this.taskArray).subscribe(data=>{
      this.showNotification()
    },(err)=>{
      this.alertService.open(`Assiged All Tasks Failed`, {label: `Assignment Failed`}).subscribe();
    })
  }



  AddNewTask(){
    const Task = {
      taskID:uuid.v4(),
      searchableID:uuid.v4(),
      title:"Task Title",
      description: "This is just a simple description of the task at hand, here you can store links, and store reminders of some small and important stuffs",
      taskType:"Reminder",
      priorityType:"IMPORTANT_NOT_URGENT",
      layout_x:0.000,
      layout_y:0.000,
      projectID:this.projectID,
      owner:this.userId,
      dueDate:new Date(),
      startDate:new Date(),
      timeSpent:0,
      isHabit:true,
      complexityType:"MEDIUM",
      startCondition:"START AT 17:00",
      stopping_condition:"STOP_ON_COMPLETE",
      subTasks:[],
      custom_status:null,
      comments:[],
      labels:[],
      collaborators:[],
      currentStatus:"TODO"
    }
   this.isloading = true
    this.newTask = this.http.post(this.prod_api+"/tasks/create",Task).subscribe(data=>{
      this.newTask = data
      //console.log(this.newTask)
      this.taskArray.push(this.newTask)
      this.isloading = false
      this.changeDetector.detectChanges();
    },err=>{
      //console.log(err)
    })

  
  }


  showDialogSm(
    content: PolymorpheusContent<TuiDialogContext>, label:string
    ): void {
   
    this.dialogService.open(content,{
      label: label,
      size:'auto',
  }).subscribe();
  }

  updateDate(dateRange:TuiDayRange){

    this.taskArray.forEach(elem=>{
      elem.startDate = new Date(dateRange.from.year,dateRange.from.month,dateRange.from.day+1)
      elem.dueDate = new Date(dateRange.to.year,dateRange.to.month,dateRange.to.day+1)
    })
    this.http.put(this.prod_api+"/tasks/bulk_update",this.taskArray).subscribe(data=>{
      this.showNotification()
    },(err)=>{
      this.alertService.open(`Assiged All Tasks Failed`, {label: `Assignment Failed`}).subscribe();
    })

  }

  updateTask(task){
    this.http.put(this.prod_api+"/tasks/update",task).subscribe(data=>{
      //console.log("updated")
      //console.log(data)

    },err=>{
      //console.log(err)
    }
    )

  }

  private _reorderItem(fromIndex: number, toIndex: number): void {
    const itemOriginallyThere_ = this.taskArray[toIndex];
    const itemToBeReordered = this.taskArray.splice(fromIndex, 1)[0];
    itemToBeReordered.layout_y = toIndex
    itemOriginallyThere_.layout_y = fromIndex;
    this.updateTask(itemToBeReordered)
    this.updateTask(itemOriginallyThere_)
    this.taskArray.splice(toIndex, 0, itemToBeReordered);
    this.draggingIndex = toIndex;
  }

  onDragStart(index: number): void {
    this.draggingIndex = index;
    var emptyImage = document.createElement('img');
  }

  onDragEnter(index: number): void {
    if (this.draggingIndex !== index) {
      this._reorderItem(this.draggingIndex, index);
    }
  }

  onDragEnd(): void {
    this.draggingIndex = undefined;
  }
}
