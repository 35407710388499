<tui-loader
class="inline-flex tui-space_right-2"
    [showLoader]="isLoading"
    [inheritColor]="true"
    [overlay]="true"
>
<div *ngIf="projectID !=''">
<div id="project_container" (click)="load()" *ngIf="isVisible">
    <app-project-title-image id="more" [title]="projectName" [project]="projectRef"></app-project-title-image>
    <a routerLink="/table" [queryParams]="{id:project.id}"><button id="open">Open</button></a>
    <a routerLink="/table" [queryParams]="{id:project.id}"><app-sub-projects [numsubs]="numsubs" (click)="load()"></app-sub-projects></a>
    <span id="nrTasks">{{allTasksArray.length}} Tasks</span>
    <app-project-collaborators-component [numcollabs]="numcollabs"  [project]="projectRef" (click)="showDialogCollab(template)"></app-project-collaborators-component>
    <app-project-progress-bar [progress]="percentageComplete"></app-project-progress-bar>
    <app-project-context (loading)="setLoading()" (hide)="hideProject()" [project]="projectRef"></app-project-context>
  
</div>
</div>
</tui-loader>

<ng-template #template let-observer>
  <div id="comment-iten-container" *ngFor="let collaborator_ of project.collaborators">
    <tui-avatar
    text="{{collaborator_}}"
    size="m"
    [autoColor]="true"
></tui-avatar>
    <div id="text">
      <b>{{collaborator_}}</b>
      <div>This user has admin permissions.</div>
    </div>
    
    <button id="flow-button" (click)="removeCollab(collaborator_)">Remove</button>
  </div>
  <div id="inputbox" >
    <h2>Select A User To Add Them To The Project:</h2>
<input placeholder="search"   id="comment-input_serach" [(ngModel)]="searchtext"/>
<div id="item-container">
    <div *ngFor="let user of usersArray" >
        <div *ngIf="user.username!=''">
            <div id="flexer" *ngIf="user.username.toLowerCase().includes(searchtext.toLowerCase())"  (click)="addCollaborator(user.username)">
                <tui-avatar
                text="{{user.username}}"
                size="m"
                [autoColor]="true"
            ></tui-avatar>
                <div>
                    <div id="name">
                        {{user.username}}
                    </div>
                    <div id="bio">
                        {{user.bio}}
                    </div>
                </div>
                  
            </div>
        </div>
    </div>
</div>
  </div>

  </ng-template>