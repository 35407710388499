import { UniqueSelectionDispatcher } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { Component, Injector, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup} from '@angular/forms';
import { TuiEditorTool, TUI_EDITOR_EXTENSIONS } from '@taiga-ui/addon-editor';
import { TuiDay, TuiDayRange } from '@taiga-ui/cdk';
import * as e from 'express';
import { GoalsNavServiceService } from 'src/app/services/goals-nav-service.service';
import * as uuid from "uuid"

@Component({
  selector: 'app-goals-item',
  templateUrl: './goals-item.component.html',
  styleUrls: ['./goals-item.component.sass'],
  providers: [
    {
      provide: TUI_EDITOR_EXTENSIONS,
      deps: [Injector],
      useFactory: (injector: Injector) => [
          import('@taiga-ui/addon-editor/extensions/starter-kit')
              .then(({StarterKit}) => StarterKit)
              .then(extension => extension.configure({heading: {levels: [1, 2]}})),
          import('@tiptap/extension-text-align').then(({default: TextAlign}) =>
              TextAlign.configure({types: ['heading', 'paragraph']}),
          ),
          import('@tiptap/extension-text-style').then(
              ({default: TextStyle}) => TextStyle,
          ),
          import('@tiptap/extension-underline').then(
              ({default: Underline}) => Underline,
          ),
          import('@tiptap/extension-subscript').then(
              ({default: Subscript}) => Subscript,
          ),
          import('@tiptap/extension-superscript').then(
              ({default: Superscript}) => Superscript,
          ),
          import('@taiga-ui/addon-editor/extensions/font-color').then(
              ({FontColor}) => FontColor,
          ),
          import('@taiga-ui/addon-editor/extensions/link').then(
              ({TuiLink}) => TuiLink,
          ),
          import('@taiga-ui/addon-editor/extensions/jump-anchor').then(
              ({TuiJumpAnchor}) => TuiJumpAnchor,
          ),
          import('@taiga-ui/addon-editor/extensions/file-link').then(
              ({TuiFileLink}) => TuiFileLink,
          ),
          import('@taiga-ui/addon-editor/extensions/background-color').then(
              ({BackgroundColor}) => BackgroundColor,
          ),
          import('@taiga-ui/addon-editor/extensions/table').then(({TuiTable}) =>
              TuiTable.configure({resizable: true}),
          ),
          import('@tiptap/extension-table-row').then(
              ({default: TableRow}) => TableRow,
          ),
          import('@tiptap/extension-table-cell').then(
              ({default: TableCell}) => TableCell,
          ),
          import('@tiptap/extension-table-header').then(
              ({TableHeader}) => TableHeader,
          ),
          import('@taiga-ui/addon-editor/extensions/indent-outdent').then(
              ({TuiTabExtension}) => TuiTabExtension,
          ),
          import('@taiga-ui/addon-editor/extensions/table-cell-background').then(
              ({TableCellBackground}) => TableCellBackground,
          ),
          import('@taiga-ui/addon-editor/extensions/details').then(
              ({TuiDetailsContent}) => TuiDetailsContent,
          ),
          import('@taiga-ui/addon-editor/extensions/details').then(
              ({TuiDetails}) => TuiDetails,
          ),
          import('@taiga-ui/addon-editor/extensions/details').then(
              ({TuiSummary}) => TuiSummary,
          ),
          import('@taiga-ui/addon-editor/extensions/font-size').then(
              ({TuiFontSize}) => TuiFontSize,
          ),
          import('@taiga-ui/addon-editor/extensions/youtube').then(
            ({Youtube}) => Youtube,
          ),
          import('@taiga-ui/addon-editor/extensions/image-editor').then(
              ({createImageEditorExtension}) =>
                  createImageEditorExtension(injector),
          ),
          import('@tiptap/extension-focus').then(({FocusClasses}) =>
              FocusClasses.configure({
                  className: 'has-focus',

                  /**
                   * @description:
                   * Apply the class to 'all', the 'shallowest' or the 'deepest' node.
                   *
                   * Default: 'all'
                   */
                  mode: 'shallowest',
              }),
          ),
      ],
  }
],
})
export class GoalsItemComponent implements OnInit {
  nameString: any;
  type_display = null
  constructor(private http:HttpClient,private goal_service:GoalsNavServiceService) { }


  readonly builtInTools = [TuiEditorTool.Undo, 
    TuiEditorTool.Anchor,
    TuiEditorTool.Bold,
    TuiEditorTool.Clear,
    TuiEditorTool.Code,
    TuiEditorTool.Color,
    TuiEditorTool.HR,
    TuiEditorTool.Img,
    TuiEditorTool.Italic,
    TuiEditorTool.Link,
    TuiEditorTool.List,
    TuiEditorTool.MergeCells,
    TuiEditorTool.Quote,
    TuiEditorTool.RowsColumnsManaging,
    TuiEditorTool.Size,
    TuiEditorTool.SplitCells,
    TuiEditorTool.Strikethrough,
    TuiEditorTool.Sub,
    TuiEditorTool.Sup,
    TuiEditorTool.Table,
    TuiEditorTool.Tex,
    TuiEditorTool.Underline,
    TuiEditorTool.Hilite,
    TuiEditorTool.Undo];
  
  showCal = false
  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"

  username = localStorage.getItem("userId")
  isLoading = false

  trash = "../../../assets/trash.svg"

  @Input() goal:any ={
    owner: this.username,
    goalID:uuid.v4(),
    name:"Goal Name",
    why:"This is where you put down why you want to achieve the goal, make sure it relevent to you and specific to your situation",
    how:"This is a basic off the top of your head plan to just put into perspective what you will need in order to accomplish this goal",
    units:0,
    progress:0,
    dueDate:"",
    isComplete:false
  }

  @Input() all_goals:any

  showWhy = false
  showHow= false
  showUnit = false
  showTrack= false
  showDue= false
  isVisible= true
  showSub= false
  isComplete = new FormGroup({
    Completevalue: new FormControl(false),
  });


  readonly testForm = new FormGroup({
    testValue: new FormControl(new TuiDay(2023, 0, 31)),
  });

unitForm = new FormGroup({
  unit: new FormControl(0),
});

progressForm = new FormGroup({
  progress: new FormControl(0),
});

percentageComplete

ngOnInit(): void {
    //console.log(this.goal)

    this.unitForm = new FormGroup({
      unit: new FormControl(this.goal.units),
    });

    this.isComplete = new FormGroup({
      Completevalue: new FormControl(this.goal.isComplete)
    })

    this.percentageComplete = (this.goal.progress/this.goal.units)*100
    this.percentageComplete = parseFloat(this.percentageComplete.toFixed(2))
    if(this.percentageComplete>=100){
      this.goal.isComplete = true
      this.isComplete.value.Completevalue = true
      this.percentageComplete = 100
      this.updateGoalFast(null)
    }

    this.goal_service.goal_type_.subscribe((data)=>{
      this.type_display = data
      //console.log(this.type_display)
  })

  }

  ngOnChanges(){
    //console.log(this.goal)

    this.unitForm = new FormGroup({
      unit: new FormControl(this.goal.units),
    });

    this.percentageComplete = (this.goal.progress/this.goal.units)*100
    this.percentageComplete = parseFloat(this.percentageComplete.toFixed(2))

  }

  startUpdate(event){
    if(event){
      this.updateDate(this.testForm.value.testValue)
    }else{
      this.showDue = false
    }

  }

  updateDate(dateRange:TuiDay){
    this.goal.dueDate = new Date(dateRange.year,dateRange.month,dateRange.day+1)
    //console.log(this.goal.dueDate)
    this.updateGoal()
    setTimeout(() => {
      this.showDue = false
    }, 30000);
    
  }
  

  updateName(){
    var elem:any = document.getElementById(this.goal.goalID)
    var innerHtml:string = elem.innerText
    //console.log(innerHtml)
    this.goal.name = innerHtml
    this.updateGoalFast(null)
  }

  updatewhy(){
    var elem:any = document.getElementById(this.goal.goalID+"why")
    var innerHtml:string = elem.innerText
    //console.log(innerHtml)
    this.goal.why = innerHtml
    this.updateGoalFast(null)
  }

  updatehow(){
    var elem:any = document.getElementById(this.goal.goalID+"how")
    var innerHtml:string = elem.innerText
    //console.log(innerHtml)
    this.goal.how = innerHtml
    this.updateGoalFast(null)
  }
  
  toggleWhy(){
    this.reset()
    this.showWhy = !this.showWhy
  }
  toggleHow(){
    this.reset()
    this.showHow = !this.showHow
  }
  toggleUnits(){
    this.reset()
    this.showUnit = !this.showUnit
  }

  toggleTrack(){
    this.reset()
    this.showTrack = !this.showTrack
  }

  toggleDue(){
    this.reset()
    this.showDue = !this.showDue
  }
  toggleSub(){
    this.reset()
    this.showSub = !this.showSub
  }

  saveProgress(){
    this.goal.progress += this.progressForm.value.progress
    this.percentageComplete = (this.goal.progress/this.goal.units)*100
    this.percentageComplete = parseFloat(this.percentageComplete.toFixed(2))
    if(this.percentageComplete>=100){
      this.goal.isComplete = true
      this.isComplete.value.Completevalue = true
      this.percentageComplete = 100
    }
    this.updateGoal()

  }

  saveUnits(){
    this.goal.units = this.unitForm.value.unit
    this.percentageComplete = (this.goal.progress/this.goal.units)*100
    this.percentageComplete = parseFloat(this.percentageComplete.toFixed(3))
    if(this.percentageComplete>=100){
      this.goal.isComplete = true
      this.isComplete.value.Completevalue = true
    }
    this.updateGoal()
  }

  toggleComplete(){
    this.goal.isComplete = !this.isComplete.value.Completevalue
    //console.log(this.goal.isComplete)
    this.updateGoal()
  }


  createGoal(){
    //console.log("creating goal...")
    var goal = {
      owner: this.username,
      goalID:uuid.v4(),
      name:"Goal Name",
      why:"This is where you put down why you want to achieve the goal, make sure it relevent to you and specific to your situation",
      how:"This is a basic off the top of your head plan to just put into perspective what you will need in order to accomplish this goal",
      units:100,
      progress:0,
      dueDate:new Date(),
      isComplete:false,
      type:this.type_display,
      parentGoal: this.goal.goalID
  }

  this.http.post(this.prod_api+"/goals/create",goal).subscribe(
    data=>{
      this.all_goals.push(data)
      //console.log(data)
    }, err=>{
      //console.log(err)
    });


  }

  updateGoal(){
    this.isLoading = true
    this.http.put(this.prod_api+"/goals/update",this.goal).subscribe(
      data=>{
        this.goal = data
        this.isLoading = false
      }, err=>{
        //console.log(err)
      });
  }
  updateGoalFast(event){
    if(event){
      event.preventDefault()
    }
    this.isLoading = true
    this.http.put(this.prod_api+"/goals/update",this.goal).subscribe(
      data=>{
        //this.goal = data
        this.isLoading = false
      }, err=>{
        //console.log(err)
      });
  }


  DeleteTask(){
    this.http.put(this.prod_api+"/goals/delete",this.goal).subscribe(data=>{
      this.isVisible = false;
      //console.log(data)
    },err=>{
      //console.log(err)
    })
  }

  reset(){
    this.showWhy = false
    this.showHow= false
    this.showUnit = false
    this.showTrack= false
    this.showDue= false
    this.showSub= false
  }

}
