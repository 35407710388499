import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-folder-item',
  templateUrl: './folder-item.component.html',
  styleUrls: ['./folder-item.component.sass']
})
export class FolderItemComponent implements OnInit {

  dev_api ="http://localhost:8080"
  prod_api ="https://server.flowspaceproducitivity.com"
  
  constructor() { }

  ngOnInit(): void {
  }

}
