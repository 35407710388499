import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { NoteEditorServiceService } from 'src/app/services/note_editor_service/note-editor-service.service';
import { WebsocketService } from 'src/app/services/websockets/web-socket-service.service';
import * as uuid from "uuid"


@Component({
  selector: 'app-note-container',
  templateUrl: './note-container.component.html',
  styleUrls: ['./note-container.component.sass']
})

export class NoteContainerComponent implements OnInit {

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  note: any;
  

  constructor(private serviceSocket:WebsocketService,private http:HttpClient,private Activatedroute:ActivatedRoute,private router:Router,private toastr:ToastrService,private service:NoteEditorServiceService,private changeDetector:ChangeDetectorRef) {}
   
  apiObject:any
  
  NotesArray:any
  sub:any
  notebookId:any
  title:any
  newNote:any
  currentId = uuid.v4()
  currentIndex:any
  index:number = 0
  searchableId = ""
  notebook
  collaboratorsTank;
  public username = localStorage.getItem('userId')
  isLoading = false
  ngOnInit(): void {

    
    this.sub = this.Activatedroute.queryParamMap
    .subscribe(params => {
    this.notebookId = params.get('id')||this.username;     
    });

    this.sub = this.Activatedroute.queryParamMap
    .subscribe(params => {
    this.title = params.get('title')||"Shared Notes";     
    });

    this.sub = this.Activatedroute.queryParamMap
    .subscribe(params => {
    this.searchableId = params.get('searchable')||'';     
    });

    this.isLoading = true

    this.http.get(this.prod_api+"/notebooks/notebook_id?SearchableID="+this.notebookId).subscribe(data=>{
      this.notebook = data
      //console.log(this.notebook)
      if(this.notebook==null){
        this.http.get(this.prod_api+"/notes/notebook?NotebookID="+this.username).subscribe(
          data=>{
           // //console.log(data);
            this.NotesArray = data;
            //console.log(this.NotesArray)    
            this.isLoading = false
            this.changeDetector.detectChanges()
          }, err=>{
            //console.log(err);
          }
        );
      }else{
        this.http.get(this.prod_api+"/notes/notebook?NotebookID="+this.notebookId).subscribe(
          data=>{
           // //console.log(data);
            this.NotesArray = data;
            //console.log(this.NotesArray)
            this.isLoading = false
            this.changeDetector.detectChanges()
          }, err=>{
            //console.log(err);
          }
        );
      }
      this.collaboratorsTank = this.notebook.collaborators
      this.isLoading = false
    })


  

    this.service.editorIndex.subscribe(data=>{
      this.index = data
      //console.log(this.index)
    })

    this.service.editorCurrentNote.subscribe(data=>{
      this.note = data
      //console.log(this.note)
      document.getElementById("container_content_editable")!.innerHTML = this.note.content
    })


  }

  createNote(){
    this.isLoading = false
    const note = {
      title:"Note Title",
      content:"",
      favourite:false,
      collaboratorsTank :[],
      labels:[],
      isPinned :false,
      notebookID:this.notebookId,
      id:uuid.v4(),
      searchableID :uuid.v4(),
      dateCreated: new Date(),
      lastModified: new Date(),
      noteOwner:localStorage.getItem('userId'),
    }

    this.newNote = this.http.post(this.prod_api+"/notes/add_note",note).subscribe(data=>{
      this.newNote = data
      //console.log(this.newNote)
      this.NotesArray.push(this.newNote)
      this.isLoading = false
    },err=>{
      //console.log(err)
      this.isLoading = false
    })

  }

  // updatedText(note:any){
   
  //   //console.log(note.index)
  //   this.index = note.index
  //   ////console.log("parent caught it")
  // }

  autosave(){
    //console.log(this.index)
    this.NotesArray[this.index].content = document.getElementById("container_content_editable")?.innerHTML
    
    this.http.put(this.prod_api+"/notes/update_note",this.NotesArray[this.index]).subscribe(
        data=>{
          this.NotesArray[this.index] = data
          //console.log( this.NotesArray[this.index])
          this.service.updateProject(this.NotesArray)
        }, err=>{
          //console.log(err)
          this.toastr.error("Note Failed To Save")
        });
  }


}
