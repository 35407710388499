import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { WebsocketService } from 'src/app/services/websockets/web-socket-service.service';

@Component({
  selector: 'app-recent-note-tile',
  templateUrl: './recent-note-tile.component.html',
  styleUrls: ['./recent-note-tile.component.sass']
})
export class RecentNoteTileComponent implements OnInit {

  constructor(private http:HttpClient,private socketService:WebsocketService) { }
  open:boolean=false
  items=[{index:0,name:'Delete'},{index:1,name:'Share'},{index:2,name:'Change Picture'}]
  @Input() note:any
  dateString:string
  isVisible:boolean = true
  
  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  
  ngOnInit(): void {
   this.dateString = new Date(this.note.dateCreated).toISOString().split('T')[0]
   //console.log(this.dateString)
  }

  deleteNote(){
    this.note = this.http.delete(this.prod_api+"/notes/delete?noteId="+this.note.id).subscribe(data=>{
      this.socketService.showNotification("Successfully Deleted Note",'😌😌😌🔥🔥🔥🔥😭😭')
      this.isVisible = false
    },err=>{
      //console.log(err)
    })
  }

}
