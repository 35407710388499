import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-header-title',
  templateUrl: './header-title.component.html',
  styleUrls: ['./header-title.component.sass']
})
export class HeaderTitleComponent implements OnInit {

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  
  constructor() { }

  @Input() title:string=""
  ngOnInit(): void {
  }

}
