import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Injector, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, map } from 'rxjs';
import * as uuid from 'uuid'
import {
  defaultEditorExtensions,
  TUI_EDITOR_EXTENSIONS,
} from '@taiga-ui/addon-editor';
import { FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-vboard-item',
  templateUrl: './vboard-item.component.html',
  styleUrls: ['./vboard-item.component.sass'],
  providers: [
    {
      provide: TUI_EDITOR_EXTENSIONS,
      useValue: defaultEditorExtensions,
    },
  ],
changeDetection: ChangeDetectionStrategy.OnPush,
})



export class VboardItemComponent implements OnInit {
  content:any =""

  @Input() item:any
  @Output() loadNewBoard:EventEmitter<any> = new EventEmitter()
  
  control = `<h2 style="color:white">Title Here</h2><p style="color:white">this is where you tell your story</p>`

  readonly coordinates$ = new BehaviorSubject([0, 0]);
  readonly transform$ = this.coordinates$.pipe(
      map(coords =>
          this.sanitizer.bypassSecurityTrustStyle(
              `translate(${coords[0]}px, ${coords[1]}px)`,
          ),
      ),
  );
  

  className="updateBoard"
  imgSize="normalImg"
  color:boolean = false
  isVisible: boolean = true;
  isSaved: boolean = true;
  textVisible: boolean = true;
  showVideo: boolean = false;
  showImage: boolean = true;
  videoSrc:string = "https://www.youtube.com/embed/yUJWpHmL2qg"
  imgSrc:string = "https://images.unsplash.com/photo-1542293787938-c9e299b880cc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80"
  colorId = uuid.v4()
  picId = uuid.v4()
  link: string=""
  colorString:string = "#ffffff"
  isBoard:boolean = false
  isloading:boolean =false
  isDisabled=localStorage.getItem("isDisabled")
  editor_class = "editor"

  hideToolbar = localStorage.getItem("hideToolbar")
  

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"


  constructor(@Inject(DomSanitizer) private readonly sanitizer: DomSanitizer,private http:HttpClient,private readonly changeDetector: ChangeDetectorRef) { }

  onPan(delta: readonly [number, number]): void {
    this.coordinates$.next([
        this.currentCoords[0] + delta[0],
        this.currentCoords[1] + delta[1],
    ]);
    this.content ="x:"+this.currentCoords[0]
    this.content +="y:"+this.currentCoords[1]

    this.item.x = this.currentCoords[0]
    this.item.y = this.currentCoords[1]
    this.updateItem()
  }

  get currentCoords(): number[] {
    return this.coordinates$.value;
  }


  ngOnInit(): void {
   this.onPan([this.item.x,this.item.y])
   this.imgSize = this.item.imgSize
   this.imgSrc = this.item.imgSrc
   this.videoSrc = this.item.videoSrc
   this.isVisible = this.item.isVisible
   this.isSaved = this.item.isSaved
   this.textVisible = this.item.textVisible
   this.showVideo = this.item.showVideo
   this.showImage = this.item.showImage
   this.color = this.item.color
   this.className = this.item.className
   this.colorString = this.item.colorString
   this.control =this.item.textContent
   if(this.item.isBoard == null){
    this.item.isBoard = false
    this.updateItem
   }
   this.isBoard = this.item.isBoard
 
  }

  resize(){
    if(this.className!='large'){
      this.className="large"
      this.imgSize="largeImg"
      this.editor_class = "editor_wide"
      this.showImage = true
      this.isloading = true
      this.item.className = this.className
      this.item.imgSize = this.imgSize
      this.item.showImage = this.showImage

      this.updateItem()
    }else{
      this.className="updateBoard"
      this.imgSize="normalImg"
      this.editor_class = "editor"
      this.showImage = true
      this.isloading = true
      this.item.className = this.className
      this.item.imgSize = this.imgSize
      this.item.showImage = this.showImage

      this.updateItem()
    }
 
  }


  resize_Tiny(){
      this.className="color_"
      this.item.className = this.className
      this.isloading = true
      this.updateItem()
  }


  hideImg(){
    this.showImage = !this.showImage
    this.item.showImage = this.showImage
    this.isloading = true
    this.updateItem()
  }

  deleteItem(){
    this.isloading = true
    this.http.delete(this.prod_api+"/vitems/delete",{body:this.item}).subscribe(data=>{
      this.isVisible=false
      //console.log(data)
      this.isloading = false
      this.changeDetector.detectChanges()
    },err=>{
      //console.log(err)
    })
  }

  toggleVideo(){
    this.showVideo = !this.showVideo
    this.showImage = !this.showVideo
    this.isloading = true
    this.item.showVideo = this.showVideo
    this.item.showImage = this.showImage
    this.updateItem()
  }

  toggleText(){
    this.textVisible=!this.textVisible
    this.item.textVisible = this.textVisible
    this.isloading = true
    this.updateItem()
  }

  
  toggleEditor(){
    this.isSaved=!this.isSaved
    this.item.isSaved = this.isSaved
    this.isloading = true
    this.item.textContent = this.control
    this.updateItem()
  }
  
  toggleColor(){
    this.color=!this.color
    this.showImage = !this.color
    if(this.showVideo){
      this.showVideo = false
    }
    
    this.isloading = true
    this.item.showVideo = this.showVideo
    this.item.showImage = this.showImage
    this.item.color = this.color
    this.updateItem()
  }

  updateColor(){
    this.item.colorString = this.colorString
    this.isloading = true
    this.updateItem()
  }

  checkYTVid(){
    //console.log("the change function ran")
    if(this.videoSrc.includes("youtu.be")){
      this.videoSrc = this.videoSrc.replace("youtu.be","www.youtube.com/embed")
      this.item.videoSrc = this.videoSrc
      this.isloading = true
      this.updateItem()
      //console.log(this.videoSrc)
    }else if(this.videoSrc.includes("https://www.youtube.com/watch?v=")){
      this.videoSrc = this.videoSrc.replace("www.youtube.com/watch?v=","www.youtube.com/embed/")
      this.isloading = true
      this.item.videoSrc = this.videoSrc
      this.updateItem()

      //console.log(this.videoSrc)
    }

  }

  uploadImage(event:any){
    this.isloading = true
    var files = event.target.files
    const reader = new FileReader();
    reader.readAsDataURL(files.item(0));
    reader.onload = () => {
      const formData = new FormData();
      formData.append("image", files.item(0));
      formData.append("token", "Yauj2ne4aVEjPjcjXf3vFhw93APcr7hm");
 
      this.http.get("https://api.gofile.io/getServer").subscribe((data:any)=>{
        var url = "https://"+data.data.server+".gofile.io/uploadFile"
        this.http.post(url,formData,{
          reportProgress: true,
        }).subscribe((data:any)=>{
          this.http.get("https://api.gofile.io/getContent?contentId="+data.data.parentFolder+"&token=Yauj2ne4aVEjPjcjXf3vFhw93APcr7hm").subscribe((dataFileLink:any)=>{
            var parent = data.data.fileId;
            console.log(dataFileLink.data.contents[parent]);

            const options = {
            'contentId': dataFileLink.data.contents[parent].id,
            'token': 'Yauj2ne4aVEjPjcjXf3vFhw93APcr7hm',
            'option': 'directLink',
            'value': 'true',
            };

            this.http.put("https://api.gofile.io/setOption", options).subscribe((data: any) => {
            console.log(data);
            if (data.status === 'ok') {
            console.log(data.data)
            this.imgSrc = data.data
            this.item.imgSrc = this.imgSrc
            this.updateItem()

            this.changeDetector.detectChanges();
            }
            })
         
        })
      })
    })
    };
 
  }

  uploadLink(){
        this.isloading = true
        var base64 = this.imgSrc
        //console.log(base64);

        const formData = new FormData();
        formData.append("image", base64.toString());
        this.http.post("https://api.imgbb.com/1/upload?key=4f3f43436463b329066caa968cf275fa",formData).subscribe((data:any)=>{
          //console.log(data.data.url)
          this.imgSrc = data.data.url
          this.changeDetector.detectChanges()
          this.item.imgSrc = this.imgSrc
          this.updateItem()
        },err=>{
          this.isloading = false
          //console.log(err)
          this.changeDetector.detectChanges()
        })
    
 
  }

  link_(event:any){
    event.preventDefault()
    this.isBoard = true
    this.item.isBoard = this.isBoard
    this.isloading = true
    this.updateItem()
  }

  loadNewBoard_(){
    this.loadNewBoard.emit(this.item.id)
  }

  updateItem(){
    this.http.put(this.prod_api+"/vitems/update",this.item).subscribe(data=>{
      this.item = data
      this.isloading = false
      this.changeDetector.detectChanges()
    },err=>{
      //console.log(err)
      this.isloading = false
      this.changeDetector.detectChanges()
    })
  }

}
