import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WebsocketService } from 'src/app/services/websockets/web-socket-service.service';
import * as uuid from "uuid"
@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.sass']
})
export class MessageComponent implements OnInit {


  regExUrl = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g
  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"

  constructor(private http:HttpClient,private service:WebsocketService) { }
  @Input() m:any
  @Input() chat:any
  @Output() reply:EventEmitter<any> = new EventEmitter()
  open_menu=false
  hasReply=false
  username = localStorage.getItem('userId')
  isHiddenOptions:boolean = false

  ngOnInit(): void {
    //console.log(this.m)
    if(this.m.replyMess!=null){
      this.hasReply = true
    }

    setTimeout(e=>{
      let el = document.getElementById(this.m.id);
      el.scrollIntoView({ block: 'center', behavior: 'smooth' });
    },600)

  }

  toggleOptions(value:boolean){
    this.isHiddenOptions = value
  }

  isNative(username){
    if(username.from != this.username){
      return ""
    }
    return "from"
  }


  isRecieved(username){
    if(username.from != this.username){
      return "sent"
    }
    return "recieved"
  }

  isSentName(username){
    if(username.from != this.username){
      return "user"
    }
    return "user_sent"
  }


  isSentTime(username){
    if(username.from != this.username){
      return "time" 
    }
    return "time_sent"
  }



  isName(username){
    if(username.from != this.username){
      return username.from
    }
    return username.to
  }


  public checkLinks(id:string,message:any){
    const urls = document.getElementById(id)?.innerText.match(this.regExUrl)
    var collection = document.getElementsByClassName('anchor')
    Array.from(collection).forEach(elem=>{
      var newdoc = document.getElementById(id)?.innerHTML.replace(elem.outerHTML,elem.innerHTML)
      document.getElementById(id).innerHTML= newdoc
    })

    if(urls!=null){
      urls.forEach((elem:any)=>{
           
              var newdoc = document.getElementById(id).innerHTML.replace(elem.replace('&','&amp;'),"<a class='anchor' contenteditable='false' target='_blank' href='"+elem+"'>"+elem+"</a>")
              document.getElementById(id).innerHTML = newdoc
              //console.log(document.getElementById(id).innerHTML)
      })
    }
  }

  updateReactions(reaction:string){
    if(this.m.reactions ==null){
      this.m.reactions = []
    }
    //console.log(this.m)

    this.m.reactions.push(reaction);
    this.http.put(this.prod_api+"/chatterbox/update",this.m).subscribe(data=>{
      this.m = data
      //this.service.sendMessage(this.m,this.chat)
    },err=>{
      //console.log(err)
    })

  }

  replyEvent(){
    this.reply.emit(this.m)
    this.open_menu = false
  }

  createTaskFromMessage(){
    this.m.contented
  }
    
  scroll(){
    let el = document.getElementById(this.m.replyID);
    el.style.backgroundColor = "#444"

    setTimeout(()=>{
      el.style.backgroundColor = "transparent"
    },1000)
    el.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }


  AddNewTask(){
    const Task = {
      taskID:uuid.v4(),
      searchableID:uuid.v4(),
      title:this.m.content,
      description: this.m.content,
      taskType:"Reminder",
      priorityType:"IMPORTANT_NOT_URGENT",
      layout_x:0.000,
      layout_y:0.000,
      projectID:this.username,
      owner:this.username,
      dueDate:new Date(),
      startDate:new Date(),
      timeSpent:0,
      isHabit:true,
      complexityType:"MEDIUM",
      startCondition:"START AT 17:00",
      stopping_condition:"STOP_ON_COMPLETE",
      subTasks:[],
      custom_status:null,
      comments:[],
      labels:[],
      collaborators:[],
      currentStatus:"TODO"
    }
   
    this.http.post(this.prod_api+"/tasks/create",Task).subscribe(data=>{
      
      this.service.showNotification("Created New Task","created Task From Message "+this.m.content)
     
    },err=>{
      //console.log(err)
    })

  
  }

}
