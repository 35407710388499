
    <tui-hosted-dropdown
        
        [sided]="false"
        [content]="timer_controls"
        [(open)]="open"
        id="status" 
        >
        <div id="container" >
            <img src="../../../assets/time.png" alt="dp" width="15px" height="15px" id="img">
            <div  id="{{currentId}}" class="{{text}}">{{timeString}}</div>
            <tui-badge *ngIf="play"
            status="success"
            value=""
            class="tui-space_right-2"
        ></tui-badge>
        
        </div>

</tui-hosted-dropdown>


<ng-template
#timer_controls
>
<div id="time">
    <div id="time-value" contenteditable="true">{{timeString}}</div>
    <div id="buttons">
        <button id="control" (click)="startTimer()">CountUp</button>
        <button id="control" (click)="reverseDirection()">Countdown</button> | 
        <button id="control" (click)="pauseTimer()">Stop</button>
        <button id="control" (click)="SetCountdown()">Set</button>
    </div>
    <input type="number" id="comment-input"  [(ngModel)]="time_value" placeholder="Enter Time In Seconds"/>
</div>
</ng-template>
   

