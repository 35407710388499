import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, HostListener, Inject, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { ModalService } from 'src/app/Modals/_modal/modal.service';
import { FileServiceService } from 'src/app/services/files/file-service.service';
import { buttonArray } from './toolbar_buttons';
import { project } from 'src/app/Dashboard/ProjectDashboard/dash-contianer/project';

import * as uuid from "uuid"
import { ActivatedRoute } from '@angular/router';
import { TuiDialogService,TuiDialogContext } from '@taiga-ui/core';
import {PolymorpheusContent} from '@tinkoff/ng-polymorpheus';
import { create } from 'domain';
import { WebsocketService } from 'src/app/services/websockets/web-socket-service.service';


@Component({
  selector: 'app-toolbar-table-view',
  templateUrl: './toolbar-table-view.component.html',
  styleUrls: ['./toolbar-table-view.component.sass']
})
export class ToolbarTableViewComponent implements OnInit {

  buttonArray = buttonArray
  newTemplate:any
  title:string=""
  description:string=""
  project:any
  apiObject:any
  subProjects:any
  numsubs:number=0
  blockArray:any
  username:any = localStorage.getItem("userId")
  currentId = uuid.v4()
  sub:any
  projectID:string = ""
  collabs:number = 0
  notifications:any


  constructor(private messageService:WebsocketService, private http:HttpClient, private toastr:ToastrService,private uploadService: FileServiceService,public modalService:ModalService,private Activatedroute:ActivatedRoute,@Inject(TuiDialogService) private readonly dialogService: TuiDialogService,private readonly changeDetector: ChangeDetectorRef) {   
   
  }



  ngOnInit(): void {
    
    this.sub = this.Activatedroute.queryParamMap
    .subscribe(params => {
    this.projectID = params.get('id')||"no such id in the database";     
    //console.log(this.projectID)
 
    });

    this.project = this.http.get(this.prod_api+"/projects/personal/id?ProjectID="+this.projectID).subscribe(data=>{
      this.project = data
      //console.log(this.project)
      //console.log(this.project.title)
      this.title = this.project.title
      this.description = this.project.description
      this.collabs = this.project.collaborators.length
      this.changeDetector.detectChanges();
      
    })
        
    this.apiObject = this.http.get(this.prod_api+"/projects/subprojects?MainProjectToken="+this.projectID).subscribe(
      data=>{
        this.blockArray = data
        this.changeDetector.detectChanges();
        
      }, err=>{
        //console.log(err)
      }); 

      //console.log(this.projectID)
      this.notifications = this.http.get(this.prod_api+"/chatterbox/getMessages?roomId="+this.projectID).subscribe((data)=>{
        this.notifications = data
        this.changeDetector.detectChanges();
        this.notifications.reverse()
        //console.log(this.notifications)
      },err=>{
        //console.log(err)
      })
  }

  deleteProject(){
    this.http.delete(this.prod_api+"/projects/delete?ProjectID="+this.projectID).subscribe((data)=>{
      this.project = data
      this.changeDetector.detectChanges();
    },err=>{
      //console.log(err)
    })
  }

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"

  selectedFiles: any;
  currentFileUpload: any;
  progress:number = 0;
  selectedFile = null;
  changeImage = false;
  filename="no file selected"
  isUploading:boolean = true
  readonly items = ['Share Project As Template','Complete/Archive Project']
  open:boolean = false


  createTemplate(){
    //console.log("update properly")
    this.newTemplate = this.http.get(this.prod_api+"/templates/create?id="+this.project.id+"&userId="+this.username).subscribe(data=>{
      this.newTemplate = data
      this.changeDetector.detectChanges();
      //console.log(this.newTemplate)
      this.toastr.success("Successfully Created Template")
    },err=>{
      //console.log(err)
      this.toastr.error("Failed To Created Template")
    })
  }



  warnUser(){
    this.showDialog("please right click this button")
  }

  MenuItem(name:string){
    if(name=="Share Project As Template"){
      this.createTemplate()
    }

    if(name=="Complete/Archive Project"){
      this.project.complete = true
      this.project.archived = true
      this.updateProject()
    }
  }

  showDialog(str): void {
    this.dialogService
        .open(str, {label: `Warning`, size: `s`})
        .subscribe();
  }

  updateProject(){
    this.http.put(this.prod_api+"/projects/update",this.project).subscribe(data=>{
      //console.log("this project was updated")
    })
  }
  
  downloadFile(model:any){
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', this.prod_api+'/file/download/'+model.fileID);
    link.setAttribute('download', model.name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  deleteFile(model:any){
    this.project.fileArray.forEach((element:any) => {
      if(model == element){
        this.project.fileArray[this.project.fileArray.indexOf(element)].isDeleted = true
        this.http.put(this.prod_api+"/projects/update",this.project).subscribe(data=>{
          //console.log(data)
        })
      }
    });
  }
  change($event: any) {
    this.changeImage = true;
  }
  changedImage(event: { target: { files: null[]; }; }) {
    this.selectedFile = event.target.files[0];
  }

  upload() {
    this.changeDetector.detectChanges()
    this.isUploading = !this.isUploading
    this.progress = 0;
    this.currentFileUpload = this.selectedFiles.item(0);
    this.uploadService.pushFileToStorage(this.currentFileUpload,this.project.id).subscribe((event: any) => {
      if (event.type === HttpEventType.UploadProgress) {
        this.progress = Math.round(100 * event.loaded / event.total);
        this.changeDetector.detectChanges()
      } else if (event instanceof HttpResponse) {
        this.toastr.success('File Successfully Uploaded');
        const m = {
          roomId:this.project.id,
          to:null,
          attachments:'',
          content: this.project.owner+", uploaded  a File on"+this.project.title,
          time: new Date().toTimeString().split(/\s+/)[0],
          from: this.username,
          date: new Date().toDateString(),
          type: "notification",
          id: uuid.v4()
        }
        this.project.collaborators.push(this.username)
        //console.log(this.project)
    
        this.messageService.sendMessage(m,{roomId:this.project.id,participants:this.project.collaborators,lastMessage:""});
        this.isUploading = !this.isUploading
        this.changeDetector.detectChanges()
      }
      this.selectedFiles = undefined;
     }
    );
  }
  
  selectFile(event: any) {
    this.selectedFiles = event.target.files;
  }

  pushFileToStorage(file: File) {
    const data: FormData = new FormData();
    data.append('file', file);
    this.http.put(this.prod_api+'/projects/upload?projectID='+this.project.projectID, data);
}

showDialogCollab(content: PolymorpheusContent<TuiDialogContext>): void {
  this.dialogService.open(content,{size:'auto'}).subscribe();
}



rounding(num:Number){
  return num.toFixed(2)
}




}
