<div id="container">
    <tui-loader
[showLoader]="isLoading"
[overlay]="true"
>
    <div id="title">Login</div>
    <div id="login_only" *ngIf="isOld">
        <div id="cont"><input placeholder="username" type="text" id="text_" [(ngModel)]="title"></div>  
        <div id="spacer"></div>
        <div id="cont"><input placeholder="password" [type]="show ? 'text' : 'password'" id="text" [(ngModel)]="password"> <button id="flow-button-hide" (click)="password_()"><img src="../../../assets/{{hide_icon}}.png" width="30px" height="30px"/></button></div>  
        <button id="flow-button" (click)="getUserMannual()">Login </button>
        <br/>
        <h4>Or</h4>
        <br/>
    </div>
   
    <firebase-ui (signInSuccessWithAuthResult)="successLoginCallback($event)"
    (signInFailure)="errorLoginCallback($event)"></firebase-ui>

    <div (click)="toggleOld()" id="explain">Login with your custom details</div>
    </tui-loader>
</div> 
