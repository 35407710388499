import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private loginFlag = new BehaviorSubject<boolean>(true);
  flag = this.loginFlag.asObservable();
  constructor() { }

  updateFlag(message: boolean) {
    this.loginFlag.next(message)
    }
}
