import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-day-label',
  templateUrl: './day-label.component.html',
  styleUrls: ['./day-label.component.sass']
})
export class DayLabelComponent implements OnInit {

  constructor() { }

  @Input() title;
  @Input() value;
  date = new Date()
  style:string="reg"

  ngOnInit(): void {

    if(this.date.getDay() == this.value){
      this.style = "today"
    }
    if(this.value == 165432){
      this.style = "timer"
    }
  }

}
