import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-project-template-card',
  templateUrl: './project-template-card.component.html',
  styleUrls: ['./project-template-card.component.sass']
})
export class ProjectTemplateCardComponent implements OnInit {

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"

  
  constructor(private http:HttpClient, private toastr:ToastrService) { }
  @Input() template:any
  @Input() project:any
  @Input() isArchive:boolean 
  userId:any = localStorage.getItem("userId")
  downloaded:any
  ngOnInit(): void {
  }

  download(){
    //console.log("Updated the songs in the right way")
    this.downloaded =this.http.get(this.prod_api+"/templates/download?id="+this.userId+"&template="+this.template.templateId).subscribe(data=>{
      this.downloaded = data
      //console.log(this.downloaded)
      this.toastr.success("Successfully downloaded template")
    },err=>{
      //console.log(err)
      this.toastr.error("Failed to  downloaded template")
    })
  }

  unarchive(){
    this.project.archived = false
    this.project.complete = false
    this.updateProject()
  }

  
updateProject(){
  this.project = this.http.put(this.prod_api+"/projects/update",this.project).subscribe(
      data=>{
        this.project = data
        //console.log(this.project.title)
      }, err=>{
        //console.log(err)
      });
}

}
