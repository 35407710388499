<!-- <div id="container" class="{{styleType}}"  *tuiSidebar="open_sidebar; direction: 'right'" (click)="showDialog(template)" class="{{isToday()}}"> -->
<div 
    id="container" 
    class="{{styleType}}"  
    (tuiActiveZoneChange)="onActiveZone($event)"  
    class="{{isToday()}}">

    <div *ngIf="block.isShown">
        {{block.day}}  
        <div  class="scroll">
            <div *ngFor="let task of block.taskArray">
                <app-task-mark [task]="task"></app-task-mark>
            </div>
        </div>
    </div>
</div>


<div *tuiSidebar="open_sidebar; direction: 'right';autoWidth:true;">
    <div class="widther">
        <h1>{{block.blockDate.getDate()}} {{months[block.blockDate.getMonth()]}} {{block.blockDate.getFullYear()}}</h1>
        <button id="flow-button" (click)="AddNewTask()">Add Task</button>

        <br/>
        <br/>
        <div id="tasks" *ngFor="let task_ of block.taskArray">
            <div *ngIf="task_.additionalString==null ||task_.additionalString==''">
                <app-table-item [isOnCalendar]="true" [blockTaskArray]="block.taskArray" [taskArray]="block.taskArrayComplete" [task]="task_" [title]="task_.title"  [numcollabs]="task_.collaborators.length" [subTaskArray]="task_.subTasks" [parent_project]="defaultProject" [messageService]="messageService"></app-table-item>
            </div>
        </div>
    </div>
  
</div>


   
