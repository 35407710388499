import { Component, OnInit,ChangeDetectionStrategy, ChangeDetectorRef, HostListener, ElementRef, ViewChild, Inject } from '@angular/core';
import { changeDateSeparator, tuiClamp, TuiZoom } from '@taiga-ui/cdk';
import { Subject, scan, startWith, map, BehaviorSubject } from 'rxjs';
import * as uuid from "uuid"
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContainerComponent implements OnInit {

  xcord:number
  ycord:number

  boardLink:string = ""
  linkMode:boolean = false
  isloading:boolean = false


  constructor(@Inject(DomSanitizer) private readonly sanitizer: DomSanitizer,private http:HttpClient,private readonly changeDetector: ChangeDetectorRef) { }
  items:any[]=[]
  username = localStorage.getItem('userId')

  @ViewChild('zoomContainer') zoomContainerRef: ElementRef;

  private zoomLevel = 1.1;
  private panX = 0;
  private panY = 0;


  onZoom(event: WheelEvent) {
    if (event.ctrlKey) {
      event.preventDefault();

      const zoomContainer = this.zoomContainerRef.nativeElement;
      const rect = zoomContainer.getBoundingClientRect();
      const containerX = rect.left;
      const containerY = rect.top;
      const containerW = rect.width;
      const containerH = rect.height;
      const mouseX = event.clientX - containerX;
      const mouseY = event.clientY - containerY;
      const cx = mouseX / containerW;
      const cy = mouseY / containerH;
  

      const delta = event.deltaY;
      const direction = delta > 0 ? -1 : 1;
      const newZoomLevel = this.zoomLevel + direction * 0.05;

      // Clamp the zoom level to the range [0.3, 3]
      this.zoomLevel = Math.min(Math.max(newZoomLevel, 0.5), 3);

      zoomContainer.style.transformOrigin = `${cx * 100}% ${cy * 100}%`;
      zoomContainer.style.transform = `scale(${this.zoomLevel})`;

    } else {
      // this.panX += event.deltaX;
      // this.panY += event.deltaY;

      // const zoomContainer = this.zoomContainerRef.nativeElement;
      // zoomContainer.style.transform = `scale(${this.zoomLevel}) translate(${this.panX}px, ${this.panY}px)`;
    }
  }

  ngOnInit(): void {
    this.isloading = true
    this.http.get(this.prod_api+"/vitems/personal?id="+this.username).subscribe((data:any[])=>{
      //console.log(data)
      if(data.length == 0){
        this.isloading = false
        this.changeDetector.detectChanges()
        //console.log("closed")
      }else{
        data.forEach(element => {
          this.items.push(element)
          this.changeDetector.detectChanges()
          this.scroll(element.id)
          
        });
      }
      

      // Create a new interact.js instance for the element
      this.isloading = false

    })
  
  }

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"

  placeNew(){
    this.isloading = true
    var currentId = uuid.v4()
    let el_scr = document.getElementById("container_vision");
    this.xcord = el_scr.scrollLeft+600
    this.ycord = el_scr.scrollTop+300

    if(this.linkMode){
      var item = {
        "textContent":"<h2 style='color:white'>Title Here</h2><p style='color:white'>this is where you tell your story</p>",
        "videoSrc":"https://www.youtube.com/embed/yUJWpHmL2qg",
        "imgSrc":"https://images.unsplash.com/photo-1542293787938-c9e299b880cc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80",
        "isVisible": true,
        "isSaved": true,
        "textVisible": true,
        "showVideo": false,
        "showImage": true,
        "x":this.xcord,
        "y":this.ycord,
        "className":"updateBoard",
        "imgSize":"normalImg",
        "color": false,
        "colorString": '#fff',
        "link":this.boardLink,
        "isBoard":false,
        "id":uuid.v4(),
        "owner":this.username
      }

      this.http.post(this.prod_api+"/vitems/create",item).subscribe(data=>{
        this.items.push(item)
        this.scroll(item.id)
        this.isloading = false
      },err=>{
        //console.log(err)
      })
    }else{
      var item_ = {
        "textContent":"<h2 style='color:white'>Title Here</h2><p style='color:white'>this is where you tell your story</p>",
        "videoSrc":"https://www.youtube.com/embed/yUJWpHmL2qg",
        "imgSrc":"https://images.unsplash.com/photo-1542293787938-c9e299b880cc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80",
        "isVisible": true,
        "isSaved": true,
        "textVisible": true,
        "showVideo": false,
        "showImage": true,
        "x":this.xcord,
        "y":this.ycord,
        "className":"updateBoard",
        "imgSize":"normalImg",
        "color": false,
        "colorString": '#fff',
        "link":null,
        "isBoard":false,
        "id":uuid.v4(),
        "owner":this.username
      }

      this.http.post(this.prod_api+"/vitems/create",item_).subscribe(data=>{
        this.items.push(item_)
        this.changeDetector.detectChanges()
        this.scroll(item_.id)
        this.isloading = false
      },err=>{
        //console.log(err)
      })
    }
  }
  

  scroll(el_str:string) {
    //console.log(`scrolling to ${el_str}`);
    this.changeDetector.detectChanges()
    
    setTimeout(()=>{
      let el = document.getElementById(el_str);
      el.scrollIntoView({ block: 'center',inline:'center', behavior: 'smooth' });
      this.isloading = false
    },200)
  }

  updateBoard(date){
    this.items =[]
    this.boardLink = date
    this.linkMode = true
    this.isloading = true
    this.http.get(this.prod_api+"/vitems/personal?id="+this.username).subscribe((data:any[])=>{
      data.forEach(element => {
        this.items.push(element)
        this.scroll(element.id)
        this.isloading = false
      });
    })


  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
    if(event.keyCode == 17){
      this.changeDetector.detectChanges()
    }
  }
  @HostListener('document:keyup', ['$event'])
  handleKeyboardUpEvent(event: KeyboardEvent) { 
    if(event.keyCode == 17){
      this.changeDetector.detectChanges()
    }
  }




}
