<div id="toolbar">
    <div  id="second">
    <app-view-switcher></app-view-switcher>
    </div>

    <div id="inner-toolbar">
        <div id="buttons" *ngFor="let button of buttonArray">
            <div id="button_container" routerLink="/{{button.router}}">
                <div id="title_tooblbar_button"><img src="{{button.image}}"/></div>
                <div id="back_toobar_button">{{button.userid}}</div>
            </div>
         </div>
    </div>
</div>

<div id="days">
    <div *ngFor="let block of DayBlocks">
        <app-daily-day-label [blocks]="DayBlocks"  *ngIf="block.day!=0" [day]="block.day" [month]="block.blockDate.getMonth()" (selectDay)="selectDay($event)"></app-daily-day-label>
    </div>
</div>
<tui-loader
[showLoader]="isLoading"
[overlay]="true"
>
<div id="tasks">
    <button id="flow-button" (click)="AddNewTask()">Add Task</button>
    <div *ngFor="let task_ of combined">
        <ng-container>
            <div *ngIf="task_.additionalString==null ||task_.additionalString==''">
                <app-table-item *ngIf="compareDates(this.selectedDay.blockDate,task_.dueDate)" [blockTaskArray]="taskArray" [taskArray]="taskArray" [task]="task_" [title]="task_.title"  [numcollabs]="task_.collaborators.length" [subTaskArray]="task_.subTasks" [parent_project]="defaultProject" [messageService]="messageService"></app-table-item>
            </div>
        </ng-container>
    </div>
</div>
</tui-loader>
