
<div id="table_item_container"> 
  <tui-loader
    class="inline-flex tui-space_right-2"
    [showLoader]="isloading"
    [inheritColor]="true"
    [overlay]="true"
>

<div *ngFor="let task of taskArray; let index = index"
      [draggable]="true" 
      [class.dragging]="index === this.draggingIndex"
      (dragstart)="onDragStart(index)" 
      (dragenter)="onDragEnter(index)" 
      (dragend)="onDragEnd()"
      class="drag-wrapper">
  <div *ngIf="(hiddenMode==false)">
    <div *ngIf="task.additionalString==null || task.additionalString==''">
      <app-table-item [index]="index" [blockTaskArray]="[]" [taskArray]="taskArray" [task]="task" [title]="task.title"  [numcollabs]="task.collaborators.length" [subTaskArray]="task.subTasks" [parent_project]="parentProject" [messageService]="messageService"></app-table-item>
    </div>
  </div>
  <div *ngIf="(hiddenMode==true)">
    
      <div *ngIf="task.additionalString==null || task.additionalString==''">
        <div *ngIf="task.currentStatus!='COMPLETE'">
          <app-table-item  [index]="index" [blockTaskArray]="[]" [taskArray]="taskArray" [task]="task" [title]="task.title"  [numcollabs]="task.collaborators.length" [subTaskArray]="task.subTasks" [parent_project]="parentProject" [messageService]="messageService"></app-table-item>
        </div>
      </div>
 
</div>
</div>

    
  </tui-loader>
    <div style="display: flex;" id="toobar">
        <button id="flow-button" (click)="AddNewTask()">Add Task</button>
        <div id="flow-hide" (click)="hideComplete()"><img src="../../../assets/{{hide_icon}}.png" width="24px" height="24px"/></div>
        <div id="flow-hide" (click)="completeAll()"><img src="../../../assets/completeAll.png" width="24px" height="24px" /></div>
        <div id="flow-hide" (click)="showDialogSm(template,'Assign Collaborator')"><img src="../../../assets/colla.png" width="24px" height="24px" /></div>
        <div id="flow-hide" (click)="showDialogSm(datepicker,'Bulk Date')"><img src="../../../assets/sml_calendar.png" width="14px" height="14px"  class="date" /></div>
    </div>
   
</div>



<ng-template  #template let-observer>
    <div id="comment-iten-container">
      <div>
        {{collaborator_}}
      </div>
      <button id="flow-button" (click)="removeCollab(collaborator_)">Remove</button>
    </div>
    <div id="inputbox" >
      <form [formGroup]="testForm">
        <tui-select formControlName="testValue">
          <tui-data-list-wrapper
            *tuiDataList
            [items]="items"
          ></tui-data-list-wrapper>
        </tui-select>
      </form>
      <div id="flexer">
        <button id="flow-button" (click)="selectUser()">Assign To Collaborator</button>
      </div>
    </div>

</ng-template>


<ng-template  #datepicker let-observer>
  <div id="calendar_wrapper">
    <h3>Please Select A start and End Date</h3>
    <tui-calendar-range (valueChange)="updateDate($event)" [value]="value"></tui-calendar-range>
  </div>


</ng-template>
