<div id="container">
<div id="break"> Users  </div>
<div id="test" (click)="createUser()"> create user</div>
<div id="dump">
    {{create_user | async | json}}
</div>


<div id="test" (click)="getAllUsers()"> get All user</div>
<div id="dump">
    {{get_users | async | json}}
</div>


<div id="test" (click)="getOneUser()"> get One user</div>
<div id="dump">
    {{get_user | async | json}}
</div>


<div id="test" (click)="UpdateUser()"> Update user</div>
<div id="dump">
    {{update_user | async | json}}
</div>

<div id="test" (click)="DeleteUser()"> Delete user</div>
<div id="dump">
    {{delete_user | async | json}}
</div>



<div id="break"> Tasks  </div>


<div id="test" (click)="createTask()"> create task</div>
<div id="dump">
    {{create_task | async | json}}
</div>


<div id="test" (click)="getAllTasks()"> get All task</div>
<div id="dump">
    {{get_tasks | async | json}}
</div>


<div id="test" (click)="getOneTask()"> get One task</div>
<div id="dump">
    {{get_task | async | json}}
</div>


<div id="test" (click)="UpdateTask()"> Update task</div>
<div id="dump">
    {{update_task | async | json}}
</div>

<div id="test" (click)="DeleteTask()"> Delete task</div>
<div id="dump">
    {{delete_task | async | json}}
</div>

<div id="break"> Projects  </div>


<div id="test" (click)="CreateProject()"> create project</div>
<div id="dump">
    {{create_project| async | json}}
</div>


<div id="test" (click)="getYourProjects()"> get your projects</div>
<div id="dump">
    {{get_projects | async | json}}
</div>


<div id="test" (click)="getProject()"> get One project</div>
<div id="dump">
    {{get_project | async | json}}
</div>


<div id="test" (click)="UpdateProject()"> Update project</div>
<div id="dump">
    {{update_project | async | json}}
</div>

<div id="test" (click)="DeleteProject()"> delete project</div>
<div id="dump">
    {{delete_project | async | json}}
</div>

</div>

