import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-view-toolbar-button',
  templateUrl: './table-view-toolbar-button.component.html',
  styleUrls: ['./table-view-toolbar-button.component.sass']
})
export class TableViewToolbarButtonComponent implements OnInit {

  name:string =""
  icon_url:string = ""

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  
  constructor() { }


  ngOnInit(): void {
  }

}
