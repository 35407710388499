import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';

import { buttonArray } from './toolbar_buttons';
import * as uuid from "uuid"
import { project } from 'src/app/Dashboard/ProjectDashboard/dash-contianer/project';

@Component({
  selector: 'app-time-view-container',
  templateUrl: './time-view-container.component.html',
  styleUrls: ['./time-view-container.component.sass']
})
export class TimeViewContainerComponent implements OnInit {
  slots = [
    {"slot":"00:00","tasks":[],"value":"00:00","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"00:00","day":1,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"00:00","day":2,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"00:00","day":3,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"00:00","day":4,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"00:00","day":5,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"00:00","day":6,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"00:00","day":0,"scroll":"o_1","slotDate":null},
    {"slot":"00:30","tasks":[],"value":"00:30","day":152245,"scroll":"o_2","slotDate":null},{"slot":"","tasks":[],"value":"00:30","day":1,"scroll":"o_2","slotDate":null},{"slot":"","tasks":[],"value":"00:30","day":2,"scroll":"o_2","slotDate":null},{"slot":"","tasks":[],"value":"00:30","day":3,"scroll":"o_2","slotDate":null},{"slot":"","tasks":[],"value":"00:30","day":4,"scroll":"o_2","slotDate":null},{"slot":"","tasks":[],"value":"00:30","day":5,"scroll":"o_2","slotDate":null},{"slot":"","tasks":[],"value":"00:30","day":6,"scroll":"o_2","slotDate":null},{"slot":"","tasks":[],"value":"00:30","day":0,"scroll":"o_2","slotDate":null},
    {"slot":"01:00","tasks":[],"value":"01:00","day":152245,"scroll":"o_3","slotDate":null},{"slot":"","tasks":[],"value":"01:00","day":1,"scroll":"o_3","slotDate":null},{"slot":"","tasks":[],"value":"01:00","day":2,"scroll":"o_3","slotDate":null},{"slot":"","tasks":[],"value":"01:00","day":3,"scroll":"o_3","slotDate":null},{"slot":"","tasks":[],"value":"01:00","day":4,"scroll":"o_3","slotDate":null},{"slot":"","tasks":[],"value":"01:00","day":5,"scroll":"o_3","slotDate":null},{"slot":"","tasks":[],"value":"01:00","day":6,"scroll":"o_3","slotDate":null},{"slot":"","tasks":[],"value":"01:00","day":0,"scroll":"o_3","slotDate":null},
    {"slot":"01:30","tasks":[],"value":"01:30","day":152245,"scroll":"o_5","slotDate":null},{"slot":"","tasks":[],"value":"01:30","day":1,"scroll":"o_5","slotDate":null},{"slot":"","tasks":[],"value":"01:30","day":2,"scroll":"o_5","slotDate":null},{"slot":"","tasks":[],"value":"01:30","day":3,"scroll":"o_5","slotDate":null},{"slot":"","tasks":[],"value":"01:30","day":4,"scroll":"o_5","slotDate":null},{"slot":"","tasks":[],"value":"01:30","day":5,"scroll":"o_5","slotDate":null},{"slot":"","tasks":[],"value":"01:30","day":6,"scroll":"o_5","slotDate":null},{"slot":"","tasks":[],"value":"01:30","day":0,"scroll":"o_5","slotDate":null},
    {"slot":"02:00","tasks":[],"value":"02:00","day":152245,"scroll":"o_6","slotDate":null},{"slot":"","tasks":[],"value":"02:00","day":1,"scroll":"o_6","slotDate":null},{"slot":"","tasks":[],"value":"02:00","day":2,"scroll":"o_6","slotDate":null},{"slot":"","tasks":[],"value":"02:00","day":3,"scroll":"o_6","slotDate":null},{"slot":"","tasks":[],"value":"02:00","day":4,"scroll":"o_6","slotDate":null},{"slot":"","tasks":[],"value":"02:00","day":5,"scroll":"o_6","slotDate":null},{"slot":"","tasks":[],"value":"02:00","day":6,"scroll":"o_6","slotDate":null},{"slot":"","tasks":[],"value":"02:00","day":0,"scroll":"o_6","slotDate":null},
    {"slot":"02:30","tasks":[],"value":"02:30","day":152245,"scroll":"o_7","slotDate":null},{"slot":"","tasks":[],"value":"02:30","day":1,"scroll":"o_7","slotDate":null},{"slot":"","tasks":[],"value":"02:30","day":2,"scroll":"o_7","slotDate":null},{"slot":"","tasks":[],"value":"02:30","day":3,"scroll":"o_7","slotDate":null},{"slot":"","tasks":[],"value":"02:30","day":4,"scroll":"o_7","slotDate":null},{"slot":"","tasks":[],"value":"02:30","day":5,"scroll":"o_7","slotDate":null},{"slot":"","tasks":[],"value":"02:30","day":6,"scroll":"o_7","slotDate":null},{"slot":"","tasks":[],"value":"02:30","day":0,"scroll":"o_7","slotDate":null},
    {"slot":"03:00","tasks":[],"value":"03:00","day":152245,"scroll":"o_8","slotDate":null},{"slot":"","tasks":[],"value":"03:00","day":1,"scroll":"o_8","slotDate":null},{"slot":"","tasks":[],"value":"03:00","day":2,"scroll":"o_8","slotDate":null},{"slot":"","tasks":[],"value":"03:00","day":3,"scroll":"o_8","slotDate":null},{"slot":"","tasks":[],"value":"03:00","day":4,"scroll":"o_8","slotDate":null},{"slot":"","tasks":[],"value":"03:00","day":5,"scroll":"o_8","slotDate":null},{"slot":"","tasks":[],"value":"03:00","day":6,"scroll":"o_8","slotDate":null},{"slot":"","tasks":[],"value":"03:00","day":0,"scroll":"o_8","slotDate":null},
    {"slot":"03:30","tasks":[],"value":"03:30","day":152245,"scroll":"o_9","slotDate":null},{"slot":"","tasks":[],"value":"03:30","day":1,"scroll":"o_9","slotDate":null},{"slot":"","tasks":[],"value":"03:30","day":2,"scroll":"o_9","slotDate":null},{"slot":"","tasks":[],"value":"03:30","day":3,"scroll":"o_9","slotDate":null},{"slot":"","tasks":[],"value":"03:30","day":4,"scroll":"o_9","slotDate":null},{"slot":"","tasks":[],"value":"03:30","day":5,"scroll":"o_9","slotDate":null},{"slot":"","tasks":[],"value":"03:30","day":6,"scroll":"o_9","slotDate":null},{"slot":"","tasks":[],"value":"03:30","day":0,"scroll":"o_9","slotDate":null},
    {"slot":"04:00","tasks":[],"value":"04:00","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"04:00","day":1,"scroll":"o_10","slotDate":null},{"slot":"","tasks":[],"value":"04:00","day":2,"scroll":"o_10","slotDate":null},{"slot":"","tasks":[],"value":"04:00","day":3,"scroll":"o_10","slotDate":null},{"slot":"","tasks":[],"value":"04:00","day":4,"scroll":"o_10","slotDate":null},{"slot":"","tasks":[],"value":"04:00","day":5,"scroll":"o_10","slotDate":null},{"slot":"","tasks":[],"value":"04:00","day":6,"scroll":"o_10","slotDate":null},{"slot":"","tasks":[],"value":"04:00","day":0,"scroll":"o_10","slotDate":null},
    {"slot":"04:30","tasks":[],"value":"04:30","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"04:30","day":1,"scroll":"o_11","slotDate":null},{"slot":"","tasks":[],"value":"04:30","day":2,"scroll":"o_11","slotDate":null},{"slot":"","tasks":[],"value":"04:30","day":3,"scroll":"o_11","slotDate":null},{"slot":"","tasks":[],"value":"04:30","day":4,"scroll":"o_11","slotDate":null},{"slot":"","tasks":[],"value":"04:30","day":5,"scroll":"o_11","slotDate":null},{"slot":"","tasks":[],"value":"04:30","day":6,"scroll":"o_11","slotDate":null},{"slot":"","tasks":[],"value":"04:30","day":0,"scroll":"o_11","slotDate":null},
    {"slot":"05:00","tasks":[],"value":"05:00","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"05:00","day":1,"scroll":"o_12","slotDate":null},{"slot":"","tasks":[],"value":"05:00","day":2,"scroll":"o_12","slotDate":null},{"slot":"","tasks":[],"value":"05:00","day":3,"scroll":"o_12","slotDate":null},{"slot":"","tasks":[],"value":"05:00","day":4,"scroll":"o_12","slotDate":null},{"slot":"","tasks":[],"value":"05:00","day":5,"scroll":"o_12","slotDate":null},{"slot":"","tasks":[],"value":"05:00","day":6,"scroll":"o_12","slotDate":null},{"slot":"","tasks":[],"value":"05:00","day":0,"scroll":"o_12","slotDate":null},
    {"slot":"05:30","tasks":[],"value":"05:30","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"05:30","day":1,"scroll":"o_13","slotDate":null},{"slot":"","tasks":[],"value":"05:30","day":2,"scroll":"o_13","slotDate":null},{"slot":"","tasks":[],"value":"05:30","day":3,"scroll":"o_13","slotDate":null},{"slot":"","tasks":[],"value":"05:30","day":4,"scroll":"o_13","slotDate":null},{"slot":"","tasks":[],"value":"05:30","day":5,"scroll":"o_13","slotDate":null},{"slot":"","tasks":[],"value":"05:30","day":6,"scroll":"o_13","slotDate":null},{"slot":"","tasks":[],"value":"05:30","day":0,"scroll":"o_13","slotDate":null},
    {"slot":"06:00","tasks":[],"value":"06:00","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"06:00","day":1,"scroll":"o_14","slotDate":null},{"slot":"","tasks":[],"value":"06:00","day":2,"scroll":"o_14","slotDate":null},{"slot":"","tasks":[],"value":"06:00","day":3,"scroll":"o_14","slotDate":null},{"slot":"","tasks":[],"value":"06:00","day":4,"scroll":"o_14","slotDate":null},{"slot":"","tasks":[],"value":"06:00","day":5,"scroll":"o_14","slotDate":null},{"slot":"","tasks":[],"value":"06:00","day":6,"scroll":"o_14","slotDate":null},{"slot":"","tasks":[],"value":"06:00","day":0,"scroll":"o_14","slotDate":null},
    {"slot":"06:30","tasks":[],"value":"06:30","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"06:30","day":1,"scroll":"o_15","slotDate":null},{"slot":"","tasks":[],"value":"06:30","day":2,"scroll":"o_15","slotDate":null},{"slot":"","tasks":[],"value":"06:30","day":3,"scroll":"o_15","slotDate":null},{"slot":"","tasks":[],"value":"06:30","day":4,"scroll":"o_15","slotDate":null},{"slot":"","tasks":[],"value":"06:30","day":5,"scroll":"o_15","slotDate":null},{"slot":"","tasks":[],"value":"06:30","day":6,"scroll":"o_15","slotDate":null},{"slot":"","tasks":[],"value":"06:30","day":0,"scroll":"o_15","slotDate":null},
    {"slot":"07:00","tasks":[],"value":"07:00","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"07:00","day":1,"scroll":"o_16","slotDate":null},{"slot":"","tasks":[],"value":"07:00","day":2,"scroll":"o_16","slotDate":null},{"slot":"","tasks":[],"value":"07:00","day":3,"scroll":"o_16","slotDate":null},{"slot":"","tasks":[],"value":"07:00","day":4,"scroll":"o_16","slotDate":null},{"slot":"","tasks":[],"value":"07:00","day":5,"scroll":"o_16","slotDate":null},{"slot":"","tasks":[],"value":"07:00","day":6,"scroll":"o_16","slotDate":null},{"slot":"","tasks":[],"value":"07:00","day":0,"scroll":"o_16","slotDate":null},
    {"slot":"07:30","tasks":[],"value":"07:30","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"07:30","day":1,"scroll":"o_17","slotDate":null},{"slot":"","tasks":[],"value":"07:30","day":2,"scroll":"o_17","slotDate":null},{"slot":"","tasks":[],"value":"07:30","day":3,"scroll":"o_17","slotDate":null},{"slot":"","tasks":[],"value":"07:30","day":4,"scroll":"o_17","slotDate":null},{"slot":"","tasks":[],"value":"07:30","day":5,"scroll":"o_17","slotDate":null},{"slot":"","tasks":[],"value":"07:30","day":6,"scroll":"o_17","slotDate":null},{"slot":"","tasks":[],"value":"07:30","day":0,"scroll":"o_17","slotDate":null},
    {"slot":"08:00","tasks":[],"value":"08:00","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"08:00","day":1,"scroll":"o_18","slotDate":null},{"slot":"","tasks":[],"value":"08:00","day":2,"scroll":"o_18","slotDate":null},{"slot":"","tasks":[],"value":"08:00","day":3,"scroll":"o_18","slotDate":null},{"slot":"","tasks":[],"value":"08:00","day":4,"scroll":"o_18","slotDate":null},{"slot":"","tasks":[],"value":"08:00","day":5,"scroll":"o_18","slotDate":null},{"slot":"","tasks":[],"value":"08:00","day":6,"scroll":"o_18","slotDate":null},{"slot":"","tasks":[],"value":"08:00","day":0,"scroll":"o_18","slotDate":null},
    {"slot":"08:30","tasks":[],"value":"08:30","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"08:30","day":1,"scroll":"o_19","slotDate":null},{"slot":"","tasks":[],"value":"08:30","day":2,"scroll":"o_19","slotDate":null},{"slot":"","tasks":[],"value":"08:30","day":3,"scroll":"o_19","slotDate":null},{"slot":"","tasks":[],"value":"08:30","day":4,"scroll":"o_19","slotDate":null},{"slot":"","tasks":[],"value":"08:30","day":5,"scroll":"o_19","slotDate":null},{"slot":"","tasks":[],"value":"08:30","day":6,"scroll":"o_19","slotDate":null},{"slot":"","tasks":[],"value":"08:30","day":0,"scroll":"o_19","slotDate":null},
    {"slot":"09:00","tasks":[],"value":"09:00","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"09:00","day":1,"scroll":"o_20","slotDate":null},{"slot":"","tasks":[],"value":"09:00","day":2,"scroll":"o_20","slotDate":null},{"slot":"","tasks":[],"value":"09:00","day":3,"scroll":"o_20","slotDate":null},{"slot":"","tasks":[],"value":"09:00","day":4,"scroll":"o_20","slotDate":null},{"slot":"","tasks":[],"value":"09:00","day":5,"scroll":"o_20","slotDate":null},{"slot":"","tasks":[],"value":"09:00","day":6,"scroll":"o_20","slotDate":null},{"slot":"","tasks":[],"value":"09:00","day":0,"scroll":"o_20","slotDate":null},
    {"slot":"09:30","tasks":[],"value":"09:30","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"09:30","day":1,"scroll":"o_21","slotDate":null},{"slot":"","tasks":[],"value":"09:30","day":2,"scroll":"o_21","slotDate":null},{"slot":"","tasks":[],"value":"09:30","day":3,"scroll":"o_21","slotDate":null},{"slot":"","tasks":[],"value":"09:30","day":4,"scroll":"o_21","slotDate":null},{"slot":"","tasks":[],"value":"09:30","day":5,"scroll":"o_21","slotDate":null},{"slot":"","tasks":[],"value":"09:30","day":6,"scroll":"o_21","slotDate":null},{"slot":"","tasks":[],"value":"09:30","day":0,"scroll":"o_21","slotDate":null},
    {"slot":"10:00","tasks":[],"value":"10:00","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"10:00","day":1,"scroll":"o_22","slotDate":null},{"slot":"","tasks":[],"value":"10:00","day":2,"scroll":"o_22","slotDate":null},{"slot":"","tasks":[],"value":"10:00","day":3,"scroll":"o_22","slotDate":null},{"slot":"","tasks":[],"value":"10:00","day":4,"scroll":"o_22","slotDate":null},{"slot":"","tasks":[],"value":"10:00","day":5,"scroll":"o_22","slotDate":null},{"slot":"","tasks":[],"value":"10:00","day":6,"scroll":"o_22","slotDate":null},{"slot":"","tasks":[],"value":"10:00","day":0,"scroll":"o_22","slotDate":null},
    {"slot":"10:30","tasks":[],"value":"10:30","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"10:30","day":1,"scroll":"o_23","slotDate":null},{"slot":"","tasks":[],"value":"10:30","day":2,"scroll":"o_23","slotDate":null},{"slot":"","tasks":[],"value":"10:30","day":3,"scroll":"o_23","slotDate":null},{"slot":"","tasks":[],"value":"10:30","day":4,"scroll":"o_23","slotDate":null},{"slot":"","tasks":[],"value":"10:30","day":5,"scroll":"o_23","slotDate":null},{"slot":"","tasks":[],"value":"10:30","day":6,"scroll":"o_23","slotDate":null},{"slot":"","tasks":[],"value":"10:30","day":0,"scroll":"o_23","slotDate":null},
    {"slot":"11:00","tasks":[],"value":"11:00","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"11:00","day":1,"scroll":"o_24","slotDate":null},{"slot":"","tasks":[],"value":"11:00","day":2,"scroll":"o_24","slotDate":null},{"slot":"","tasks":[],"value":"11:00","day":3,"scroll":"o_24","slotDate":null},{"slot":"","tasks":[],"value":"11:00","day":4,"scroll":"o_24","slotDate":null},{"slot":"","tasks":[],"value":"11:00","day":5,"scroll":"o_24","slotDate":null},{"slot":"","tasks":[],"value":"11:00","day":6,"scroll":"o_24","slotDate":null},{"slot":"","tasks":[],"value":"11:00","day":0,"scroll":"o_24","slotDate":null},
    {"slot":"11:30","tasks":[],"value":"11:30","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"11:30","day":1,"scroll":"o_25","slotDate":null},{"slot":"","tasks":[],"value":"11:30","day":2,"scroll":"o_25","slotDate":null},{"slot":"","tasks":[],"value":"11:30","day":3,"scroll":"o_25","slotDate":null},{"slot":"","tasks":[],"value":"11:30","day":4,"scroll":"o_25","slotDate":null},{"slot":"","tasks":[],"value":"11:30","day":5,"scroll":"o_25","slotDate":null},{"slot":"","tasks":[],"value":"11:30","day":6,"scroll":"o_25","slotDate":null},{"slot":"","tasks":[],"value":"11:30","day":0,"scroll":"o_25","slotDate":null},
    {"slot":"12:00","tasks":[],"value":"12:00","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"12:00","day":1,"scroll":"o_26","slotDate":null},{"slot":"","tasks":[],"value":"12:00","day":2,"scroll":"o_26","slotDate":null},{"slot":"","tasks":[],"value":"12:00","day":3,"scroll":"o_26","slotDate":null},{"slot":"","tasks":[],"value":"12:00","day":4,"scroll":"o_26","slotDate":null},{"slot":"","tasks":[],"value":"12:00","day":5,"scroll":"o_26","slotDate":null},{"slot":"","tasks":[],"value":"12:00","day":6,"scroll":"o_26","slotDate":null},{"slot":"","tasks":[],"value":"12:00","day":0,"scroll":"o_26","slotDate":null},
    {"slot":"12:30","tasks":[],"value":"12:30","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"12:30","day":1,"scroll":"o_27","slotDate":null},{"slot":"","tasks":[],"value":"12:30","day":2,"scroll":"o_27","slotDate":null},{"slot":"","tasks":[],"value":"12:30","day":3,"scroll":"o_27","slotDate":null},{"slot":"","tasks":[],"value":"12:30","day":4,"scroll":"o_27","slotDate":null},{"slot":"","tasks":[],"value":"12:30","day":5,"scroll":"o_27","slotDate":null},{"slot":"","tasks":[],"value":"12:30","day":6,"scroll":"o_27","slotDate":null},{"slot":"","tasks":[],"value":"12:30","day":0,"scroll":"o_27","slotDate":null},
    {"slot":"13:00","tasks":[],"value":"13:00","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"13:00","day":1,"scroll":"o_28","slotDate":null},{"slot":"","tasks":[],"value":"13:00","day":2,"scroll":"o_28","slotDate":null},{"slot":"","tasks":[],"value":"13:00","day":3,"scroll":"o_28","slotDate":null},{"slot":"","tasks":[],"value":"13:00","day":4,"scroll":"o_28","slotDate":null},{"slot":"","tasks":[],"value":"13:00","day":5,"scroll":"o_28","slotDate":null},{"slot":"","tasks":[],"value":"13:00","day":6,"scroll":"o_28","slotDate":null},{"slot":"","tasks":[],"value":"13:00","day":0,"scroll":"o_28","slotDate":null},
    {"slot":"13:30","tasks":[],"value":"13:30","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"13:30","day":1,"scroll":"o_29","slotDate":null},{"slot":"","tasks":[],"value":"13:30","day":2,"scroll":"o_29","slotDate":null},{"slot":"","tasks":[],"value":"13:30","day":3,"scroll":"o_29","slotDate":null},{"slot":"","tasks":[],"value":"13:30","day":4,"scroll":"o_29","slotDate":null},{"slot":"","tasks":[],"value":"13:30","day":5,"scroll":"o_29","slotDate":null},{"slot":"","tasks":[],"value":"13:30","day":6,"scroll":"o_29","slotDate":null},{"slot":"","tasks":[],"value":"13:30","day":0,"scroll":"o_29","slotDate":null},
    {"slot":"14:00","tasks":[],"value":"14:00","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"14:00","day":1,"scroll":"o_30","slotDate":null},{"slot":"","tasks":[],"value":"14:00","day":2,"scroll":"o_30","slotDate":null},{"slot":"","tasks":[],"value":"14:00","day":3,"scroll":"o_30","slotDate":null},{"slot":"","tasks":[],"value":"14:00","day":4,"scroll":"o_30","slotDate":null},{"slot":"","tasks":[],"value":"14:00","day":5,"scroll":"o_30","slotDate":null},{"slot":"","tasks":[],"value":"14:00","day":6,"scroll":"o_30","slotDate":null},{"slot":"","tasks":[],"value":"14:00","day":0,"scroll":"o_30","slotDate":null},
    {"slot":"14:30","tasks":[],"value":"14:30","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"14:30","day":1,"scroll":"o_31","slotDate":null},{"slot":"","tasks":[],"value":"14:30","day":2,"scroll":"o_31","slotDate":null},{"slot":"","tasks":[],"value":"14:30","day":3,"scroll":"o_31","slotDate":null},{"slot":"","tasks":[],"value":"14:30","day":4,"scroll":"o_31","slotDate":null},{"slot":"","tasks":[],"value":"14:30","day":5,"scroll":"o_31","slotDate":null},{"slot":"","tasks":[],"value":"14:30","day":6,"scroll":"o_31","slotDate":null},{"slot":"","tasks":[],"value":"14:30","day":0,"scroll":"o_31","slotDate":null},
    {"slot":"15:00","tasks":[],"value":"15:00","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"15:00","day":1,"scroll":"o_32","slotDate":null},{"slot":"","tasks":[],"value":"15:00","day":2,"scroll":"o_32","slotDate":null},{"slot":"","tasks":[],"value":"15:00","day":3,"scroll":"o_32","slotDate":null},{"slot":"","tasks":[],"value":"15:00","day":4,"scroll":"o_32","slotDate":null},{"slot":"","tasks":[],"value":"15:00","day":5,"scroll":"o_32","slotDate":null},{"slot":"","tasks":[],"value":"15:00","day":6,"scroll":"o_32","slotDate":null},{"slot":"","tasks":[],"value":"15:00","day":0,"scroll":"o_32","slotDate":null},
    {"slot":"15:30","tasks":[],"value":"15:30","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"15:30","day":1,"scroll":"o_33","slotDate":null},{"slot":"","tasks":[],"value":"15:30","day":2,"scroll":"o_33","slotDate":null},{"slot":"","tasks":[],"value":"15:30","day":3,"scroll":"o_33","slotDate":null},{"slot":"","tasks":[],"value":"15:30","day":4,"scroll":"o_33","slotDate":null},{"slot":"","tasks":[],"value":"15:30","day":5,"scroll":"o_33","slotDate":null},{"slot":"","tasks":[],"value":"15:30","day":6,"scroll":"o_33","slotDate":null},{"slot":"","tasks":[],"value":"15:30","day":0,"scroll":"o_33","slotDate":null},
    {"slot":"16:00","tasks":[],"value":"16:00","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"16:00","day":1,"scroll":"o_34","slotDate":null},{"slot":"","tasks":[],"value":"16:00","day":2,"scroll":"o_34","slotDate":null},{"slot":"","tasks":[],"value":"16:00","day":3,"scroll":"o_34","slotDate":null},{"slot":"","tasks":[],"value":"16:00","day":4,"scroll":"o_34","slotDate":null},{"slot":"","tasks":[],"value":"16:00","day":5,"scroll":"o_34","slotDate":null},{"slot":"","tasks":[],"value":"16:00","day":6,"scroll":"o_34","slotDate":null},{"slot":"","tasks":[],"value":"16:00","day":0,"scroll":"o_34","slotDate":null},
    {"slot":"16:30","tasks":[],"value":"16:30","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"16:30","day":1,"scroll":"o_35","slotDate":null},{"slot":"","tasks":[],"value":"16:30","day":2,"scroll":"o_35","slotDate":null},{"slot":"","tasks":[],"value":"16:30","day":3,"scroll":"o_35","slotDate":null},{"slot":"","tasks":[],"value":"16:30","day":4,"scroll":"o_35","slotDate":null},{"slot":"","tasks":[],"value":"16:30","day":5,"scroll":"o_35","slotDate":null},{"slot":"","tasks":[],"value":"16:30","day":6,"scroll":"o_35","slotDate":null},{"slot":"","tasks":[],"value":"16:30","day":0,"scroll":"o_35","slotDate":null},
    {"slot":"17:00","tasks":[],"value":"17:00","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"17:00","day":1,"scroll":"o_36","slotDate":null},{"slot":"","tasks":[],"value":"17:00","day":2,"scroll":"o_36","slotDate":null},{"slot":"","tasks":[],"value":"17:00","day":3,"scroll":"o_36","slotDate":null},{"slot":"","tasks":[],"value":"17:00","day":4,"scroll":"o_36","slotDate":null},{"slot":"","tasks":[],"value":"17:00","day":5,"scroll":"o_36","slotDate":null},{"slot":"","tasks":[],"value":"17:00","day":6,"scroll":"o_36","slotDate":null},{"slot":"","tasks":[],"value":"17:00","day":0,"scroll":"o_36","slotDate":null},
    {"slot":"17:30","tasks":[],"value":"17:30","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"17:30","day":1,"scroll":"o_37","slotDate":null},{"slot":"","tasks":[],"value":"17:30","day":2,"scroll":"o_37","slotDate":null},{"slot":"","tasks":[],"value":"17:30","day":3,"scroll":"o_37","slotDate":null},{"slot":"","tasks":[],"value":"17:30","day":4,"scroll":"o_37","slotDate":null},{"slot":"","tasks":[],"value":"17:30","day":5,"scroll":"o_37","slotDate":null},{"slot":"","tasks":[],"value":"17:30","day":6,"scroll":"o_37","slotDate":null},{"slot":"","tasks":[],"value":"17:30","day":0,"scroll":"o_37","slotDate":null},
    {"slot":"18:00","tasks":[],"value":"18:00","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"18:00","day":1,"scroll":"o_38","slotDate":null},{"slot":"","tasks":[],"value":"18:00","day":2,"scroll":"o_38","slotDate":null},{"slot":"","tasks":[],"value":"18:00","day":3,"scroll":"o_38","slotDate":null},{"slot":"","tasks":[],"value":"18:00","day":4,"scroll":"o_38","slotDate":null},{"slot":"","tasks":[],"value":"18:00","day":5,"scroll":"o_38","slotDate":null},{"slot":"","tasks":[],"value":"18:00","day":6,"scroll":"o_38","slotDate":null},{"slot":"","tasks":[],"value":"18:00","day":0,"scroll":"o_38","slotDate":null},
    {"slot":"18:30","tasks":[],"value":"18:30","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"18:30","day":1,"scroll":"o_39","slotDate":null},{"slot":"","tasks":[],"value":"18:30","day":2,"scroll":"o_39","slotDate":null},{"slot":"","tasks":[],"value":"18:30","day":3,"scroll":"o_39","slotDate":null},{"slot":"","tasks":[],"value":"18:30","day":4,"scroll":"o_39","slotDate":null},{"slot":"","tasks":[],"value":"18:30","day":5,"scroll":"o_39","slotDate":null},{"slot":"","tasks":[],"value":"18:30","day":6,"scroll":"o_39","slotDate":null},{"slot":"","tasks":[],"value":"18:30","day":0,"scroll":"o_39","slotDate":null},
    {"slot":"19:00","tasks":[],"value":"19:00","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"19:00","day":1,"scroll":"o_40","slotDate":null},{"slot":"","tasks":[],"value":"19:00","day":2,"scroll":"o_40","slotDate":null},{"slot":"","tasks":[],"value":"19:00","day":3,"scroll":"o_40","slotDate":null},{"slot":"","tasks":[],"value":"19:00","day":4,"scroll":"o_40","slotDate":null},{"slot":"","tasks":[],"value":"19:00","day":5,"scroll":"o_40","slotDate":null},{"slot":"","tasks":[],"value":"19:00","day":6,"scroll":"o_40","slotDate":null},{"slot":"","tasks":[],"value":"19:00","day":0,"scroll":"o_40","slotDate":null},
    {"slot":"19:30","tasks":[],"value":"19:30","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"19:30","day":1,"scroll":"o_41","slotDate":null},{"slot":"","tasks":[],"value":"19:30","day":2,"scroll":"o_41","slotDate":null},{"slot":"","tasks":[],"value":"19:30","day":3,"scroll":"o_41","slotDate":null},{"slot":"","tasks":[],"value":"19:30","day":4,"scroll":"o_41","slotDate":null},{"slot":"","tasks":[],"value":"19:30","day":5,"scroll":"o_41","slotDate":null},{"slot":"","tasks":[],"value":"19:30","day":6,"scroll":"o_41","slotDate":null},{"slot":"","tasks":[],"value":"19:30","day":0,"scroll":"o_41","slotDate":null},
    {"slot":"20:00","tasks":[],"value":"20:00","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"20:00","day":1,"scroll":"o_42","slotDate":null},{"slot":"","tasks":[],"value":"20:00","day":2,"scroll":"o_42","slotDate":null},{"slot":"","tasks":[],"value":"20:00","day":3,"scroll":"o_42","slotDate":null},{"slot":"","tasks":[],"value":"20:00","day":4,"scroll":"o_42","slotDate":null},{"slot":"","tasks":[],"value":"20:00","day":5,"scroll":"o_42","slotDate":null},{"slot":"","tasks":[],"value":"20:00","day":6,"scroll":"o_42","slotDate":null},{"slot":"","tasks":[],"value":"20:00","day":0,"scroll":"o_42","slotDate":null},
    {"slot":"20:30","tasks":[],"value":"20:30","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"20:30","day":1,"scroll":"o_43","slotDate":null},{"slot":"","tasks":[],"value":"20:30","day":2,"scroll":"o_43","slotDate":null},{"slot":"","tasks":[],"value":"20:30","day":3,"scroll":"o_43","slotDate":null},{"slot":"","tasks":[],"value":"20:30","day":4,"scroll":"o_43","slotDate":null},{"slot":"","tasks":[],"value":"20:30","day":5,"scroll":"o_43","slotDate":null},{"slot":"","tasks":[],"value":"20:30","day":6,"scroll":"o_43","slotDate":null},{"slot":"","tasks":[],"value":"20:30","day":0,"scroll":"o_43","slotDate":null},
    {"slot":"21:00","tasks":[],"value":"21:00","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"21:00","day":1,"scroll":"o_44","slotDate":null},{"slot":"","tasks":[],"value":"21:00","day":2,"scroll":"o_44","slotDate":null},{"slot":"","tasks":[],"value":"21:00","day":3,"scroll":"o_44","slotDate":null},{"slot":"","tasks":[],"value":"21:00","day":4,"scroll":"o_44","slotDate":null},{"slot":"","tasks":[],"value":"21:00","day":5,"scroll":"o_44","slotDate":null},{"slot":"","tasks":[],"value":"21:00","day":6,"scroll":"o_44","slotDate":null},{"slot":"","tasks":[],"value":"21:00","day":0,"scroll":"o_44","slotDate":null},
    {"slot":"21:30","tasks":[],"value":"21:30","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"21:30","day":1,"scroll":"o_45","slotDate":null},{"slot":"","tasks":[],"value":"21:30","day":2,"scroll":"o_45","slotDate":null},{"slot":"","tasks":[],"value":"21:30","day":3,"scroll":"o_45","slotDate":null},{"slot":"","tasks":[],"value":"21:30","day":4,"scroll":"o_45","slotDate":null},{"slot":"","tasks":[],"value":"21:30","day":5,"scroll":"o_45","slotDate":null},{"slot":"","tasks":[],"value":"21:30","day":6,"scroll":"o_45","slotDate":null},{"slot":"","tasks":[],"value":"21:30","day":0,"scroll":"o_45","slotDate":null},
    {"slot":"22:00","tasks":[],"value":"22:00","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"22:00","day":1,"scroll":"o_46","slotDate":null},{"slot":"","tasks":[],"value":"22:00","day":2,"scroll":"o_46","slotDate":null},{"slot":"","tasks":[],"value":"22:00","day":3,"scroll":"o_46","slotDate":null},{"slot":"","tasks":[],"value":"22:00","day":4,"scroll":"o_46","slotDate":null},{"slot":"","tasks":[],"value":"22:00","day":5,"scroll":"o_46","slotDate":null},{"slot":"","tasks":[],"value":"22:00","day":6,"scroll":"o_46","slotDate":null},{"slot":"","tasks":[],"value":"22:00","day":0,"scroll":"o_46","slotDate":null},
    {"slot":"22:30","tasks":[],"value":"22:30","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"22:30","day":1,"scroll":"o_47","slotDate":null},{"slot":"","tasks":[],"value":"22:30","day":2,"scroll":"o_47","slotDate":null},{"slot":"","tasks":[],"value":"22:30","day":3,"scroll":"o_47","slotDate":null},{"slot":"","tasks":[],"value":"22:30","day":4,"scroll":"o_47","slotDate":null},{"slot":"","tasks":[],"value":"22:30","day":5,"scroll":"o_47","slotDate":null},{"slot":"","tasks":[],"value":"22:30","day":6,"scroll":"o_47","slotDate":null},{"slot":"","tasks":[],"value":"22:30","day":0,"scroll":"o_47","slotDate":null},
    {"slot":"23:00","tasks":[],"value":"23:00","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"23:00","day":1,"scroll":"o_48","slotDate":null},{"slot":"","tasks":[],"value":"23:00","day":2,"scroll":"o_48","slotDate":null},{"slot":"","tasks":[],"value":"23:00","day":3,"scroll":"o_48","slotDate":null},{"slot":"","tasks":[],"value":"23:00","day":4,"scroll":"o_48","slotDate":null},{"slot":"","tasks":[],"value":"23:00","day":5,"scroll":"o_48","slotDate":null},{"slot":"","tasks":[],"value":"23:00","day":6,"scroll":"o_48","slotDate":null},{"slot":"","tasks":[],"value":"23:00","day":0,"scroll":"o_48","slotDate":null},
    {"slot":"23:30","tasks":[],"value":"23:30","day":152245,"scroll":"o_1","slotDate":null},{"slot":"","tasks":[],"value":"23:30","day":1,"scroll":"o_49","slotDate":null},{"slot":"","tasks":[],"value":"23:30","day":2,"scroll":"o_49","slotDate":null},{"slot":"","tasks":[],"value":"23:30","day":3,"scroll":"o_49","slotDate":null},{"slot":"","tasks":[],"value":"23:30","day":4,"scroll":"o_49","slotDate":null},{"slot":"","tasks":[],"value":"23:30","day":5,"scroll":"o_49","slotDate":null},{"slot":"","tasks":[],"value":"23:30","day":6,"scroll":"o_49","slotDate":null},{"slot":"","tasks":[],"value":"23:30","day":0,"scroll":"o_49","slotDate":null}
  ]

  buttonArray = buttonArray
  isLoading= false
  monday:Date  = new Date()
  sunday:Date  = new Date()
  taskArray:any = []
  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"

  currentId = uuid.v4()
  username:string = localStorage.getItem('userId')
  defaultProject:any

  constructor(private http:HttpClient,private el: ElementRef) {}
  ngOnInit(): void {
    this.isLoading = true
    this.getDefault()
    this.sunday = this.getSundayOfCurrentWeek()
    this.monday = this.startOfWeek()
    this.setDates()
    var date = new Date();
    date.setDate(this.sunday.getDate()+1)

    this.http.get(this.prod_api+"/tasks/range?start="+this.monday.toISOString()+"&end="+this.sunday.toISOString()+"&OwnerToken="+this.username).subscribe(data=>{
      this.taskArray = data
      //console.log(this.taskArray)
      //console.log(this.monday)
      this.populate()
      this.scrollToTime()
      this.isLoading = false
    })
  }

  populate(){
    this.taskArray.forEach((elem:any)=>{
      this.slots.forEach(e=>{
        if(new Date(elem.dueDate).getDay()==e.day && elem.timeString==e.value){
          e.tasks.push(elem)
          e.slotDate = new Date(elem.dueDate)
        }
      })
    })
    

  }

  setDates(){
    var monday = new Date()
    monday.setDate(this.monday.getDate()+1)
    var tuesday = new Date()
    tuesday.setDate(this.monday.getDate()+2)
    var wednesday = new Date()
    wednesday.setDate(this.monday.getDate()+3)
    var thursday = new Date()
    thursday.setDate(this.monday.getDate()+4)
    var friday = new Date()
    friday.setDate(this.monday.getDate()+5)
    var saturday = new Date()
    saturday.setDate(this.monday.getDate()+6)
    var sunday = this.sunday

    this.slots.forEach(elem=>{
      switch(elem.day){
        case 0:
          elem.slotDate = sunday
        break
        case 1:
          elem.slotDate = monday
        break
        case 2:
          elem.slotDate = tuesday
        break
        case 3:
          elem.slotDate = wednesday
        break
        case 4:
          elem.slotDate = thursday
        break
        case 5:
          elem.slotDate = friday
        break
        case 6:
          elem.slotDate = saturday
        break
        case 152245:
          elem.slotDate = new Date(saturday.getDate()+45)
        break
      }
    })
  }


  createProject()
  {
    this.isLoading = true
     const Project:project = {
       "id":localStorage.getItem("userId")||"",
       "subProject":"",
       "searchableID":uuid.v4(),
       "project_color":"",
       "owner":localStorage.getItem("userId")||"",
       "title":"Default Project",
       "description":"just a basic project to contain tasks added through the calendar but have no parents, tasks from here can always to moved at your earliest convience",
       "collaborators":[],
       "deadline":new Date(),
       "dateCreated":new Date(),
       "dateStart":new Date(),
       "archived":false,
       "deleted":false,
       "complete":false,
       "additionalArray":[],
       "additionalString":""
     }

     this.http.post(this.prod_api+"/projects/new",Project).subscribe(data=>{
         this.defaultProject = data
         this.isLoading = false
         //this.toastr.success("Successfully Created Project")
     },err=>{
       //console.log(err)
       this.isLoading = false
      // this.toastr.error("Failed to Created project")
     }) 
  }



getDefault(){
  this.isLoading = true
    this.http.get(this.prod_api+"/projects/personal/id?ProjectID="+this.username).subscribe(data=>{
      this.defaultProject = data
     // this.toastr.success("Successfully Got Project")
     this.isLoading = false
   },err=>{
    this.createProject()
    //console.log(err)
    this.isLoading = false
    //this.toastr.error("Failed to Get project")
   })
  }

 startOfWeek()
  {
    var date = new Date()
    var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 0);
    return new Date(date.setDate(diff)-1);
  }


 getSundayOfCurrentWeek() {
    const today = new Date();
    const first = today.getDate() - today.getDay() + 1;
    const last = first + 6;
    const sunday = new Date(today.setDate(last)+1);
    return sunday;
  }

  scrollToTime(){
    const d = new Date()
    const hour = d.getHours()
    //console.log(hour)

    switch(hour) { 
      case 0: { 
         //statements; 
         this.scroll("o_1")
         this.scroll("o_1")
         break; 
      } 
      case 1: { 
         //statements; 
         this.scroll("o_3")
         this.scroll("o_3")
         break; 
      } 
      case 2: { 
        //statements; 
        this.scroll("o_6")
        this.scroll("o_6")
        break; 
     }
     case 3: { 
      //statements; 
      this.scroll("o_8")
      this.scroll("o_8")
      break; 
     }
     case 4: { 
      //statements; 
      this.scroll("o_10")
      this.scroll("o_10")
      break; 
     }

    case 5: { 
      //statements; 
      this.scroll("o_12")
      this.scroll("o_12")
      break; 
    }
    case 6: { 
      //statements; 
      this.scroll("o_14")
      this.scroll("o_14")
      break; 
    }
    case 7: { 
      //statements; 
      this.scroll("o_16")
      this.scroll("o_16")
      break; 
    }
    case 8: { 
      //statements; 
      this.scroll("o_18")
      this.scroll("o_18")
      break; 
    }
    case 9: { 
      //statements; 
      this.scroll("o_20")
      this.scroll("o_20")
      break; 
    }
    case 10: { 
      //statements; 
      this.scroll("o_22")
      this.scroll("o_22")
      break; 
    }
    case 11: { 
      //statements; 
      this.scroll("o_24")
      this.scroll("o_24")
      break; 
    }
    case 12: { 
      //statements; 
      this.scroll("o_26")
      this.scroll("o_26")
      break; 
    }
    case 13: { 
      //statements; 
      this.scroll("o_28")
      this.scroll("o_28")
      break; 
    }
    case 14: { 
      //statements; 
      this.scroll("o_30")
      this.scroll("o_30")
      break; 
    }
    case 15: { 
      //statements; 
      this.scroll("o_32")
      this.scroll("o_32")
      break; 
    }
    case 16: { 
      //statements; 
      this.scroll("o_34")
      this.scroll("o_34")
      break; 
    }
    case 17: { 
      //statements; 
      this.scroll("o_36")
      this.scroll("o_36")
      break; 
    }
    case 18: { 
      //statements; 
      this.scroll("o_38")
      this.scroll("o_38")
      break; 
    }
    case 19: { 
      //statements; 
      this.scroll("o_40")
      this.scroll("o_40")
      break; 
    }
    case 20: { 
      //statements; 
      this.scroll("o_40")
      this.scroll("o_40")
      break; 
    }
    case 21: { 
      //statements; 
      this.scroll("o_42")
      this.scroll("o_42")
      break; 
    }
    case 22: { 
      //statements; 
      this.scroll("o_44")
      this.scroll("o_44")
      break; 
    }
    case 23: { 
      //statements; 
      this.scroll("o_48")
      this.scroll("o_48")
      break; 
    }
   } 

  }

  scroll(el_str:string) {
    //console.log(`scrolling to ${el_str}`);
    let el = document.getElementById(el_str);
    el.scrollIntoView({ block: 'start', behavior: 'smooth' });
    
}

}
