import { Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { CleanupCrewService } from 'src/app/services/cleanup_crew/cleanup-crew.service';


@Component({
  selector: 'app-daily-day-label',
  templateUrl: './daily-day-label.component.html',
  styleUrls: ['./daily-day-label.component.sass']
})
export class DailyDayLabelComponent implements OnInit {

  constructor(private cleanup:CleanupCrewService) { }

  @Input() day
  @Input() month

  @Output() selectDay:EventEmitter<any> = new EventEmitter()
  monthStr
  today = new Date()
  mark1:boolean = false
  mark2:boolean = false
  mark3:boolean = false
  mark4:boolean = false
  mark5:boolean = false
  mark6:boolean = false

  style = "day"
  @Input() taskArray
  @Input() blocks
  months=["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]

  ngOnInit(): void {
    this.monthStr = this.months[this.month]
    if(this.today.getUTCDate()==this.day){
      this.style="today"
      setTimeout(()=>{
        var el = document.getElementById("today")
        el.scrollIntoView({ block: 'center',inline:'center', behavior: 'smooth' })
      },600)

  }
    this.blocks.forEach(element => {

      if(element.day===this.day){
        //console.log(element)
        if(element.taskArray.length<1){
          this.mark1 = false
          this.mark2 = false
          this.mark3 = false
          this.mark4 = false
          this.mark5 = false
          this.mark6 = false
        }

        if(element.taskArray.length===1){
          this.mark1 = true
          this.mark2 = false
          this.mark3 = false
          this.mark4 = false
          this.mark5 = false
          this.mark6 = false
         }

         if(element.taskArray.length>=2){
          this.mark1 = true
          this.mark2 = false
          this.mark3 = false
          this.mark4 = false
          this.mark5 = false
          this.mark6 = false
         }

         if(element.taskArray.length>=3){
          this.mark1 = true
          this.mark2 = true
          this.mark3 = false
          this.mark4 = false
          this.mark5 = false
          this.mark6 = false
         }

         if(element.taskArray.length>=4){
          this.mark1 = true
          this.mark2 = true
          this.mark3 = true
          this.mark4 = false
          this.mark5 = false
          this.mark6 = false
         }

         if(element.taskArray.length<=6 && element.taskArray.length>4){
          this.mark1 = true
          this.mark2 = true
          this.mark3 = true
          this.mark4 = true
          this.mark5 = false
          this.mark6 = false
         }


        if(element.taskArray.length>6){
            this.mark1 = true
            this.mark2 = true
            this.mark3 = true
            this.mark4 = true
            this.mark5 = true
            this.mark6 = true
        }









      }
    });

    this.cleanup.day.subscribe(data=>{
        if(data != this.day){
            this.style = "day"
        }else{
          this.style = "today"
        }
    })

  }

  emitDay(){
    this.selectDay.emit(this.day)
    this.cleanup.updateBlocks(this.day)
  }

}
