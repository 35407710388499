import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import { project } from 'src/app/Dashboard/ProjectDashboard/dash-contianer/project';
import * as uuid from "uuid"
import { buttonArray } from './toolbar_buttons';

@Component({
  selector: 'app-overdue-container',
  templateUrl: './overdue-container.component.html',
  styleUrls: ['./overdue-container.component.sass']
})
export class OverdueContainerComponent implements OnInit {
  now: Date;
  firstDay: Date;
  lastDay: Date;
  currentId = uuid.v4()
  taskArray: any;
  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"
  username = localStorage.getItem("userId")
  defaultProject:any 
  buttonArray = buttonArray
  isLoading = false

  constructor(private http:HttpClient) { }
  

  ngOnInit(): void {
    this.getDefault()
    this.populate(new Date())
  }



  
  populate(currentDate:Date){
    this.isLoading = true
    this.now = currentDate
    this.firstDay = new Date(this.now.getFullYear(), this.now.getMonth()-12, 1);
    this.lastDay = new Date(this.now.getFullYear(), this.now.getMonth() + 1, 1);
    var tempArray

    this.http.get(this.prod_api+"/tasks/range?start="+this.firstDay.toISOString()+"&end="+this.lastDay.toISOString()+"&OwnerToken="+this.username).subscribe(data=>{
      tempArray = data
      tempArray.sort(function compare(a, b) {
        var dateA = new Date(a.dueDate);
        var dateB = new Date(b.dueDate);
        this.isLoading = false
        return dateA.valueOf() - dateB.valueOf();

      });
      //console.log(tempArray)
      this.taskArray = tempArray

      
    })

   
 
  }

  getDefault(){
    this.isLoading = true
    this.http.get(this.prod_api+"/projects/personal/id?ProjectID="+this.username).subscribe(data=>{
      this.defaultProject = data
      this.isLoading = false
     // this.toastr.success("Successfully Got Project")
   },err=>{
    this.isLoading = false
    this.createProject()
    //console.log(err)
    //this.toastr.error("Failed to Get project")
   })
  }


  createProject()
  {
  
    this.isLoading = true
     const Project:project = {
       "id":localStorage.getItem("userId")||"",
       "subProject":"",
       "searchableID":uuid.v4(),
       "project_color":"",
       "owner":localStorage.getItem("userId")||"",
       "title":"Default Project",
       "description":"just a basic project to contain tasks added through the calendar but have no parents, tasks from here can always to moved at your earliest convience",
       "collaborators":[],
       "deadline":new Date(),
       "dateCreated":new Date(),
       "dateStart":new Date(),
       "archived":false,
       "deleted":false,
       "complete":false,
       "additionalArray":[],
       "additionalString":""
     }

     this.http.post(this.prod_api+"/projects/new",Project).subscribe(data=>{
         this.defaultProject = data
         this.isLoading = false
         //this.toastr.success("Successfully Created Project")
     },err=>{
       //console.log(err)
       this.isLoading = false
      // this.toastr.error("Failed to Created project")
     }) 
  }

}
