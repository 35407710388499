// src\app\services\websocket.service.ts
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, Input } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { fromEvent, merge, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { TuiAlertService, TuiNotification } from "@taiga-ui/core";
import { io } from "socket.io-client";


@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  constructor(private http:HttpClient,@Inject(TuiAlertService) private readonly alertService: TuiAlertService) {
    this.socket = io("https://server.flowspaceproducitivity.com:5000", {
      'reconnection': true,
      'reconnectionDelay': 600000,
      'reconnectionDelayMax' : 600000,
      'reconnectionAttempts': 1
  }).connect().once('connect',()=>{
    if(this.socket.connected){
      this.showNotification("Connection Status", "Connected successfully to the flowspace server")
    }else{
      alert("socket not connected")
    }
  });

  
  }

  showNotification(title:string, content:string): void {
    this.alertService.open(content.replace("<br/>"," "), {label: title,status: TuiNotification.Success}).subscribe();
  }

  networkStatus: boolean = false;
  networkStatus$: Subscription = Subscription.EMPTY;


  private allmsg:any[] = [];
  private mess = new BehaviorSubject<any[]>([]);
  private newChatRooms = new BehaviorSubject<any[]>([]);
  private totalChats = new BehaviorSubject<number>(0);
  public chats:any[]=[]
  private user:any = {name:"arthur"}
  private username:string = localStorage.getItem("userId")
  private apiObject:any
  private totalNewChatCount:number = 0;

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"


  public roomId:string=""

  chatRooms = this.newChatRooms.asObservable()
  messages = this.mess.asObservable()
  bigChats = this.totalChats.asObservable()

  public socket;
  //socket = io("http://localhost:5000");
  
  


  initializeWebSocketConnection(user) {
    let audio = new Audio('https://drive.google.com/uc?export=download&id=1M95VOpto1cQ4FQHzNBaLf0WFQglrtWi7');
    //console.log("initializing socket connection:")
    //this.showNotification("Starting Up Flowspace","Connecting and testing")
    this.socket.emit("Join_Notifications",this.username)
    //console.log(this.socket.connected)
   

    this.socket.on("recieve_text",(message__:any)=>{
       if (typeof message__ === 'string'){
         //console.log(message__)
         var message = JSON.parse(message__)
         if(this.roomId != this.username){
           this.allmsg.push(message);
           this.mess.next(this.allmsg)
           this.allmsg = []
  
           if(message.roomId != this.roomId){
             this.totalNewChatCount++
             this.totalChats.next(this.totalNewChatCount)
           }
  
  
           audio.play();
            var string = message.content.toString().replace(/<br\s*\/?>/gi,' ')
            //console.log(string)
           //var string = "test string"
           //console.log(string)
           this.showNotification(message.from.toString(),string)
  
           this.http.post(this.prod_api+"/chatterbox/newMess",message).subscribe(data=>{
             //this.showNotification("Message Saved", "A New messaage was Added to the chat")
           },err=>{
             //console.log(err)
           })
        
        
       }else{
         if(message.from == this.username){
  
         }else{
           this.totalNewChatCount++
           this.totalChats.next(this.totalNewChatCount)
             this.allmsg.push(message);
             this.mess.next(this.allmsg)
             this.allmsg = []
         }
       }
       }else{
        //console.log(message__)
        var message = message__
        if(this.roomId != this.username){
          this.allmsg.push(message);
          this.mess.next(this.allmsg)
          this.allmsg = []
  
          if(message.roomId != this.roomId){
            this.totalNewChatCount++
            this.totalChats.next(this.totalNewChatCount)
          }
  
  
          audio.play();
            var string = message.content.toString().replace(/<br\s*\/?>/gi,' ')
            //console.log(string)
          //var string = "test string"
          this.showNotification(message.from.toString(),string)
  
          this.http.post(this.prod_api+"/chatterbox/newMess",message).subscribe(data=>{
            //this.showNotification("Message Saved", "A New messaage was Added to the chat")
          },err=>{
            //console.log(err)
          })
        
        
      }else{
        if(message.from == this.username){
  
        }else{
          this.totalNewChatCount++
          this.totalChats.next(this.totalNewChatCount)
            this.allmsg.push(message);
            this.mess.next(this.allmsg)
            this.allmsg = []
        }
      }
      }
  
    })

    this.socket.on("new_chat",(chatroom:any)=>{
      //console.log(chatroom)
        if(this.chats!=null && user!= null){
          //console.log("chats is not null")
          this.chats.push(chatroom)
          this.newChatRooms.next(this.chats)
          chatroom.participants.forEach((elem)=>{
            if(elem!=this.username){
              this.showNotification("New Chat Started",elem)
            }
          })
          
        }else if(user!= null){
          //console.log("chats is null")
          this.chats = []
          this.chats.push(chatroom)
          this.newChatRooms.next(this.chats)
          chatroom.participants.forEach((elem)=>{
            if(elem!=this.username){
              this.showNotification("New Chat Started",elem)
            }
          })
        }
      
    })

    this.socket.on("notification",(message:any)=>{
      this.showNotification("New Notifications",message.content.replace("<br/>",""))
    })

    this.socket.on("disconnect",()=>{
      this.socket = io("https://server.flowspaceproducitivity.com:5000");
      this.initializeWebSocketConnection(this.user)
    })
    this.socket.on("closed",()=>{
      this.socket = io("https://server.flowspaceproducitivity.com:5000");
      this.initializeWebSocketConnection(this.user)
    })


  }


      // To check internet connection stability
  checkNetworkStatus() {
        this.networkStatus = navigator.onLine;
        this.networkStatus$ = merge(
          of(null),
          fromEvent(window, 'online'),
          fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
            //console.log('status', status);
            this.networkStatus = status;
      });
   }




  updateUser(user){
    //console.log("updating user object")
    this.http.put(this.prod_api+"/users/update",user).subscribe(
      data=>{
        this.user = data
        //console.log(this.user)
      }, err=>{
        //console.log(err)
      });
  }

  updateRoomId(id:string){
    if(id!=null){
      //console.log("*********************"+this.roomId)
      this.roomId = id
      //console.log(this.username)
    }
  }

  updateUserChatContainerNoInit(data){
    if(this.chats.length==0){
      this.user=data
    }
  }
  
  updateUserChatContainer(data){
    if(this.chats.length==0){
      this.user=data
      this.http.get(this.prod_api+"/chatterbox/getChats?username="+this.user.username).subscribe((data:any)=>{
        
        data.forEach(element => {
          this.chats.push(element)
        });
        
        this.newChatRooms.next(this.chats)
      },err=>{
        //console.log(err)
      })
    }


    this.initializeWebSocketConnection(data)
  }
  
  sendMessage(message: any,room:any) {
    //console.log("socket connection status:" +this.socket.connected)
    //console.log(message)
    //console.log(message.content)
    //console.log(room.roomId)
    //console.log(room)
    if(!this.socket.connected){
      this.socket = io("https://server.flowspaceproducitivity.com:5000");
      this.socket.emit("Join_Notifications",this.username)
      this.initializeWebSocketConnection(this.user)
      if(this.socket.connected){
        room.participants.forEach(member=>{
          //console.log(member)
          this.socket.emit("send_text",message,member)
        })
         
        try{
          this.http.put(this.prod_api+"/chatterbox/updateChat?message="+message.content+"&roomId="+room.roomId,{}).subscribe(data=>{
            //console.log("message updated")
          },err=>{
            //console.log(err)
          })
        }catch{
            //console.log("found no such room")
        }
      }else{
        //alert("You are not connected to the internet, please try refreshing your browser")
      }
    }else{
      room.participants.forEach(member=>{
        //console.log(member)
        this.socket.emit("send_text",message,member)
    })
       
      try{
        this.http.put(this.prod_api+"/chatterbox/updateChat?message="+message.content+"&roomId="+room.roomId,{}).subscribe(data=>{
          //console.log("message updated")
        },err=>{
          //console.log(err)
        })
      }catch{
          //console.log("found no such room")
      }
    }
 
  }

  newChat(chatroom:any,message:any,recipient:any,sender:any){
    this.socket.emit("new_chat",chatroom,message,recipient,this.username)
    this.http.post(this.prod_api+"/chatterbox/newChat",chatroom).subscribe(data=>{
      //console.log("message updated")
      this.sendMessage(message,chatroom)
    },err=>{
      //console.log(err)
    })
    

  }

  newProject(chatroom:any){
    this.http.post(this.prod_api+"/chatterbox/newChat",chatroom).subscribe(data=>{
      //console.log("message updated")
    },err=>{
      //console.log(err)
    })

    

  }


}
