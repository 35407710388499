import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input,ChangeDetectionStrategy, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as uuid from "uuid"
import {TuiAlertService, TuiDialogContext, TuiDialogService, TuiDialogSize} from '@taiga-ui/core';
import {PolymorpheusContent} from '@tinkoff/ng-polymorpheus';

@Component({
  selector: 'app-collaborators-item',
  templateUrl: './collaborators-item.component.html',
  styleUrls: ['./collaborators-item.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollaboratorsItemComponent implements OnInit {

  dev_api ="http://localhost:443"
  prod_api ="https://server.flowspaceproducitivity.com"


  constructor(private http:HttpClient, private readonly dialogService: TuiDialogService) { }
  @Input() collabs:number=0 
  @Input() project:any 
  @Input() parent_project:any 
  collaborator: string="";
  @Input() messageService:any
  username = localStorage.getItem("userId")
  color ="#0000ff"
  testForm = new FormGroup({
    testValue: new FormControl(),
  });

  usersArray:any

items = [
  `Luke Skywalker`,
  `Leia Organa Solo`,
  `Darth Vader`,
  `Han Solo`,
  `Obi-Wan Kenobi`,
  `Yoda`,
];


  ngOnInit(): void {
    this.items = this.parent_project.collaborators

    this.http.get(this.prod_api+"/users/migrate").subscribe(data=>{
      this.usersArray = data
      ////console.log(this.usersArray)
    })
  }

  updateProject(){
    this.http.put(this.prod_api+"/tasks/update",this.project).subscribe(
        data=>{
          this.project = data
          //console.log(this.project)
        }, err=>{
          //console.log(err)
        });
  }

  addCollaborator(name:string){
    this.project.collaborators.push(name)
    const m = {
      roomId:this.parent_project.id,
      to:null,
      attachments:'',
      content: this.username+", added a collaborator",
      time: new Date().toTimeString().split(/\s+/)[0],
      from: this.username,
      date: new Date().toDateString(),
      type: "notification",
      id: uuid.v4()
    }
    this.messageService.sendMessage(m,{roomId:this.parent_project.id,participants:this.parent_project.collaborators});
    this.updateProject()
  }
  
  removeCollab(collab:string){
    var index = this.project.collaborators.indexOf(collab)
    this.project.collaborators.splice(index,1)
    //console.log()
    const m = {
      roomId:this.parent_project.id,
      to:null,
      attachments:'',
      content: this.username+", removed a collaborator",
      time: new Date().toTimeString().split(/\s+/)[0],
      from: this.username,
      date: new Date().toDateString(),
      type: "notification",
      id: uuid.v4()
    }
    this.messageService.sendMessage(m,{roomId:"",participants:[this.username]});
    this.updateProject()
  }


showDialogSm(
  content: PolymorpheusContent<TuiDialogContext>, 
  ): void {
 
  this.dialogService.open(content,{
    label: `Task Collaborators`,
    size:'m',
}).subscribe();
}

}
