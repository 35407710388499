<div id="container">
    <tui-loader
[showLoader]="isLoading"
[overlay]="true"
>
    <div id="top_content" >
    <div id="title">{{username}}'s Notebooks</div>
    <button id="flow-button" class="padder" (click)="createNotebook()">Add Notebook</button>
   
    <div id="notebooks">
        <div id="flexible" *ngFor="let book of notebookArray">
            <app-notebook-cover-tile [notebook]="book"></app-notebook-cover-tile>
        </div>
    </div>
</div>

<div id="shared">
    <div id="sub-title">Your Shared Notebooks</div>
    <div id="notebooks">
        <div id="flexible" *ngFor="let book of sharedNotebookArray">
            <app-notebook-cover-tile [title_type]="'title_shared'" [container_type]="'container_shared'" [notebook]="book"></app-notebook-cover-tile>
        </div>
    </div>
    <div id="shared_notes">
        <div id="sub-title">Your Shared Note's</div>
        <div ng *ngFor="let note of sharedNotesArray">
            <app-recent-note-tile [note]="note"></app-recent-note-tile>
        </div>
           
        
        
    </div>
</div>
</tui-loader>

</div>

