import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { project } from 'src/app/Dashboard/ProjectDashboard/dash-contianer/project';



const Project:project = {
  "id":"",
  "subProject":"",
  "searchableID":"",
  "owner":"Katleho.Sebiloane",
  "title":"Project Title",
  "description":"just a basic description of the project as a whole, this can contain links to notes and other parts of the project that really matter etc",
  "collaborators":["Kelebogile.Modise"],
  "deadline":new Date(),
  "dateCreated":new Date(),
  "dateStart":new Date(),
  "archived":false,
  "deleted":false,
  "complete":false,
  "additionalString":"",
  "additionalArray":[],
  "project_color":""
}

@Injectable({
  providedIn: 'root'
})

export class ProjectInfoServiceService {

  

  private info = new BehaviorSubject<project>(Project);
  
  projectData = this.info.asObservable();
  constructor() { }

  updateProject(message: any) {
    this.info.next(message)
    //console.log(this.info)
    }
}
